import React from 'react';
import { Redirect } from 'react-router-dom';
import { CITY_NAME } from '../../../Config';
import RootLngRoute from '../RootLngRoute';

function UpdateInfoRoute({ component: Component, ...rest }) {
  return (
    <RootLngRoute
      {...rest}
      render={props => {
        return CITY_NAME === 'sevastopol' || CITY_NAME === 'rostov' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}

export default UpdateInfoRoute;
