import { observable, action, toJS } from 'mobx';

import { FeedbackProvider } from './APIProvider';
import fetch from '../utils/fetch';
import userStore from './UserStore';
import i18n from 'i18next';

/*
 Message
 {
   date: number,
   text: string,
   out: [0, 1] - отправленое мне, или мной
 }

 Feedback
 {
  id: number,
  status: string,
  date: number,
  title: string,
 }

 ListItem
 {
  feedback: Feedback,
  lastMessage: Message
 }

 List
 {
   count: number,
   items: ListItem[]
 }
*/

class FeedbackStore {
  @observable
  feedbacks = {
    count: 0,
    status: fetch('pending'),
    items: [],
  };

  @observable
  messages = {};

  @observable
  errorMessages = {};

  @observable
  messageIsSending = {};

  setMessageIsSending = (feedbackId, state) => {
    this.messageIsSending = {
      ...this.messageIsSending,
      [feedbackId]: state,
    };
  };

  getMessageIsSending = (feedbackId) => {
    return this.messageIsSending[feedbackId];
  };

  @action
  async loadList() {
    if (!this.feedbacks.status.done || !this.feedbacks.status.isLoading) {
      this.feedbacks.status = fetch('loading');

      const feedbacks = await FeedbackProvider.loadShortFeedbackList();
      this.feedbacks.count = feedbacks.count;

      this.feedbacks.items = feedbacks.items
        ? feedbacks.items.map((feedback) => {
            return {
              ...feedback.appeal,
              lastMessage: feedback.lastMessage,
              statusColor: this.getFeedbackColor(feedback.status),
            };
          })
        : [];

      this.feedbacks.status = fetch('done');
    }
  }

  loadMessages = async (id, force) => {
    this.messages = {
      ...this.messages,
      [id]: {
        ...this.messages[id],
        count: 0,
        items: [],
        fetchStatus: fetch('loading'),
      },
    };

    const messages = await FeedbackProvider.loadMessages(id);

    let prevmessage = null;

    const normalMessages = messages.items
      ? messages.items.map((message) => {
          let normalMessage = null;
          if (message.out !== prevmessage) {
            normalMessage = {
              ...message,
              withTitle: true,
            };
          } else {
            normalMessage = {
              ...message,
              withTitle: false,
            };
          }

          prevmessage = message.out;

          return normalMessage;
        })
      : [];

    this.messages = {
      ...this.messages,
      [id]: {
        count: messages.count,
        items: normalMessages,
        fetchStatus: fetch('done'),
      },
    };

    return this.messages[id];
  };

  getErrorMessagesByFeedbackId = (id) => {
    return this.errorMessages[id] ? this.errorMessages[id] : [];
  };

  setLastMessageByFeedbackId = (id, message) => {
    this.feedbacks.items = this.feedbacks.items.map((feedback) => {
      if (feedback.id !== id) return feedback;
      return {
        ...feedback,
        lastMessage: {
          ...feedback.lastMessage,
          text: message,
        },
      };
    });
  };

  @action
  sendMessage = async (message, feedback, messageId = false) => {
    if (messageId === false) {
      const messagesByFeedback = this.messages[feedback.id];
      const currentMessages = messagesByFeedback
        ? this.messages[feedback.id].items
        : [];
      const currentCount = messagesByFeedback ? messagesByFeedback.count : 0;
      this.messages = {
        ...this.messages,
        [feedback.id]: {
          ...this.messages[feedback.id],
          fetchStatus: fetch('pending'),
          count: currentCount + 1,
          items: [
            ...currentMessages,
            {
              date: new Date(),
              text: message,
              out: 1,
              withTitle:
                currentMessages.length > 0
                  ? !currentMessages[currentMessages.length - 1].out
                  : true,
            },
          ],
        },
      };
    } else {
      this.errorMessages = {
        ...this.errorMessages,
        [feedback.id]: this.errorMessages[feedback.id].filter(
          (badMessageId) => badMessageId !== messageId
        ),
      };
    }

    this.setMessageIsSending(feedback.id, true);
    const response = await FeedbackProvider.sendMessageToFeedback(
      feedback.id,
      message
    );
    this.setMessageIsSending(feedback.id, false);

    const errorMessagesByFeedback = this.getErrorMessagesByFeedbackId(
      feedback.id
    );

    if (response.status !== 'ok') {
      this.errorMessages = {
        ...this.errorMessages,
        [feedback.id]: [
          ...errorMessagesByFeedback,
          messageId !== false
            ? messageId
            : this.messages[feedback.id].items.length - 1,
        ],
      };
    } else {
      this.setLastMessageByFeedbackId(feedback.id, message);
      this.errorMessages = {
        ...this.errorMessages,
        [feedback.id]: errorMessagesByFeedback.filter(
          (badMessageId) => badMessageId !== messageId
        ),
      };
    }

    return response;
  };

  getListShortStatus(type) {
    const status = this.feedbacks.status;
    if (status.hasOwnProperty(type)) {
      return status[type];
    }
  }

  async sendFeedback({ message, topic, phone, email }) {
    const response = await FeedbackProvider.sendFeedback({
      idSubject: topic,
      message,
      phone,
      email,
    });

    if (response.status === 'ok') {
      this.loadList();
    }

    return response;
  }

  normalizeFeedback(feedback) {
    if (feedback && Array.isArray(feedback))
      return feedback.map((feedbackItem) => ({
        status: feedbackItem.status,
        sourceDate: feedbackItem.sourceDate,
        replyDate: feedbackItem.replyDate,
        reply: feedbackItem.reply,
        source: feedbackItem.source,
        statusColor: this.getFeedbackColor(feedbackItem.status),
      }));

    return [];
  }

  getFeedbackColor(status) {
    switch (status) {
      case 'Зарегистрирован':
      case 'В работе':
      case 'Требует уточнения':
        return '#28a745';

      case 'Завершен':
        return '#6c757d';

      default:
        return 'black';
    }
  }
}

export default new FeedbackStore();
