import React, { PureComponent } from "react";

import { Button } from "reactstrap";
import FontAwesome from "@fortawesome/react-fontawesome";
import trashAlt from "@fortawesome/fontawesome-free-solid/faTrashAlt";
import checkAlt from "@fortawesome/fontawesome-free-solid/faCheck";
import timesAlt from "@fortawesome/fontawesome-free-solid/faTimes";
import pencilAlt from "@fortawesome/fontawesome-free-solid/faPencilAlt";

import PropTypes from "prop-types";

export default class EditableListItem extends PureComponent {
  constructor(props) {
    super(props);

    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;
  }

  renderButton(action, icon, isDisabled) {
    if (action) {
      return (
        <Button color="light" onClick={action} disabled={isDisabled}>
          <div className="translate">
            <FontAwesome icon={icon} />
          </div>
        </Button>
      );
    }
  }

  render() {
    const {
      children,
      onEditButtonClick,
      onRemoveButtonClick,
      onActivateButtonClick,
      onDeactivateButtonClick,
      item
    } = this.props;

    const active = item.active === undefined ? false : item.active;

    return (
      <div className="editable-item">
        <div className="item-content">{children}</div>

        <div className="buttons">
          {this.renderButton(onEditButtonClick, pencilAlt, false)}
          {this.renderButton(onRemoveButtonClick, trashAlt, false)}
          {this.renderButton(onActivateButtonClick, checkAlt, active)}
          {this.renderButton(onDeactivateButtonClick, timesAlt, !active)}
        </div>
      </div>
    );
  }

  static propTypes = {
    onEditButtonClick: PropTypes.func,
    onRemoveButtonClick: PropTypes.func,
    onSetMainClick: PropTypes.func
  };

  static defaultProps = {
    onEditButtonClick: null,
    onRemoveButtonClick: null,
    onSetMainClick: () => null
  };
}
