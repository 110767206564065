import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import ApiProvider from '../../../Stores/APIProvider';
import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';

import './style/index.css';

@translate(['parking'], { wait: true })
@observer
class ExportDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.xlsLink = '';
    this.pdfLink = '';
  }

  toggleDropDown = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  componentDidUpdate = () => {
    const filter = OperationsHistoryStore.getOptions();
    ['xls', 'pdf'].forEach(
      (type) =>
        (this[`${type}Link`] = ApiProvider.downloadOperationsWithFilter({
          ...filter,
          type,
        }))
    );
  };

  render() {
    const { isAllDataLoaded, t, disabled } = this.props;

    if (isAllDataLoaded) {
      const { isOpen } = this.state;

      return (
        <Fragment>
          <Dropdown
            className="export-operations"
            isOpen={isOpen}
            toggle={this.toggleDropDown}
          >
            <DropdownToggle
              disabled={disabled}
              className="export-operations__toggler"
              caret
            >
              {t('operations:export')}
            </DropdownToggle>
            <DropdownMenu className="export-operations__menu">
              <a href={this.xlsLink}>
                <DropdownItem>{t('operations:downloadXls')}</DropdownItem>
              </a>
              <a href={this.pdfLink}>
                <DropdownItem>{t('operations:downloadPdf')}</DropdownItem>
              </a>
            </DropdownMenu>
          </Dropdown>
        </Fragment>
      );
    }

    return null;
  }
}

export default ExportDropDown;
