import React from "react";
import { translate } from "react-i18next";
import { observer } from 'mobx-react';
import ReceiptFormView from '../View';
import SettingsStore from "../../../../../Stores/SettingsStore";
import userStore from "../../../../../Stores/UserStore";
import APIProvider from "../../../../../Stores/APIProvider/APIProvider";

@translate(['parking'], { wait: true })
@observer
class ReceiptFormContainer extends React.Component {
  constructor(props) {
    super(props);
    if (undefined === this.props.isGetReceipt) {
      this.state = {
        isGetReceipt: false,
        receiptnumber: 0,
        receiptType: this.props.receiptType,
      };
    } else {
      this.state = {
        isGetReceipt: true,
        receiptnumber: this.props.receiptnumber,
        receiptType: this.props.receiptType,
      };
    }
  }

  onAbort = () => {
    const { onAbort } = this.props;
    this.props.setReceiptMode({ isReceiptMode: false });
    onAbort();
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let result;
    if ('refill' == this.props.receiptReferer) {
      if (formData.get("type") == "1") {
        result = await APIProvider.createReceiptRefill2Individual({
          "firstName": formData.get("firstName"),
          "lastName": formData.get("lastName"),
          "middleName": formData.get("middleName"),
          "address": formData.get("address"),
          "typeDoc": formData.get("typeDoc"),
          "seriesDoc": formData.get("seriesDoc"),
          "numberDoc": formData.get("numberDoc"),
          "DateDoc": formData.get("DateDoc"),
          "issuer": formData.get("issuer"),
          "type": formData.get("type"),
          "sum": formData.get("sum")
        });
      } else if (formData.get("type") == "2") {
        result = await APIProvider.createReceiptRefill2Legal({
          "name": formData.get("name"),
          "ogrn": formData.get("ogrn"),
          "inn": formData.get("inn"),
          "kpp": formData.get("kpp"),
          "address": formData.get("address"),
          "type": formData.get("type"),
          "sum": formData.get("sum")
        });
      } else {
        result = null;
      }
      if (null !== result) {
        this.setState({
          receiptnumber: result.result.receiptnumber,
          isGetReceipt: true
        });
      }
    } else if ('subscription' == this.props.receiptReferer) {
      if (formData.get("type") == "1") {
        result = await APIProvider.createReceiptSubscription2Individual({
          "firstName": formData.get("firstName"),
          "lastName": formData.get("lastName"),
          "middleName": formData.get("middleName"),
          "address": formData.get("address"),
          "typeDoc": formData.get("typeDoc"),
          "seriesDoc": formData.get("seriesDoc"),
          "numberDoc": formData.get("numberDoc"),
          "DateDoc": formData.get("DateDoc"),
          "issuer": formData.get("issuer"),
          "type": formData.get("type"),
          "codeSubscription": formData.get("codeSubscription"),
          "grz": formData.get("grz"),
        });
      } else if (formData.get("type") == "2") {
        result = await APIProvider.createReceiptSubscription2Legal({
          "name": formData.get("name"),
          "ogrn": formData.get("ogrn"),
          "inn": formData.get("inn"),
          "kpp": formData.get("kpp"),
          "address": formData.get("address"),
          "type": formData.get("type"),
          "codeSubscription": formData.get("codeSubscription"),
          "grz": formData.get("grz"),
        });
      } else {
        result = null;
      }
      // console.log('>>> Subscribtion result:', result);
      if (null !== result && undefined !== result.result) {
        this.setState({
          receiptnumber: result.result.receiptnumber,
          isGetReceipt: true
        });
      }
    }
    if (formData.get('type') == '1') {
      this.setState({
        receiptType: 'individual',
      });
    } else {
      this.setState({
        receiptType: 'legal',
      });
    }
  }

  render() {
    // console.log(">>> Receipt container | props: ", this.props)
    return (
      <ReceiptFormView
        t={this.props.t}
        services={SettingsStore.settings.paymentsMethods}
        phones={userStore.getAllPhones()}
        onAbort={this.onAbort}
        amount={this.props.amount}
        onSubmit={this.handleFormSubmit}
        receiptnumber={this.state.receiptnumber}
        isGetReceipt={this.state.isGetReceipt}
        receiptType={this.state.receiptType}
        codeSubscription={this.props.codeSubscription}
        grz={this.props.grz}
      />
    );
  }
}

export default ReceiptFormContainer;