import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableMainPage = Loadable({
  loader: () => import('../MainPage/MainPage'),
  loading: Loader,
  delay: 400 // 0.3 seconds
});

export default LoadableMainPage;
