import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableMainMap = Loadable({
  loader: () => import('../../Components/Map/MainMap'),
  loading: Loader,
  delay: 500 // 0.3 seconds
});

export default LoadableMainMap;
