import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableRestorePass = Loadable({
  loader: () => import('../RestorePassword'),
  loading: Loader,
  delay: 400 // 0.3 seconds
});

export default LoadableRestorePass;
