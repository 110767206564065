import React from 'react';
import { observer } from 'mobx-react';
import ModalStore from '../../Stores/ModalStore';
import Dialog from '@material-ui/core/Dialog';
import modalList from './modalList';

@observer
class ModalManager extends React.Component {
  handleClose = (props) => {
    if (ModalStore.props.onClose) {
      ModalStore.props.onClose(props);
    }

    ModalStore.hideModal();
  };

  render() {
    const SpecifiedModal = modalList[ModalStore.type] || (() => null);

    return (
      <Dialog
        open={ModalStore.open}
        {...ModalStore.internalProps}
        onClose={this.handleClose}
      >
        <SpecifiedModal {...ModalStore.props} closeModal={this.handleClose} />
      </Dialog>
    );
  }
}

export default ModalManager;
