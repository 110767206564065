import React from 'react';

import { Redirect } from 'react-router-dom';
import UserStore from '../../../Stores/UserStore';
import RootLngRoute from '../RootLngRoute';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <RootLngRoute
    {...rest}
    render={props => {
      const isPublic = rest.public;
      const isRedirect = isPublic ? !UserStore.isLoggedIn() : UserStore.isLoggedIn();
      return isRedirect ? <Component {...props} /> : <Redirect to={'/'} />;
    }}
  />
);

export default PrivateRoute;
