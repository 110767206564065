import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import FieldPreLoader from '../../../Components/FieldPreLoader/index';
import { translate } from 'react-i18next';

import PeriodFilters from './PeriodFilters';
import OperationTypeFilters from './OperationTypeFilters';
import CarFilter from './CarFilter';
import ZoneFilter from './ZoneFilter';

@translate(['operationsHistory'], { wait: true })
@observer
export default class OperationsFilters extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    if (!this.props.isAllDataLoaded) {
      return (
        <Fragment>
          <FieldPreLoader visible style={{ width: 100, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ width: 250, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ width: 200, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ width: 150, marginBottom: 20 }} />

          <FieldPreLoader visible style={{ width: 100, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ width: 250, marginBottom: 20 }} />

          <FieldPreLoader visible style={{ width: 100, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ width: 250, marginBottom: 20 }} />

          <FieldPreLoader visible style={{ width: 200, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ width: 150, marginBottom: 10 }} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <PeriodFilters />
        <OperationTypeFilters />
        <CarFilter />
        <ZoneFilter />
      </Fragment>
    );
  }
}
