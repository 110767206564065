import CloudPaymentsStore from '../../Stores/CloudPaymentStore';

const en = {
  [CloudPaymentsStore.DESCRIPTION_TYPES.EXTENSION]: 'Parking extension',
  [CloudPaymentsStore.DESCRIPTION_TYPES.REFILL]: 'Top up your wallet balance',
  [CloudPaymentsStore.DESCRIPTION_TYPES.PAYMENT]: 'Parking fee'
};

const ru = {
  [CloudPaymentsStore.DESCRIPTION_TYPES.EXTENSION]: 'Продление парковки',
  [CloudPaymentsStore.DESCRIPTION_TYPES.REFILL]: 'Пополнение баланса кошелька',
  [CloudPaymentsStore.DESCRIPTION_TYPES.PAYMENT]: 'Оплата парковки'
};

export default {
  en,
  ru
};
