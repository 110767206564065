import React from 'react';
import ReactDOM from 'react-dom';

import SentryContainer from './Containers/Sentry';
import App from './App';
import ScrollToTop from './Containers/ScrollToTop';

import { BrowserRouter } from 'react-router-dom';
import SentryUtil from './utils/Sentry';

import registerServiceWorker from './registerServiceWorker';

import 'bootstrap/dist/js/bootstrap.js';

import '../src/Components/styles/main.css';

if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
  const sentry = new SentryUtil();
  console.log(process.env.REACT_APP_VERSION);//выводим в консоль номер версии чтобы знать какой билд на сервере
}

ReactDOM.render(
  <BrowserRouter>
    <SentryContainer>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </SentryContainer>
  </BrowserRouter>,
  document.getElementById('root')
);

//убрал, нужно доработать сервис воркер, а потом включать
//registerServiceWorker();
