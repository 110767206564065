import React, { Component } from 'react';

import Marquee from '../Common/Marquee';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import Link from '../../Containers/Links/LngLink';

import './style/index.css';

class RuningLine extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: true
    };
  }

  closeNotification = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { message, url } = this.props;
    const { isOpen } = this.state;

    if (message && isOpen) {
      return (
        <div className="notification">
          <div className="notification__message">
            <Link to={url} className="notification__url">
              <Marquee text={message} />
            </Link>
          </div>

          <div className="notification__close">
            <FontAwesomeIcon icon={faTimes} onClick={this.closeNotification} />
          </div>
        </div>
      );
    }

    return null;
  }
}

export default RuningLine;
