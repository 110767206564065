import React from 'react';
import qs from 'qs';

function withSearchUrl (Component) {
    return function (props) {

        const search = props.location.search;
        const parsedSearch = search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};

        return <Component { ...props } search = { parsedSearch }/>
    }
}

export default withSearchUrl;
