import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react/index';

import 'react-accessible-accordion/dist/fancy-example.css';
import './HeaderMenu.css';

import ControlGroup from '../ControlGroup';

import BalanceDropDown from './Balance';
import ParkingDropDown from './Parking';
import ProfileDropDown from './Profile';

import UserStore from '../../Stores/UserStore';

@observer
class HeaderMenu extends Component {
  render() {
    return (
      <Fragment>
        <div className="profile-controls__group">
          <ControlGroup isShadowOn={false}>
            <BalanceDropDown />

            <ParkingDropDown />

            <ProfileDropDown logout={this.props.logout} />
          </ControlGroup>
        </div>
      </Fragment>
    );
  }

  findBenefit() {
    return UserStore.getBenefits().findIndex(benefit => {
      return String(benefit.transport.numberTs).replace(/\s/g, '') === this.selectedTransport;
    });
  }
}

export default HeaderMenu;
