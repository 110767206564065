import React, { Component, createRef } from "react";

import Select from "../../MaterialDesign/SearchSelect";
import SettingsStore from "../../../Stores/SettingsStore";

class ParkingInput extends Component {
  constructor() {
    super();

    this.ref = createRef();
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.ref.current) {
      this.ref.current.input.current.setErrorState(
        !this.props.error,
        this.props.errorText
      );
    }
  }

  render() {
    return (
      <Select
        selectOptions={this.getSelectOptions()}
        inputOptions={this.getInputOptions()}
        onlySelect
        bcsClr
        ref={this.ref}
      />
    );
  }

  handleSelect = selected => {
    const { onSelect } = this.props;
    onSelect(selected);
  };

  getLabels = () => {
    const { parkings } = this.props;
    return parkings
      .map((item, index) => {
        const label = <div key={index}>{item.name}</div>;

        return {
          label,
          value: item.code || item.zone.zonenumber,
          payload: {
            zonenumber: item.zone.zonenumber,
            address: item.address
          }
        };
      })
      .sort((a, b) => {
        return String(a.value).localeCompare(String(b.value));
      });
  };

  getInputOptions = () => {
    return {
      name: "zone",
      id: "zone",
      label: SettingsStore.getZoneOrParkingLabel(),
      type: "text",
      onChange: this.handleSelect,
      error: this.props.error,
      errorText: this.props.error
    };
  };

  getSelectOptions = () => {
    return {
      label: "Парковки",
      options: this.getLabels(),
      onSelect: this.handleSelect,
      defaultValue: this.props.defaultValue || "",
      error: this.props.error,
      errorText: this.props.error
    };
  };

  reset = () => {
    this.ref.current.reset();
  };
}

export default ParkingInput;
