import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

const LngLink = ({ to, children, ...rest }) => {
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};
export default LngLink;
