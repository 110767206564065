import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContentEditable from '../../../../../Components/Common/ContentEditable';
import Button from 'reactstrap/lib/Button';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faPaperPlane from '@fortawesome/fontawesome-free-solid/faPaperPlane';

const sender = {
  flexShrink: 0,
  position: 'relative',
  display: 'flex',
  minHeight: '46px',
  maxWidth: '100%',
  padding: '4px',
  backgroundColor: '#f1f3f7',
  borderRadius: '3px',
};

const input = (disabled) => ({
  position: 'relative',
  flexGrow: '1',
  padding: '10px',
  lineHeight: '1.3',
  fontSize: '14px',
  fontWeight: 100,
  color: 'black',
  border: 'none',
  borderRadius: '3px',
  cursor: disabled ? 'not-allowed' : 'text',
});

const button = (disabled) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
});

class CommentsSender extends Component {
  static defaultProps = {
    onSend: () => {},
    placeholder: '',
  };

  static propTypes = {
    onSend: PropTypes.func,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.text = '';
    this.state = {
      active: false,
    };
  }

  clear = (input) => {
    input.innerText = '';
    this.setState({ active: false });
  };

  handleKeyPress = (keyPressEvent) => {
    if (keyPressEvent.key === 'Enter') {
      keyPressEvent.preventDefault();
      this.send();
    }
  };

  handleChange = (text) => {
    this.setState({ active: text.length > 0 });
  };

  send = () => {
    const { onSend } = this.props;
    const input = this.inputRef.current.input.current;
    const text = input.innerText.trim();

    if (this.state.active && text !== '') {
      onSend(text.trim());
      this.clear(input);
    }
  };

  render() {
    const { placeholder, disabled } = this.props;

    return (
      <div style={sender}>
        <ContentEditable
          disabled={disabled}
          style={input(disabled)}
          placeholder={placeholder}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChange}
          ref={this.inputRef}
        />
        <Button
          style={button(disabled)}
          onClick={this.send}
          disabled={disabled || !this.state.active}
          color="primary"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </div>
    );
  }
}

export default CommentsSender;
