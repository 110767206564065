import React, { Component } from "react";

import { getIn } from "formik";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class FormikSelect extends Component {
  handleChange = async e => {
    const {
      onChange,
      field: { name },
      form: { setFieldValue, setFieldTouched }
    } = this.props;

    e.stopPropagation();
    const value = e.target.value;
    await setFieldTouched(name, true, true);
    await setFieldValue(name, value, true);
    if (onChange) {
      onChange(e);
    }
  };

  render() {
    const {
      label,
      field: { name = null, value = "" },
      form: { touched, errors, isSubmitting },
      items,
      fullwidth,
      onChange,
      helperText = "",
      acceptEmpty = true,
      renderItems = null,
      ...rest
    } = this.props;

    const isFieldTouched = !!getIn(touched, name);
    const error = getIn(errors, name);

    const showError = !!error && isFieldTouched;

    return (
      <>
        <FormControl error={showError} fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            id={name}
            onChange={this.handleChange}
            value={value || ""}
            disabled={rest.disabled ? rest.disabled : isSubmitting}
            {...rest}
          >
            {renderItems
              ? renderItems(items)
              : items
              ? items.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              : null}
          </Select>
          {showError ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
      </>
    );
  }
}

export default FormikSelect;
