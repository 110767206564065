import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import NoMatch from './Components/NoMatch';

import PrivateRoute from './Containers/Routes/PrivateRouter';
import OnlyGuestRoute from './Containers/Routes/OnlyGuestRoute';
import UnregisterPaymentRoute from './Containers/Routes/UnregisterPaymentRoute';
import UpdateInfoRoute from './Containers/Routes/UpdateInfoRoute';

import {
  MainPage,
  News,
  NewsList,
  Page,
  Profile,
  Registration,
  RestorePass,
} from './Containers/Loadable';

import UnregisterPayment from './Containers/UnregisterPayment';
import { CheckWrapper } from './Containers/UnregisterPayment/Check';
import UpdatePage from './Components/UpdatePage';
import withSearch from './Components/Common/HOC/withSearchUrl';

import CloudPayments from './Containers/CloudPaymentContainer';
import PhonePaymentCallback from './Components/PhonePaymentCallback';
import SmsCommandsView from './Containers/SmsCommandsContainer';
import Route from './Containers/Routes/RootLngRoute';

const CloudPaymentWithSearch = withSearch(CloudPayments);

@observer
class Router extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          exact
          path={'/registration'}
          public
          component={Registration}
        />
        <Route
          exact
          path={'/:lng/registration'}
          public
          component={Registration}
        />
        <Route
          path={'/registration/confirmation/:step/:salt'}
          component={Registration}
        />
        <Route
          path={'/:lng/registration/confirmation/:step/:salt'}
          component={Registration}
        />
        <Route
          exact
          path={'/registration/confirmation/:step/:salt/:code'}
          component={Registration}
        />
        <Route
          exact
          path={'/:lng/registration/confirmation/:step/:salt/:code'}
          component={Registration}
        />

        <PrivateRoute path={'/profile'} component={Profile} />
        <PrivateRoute path={'/:lng/profile'} component={Profile} />

        <Route path={'/pages'} component={Page} />
        <Route path={'/:lng/pages'} component={Page} />

        <Route exact path={'/news'} component={NewsList} />
        <Route exact path={'/news/:id'} component={News} />
        <Route exact path={'/:lng/news'} component={NewsList} />
        <Route exact path={'/:lng/news/:id'} component={News} />
        <Route exact path={'/payment'} component={CloudPaymentWithSearch} />
        <Route
          exact
          path={'/:lng/payment'}
          component={CloudPaymentWithSearch}
        />
        <Route
          exact
          path={'/phone-payment-callback'}
          component={PhonePaymentCallback}
        />
        <Route
          exact
          path={'/:lng/phone-payment-callback'}
          component={PhonePaymentCallback}
        />

        <UpdateInfoRoute exact path={'/update'} component={UpdatePage} />
        <UpdateInfoRoute exact path={'/:lng/update'} component={UpdatePage} />

        <Route exact path={'/sms-commands'} component={SmsCommandsView} />
        <Route exact path={'/:lng/sms-commands'} component={SmsCommandsView} />

        <UnregisterPaymentRoute
          exact
          path={'/guest-payment'}
          component={UnregisterPayment}
        />
        <UnregisterPaymentRoute
          exact
          path={'/:lng/guest-payment'}
          component={UnregisterPayment}
        />

        <OnlyGuestRoute exact path={'/check'} component={CheckWrapper} />
        <OnlyGuestRoute exact path={'/:lng/check'} component={CheckWrapper} />

        <Route exact path={'/:lng/restore-password'} component={RestorePass} />
        <Route exact path={'/restore-password'} component={RestorePass} />
        <Route
          exact
          path={'/:lng/restore-password/:step'}
          component={RestorePass}
        />
        <Route exact path={'/restore-password/:step'} component={RestorePass} />
        <Route
          exact
          path={'/:lng/restore-password/:step/:salt'}
          component={RestorePass}
        />
        <Route
          exact
          path={'/restore-password/:step/:salt'}
          component={RestorePass}
        />

        <Route exact path={'/'} component={MainPage} />
        <Route exact path={'/:lng'} component={MainPage} />

        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default Router;
