import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

export default class ButtonSelect extends Component {
  handleClick = () => {
    const { typeIndex, categoryIndex } = this.props;

    this.props.change(typeIndex, categoryIndex);
  };

  render() {
    const { checked, bg, color, children } = this.props;
    const buttonClass = checked ? `filter__button  ${bg}` : 'filter__button btn-options-grey';

    return (
      <Button
        className={buttonClass}
        color={checked ? color || 'primary' : 'light'}
        onClick={this.handleClick}
      >
        {children}
      </Button>
    );
  }

  static propTypes = {
    checked: PropTypes.bool,

    typeIndex: PropTypes.number,
    categoryIndex: PropTypes.number,

    bg: PropTypes.string,
    color: PropTypes.string,

    change: PropTypes.func
  };

  static defaultProps = {
    checked: false,
    change: () => null
  };
}
