import React from 'react';

import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableProfile = Loadable.Map({
  loader: {
    Profile: () => import('../ProfileContainer')
  },
  render(loaded, props) {
    const Profile = loaded.Profile.default;

    return <Profile {...props} />;
  },
  loading: Loader,
  delay: 400 // 0.3 seconds
});

import ProfileContainer from '../ProfileContainer';
export default ProfileContainer;
