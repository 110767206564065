import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import UserStore from '../../../Stores/UserStore';
import { translate } from 'react-i18next';

import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';

@translate(['operationsHistory'], { wait: true })
@observer
export default class CarFilter extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="info-field">
        <div className="name">{t('operationsHistory:filters:labels:vehicleNumberLabel')}</div>
        <div className="value">
          <select className={'ts-select'} onChange={this._handleSelectTransport}>
            <option key="ts-all" value="all">
              {t('operationsHistory:operationType:all')}
            </option>
            {UserStore.getTs().map((transport, index) => {
              if (!transport) {
                return null;
              }
              return (
                <option key={'ts-' + index} value={transport.numberTs}>
                  {transport.numberTs}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  _handleSelectTransport = event => {
    try {
      OperationsHistoryStore.setFilters({ currentTransportFilter: event.target.value });
    } catch (e) {
      OperationsHistoryStore.setFilters({ currentTransportFilter: 'all' });
    }
  };
}
