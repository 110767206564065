import React, { Component } from "react";

import PropTypes from "prop-types";

export default class FieldPreLoader extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = { visible: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    this.forceUpdate();
  }

  render() {
    let { visible, className, delay, ...rest } = this.props;
    className =
      " " + (visible ? "visible" : "") + (!!className ? " " + className : "");

    return (
      <div
        className={`field-preloader${className}`}
        style={{ animationDelay: delay + "ms" }}
        {...rest}
      />
    );
  }

  static propTypes = {
    visible: PropTypes.bool,
    delay: PropTypes.number,
    className: PropTypes.string
  };

  static defaultProps = {
    visible: false,
    delay: 0,
    className: null
  };
}
