import React from 'react';
import SectionBlock from '../../../../Components/ProfileComponents/SectionBlock';
import UserStore from '../../../../Stores/UserStore';
import Feedback from '../FeedbackFrom';
import FeedbackList from '../Conversation';

import { observer } from 'mobx-react';
import { PageWrapper } from '../../../../Components/ProfileComponents/Common';
import translate from 'react-i18next/dist/commonjs/translate';

@translate(['feedback'], { wait: true })
@observer
class FeedbackPage extends React.Component {
  render() {
    return (
      <>
        <PageWrapper>
          <SectionBlock
            title={this.props.t('feedback:title')}
            isLoading={!UserStore.profile.done}
          >
            <Feedback />
          </SectionBlock>
        </PageWrapper>
        <PageWrapper>
          <SectionBlock
            title={'Ваши обращения'}
            isLoading={!UserStore.profile.done}
          >
            <FeedbackList />
          </SectionBlock>
        </PageWrapper>
      </>
    );
  }
}

export default FeedbackPage;
