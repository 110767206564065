import React from 'react';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import userStore from '../../../../../Stores/UserStore';
import SettingsStore from '../../../../../Stores/SettingsStore';
import PaymentStore from '../../../../../Stores/PaymentStore';

import RenewtView from '../View';

@translate(['parking'], { wait: true })
@observer
class RenewFormContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      toPaymentService: null,
      costData: null,
      moneyEnough: null,
    };
  }

  handleFormChangeWhenValid = async (values) => {
    const { transport, zone, parkingCode, reservation, timestart } = this.props;

    const costResponse = await PaymentStore.costOfSession({
      reservation,
      zone,
      transport: transport,
      duration: values.duration,
      parkingCode: parkingCode,
      timestart,
    });

    this.setState({
      moneyEnough: costResponse.moneyEnough,
      costData: costResponse.costData,
    });

    if (costResponse.status !== 'ok' || costResponse.moneyEnough) {
      return costResponse;
    }

    if (!costResponse.moneyEnough) {
      const response = await PaymentStore.payRenew({
        reservation,
        payment: values.service.methodName,
        duration: values.duration,
        phone: values.phone.phone,
        timestart,
      });

      if (response) {
        this.setState({
          toPaymentService: response.hasOwnProperty('RedirectURL')
            ? response.RedirectURL.replace(',', '.')
            : null,
        });
      }

      return response;
    }
  };

  onAbort = () => {
    const { onAbort } = this.props;

    onAbort();
  };

  handleSubmit = async (values) => {
    const { onSubmit, reservation, timestart } = this.props;

    const response = await PaymentStore.payRenew({
      reservation,
      payment: values.service.methodName,
      duration: values.duration,
      phone: values.phone.phone,
      timestart,
    });

    if (response.status !== 'ok') {
      return response;
    }

    onSubmit(values);
  };

  render() {
    const { transport, zone } = this.props;

    return (
      <RenewtView
        t={this.props.t}
        costData={this.state.costData}
        moneyEnough={this.state.moneyEnough}
        toPaymentService={this.state.toPaymentService}
        ts={transport}
        durations={SettingsStore.getTimeintervals()}
        zone={zone.name}
        services={SettingsStore.settings.paymentsMethods}
        phones={userStore.getAllPhones()}
        onChange={this.handleFormChangeWhenValid}
        onAbort={this.onAbort}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default RenewFormContainer;
