import { observable } from 'mobx';

class ReservationStore {
  @observable
  _data = null;

  FREE = 'бесплатно';

  get data() {
    return this._data;
  }

  set data(value) {
    this._data = value;
  }

  getWeekDay = date => {
    switch (date.getDay) {
      case 0:
        return 'Воскресенье';
      case 6:
        return 'Суббота';
      default:
        return 'Рабочий';
    }
  };

  fixTime = time => {
    return ('0' + time).slice(-2);
  };

  getTimesAndPrices = prices => {
    let timesAndPrices = {
      times: [],
      currentWorkTime: {},
      currentPrice: null
    };

    prices.forEach(item => {
      let timeStartDate = new Date(item.timestart);
      let timeStart =
        this.fixTime(timeStartDate.getHours()) + ':' + this.fixTime(timeStartDate.getMinutes());
      let timeEndDate = new Date(item.timeend);
      let timeEnd =
        this.fixTime(timeEndDate.getHours()) + ':' + this.fixTime(timeEndDate.getMinutes());

      let currentTime = new Date();
      let currentDay = this.getWeekDay(currentTime);

      currentTime = currentTime.getHours();

      timesAndPrices.times.push({
        timeStart: timeStart,
        timeEnd: timeEnd,
        price: item.price || this.FREE,
        day: item.day
      });

      if (
        timeStartDate.getHours() <= currentTime &&
        timeEndDate.getHours() >= currentTime &&
        currentDay === item.day
      ) {
        timesAndPrices.currentWorkTime = {
          timeStart: timeStart,
          timeEnd: timeEnd,
          day: currentDay
        };
        timesAndPrices.currentPrice = item.price || this.FREE;
      }
    });
    return timesAndPrices;
  };
}

export default new ReservationStore();
