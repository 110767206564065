import React from 'react';
import { Button } from 'reactstrap';

const style = {
  textDecoration: 'none',
  color: 'var(--bgMain)'
};

const LinkButton = props => {
  return (
    <Button color="link" style={style} {...props}>
      {props.children}
    </Button>
  );
};

export default LinkButton;
