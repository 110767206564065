import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class SentryContainer extends Component {
  componentDidCatch(error) {
    if (process.env.REACT_APP_ENVIRONMENT == 'PRODUCTION') {
      Sentry.captureException(error);
    }
  }

  render() {
    return this.props.children;
  }
}

export default SentryContainer;
