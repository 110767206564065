import React from 'react';
import Button from 'reactstrap/lib/Button';
import SettingsStore from '../../../../../Stores/SettingsStore';
import PaymentStore from '../../../../../Stores/PaymentStore';
import ModalStore from '../../../../../Stores/ModalStore';

const CancelDefferedSessionBtn = ({ t, session, ...rest }) => {
  const handlePayment = async values => {
    const result = await PaymentStore.cancelDefferedSession({ ...values });
    return result;
  };

  const handleCancelSession = () => {
    ModalStore.openModal('cancelSession', {
      onAccept: handlePayment,
      paymentMethods: SettingsStore.settings.paymentsMethods,
      orderId: session.id,
      title: 'Отмена сессии',
      message: 'Отменив сессию вы берете на себя всю ответственность',
      t
    });
  };

  return (
    <Button color="primary" outline onClick={handleCancelSession} {...rest}>
      {'Оплатить'}
    </Button>
  );
};

export default CancelDefferedSessionBtn;
