import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import MapStore from '../../../Stores/MapStore';
import config from '../../../Config/index';
import { translate } from 'react-i18next';

import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';

@translate(['operationsHistory'], { wait: true })
@observer
export default class ZoneFilter extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="info-field">
        <div className="name">{t('operationsHistory:filters:labels:zoneLabel')}</div>
        <div className="value">
          <select className={'zone-select'} onChange={this._handleSelectZone}>
            <option key="zone-all" value="all">
              {t('operationsHistory:operationType:all')}
            </option>
            {config.zone === 'sevastopol'
              ? MapStore.getParkingsWithoutFree().map((zone, index) => {
                  return (
                    <option key={'code-' + zone.code} value={zone.code}>
                      {zone.code}
                    </option>
                  );
                })
              : this.getZones().map((zone, index) => {
                  return (
                    <option key={'zone-' + index} value={zone}>
                      {zone}
                    </option>
                  );
                })}
          </select>
        </div>
      </div>
    );
  }

  getZones() {
    return MapStore.sortedZones;
  }

  _handleSelectZone = event => {
    try {
      OperationsHistoryStore.setFilters({ currentZoneFilter: event.target.value });
    } catch (e) {
      OperationsHistoryStore.setFilters({ currentZoneFilter: 'all' });
    }
  };
}
