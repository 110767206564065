import React from 'react';

function Description(props) {
  const { size, className } = props;

  const clN = size ? `update-page__description--${size}` : '';
  return (
    <div className={`update-page__description ${clN} ${className || ''}`}>{props.children}</div>
  );
}

export default Description;
