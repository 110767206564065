import React, { Component } from 'react';
import { Button } from 'reactstrap';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import signInAlt from '@fortawesome/fontawesome-free-solid/faSignInAlt';

class LoginButton extends Component {
  render() {
    return (
      <Button onClick={this.handleClick} color="primary" className="menu-login btn-small-round">
        <FontAwesomeIcon icon={signInAlt} />
        <span className="menu-button-label">{this.props.children}</span>
      </Button>
    );
  }

  handleClick = () => {
    this.props.onClick();
  };
}

export default LoginButton;
