import { observable, action } from 'mobx';
import APIProvider from '../APIProvider';
import { persist, create } from 'mobx-persist';
import moment from 'moment';

class NotificationStore {
  @observable
  status = {
    done: false,
    isLoading: false,
    error: false
  };

  @persist
  @observable
  closed = false;

  @observable
  notification = {
    title: '',
    message: ''
  };

  @persist
  @observable
  datechanged = null;

  @action
  loadNotification = async () => {
    if (!this.status.done) {
      this.status.loading = true;
      const response = await APIProvider.getNotification();

      if (response.status === 'ok') {
        this.normalizeNotification(response.result);
        this.status.done = true;
      } else {
        this.status.error = true;
      }
    }
  };

  normalizeNotification(notification) {
    if (notification && !notification.title && !notification.message) {
      this.closed = true;
      return;
    }

    if (notification) {
      this.notification.title = notification.title;
      this.notification.message = notification.message;
      if (this.datechanged === null || moment(this.datechanged).isBefore(notification.updated)) {
        this.closed = false;
      }
      this.datechanged = moment(notification.updated).format();
    }
  }

  set closed(isClosed) {
    this.closed = isClosed;
  }

  get notification() {
    return this.notification;
  }
}

const notificationStore = new NotificationStore();

export function persistance() {
  const hydrate = create({});
  const hydrateResult = hydrate('notification', notificationStore);
  const rehydrate = hydrateResult.rehydrate;
  rehydrate();
}

export default notificationStore;
