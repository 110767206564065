import React from 'react';
import Button from 'reactstrap/lib/Button';
import ModalStore from '../../../../../Stores/ModalStore';
import APIProvider from '../../../../../Stores/APIProvider';
import UserStore from '../../../../../Stores/UserStore';

const AbortSessionBtn = ({ t, session, ...rest }) => {
  const handlePayment = async () => {
    const result = await APIProvider.abortSession({ orderId: session.id });
    await UserStore.updateAll();
    return result;
  };

  const handleAbortlSession = () => {
    ModalStore.openModal('confirm', {
      onAccept: handlePayment,
      message: (
        <>
          <p>Парковка будет удалена и в дальнейшем ее нельзя будет оплатить.</p>
          <p>Вы действительно хотите отменить парковочную сессию ?</p>
        </>
      ),
      title: 'Отмена парковочной сессии'
    });
  };

  return (
    <Button color="primary" outline onClick={handleAbortlSession} {...rest}>
      Отменить
    </Button>
  );
};

export default AbortSessionBtn;
