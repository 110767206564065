import { observable, computed } from 'mobx';
import MapStore from './MapStore';
import Moment from 'moment';
import UserStore from './UserStore';
import Config from '../Config';

class ProfileParkingStore {
  @observable _show = false;
  @observable defaultZoneNumber = null;
  @observable parkingCode = null;
  @observable parkingType = null;

  HOUR = 60;
  initTimes = {
    step: 15,
    maxMinutes: 1440,
    minMinutes: 15
  };

  @computed
  get show() {
    return this._show;
  }

  set show(value) {
    this._show = value;
  }

  setDefaultZoneNumber(zone) {
    this.defaultZone = zone;
  }

  getDefaultZoneNumber() {
    return this.defaultZone || '';
  }

  set parkingCode(value) {
    this.parkingCode = value;
  }

  get parkingCode() {
    return this.parkingCode;
  }

  set parkingType(value) {
    this.parkingType = value;
  }

  get parkingType() {
    return this.parkingType;
  }

  @observable parkingTypes = [
    {
      type: 'road',
      name: 'Придорожная'
    },
    {
      type: 'barrier',
      name: 'Плоскостная со шлагбаумом'
    }
  ];

  /***
   * Высчитывает стоимость парковки на указанной зоне и ТС
   * @param {Moment|Date|string|number} startDate - Дата, с которой начинаем
   *   считать стоимость
   * @param {number|string} duration - Количество часов, с плавающей точкой или
   *   в формате HH:mm
   * @param {string|number} zoneName - Имя зоны
   * @param {string} carNumber - Регистрационный номер автомобиля
   * @public
   * @return {{price : number, discountAmount : number, discountPercentage :
   *   number, finalPrice : number, benefitsName : Array<string>}}
   */
  calculateParkingPrice({ startDate, duration, zoneName, carNumber }) {
    const result = {
      price: 0, // Цена без учета скидки
      discountAmount: 0, // Сумма скидки
      discountPercentage: 0, // Общий процент скидки
      finalPrice: 0, // Цена с учетом скидки
      benefitsName: [] // Названия всех применных льгот
    };

    // Берем выбранную зону. Если она не нашлась, то выходим
    const selectedZone = this._findZoneByName(zoneName);
    if (!selectedZone) {
      return result;
    }

    // Создаем объект Moment, потом в цикле будем добавлеть к нему часы
    const currentPeriodInterval = Moment(startDate);
    const parsedDuration = this._transformDuration(duration);

    if (isNaN(parsedDuration) || parsedDuration <= 0 || !parsedDuration) {
      return result;
    }
    // Бьем продолжительность на почасовые интервалы: 1.5 вернет [1, 0.5], 2 - венет [1, 1];
    const timeIntervals = this._getTimeIntervalsFromDuration(parsedDuration);

    // Использую Set для избежания дубликатов названий льгот.
    const benefitsName = new Set();
    // Начинаем считать стоимость для каждого интервала
    timeIntervals.forEach(interval => {
      // Берем стоимость за час
      const currentIntervalPrice = this._getCurrentIntervalPriceFromZone(
        selectedZone,
        currentPeriodInterval
      );
      // Высчитываем стоимость за интервал
      const price = interval * currentIntervalPrice;
      // Берем максимальную доступную льготу для текущего интервала
      const benefit = this._getMaximumBenefitForZone(zoneName, carNumber, currentPeriodInterval);

      // sale - процент скидки, type - строковое значение (название льготы)
      const { sale: discountPercentage, type: benefitName } = benefit;
      const discountAmount = (price * discountPercentage) / 100;
      const priceWithDiscount = price - discountAmount;

      result.price += price;
      result.discountAmount += discountAmount;
      result.finalPrice += priceWithDiscount;

      // Если есть льгота, то добавляем ее
      typeof benefitName !== 'undefined' && benefitsName.add(benefitName);

      // Добавляем текущий интервал (час или полчаса) к глобальному объекту
      currentPeriodInterval.add(interval, 'minutes');
    });

    // Высчитываем общую скидку (расчет на то, что могут быть применены разные льготы)
    // и округляем это значение до десятых (вход - 0.532, выход - 50.3)
    result.discountPercentage = Math.floor((result.discountAmount / result.price) * 1000) / 10;
    // Округляем все значения ниже до сотых
    result.discountAmount = Math.floor(result.discountAmount * 100) / 100;
    result.price = Math.floor(result.price * 100) / 100;
    result.finalPrice = Math.floor(result.finalPrice * 100) / 100;

    result.benefitsName = Array.from(benefitsName); // Set --> Array

    return result;
  }

  /***
   * Ищет зону с указанным номером
   * @param {number|string} zoneName - Номер зоны
   * @return {object|undefined}
   * @private
   */
  _findZoneByName(zoneName) {
    const zones = MapStore.getZones();

    return zones.find(zone => String(zone.name) === String(zoneName));
  }

  /***
   * Преобразовывает продолжительность в часы с плавающей запятой
   * @param {string|number} duration
   * @private
   */
  _transformDuration(duration) {
    if (typeof duration === 'number') {
      return duration;
    }

    if (String(duration).includes(':')) {
      const split = duration.split(':');
      const hours = parseInt(split[0], 10);
      const minutes = parseInt(split[1], 10);

      return hours + minutes / 60;
    }

    try {
      return parseInt(duration, 10);
    } catch (e) {
      return 0;
    }
  }

  /**
   * Разбивает продолжительность в часах на почасовые интервалы.
   * @param {number} duration - Продолжительность парковки в часах
   * @return {Array<number>}
   * @private
   */
  _getTimeIntervalsFromDuration(duration) {
    const periods = new Array(duration * 60).fill(1);

    return periods;
  }

  /**
   *
   * @returns {Array} - час разбитый на периоды
   * @private
   */
  _getBreakpoints() {
    const HOUR = 60;
    const minutes = Config.profile.parking.parkingPeriod;
    const intervalCounts = HOUR / minutes;
    const valueDivider = Math.floor(HOUR / minutes);

    let breakpoints = [];

    for (let i = 1; i <= intervalCounts; i++) {
      const calculatedValueNegative = i / valueDivider - 1;
      const calculatedValuePositive = i / valueDivider;

      breakpoints.push({ pos: calculatedValuePositive, neg: calculatedValueNegative });
    }

    return breakpoints;
  }

  /***
   * Берет стоимость парковки за час для текущей зоны с учетом расписания
   * @param {Object} zone - Объект зоны парковки
   * @param {Moment|Date|string|number} currentDate - дата
   * @return {number}
   * @private
   */
  _getCurrentIntervalPriceFromZone(zone, currentDate) {
    const { prices } = zone;
    const periodType = this._getCurrentDayType(currentDate);
    const currentInterval = Moment(currentDate);

    const foundPeriod = prices.find(period => {
      if (period.type !== periodType) {
        return false;
      }

      const startInterval = Moment(period.interval.from, 'HH:mm:ss');
      const endInterval = Moment(period.interval.to, 'HH:mm:ss');

      // Начало участка кода
      // Есть интервал (-ы), который граничится от 00:00 - 07:59
      // Соответственно это следующий день, если duration, например, 22 часа
      // А при конструкции Moment(period.interval.from, 'HH:mm:ss') - это текущий день
      const startTime = {
        hours: startInterval.get('hours'),
        minutes: startInterval.get('minutes'),
        seconds: startInterval.get('seconds')
      };

      const endTime = {
        hours: endInterval.get('hours'),
        minutes: endInterval.get('minutes'),
        seconds: endInterval.get('seconds')
      };

      const startOf = currentInterval.clone().set(startTime);
      const endOf = currentInterval.clone().set(endTime);
      // Конец участка кода

      return currentInterval.isBetween(startOf, endOf);
    });

    if (!!foundPeriod) {
      return foundPeriod.price / 60;
    }

    return 0;
  }

  /***
   * Возращает тип дня (default, sunday, saturday)
   * @param {Moment|Date|string|number} currentDate - дата
   * @return {string}
   * @private
   */
  _getCurrentDayType(currentDate) {
    const dayOfWeek = Moment(currentDate).format('e') * 1;

    if (dayOfWeek === 6) {
      return 'sunday';
    }

    if (dayOfWeek === 5) {
      return 'saturday';
    }

    return 'default';
  }

  /***
   * Возвращает максимально доступную льготу
   * @param {string|number} zoneName - Номер зоны
   * @param {string} carNumber - Регистрационный номер ТС
   * @param {Moment|Date|string|number} currentDate - дата
   * @return {*}
   * @private
   */
  _getMaximumBenefitForZone(zoneName, carNumber, currentDate) {
    const benefits = UserStore.getBenefits();
    const selectedZoneBenefits = benefits.filter(benefit => {
      return (
        (benefit.transport === '#' || String(benefit.transport).replace(/\s/g, '') === carNumber) &&
        (benefit.zone === '#' || String(benefit.zone) === zoneName) &&
        Moment(benefit.endOf, 'DD/MM/YYYY HH:mm').isAfter(currentDate) &&
        Moment(benefit.startOf, 'DD/MM/YYYY HH:mm').isBefore(currentDate)
      );
    });

    if (selectedZoneBenefits.length === 0) {
      return { name: undefined, sale: 0 };
    }

    return selectedZoneBenefits.reduce((max, benefit) => {
      return max.sale > benefit.sale ? max : benefit;
    });
  }

  /*
   * @param {integer} step
   * @param {integer} maxMinutes
   * @description  Отдает временные интервалы с заданным шагом и максимально заданным временем
   */
  timesWithInterval(
    step = this.initTimes.step,
    maxMinutes = this.initTimes.maxMinutes,
    minMinutes = this.initTimes.minMinutes
  ) {
    const HOUR = this.HOUR;
    const maxCounts = maxMinutes / step;
    const minCounts = minMinutes / step;

    let intervals = [];

    for (let i = minCounts; i <= maxCounts; i++) {
      //Количество минут на данном интервале
      const currentMinutes = i * step;
      //Количество часов на данном интервале
      const currentHour = Math.floor(currentMinutes / HOUR);
      //Количесвто минут в часе на данном интервале
      const currentMinutesInHour = currentMinutes % 60;
      //Строковое представление минут и часов на данном интервале
      let stringHour = currentHour;
      let stringMinutes = currentMinutesInHour;

      if (currentHour < 10) {
        stringHour = '0' + currentHour;
      }

      if (currentMinutesInHour < 10) {
        stringMinutes = '0' + currentMinutesInHour;
      }

      const label = stringHour + ':' + stringMinutes;
      const value = i * step;

      intervals.push({ label, value });
    }
    return intervals;
  }
}

export default new ProfileParkingStore();
