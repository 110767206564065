import React from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import './style.css';

const CloseButton = ({ onClose, color, className, ...rest }) => {
  return (
    <FontAwesomeIcon
      {...rest}
      className={`${className} close-btn`}
      color={color}
      icon={faTimes}
      onClick={onClose}
    />
  );
};

export default CloseButton;
