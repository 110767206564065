import { observable, action } from 'mobx';
import APIProvider from '../APIProvider';

class SmsCommandsStore {
  PENDING = 'pending';
  LOADING = 'loading';
  DONE = 'done';
  ERROR = 'error';

  @observable
  status = this.PENDING;

  @observable
  commands = [];

  @action
  loadCommands = async () => {
    if (this.status !== this.DONE) {
      this.status = this.LOADING;
      const commandsResponse = await APIProvider.getSmsCommands();

      if (commandsResponse.status === 'ok') {
        this.commands = commandsResponse.result;
        this.status = this.DONE;
      } else {
        this.status = this.ERROR;
      }
    }
  };
}

export default new SmsCommandsStore();
