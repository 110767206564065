class UrlManager {
  static isDifferentDomain(expiriencedPath) {
    let path = null;
    try {
      path = new URL(expiriencedPath);
    } catch {}
    return !!path && path.hostname !== window.location.hostname;
  }
}

export default UrlManager;
