import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Dropdown, DropdownToggle, Button } from 'reactstrap';

import DropdownMenuMaterial from '../../Common/DropdownMenuMaterial';

import { ControlChild } from '../../ControlGroup';
import Crumbs from '../../Header/Crumbs/Crumbs';

import RubleIcon from '../../icons/RubleSvg';

import { translate } from 'react-i18next';
import UIStore from '../../../Stores/UIStore';
import UserStore from '../../../Stores/UserStore';
import SettingsStore from '../../../Stores/SettingsStore';

@translate(['header'], { wait: true })
@observer
class Balance extends Component {
  render() {
    const { t } = this.props;
    const dropdowns = UIStore.dropdowns;
    const balance = UserStore.getBalance();

    const crumbsBalance = () => (balance !== -1 ? balance + 'р' : null);
    const textBalance = balance !== -1 ? balance + 'р' : 0;

    if (SettingsStore.settings.dontUseBalance) {
      return null;
    }

    return (
      <Dropdown isOpen={dropdowns.dropdownOpenBalance} toggle={this.toggleBalance}>
        <DropdownToggle className="reset-style-btn">
          <ControlChild>
            <div className="header__button" style={{ zIndex: 2 }}>
              <RubleIcon fill={'#123123'} />
            </div>

            <div className="header__button_title">{t('header:balance:balance')}</div>

            <Crumbs text={crumbsBalance} />
          </ControlChild>
        </DropdownToggle>

        <DropdownMenuMaterial className="balance">
          <span className="balance-popup__title">
            {t('header:balance:balance')}: {textBalance}
          </span>

          <Button
            className="center-btn drp-btn balance-popup__button w-100"
            onClick={this.handleClick}
            color="primary"
            size="sm"
          >
            {t('header:balance:refill')}
          </Button>
        </DropdownMenuMaterial>
      </Dropdown>
    );
  }

  /**
   * @description Открывает выпадающее меню баланса
   * */
  handleClick = () => {
    UIStore.openRefillModal();
    this.toggleBalance();
  };

  toggleBalance = () => {
    UIStore.toggleDropDown('Balance');
  };
}

export default Balance;
