import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import 'react-datepicker/dist/react-datepicker.min.css';

import moment from 'moment';
import config from '../../../Config/index';
import { translate } from 'react-i18next';
import TableRow from './TableRow';
import SettingsStore from '../../../Stores/SettingsStore';
import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';

@translate(['operationsHistory'], { wait: true })
@observer
export default class TableBody extends Component {
  @observable tableWidth = '1px';

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { t, operations, hasStatus } = this.props;
    const { PAYMENT, REFILL, RENEW, CANCEL } = OperationsHistoryStore.OPERATION_TYPES_FILTERS;

    return (
      <tbody>
        {operations.map((operation, index) => {
          const dateStart = moment(operation.startsOf).format('D MMMM HH:mm');
          const type = operation.type;
          const dateOperation = moment(operation.dateOperation).format('D MMMM HH:mm');
          let sum = operation.sum;
          let sumClassName = 'neutral';
          if (operation.type === PAYMENT || operation.type === RENEW) {
            sumClassName = 'negative';
          } else if (operation.type === REFILL) {
            sumClassName = 'positive';
          } else if (operation.type === CANCEL || operation.type === CANCEL) {
            sumClassName = 'positive';
          }

          if (Math.abs(sum) === 0) {
            sumClassName = 'neutral';
          }

          let transport =
            String(operation.transport).length !== 0
              ? String(operation.transport)
                  .replace(/\s/g, '')
                  .replace(/^([A-zА-я])(\d{3})([A-zА-я]{2})(\d{2,3})/i, '$1 $2 $3 $4')
                  .toUpperCase()
              : '—';

          const timeInterval = this._getTimeInterval(
            operation.startsOf,
            operation.endOf,
            operation.type,
            operation.dateOperation
          );

          // const status = this.getPaymentStatus(operation);
          const paymentStatus = operation.status
            ? t(`operationsHistory:paymentStatuses:${operation.status}`)
            : '-';

          const parkingLabel = t('operationsHistory:list:parking');
          const zoneLabel = t('operationsHistory:list:zone');
          const parking =
            SettingsStore.settings.paymentByParkingId && operation.parking ? (
              <span>
                # <span className={'zone-number'}>{operation.parking}</span>
              </span>
            ) : null;
          const zone = operation.zone ? (
            <span>
              # <span className={'zone-number'}>{operation.zone}</span>
            </span>
          ) : (
            ' —'
          );

          return (
            <TableRow
              index={index}
              operation={operation}
              dateStart={dateStart}
              dateOperation={dateOperation}
              sumClassName={sumClassName}
              sum={sum}
              key={index}
              zone={zone}
              paymentStatus={paymentStatus}
              transport={transport}
              zoneLabel={zoneLabel}
              parkingLabel={parkingLabel}
              parking={parking}
              timeInterval={timeInterval}
              type={type}
              hasStatus={hasStatus}
            />
          );
        })}
      </tbody>
    );
  }

  _getTimeInterval = (starts, ends, type, dateOperation) => {
    const { t } = this.props;
    if (type !== 'refill') {
      const from = moment(starts);
      const to = moment(ends);
      const diffDays = to
        .clone()
        .startOf('day')
        .diff(from.clone().startOf('day'), 'days');

      const fromText = t('operations:from');
      const toText = t('operations:to');

      if (diffDays === 0) {
        return (
          from.format('D MMMM') +
          ` ${fromText} ` +
          from.format('HH:mm') +
          ` ${toText} ` +
          to.format('HH:mm')
        );
      }

      if (diffDays === 1) {
        const diffHours = to.diff(from, 'hours');

        if (diffHours <= 5) {
          return (
            from.format('D MMMM') +
            ` ${fromText} ` +
            from.format('HH:mm') +
            ` ${toText} ` +
            to.format('HH:mm')
          );
        }
      }

      return (
        from.format('D MMMM') +
        ` ${fromText} ` +
        from.format('HH:mm') +
        ` ${toText} ` +
        to.format('D MMMM HH:mm')
      );
    }
    return moment(dateOperation).format('D MMMM HH:mm');
  };

  // getPaymentStatus(operation) {
  //   const paymentStatuses = OperationsHistoryStore.PAYMENTSTATUSES_MAP_TO_EN;
  //   return paymentStatuses[operation.status];
  // }
}
