import React from 'react';

function Title(props) {
  const { size, className } = props;
  let sizeClassName = '';

  switch (size) {
    case 'lg':
      sizeClassName = 'update-page__title--large';
      break;
    case 'big':
      sizeClassName = 'update-page__title--big';
      break;
    default:
      sizeClassName = 'update-page__title--sm';
  }

  return <h2 className={`update-page__title ${sizeClassName} ${className}`}>{props.children}</h2>;
}

export default Title;
