import { observable } from 'mobx';
// import { getPlatform } from '../utils/bowser';

class UIStore {
  @observable
  isFilterVisible = false;

  @observable
  dropdowns = {
    dropdownOpenBalance: false,
    dropdownOpenParking: false,
    dropdownOpenProfile: false,
    dropdownOpenLanguage: false
  };

  @observable
  isReservationVisible = false;

  @observable
  isSideMenuOpen = false;

  @observable
  loginModal = false;

  @observable
  simpleModal = false;

  @observable
  simpleModalText = null;

  @observable
  mapStyle = {};

  @observable
  menuOptions = [];

  @observable
  headerHeight = 0;

  @observable
  footerHeight = 0;

  @observable
  refillModal = false;

  @observable
  startWithoutRegModal = false;

  @observable editProfileInfoModalActiveStep = 'main';

  @observable editProfileInfoModalPhoneTypeAction = 'change';

  @observable
  modals = {
    editProfileInfoModalIsOpen: false,
    changeTransportModalIsOpen: false,
    changeDocumentModalIsOpen: false,
    paymentModalIsOpen: false,
    subscriptionModal: false
  };

  toggleEditProfileModal = () => {
    this.modals.editProfileInfoModalIsOpen = !this.modals.editProfileInfoModalIsOpen;
  };

  launchEditProfileModal = () => {
    this.modals.editProfileInfoModalIsOpen = true;
  };

  closeEditProfileModal = () => {
    this.modals.editProfileInfoModalIsOpen = false;
  };

  toggleChangeTransportModal = () => {
    this.modals.changeTransportModalIsOpen = !this.modals.changeTransportModalIsOpen;
  };

  launchChangeTransportModal = () => {
    this.modals.changeTransportModalIsOpen = true;
  };

  closeChangeTransportModal = () => {
    this.modals.changeTransportModalIsOpen = false;
  };

  toggleChangeDocumentModal = () => {
    this.modals.changeDocumentModalIsOpen = !this.modals.changeDocumentModalIsOpen;
  };

  launchChangeDocumentModal = () => {
    this.modals.changeDocumentModalIsOpen = true;
  };

  closeChangeDocumentModal = () => {
    this.modals.changeDocumentModalIsOpen = false;
  };

  togglePaymentModal = () => {
    this.modals.paymentModalIsOpen = !this.modals.paymentModalIsOpen;
  };

  launchPaymentModal = () => {
    this.modals.paymentModalIsOpen = true;
  };

  closePaymentModal = () => {
    this.modals.paymentModalIsOpen = false;
  };

  get filterVisibility() {
    return this.isFilterVisible;
  }

  get refillModal() {
    return this.refillModal;
  }

  set filterVisibility(value) {
    this.isFilterVisible = value;
  }

  get reservationVisibility() {
    return this.isReservationVisible;
  }

  set reservationVisibility(value) {
    this.isReservationVisible = value;
  }

  setHeaderHeight = headerHeight => {
    this.headerHeight = headerHeight;
  };

  setFooterHeight = footerHeight => {
    this.footerHeight = footerHeight;
  };

  toggleLoginModal = () => {
    this.loginModal = !this.loginModal;
  };

  toggleSimpleModal = () => {
    this.simpleModal = !this.simpleModal;
  };

  launchLoginModal = () => {
    this.loginModal = true;
  };

  closeLoginModal = () => {
    this.loginModal = false;
  };

  toggleVisibilityFilter = () => {
    this.isFilterVisible = !this.isFilterVisible;
  };

  toggleVisibilityReservation = () => {
    this.isReservationVisible = !this.isReservationVisible;
  };

  toggleSideMenu = () => {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  };

  openRefillModal = () => {
    this.refillModal = !this.refillModal;
  };

  closeRefillModal = () => {
    this.refillModal = false;
  };

  openModal = modal => {
    this[modal] = true;
  };

  toggleModal = modal => {
    this[modal] = !this[modal];
  };

  closeModal = modal => {
    this[modal] = false;
  };

  openSubscriptionModal = () => {
    this.modals.subscriptionModal = !this.subscriptionModal;
  };

  closeSubscriptionModal = () => {
    this.modals.subscriptionModal = false;
  };

  closeSideMenu = () => {
    this.isSideMenuOpen = false;
  };

  openSideMenu = () => {
    this.isSideMenuOpen = true;
  };

  computeMapStyle = () => {
    // dsiable calc header and footer
    const footerHeight = this.footerHeight;
    const headerHeight = 0;

    const html = document.documentElement;
    const siteHeight = Math.max(html.clientHeight || window.innerHeight || 0);

    let mapHeight = siteHeight - headerHeight - footerHeight;
    let marginBottom = -footerHeight;

    this.mapStyle = {
      height: `${mapHeight}px`,
      marginBottom: `${marginBottom}px`
    };
  };

  toggleDropDown = type => {
    this.dropdowns[`dropdownOpen${type}`] = !this.dropdowns[`dropdownOpen${type}`];
  };
}

export default new UIStore();
