import MainPage from './MainPage';
import Map from './Map';
import News from './News';
import NewsList from './NewsList';
import Page from './Page';
import Profile from './Profile';
import Registration from './Registration';
import RestorePass from './RestorePass';
import CoopProfile from './CoopProfile';

export {
    MainPage,
    Map,
    News,
    NewsList,
    Page,
    Profile,
    Registration,
    RestorePass,
    CoopProfile,
};
