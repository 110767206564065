import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

import HeaderMenu from '../../Components/HeaderMenu';

import UserStore from '../../Stores/UserStore';
import UIStore from '../../Stores/UIStore';
import ProfileStore from '../../Stores/ProfileStore';
import LoginStore from '../../Stores/LoginStore';

@translate(['header'], { wait: true })
class HeaderMenuContainer extends Component {
  render() {
    return (
      <HeaderMenu
        balance={UserStore.getBalance()}
        dropdowns={UIStore.dropdowns}
        openRefillModal={UIStore.openRefillModal}
        toggleDropDown={UIStore.toggleDropDown}
        lastSession={UserStore.getLastSession()}
        startTimeOfparking={UserStore.getStartTimeOfparkingProfile()}
        endTimeOfparking={UserStore.getEndTimeOfparkingProfile()}
        logout={this.logout}
        profileItems={ProfileStore.menuItems}
        updateProfile={UserStore.updateProfile}
        sessions={UserStore.getSessions()}
        history={this.props.history}
      />
    );
  }

  logout = () => {
    this.props.history.push('/');
    LoginStore.logOut();
  };
}

export default withRouter(HeaderMenuContainer);
