import React from 'react';

import Alert from 'reactstrap/lib/Alert';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import SimpleBar from 'simplebar-react';

import moment from 'moment';
import 'simplebar/dist/simplebar.min.css';

import TextBlock from './TextBlock';
import Loader from '../../../../../Components/Loader';
import Sender from './Sender';

import './style.css';

const MAX_WIDTH_OF_A_MESSAGE = '50%';

const truncateStyle = (width = '50%') => ({
  width,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const headerStatus = (color) => ({
  fontSize: 14,
  color,
});

const replyStyle = {
  marginLeft: 'auto',
  maxWidth: MAX_WIDTH_OF_A_MESSAGE,
};

const sendStyle = {
  marginRight: 'auto',
  maxWidth: MAX_WIDTH_OF_A_MESSAGE,
  marginBottm: 10,
};

const emptyTheme = {
  margin: '60px auto',
  textAlign: 'center',
};

const smile = {
  fontSize: 36,
};

const padding = '10px 20px';

const accordionBody = {
  padding: 0,
};
const scroll = {
  padding: padding,
  maxHeight: 600,
};

const senderContainer = {
  padding,
};

const AlertWrapper = {
  padding: 15,
  margin: '0 auto',
};

const ThemeBlock = ({
  feedback,
  goodMessages = [],
  errorMessages,
  messagesSending,
  onSend,
  onResend,
  onThemeClick,
  isLoading,
}) => {
  const scrollRef = React.useRef(null);
  const bodyRef = React.useRef(null);
  const messagesCount = React.useRef(goodMessages.length);
  const [sendedMessage, setSendedMessage] = React.useState('');

  const checkResponse = (response) => {
    if (response.status === 'ok') {
      setSendedMessage(response.userMessage);
    }
  };

  const handleSend = async (text, feedback) => {
    const response = await onSend(text, feedback);
    checkResponse(response);
    messagesCount.current = goodMessages.length;
  };

  const handleThemeClick = async () => {
    const accordionWrapper = bodyRef.current;
    const accordionBody = accordionWrapper.querySelector('.accordion__body');

    if (accordionBody.getAttribute('aria-hidden') === 'true') {
      const response = await onThemeClick(feedback);
      return response;
    }
  };

  const handleResend = async (messageId) => {
    const response = await onResend({
      messageId,
      feedback,
    });
    checkResponse(response);
  };

  const renderMessage = (isBad, message, id) => {
    const date = moment(message.date).format('DD.MM HH:mm');

    const commonProp = {
      id,
      key: message.date,
      text: message.text,
      date: date,
      error: isBad,
      onResend: handleResend,
    };
    return message.out ? (
      <TextBlock
        {...commonProp}
        style={replyStyle}
        title={message.withTitle ? 'Ваше сообщение' : null}
      />
    ) : (
      <TextBlock
        {...commonProp}
        style={sendStyle}
        title={message.withTitle ? 'Оператор' : null}
      />
    );
  };

  React.useEffect(() => {
    const isMessagesDifferent = goodMessages.length !== messagesCount.current;
    if (scrollRef.current && isMessagesDifferent) {
      scrollRef.current.contentWrapperEl.scrollTo({
        top: scrollRef.current.contentEl.offsetHeight,
        behavior: 'smooth',
      });
    }
  });

  if (feedback) {
    return (
      <div key={feedback.id} ref={bodyRef}>
        <Accordion>
          <AccordionItem className="accrodion-item accordion-item--hover-shodow">
            <div onClick={handleThemeClick}>
              <AccordionItemTitle
                className={'accordion-item-title accrodion-item-title--no-bg'}
              >
                <div className="conversation__header">
                  <div style={{ display: 'flex' }}>
                    <div
                      alt={feedback.subject}
                      className="conversation__truncate"
                      style={{ ...truncateStyle('100%'), marginRight: 'auto' }}
                    >
                      {feedback.subject}
                    </div>
                    <span
                      alt={feedback.status}
                      style={headerStatus(feedback.statusColor)}
                    >
                      {feedback.status}
                    </span>
                  </div>
                  <div
                    alt={feedback.lastMessage.text}
                    className="conversation__truncate conversation__last-message"
                    style={truncateStyle('100%')}
                  >
                    {feedback.lastMessage.text}
                  </div>
                </div>
              </AccordionItemTitle>
            </div>
            <AccordionItemBody style={accordionBody}>
              {sendedMessage ? (
                <div style={AlertWrapper}>
                  <Alert color="info ">{sendedMessage}</Alert>
                </div>
              ) : null}

              <SimpleBar style={scroll} ref={scrollRef}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {isLoading ? (
                    <div style={{ height: 170 }}>
                      <Loader />
                    </div>
                  ) : goodMessages && goodMessages.length ? (
                    <>
                      {goodMessages.map((message, id) =>
                        renderMessage(false, message, id)
                      )}
                      {errorMessages.map((message, id) =>
                        renderMessage(true, message, goodMessages.length + id)
                      )}
                    </>
                  ) : (
                    <div style={emptyTheme}>
                      В этой теме нет ни одного сообщения
                      <br />
                      <span style={smile}>🙂</span>
                    </div>
                  )}
                </div>
              </SimpleBar>
              <div style={senderContainer}>
                <Sender
                  placeholder="Ваше сообщение"
                  disabled={isLoading || messagesSending}
                  onSend={(text) => handleSend(text, feedback)}
                />
              </div>
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
  return null;
};

export default ThemeBlock;
