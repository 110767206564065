import React, { Component, Fragment } from "react";
import EditableList from "../EditableList";
import StateNumber from "../StateNumber";
import FieldPreLoader from "../../FieldPreLoader";
import SetPrimaryButton from "../../Common/Buttons/SetPrimaryButton";
import PropTypes from "prop-types";

import "./style.css";

export default class TransportList extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    this.forceUpdate();
  }

  handleMakePrimary = item => () => {
    const { onSetMainButtonClick } = this.props;

    onSetMainButtonClick({
      action: "edit",
      oldItem: item,
      newItem: { ...item, mainTs: true }
    });
  };

  render() {
    const {
      items,
      onEditButtonClick,
      onRemoveButtonClick,

      isLoading,
      translations
    } = this.props;

    if (isLoading) {
      return (
        <Fragment>
          <FieldPreLoader
            visible
            delay={500}
            style={{ height: 30, marginBottom: 10 }}
          />
          <FieldPreLoader
            visible
            delay={500}
            style={{ height: 30, marginBottom: 10 }}
          />
          <FieldPreLoader visible delay={500} style={{ height: 30 }} />
        </Fragment>
      );
    }

    if (!items || items.length === 0) {
      return <div className="text-secondary">{translations.emptyList}</div>;
    }
    return (
      <EditableList
        onEditButtonClick={onEditButtonClick}
        onRemoveButtonClick={onRemoveButtonClick}
        items={items}
        renderItem={this._renderItem}
        listGroupClassName="state-number-item"
      />
    );
  }

  _renderItem = item => {
    const { translations } = this.props;

    return (
      <div
        className={`info-section__item ${
          item.mainTs ? "info-section__item--main" : ""
        }`}
      >
        <div
          className="info-section__number-container"
          style={{ marginRight: "auto" }}
        >
          {item.name ? <div className="name">{item.name}</div> : null}

          <StateNumber
            number={item.number}
            country={item.country}
            region={item.region}
            formatGrz={item.formatGrz}
          />

          {!!item.STSNumber ? (
            <div className="state-number-info">
              {translations.sts}: {item.STSNumber}
            </div>
          ) : null}
        </div>
        {
          <SetPrimaryButton
            color="light"
            onClick={this.handleMakePrimary(item)}
            primary={item.mainTs}
            className="info-section__primary-btn"
          />
        }
      </div>
    );
  };

  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            name: PropTypes.string,
            number: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            regionNumber: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number
            ]),
            STSNumber: PropTypes.string
          }),
          PropTypes.shape({
            name: PropTypes.string,
            firstLetter: PropTypes.string.isRequired,
            secondNumbers: PropTypes.oneOfType([
              PropTypes.string.isRequired,
              PropTypes.number.isRequired
            ]),
            lastLetters: PropTypes.string.isRequired,
            regionNumber: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number
            ]),
            country: PropTypes.string.isRequired,
            STSNumber: PropTypes.string
          })
        ])
      )
    ]),
    onEditButtonClick: PropTypes.func,
    onRemoveButtonClick: PropTypes.func,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    items: [],
    isLoading: false,
    onEditButtonClick: () => null,
    onRemoveButtonClick: () => null
  };
}
