import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observable } from 'mobx';

import { Container, Col, Row } from 'reactstrap';

import '../../Assets/Styles/Profile/Compiled/payment.css';
import './style/index.css';

import Header from '../Common/Header';
import HeaderControllGroup from '../../Containers/HeaderMenuContainer';
import HeaderMenu from './HeaderMenu';
import ChangeLanguage from '../../Containers/ChangeLanguage';
import { ProfileButton, LoginButton, RegistrationButton } from '../Common/Buttons';
import SideMenuToggler from '../SideMenu/SideMenuToggler';
import StartWithoutRegistrationButton from '../../Containers/HeaderContainer/StartWithoutRegContainer';

import { translate } from 'react-i18next';
import { observer } from 'mobx-react';

import Payment from '../ProfileComponents/Payment';

import { HAS_TRANSLATIONS, CITY_NAME, USE_UPPER_LOGO } from '../../Config';

@translate(['header'], { wait: true })
@observer
class HeaderMain extends Component {
  @observable refillModalIsOpen = false;

  constructor() {
    super();
  }

  render() {
    const {
      isMenuLoading,
      menuFields,
      refillModalIsOpen,
      closeRefillModal,
      isLoggedIn,
      dropdowns,
      toggleDropDown,
      toggleLoginModal,
      t
    } = this.props;

    const LoginAndControllButtons = this.getLoginAndControllsButtons();
    const hasTranslation = HAS_TRANSLATIONS;

    const upperLogo = USE_UPPER_LOGO ? t('header:logoTitle:parkingSpace') : null;
    const underLogo = t(`header:logoTitle:${CITY_NAME}`);

    return (
      <Container fluid className="header-container">
        <Row>
          <Payment
            actionType="refill"
            isOpen={refillModalIsOpen}
            onSubmit={closeRefillModal}
            onExit={closeRefillModal}
          />
          <Header headerRef={this.header} underLogo={underLogo} upperLogo={upperLogo}>
            <Col className="login-btn justify-content-end" xs="9" sm="6" lg="4">
              <LoginAndControllButtons />
              <ProfileButton isLoggedIn={isLoggedIn} onClick={toggleLoginModal} />
              {hasTranslation ? (
                <ChangeLanguage
                  className={!isLoggedIn ? 'mg-l-25' : ''}
                  dropdowns={dropdowns}
                  toggleDropDown={toggleDropDown}
                />
              ) : null}
            </Col>

            <Col className="menu-small d-lg-none d-xs-flex" xs="1">
              <SideMenuToggler />
            </Col>
          </Header>
        </Row>
        {menuFields.length === 0 ? null : (
          <Row className="nav-header-row">
            <HeaderMenu isLoading={isMenuLoading} items={menuFields} />
          </Row>
        )}
      </Container>
    );
  }

  /**
   * @description Возвращаю функции вместо компонентов,
   *   чтобы их можно было использовать как реакт компонент в рендере
   */
  getLoginAndControllsButtons() {
    const { isLoggedIn, isProfileLoaded, toggleLoginModal, t } = this.props;

    if (isLoggedIn && isProfileLoaded) {
      return () => <HeaderControllGroup />;
    }

    return () => {
      return (
        <div className="header__right">
          <div className="reg-log-btn-container">
            <StartWithoutRegistrationButton>
              {t('operations:payWithout')}
              <br />
              {t('operations:registration')}
            </StartWithoutRegistrationButton>

            <RegistrationButton>{t('auth:registration')}</RegistrationButton>

            <LoginButton onClick={toggleLoginModal}>{t('auth:login')}</LoginButton>
          </div>
        </div>
      );
    };
  }
}

export default withRouter(HeaderMain);
