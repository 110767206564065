import React, { Component } from 'react';
import SideMenu from '../../Components/SideMenu/SideMenu';

import UIStore from '../../Stores/UIStore';
import LoginStore from '../../Stores/LoginStore';

import { withRouter } from 'react-router';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { translate } from 'react-i18next';

const SideMenuWithRouter = withRouter(SideMenu);
@translate(['footer'], { wait: true })
@observer
class SideMenuContainer extends Component {
  @observable UIStore;

  render() {
    const isOpen = UIStore.isSideMenuOpen;

    const container = UIStore.isSideMenuOpen ? (
      <div className={'side-menu-container'}>
        <SideMenuWithRouter
          isOpen={isOpen}
          closeMenu={this.closeMenu}
          logout={this.logout}
          {...this.props}
        />
        <div className="bg-fade" />
      </div>
    ) : null;
    return container;
  }

  closeMenu = () => {
    UIStore.closeSideMenu();
    document.body.style = 'overflowX: hidden';
    document.body.style = 'overflowY: auto';
  };

  logout = () => {
    LoginStore.logOut();
  };
}

export default SideMenuContainer;
