import React, { Component, Fragment } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Link from '../../Containers/Links/LngLink';

import UIStore from '../../Stores/UIStore';
import UserStore from '../../Stores/UserStore';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import apple from '@fortawesome/fontawesome-free-brands/faApple';
import android from '@fortawesome/fontawesome-free-brands/faAndroid';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import './style.css';
import OptionsStore from '../../Stores/OptionsStore';
import PathStore from '../../Stores/PathStore';

@observer
class SideMenu extends Component {
  @observable UIstore;

  componentDidMount() {
    this.setBodyPadding();
    this.initEvents();
  }

  componentWillUnmount() {
    this.setBodyPadding();
    this.removeEvents();
  }

  render() {
    let logoutButton = UserStore.isLoggedIn()
      ? this.renderLogoutButton()
      : null;

    const isOpen = UIStore.isSideMenuOpen;
    const menuStyle = {
      height: `${window.inerHeight}px`,
      overflow: isOpen ? 'auto' : 'hidden'
    };

    return (
      <div
        className={`menu-side ${isOpen ? 'open' : 'close'}`}
        style={menuStyle}
        ref={menu => {
          this.menu = menu;
        }}
      >
        <div className="menu-side__close" onClick={this.closeMenu}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="menu-side__sepor"></div>
        <div>
          <div className="menu-side-item">
            <Link to="/" onClick={this.closeMenu}>
              <span>{this.props.t('sideMenu:parkingMap')}</span>
            </Link>
          </div>
        </div>
        {this.renderMenu()}
        {logoutButton}
        {this.renderFooter()}
      </div>
    );
  }

  renderLogoutButton() {
    return (
      <div onClick={this.logoutClick}>
        <div className="menu-side-item">
          <span>{this.props.t('auth:logout')}</span>
        </div>
      </div>
    );
  }

  renderMenu() {
    return OptionsStore.done
      ? OptionsStore.mobileMenuItems.map((field, index) => {
          if (!UserStore.isLoggedIn()) {
            return field.title !== 'Регистрация' ? (
              <div key={index}>
                <div className="menu-side-item">
                  <Link to={field.path} onClick={this.closeMenu}>
                    <span>
                      {OptionsStore.getCurrentLocaleTitle(field.titles)}
                    </span>
                  </Link>
                </div>
              </div>
            ) : null;
          } else {
            return (
              <div key={index}>
                <div className="menu-side-item">
                  <Link to={field.path} onClick={this.closeMenu}>
                    <span>
                      {OptionsStore.getCurrentLocaleTitle(field.titles)}
                    </span>
                  </Link>
                </div>
              </div>
            );
          }
        })
      : null;
  }

  renderFooter() {
    const tel = <div className="side-phone">{this.renderPhoneNumber()}</div>;
    const app = (
      <Fragment>
        <div className="menu-side-aur-app">{this.props.t('footer:ourApp')}</div>
        <div className="side-apps inline-flex">
          <div>
            <a href={OptionsStore.appLinks.iosLink}>
              <button
                type="button"
                className="menu-login btn btn-dark btn-apps-side"
              >
                <FontAwesomeIcon icon={apple} />
                <span>{this.props.apps.iPhone}</span>
              </button>
            </a>
          </div>
          <div>
            <a href={OptionsStore.appLinks.androidLink}>
              <button
                type="button"
                className="menu-login btn btn-dark btn-apps-side"
              >
                <FontAwesomeIcon icon={android} />
                <span>{this.props.apps.android}</span>
              </button>
            </a>
          </div>
        </div>
        {OptionsStore.appLinks.yandexStoreLink &&
        OptionsStore.appLinks.yandexStoreLink !== null ? (
          <div className={'side-apps inline-flex'}>
            <div>
              <a href={OptionsStore.appLinks.yandexStoreLink}>
                <button
                  type="button"
                  className="menu-login btn btn-dark btn-apps-side"
                >
                  <img
                    style={{ width: 20 }}
                    src={'/static/media/icons/yandex-icon-store.png'}
                    alt="yandex-store"
                  />
                  <span>{'Yandex store'}</span>
                </button>
              </a>
            </div>
            {OptionsStore.appLinks.apkLink &&
            OptionsStore.appLinks.apkLink !== null ? (
              <div>
                <a href={OptionsStore.appLinks.apkLink}>
                  <button
                    type="button"
                    className="menu-login btn btn-dark btn-apps-side"
                  >
                    <img
                      style={{ width: 20 }}
                      src={'/static/media/icons/apk-file-format-symbol.svg'}
                      alt="yandex-store"
                    />
                    <span>{'.apk'}</span>
                  </button>
                </a>
              </div>
            ) : null}
          </div>
        ) : null}
      </Fragment>
    );

    return (
      <div className="menu-side-footer">
        {/* { aboutUS }*/}
        {app}
        {tel}
        {this.renderEmail()}
      </div>
    );
  }

  renderPhoneNumber() {
    const { tel } = this.props;
    return <a href={`tel:${tel}`}>{tel}</a>;
  }

  renderEmail() {
    const { email } = this.props;
    return <a href={`mailTo:${email}`}>{email}</a>;
  }

  initEvents() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('click', this.outsideClickListener);
  }

  removeEvents() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.outsideClickListener);
  }

  handleResize = () => {
    this.closeMenu();
  };

  outsideClickListener = event => {
    const menuToggler = document.querySelector('#side-menu-toggler');
    const clickInToggler = menuToggler.contains(event.target);

    if (!clickInToggler && !this.menu.contains(event.target)) {
      this.closeMenu();
    }
  };

  logoutClick = () => {
    this.props.logout();
    this.props.history.push(PathStore.pathWithLang());
    this.closeMenu();
  };

  closeMenu() {
    UIStore.closeSideMenu();
    document.body.style.overflowX = 'hidden';
    document.body.style.overflowY = 'auto';
  }

  setBodyPadding() {
    if (UIStore.isSideMenuOpen) {
      document.body.style.paddingRight = '17px';
      return;
    }

    document.body.style.paddingRight = '0';
  }
}

export default SideMenu;
