import { useEffect, useCallback } from 'react';
import { usePrevious } from 'react-use';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

const AutoSubmit = ({
  t = (text) => text,
  setSubmitting = () => null,
  setStatus = () => null,
  values,
  submitForm,
  isValid,
}) => {
  const prevValues = usePrevious(values);
  const prevValid = usePrevious(isValid);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const response = await submitForm(values);
    setSubmitting(false);

    if (response && response.status !== 'ok') {
      setStatus(t(`errors:${response.errorName}`));
    } else {
      setStatus(null);
    }
  }, [values]);

  const debouncedSubmit = debounce(handleSubmit, 300);

  useEffect(() => {
    if (
      isValid &&
      (!isEqual(prevValues, values) || !isEqual(prevValid, isValid))
    ) {
      debouncedSubmit();
    }

    return () => {
      debouncedSubmit.cancel();
    };
  }, [values, submitForm, isValid]);

  return null;
};

export default AutoSubmit;
