import React from 'react';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import userStore from '../../../../../Stores/UserStore';
import SettingsStore from '../../../../../Stores/SettingsStore';
import PaymentStore from '../../../../../Stores/PaymentStore';

import StartView from '../View';

import * as timeutil from '../../../../../utils/timeutil';

const inititalState = {
  toPaymentService: null,
  gettingPrice: false,
};

@translate(['parking'], { wait: true })
@observer
class StartFormContainer extends React.Component {
  constructor() {
    super();
    this.state = inititalState;
  }

  reset = () => {
    this.setState({ ...inititalState });
  };

  handleFormChangeWhenValid = async (values) => {
    const {
      transport,
      duration,
      zone,
      parkingCode,
      postPaymentStartTime,
    } = this.props;

    const ts = PaymentStore.getTsForPaymentByNumber(transport);
    this.setState({ gettingPrice: true });

    const response = await PaymentStore.paySession(
      !postPaymentStartTime
        ? PaymentStore.PAYMENT_TYPES.PAYMENT
        : PaymentStore.PAYMENT_TYPES.POST_PAYMENT,
      {
        number: ts.number,
        category: ts.category,
        numberFormat: ts.formatGrz,
        payment: values.service.methodName,
        phone: values.phone.phone,
        zone: zone.name,
        duration,
        parkingCode,
        postPaymentStartTime,
      }
    );

    if (response) {
      this.setState({
        gettingPrice: false,
        toPaymentService: response.hasOwnProperty('RedirectURL')
          ? response.RedirectURL.replace(',', '.')
          : null,
      });
    }

    return response;
  };

  onAbort = () => {
    const { onAbort } = this.props;

    onAbort();
  };

  handleSubmit = async (values) => {
    await userStore.updateProfile();
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    const { price, transport, duration, zone, amountToPay } = this.props;

    return (
      <StartView
        t={this.props.t}
        toPaymentService={this.state.toPaymentService}
        ts={transport}
        duration={timeutil.minutesToHour(duration)}
        zone={zone.name}
        price={price}
        dontUseBalance={SettingsStore.settings.dontUseBalance}
        amountToPay={amountToPay}
        services={SettingsStore.settings.paymentsMethods}
        phones={userStore.getAllPhones()}
        onGetPrice={this.handleFormChangeWhenValid}
        onChange={this.reset}
        gettingPrice={this.state.gettingPrice}
        onAbort={this.onAbort}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default StartFormContainer;
