import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import DatePicker from 'react-datepicker';
import { Button } from 'reactstrap';
import moment from 'moment';
import UserStore from '../../../Stores/UserStore';
import MapStore from '../../../Stores/MapStore';
import i18n from 'i18next';
import { translate } from 'react-i18next';

import PropTypes from 'prop-types';
import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';

@translate(['operationsHistory'], { wait: true })
@observer
export default class PeriodFilters extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this._handleStartDateChange = this._handleDatePickerChange.bind(this, 'start');
    this._handleEndDateChange = this._handleDatePickerChange.bind(this, 'end');
  }

  render() {
    const { t } = this.props;

    return (
      <div className="info-field">
        <div className="name">{t('operationsHistory:filters:labels:periodLabel')}</div>

        <div className="value">
          <Button
            className={this._getPeriodButtonClass('all')}
            color={this._getPeriodButtonColor('all')}
            data-filter="all"
            onClick={this._applyPeriodFilter}
          >
            {t('operationsHistory:filters:filterButtons:period:allTime')}
          </Button>

          <Button
            className={this._getPeriodButtonClass('yesterday')}
            color={this._getPeriodButtonColor('yesterday')}
            data-filter="yesterday"
            onClick={this._applyPeriodFilter}
          >
            {t('operationsHistory:filters:filterButtons:period:yesterday')}
          </Button>

          <Button
            className={this._getPeriodButtonClass('today')}
            color={this._getPeriodButtonColor('today')}
            data-filter="today"
            onClick={this._applyPeriodFilter}
          >
            {t('operationsHistory:filters:filterButtons:period:today')}
          </Button>

          <Button
            className={this._getPeriodButtonClass('weekly')}
            color={this._getPeriodButtonColor('weekly')}
            data-filter="weekly"
            onClick={this._applyPeriodFilter}
          >
            {t('operationsHistory:filters:filterButtons:period:thisWeek')}
          </Button>

          <Button
            className={this._getPeriodButtonClass('monthly')}
            color={this._getPeriodButtonColor('monthly')}
            data-filter="monthly"
            onClick={this._applyPeriodFilter}
          >
            {t('operationsHistory:filters:filterButtons:period:thisMonth')}
          </Button>

          <Button
            className={this._getPeriodButtonClass('custom')}
            color={this._getPeriodButtonColor('custom')}
            data-filter="custom"
            onClick={this._applyPeriodFilter}
          >
            {t('operationsHistory:filters:filterButtons:period:myPeriod')}
          </Button>

          <div
            className={
              'date-pickers ' +
              (OperationsHistoryStore.filters.currentPeriodFilter === 'custom'
                ? 'd-flex'
                : 'd-none')
            }
          >
            <DatePicker
              selectsStart
              dateFormat="DD/MM/YYYY"
              placeholderText={t('operationsHistory:filters:filterButtons:period:from')}
              selected={OperationsHistoryStore.filters.customPeriodStart}
              startDate={OperationsHistoryStore.filters.customPeriodStart}
              endDate={OperationsHistoryStore.filters.customPeriodEnd}
              customInput={<CustomDatePickerInput />}
              onChange={this._handleStartDateChange}
              locale={i18n.language}
            />

            <DatePicker
              selectsEnd
              dateFormat="DD/MM/YYYY"
              placeholderText={t('operationsHistory:filters:filterButtons:period:to')}
              selected={OperationsHistoryStore.filters.customPeriodEnd}
              startDate={OperationsHistoryStore.filters.customPeriodStart}
              endDate={OperationsHistoryStore.filters.customPeriodEnd}
              minDate={OperationsHistoryStore.filters.customPeriodStart}
              customInput={<CustomDatePickerInput />}
              onChange={this._handleEndDateChange}
              locale={i18n.language}
            />
          </div>
        </div>
      </div>
    );
  }

  _calculateTableWidth = () => {
    let width = document.body.clientWidth;

    if (width < 540) {
      width -= 30; // card margin
      width -= 30; // card padding
      this.tableWidth = width + 'px';
    } else {
      this.tableWidth = '100%';
    }
  };

  getZones() {
    return MapStore.sortedZones;
  }

  getOperations() {
    const filteredOperations = UserStore.getOperations(); //getFilteredOperations(a => !!a);
    const sortedOperations = filteredOperations.sort((a, b) => {
      const start = moment(a.startsOf);
      const end = moment(b.endOf);
      return end.diff(start, 'hours');
    });

    return sortedOperations;
  }

  _handleDatePickerChange = (field, date) => {
    const filters = OperationsHistoryStore.filters;

    if (field === 'start') {
      if (date > filters.customPeriodEnd) {
        OperationsHistoryStore.setFilters(
          {
            periodStart: filters.customPeriodEnd
          },
          false
        );
        OperationsHistoryStore.setFilters({
          periodStart: moment(filters.periodEnd)
            .startOf('day')
            .format()
        });
      } else {
        OperationsHistoryStore.filters.customPeriodStart = date;
        OperationsHistoryStore.setFilters({ periodStart: date.format() });
      }
      return;
    }

    if (field === 'end') {
      OperationsHistoryStore.filters.customPeriodEnd = date.endOf('day');
      OperationsHistoryStore.setFilters({ periodEnd: date.endOf('day').format() });
    }
  };

  _applyPeriodFilter = event => {
    const target = event.target;

    if (target.getAttribute) {
      const filter = target.getAttribute('data-filter');
      if (!!filter) {
        OperationsHistoryStore.setPeriodsByType(filter);
        // OperationsHistoryStore.filters.currentPeriodFilter = filter;
        // OperationsHistoryStore.setPeriods();
      }
    }
  };

  _compareValues = (currentValue, expectedValue, successValue, failValue) => {
    if (currentValue === expectedValue) {
      return successValue;
    }

    return failValue;
  };

  _getPeriodButtonClass = filter => {
    return this._compareValues(
      OperationsHistoryStore.filters.currentPeriodFilter,
      filter,
      'btn-options',
      'btn-options-grey'
    );
  };

  _getPeriodButtonColor = filter => {
    return this._compareValues(
      OperationsHistoryStore.filters.currentPeriodFilter,
      filter,
      'primary',
      'light'
    );
  };
}

export class CustomDatePickerInput extends React.Component {
  render() {
    const value = this.props.value || this.props.placeholder;

    return (
      <Button color="primary" onClick={this.props.onClick}>
        {value}
      </Button>
    );
  }

  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string
  };
}
