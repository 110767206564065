import React from "react";
import { Sessions } from "../View";
import { observer } from "mobx-react";
import UserStore from "../../../../../Stores/UserStore";
import { SectionBlock } from "../../../../../Components/ProfileComponents";
import PaymentStore from "../../../../../Stores/PaymentStore";
import { translate } from "react-i18next";
import {
  AbortSessionBtn,
  CancelNoBalanceSessionBtn
} from "../../../../../Components/Common/Buttons/Payments";
import { Button } from "reactstrap";

const SessionButtons = ({ session, ...rest }) => {
  return (
    <>
      <CancelNoBalanceSessionBtn session={session} {...rest} />
      <Button
        color="primary"
        outline
        onClick={async () => {
          return await PaymentStore.cancelNoBalanceSession({
            orderId: session.id
          });
        }}
      >
        Отложить
      </Button>
      <AbortSessionBtn session={session} {...rest} />
    </>
  );
};

@translate(["header"], { wait: true })
@observer
class SessionsContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      amountToPay: 0,
      isCancelModalOpen: false
    };
  }

  componentDidMount() {
    UserStore.loadProfileIfNeeded();
  }

  handlePayment = async values => {
    const result = await PaymentStore.cancelNoBalanceSession({ ...values });
    return result;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <SectionBlock
          title={t("parking:currentParkings:title")}
          isLoading={UserStore.profile.isLoading}
        >
          <div className="main-wrapper">
            <Sessions
              t={t}
              sessions={UserStore.getActiveSessions()}
              button={SessionButtons}
            />
          </div>
        </SectionBlock>
      </>
    );
  }
}

export default SessionsContainer;
