import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { observer } from 'mobx-react';

import App from '../../Components/App';
import { CoopProfile } from '../Loadable';
import WeAreClosed from '../../Components/Common/WeAreClosed';
import { RuningLine } from '../../Components/Notification';

import SettingsStore from '../../Stores/SettingsStore';
import OptionsStore from '../../Stores/OptionsStore';
import CityStore from '../../Stores/CityStore';
import CoopUserStore from '../../Stores/CoopUser';
import ModalManager from '../ModalManage/ModalManage';
import PopUp from '../../Containers/NotificationPopUpContainer';

import i18n from 'i18next';
import moment from 'moment';

import DOMManipulations from '../../utils/DOMManipulations';

import { translate } from 'react-i18next';

import {
  CITY_NAME_FOR_SHOW,
  APP_MODAL_BANNER,
  APP_MODAL_BANNER_IMG_URL,
  APP_MODAL_BANNER_IMG_URL_MOBILE,
  APP_MODAL_BANNER_REDIRECT_URL
} from '../../Config';
import ModalStore from '../../Stores/ModalStore';

@translate(['operationsHistory'], { wait: true })
@observer
class AppContainer extends Component {
  UNSAFE_componentWillMount() {
    this.initLanguages();
  }

  async componentDidMount() {
    CoopUserStore.isLoggedIn();

    OptionsStore.getOptions();
    SettingsStore.loadSettings();
    CityStore.nameCityForShow = CITY_NAME_FOR_SHOW;
    const imgSrc = this.getBannerUrl();

    if (APP_MODAL_BANNER && imgSrc)
      ModalStore.openModal(
        'image',
        {
          imgSrc: imgSrc,
          href: APP_MODAL_BANNER_REDIRECT_URL
        },
        { maxWidth: 'md' }
      );
  }

  async componentDidUpdate() {
    await SettingsStore.loadSettings();
    if (OptionsStore.done) this.removeLoader();
  }

  getBannerUrl = () => {
    return window.innerWidth < 750
      ? APP_MODAL_BANNER_IMG_URL_MOBILE
      : APP_MODAL_BANNER_IMG_URL;
  };

  initLanguages() {
    const lng = this.props.match.params.lng;
    if (lng !== 'ru' && lng !== 'en') {
      i18n.init({ lng: 'ru' });
      moment.locale('ru');
    } else {
      i18n.init({ lng: lng || 'ru' });
      moment.locale(lng || 'ru');
    }
  }

  removeLoader() {
    const domManipulator = new DOMManipulations(document.body);
    domManipulator.removeFirstTimeLoader();
  }

  render() {
    const { message, url } = OptionsStore.notification;
    const editedUrl = url;

    if (OptionsStore.done && SettingsStore.status.done) {
      if (OptionsStore.isClosed) {
        return <WeAreClosed />;
      }

      if (CoopUserStore.token) {
        return <Route component={CoopProfile} />;
      }
      return (
        <Fragment>
          <PopUp />
          <ModalManager />
          <RuningLine message={message} url={editedUrl} />
          <Route exact path="/" component={App} />
          <Route path="/:lng/" component={App} />
        </Fragment>
      );
    }
    return null;
  }
}

export default AppContainer;
