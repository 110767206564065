import React, { Component } from 'react';

export default class OperationsTableErrorMessage extends Component {
  render() {
    const { message } = this.props;
    return (
      <div className="d-flex flex-row">
        <span className="d-none d-lg-inline-block" style={{ width: 15, height: 1 }} />
        <div className="text-secondary" style={{ paddingLeft: 15 }}>
          {message}
        </div>
      </div>
    );
  }
}
