import React from "react";
import NotificationPopUpView from "../../Components/Notification/Popup";
import SettingsStore from "../../Stores/SettingsStore";
import NotificationStore from "../../Stores/NotificationStore";
import { observer } from "mobx-react";

@observer
class NotificationPopUpContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      isClosed: true
    };
    this.delayed = false;
  }

  componentDidMount() {
    NotificationStore.loadNotification();
  }

  componentDidUpdate() {
    if (!NotificationStore.closed && this.isLoaded && !this.delayed) {
      setTimeout(() => {
        this.setState({ isClosed: false });
        this.delayed = true;
      }, 300);
    }
  }

  handleClose = () => {
    NotificationStore.closed = true;
    this.setState({ isClosed: true });
  };

  isLoaded = () => {
    return SettingsStore.status.done && NotificationStore.status.done;
  };

  render() {
    if (!this.isLoaded()) {
      return null;
    }

    return (
      <NotificationPopUpView
        isClosed={this.state.isClosed}
        onClose={this.handleClose}
        title={NotificationStore.notification.title}
        content={NotificationStore.notification.message}
      />
    );
  }
}

export default NotificationPopUpContainer;
