import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import { observable } from "mobx";

import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

import LinkButton from "../../Common/Buttons/LinkButton";

import SendAganeButton from "../../../Components/Common/Buttons/SendAganeButton";
import Input from "../../MaterialDesign/Input";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import spinner from "@fortawesome/fontawesome-free-solid/faSpinner";
import envelope from "@fortawesome/fontawesome-free-solid/faEnvelope";
import phoneIcon from "@fortawesome/fontawesome-free-solid/faPhone";
import keyIcon from "@fortawesome/fontawesome-free-solid/faKey";
import pencileAlt from "@fortawesome/fontawesome-free-solid/faPencilAlt";

import UIStore from "../../../Stores/UIStore";
import UserStore from "../../../Stores/UserStore";
import APIProvider from "../../../Stores/APIProvider";
import OptionsStore from "../../../Stores/OptionsStore";

import { IS_EASY_REGISTRATION } from "../../../Config";

import "../../../Assets/Styles/Common/transition.css";

@observer
export default class EditProfileInfoModal extends Component {
  @observable _activeStep = "main";
  @observable _isConfirmationStep = false;
  @observable _acceptButtonLoading = false;
  @observable error = false;
  @observable isPasswordChanged = false;
  @observable alertText = "";
  @observable isAlertVisible = false;
  @observable alertStatus = "success";
  @observable refreshVisible = false;

  emailToken = null;
  changedEmail = null;

  @observable phone = null;
  phoneToken = null;
  changedPhone = null;

  _refs = {
    firstName: React.createRef(),
    lastName: React.createRef(),
    middleName: React.createRef(),

    email: React.createRef(),
    emailMain: React.createRef(),
    emailCode: React.createRef(),

    phone: React.createRef(),
    phoneCode: React.createRef(),
    sendCodeMessage: React.createRef(),

    oldPassword: React.createRef(),
    newPassword: React.createRef(),
    repeatPassword: React.createRef()
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    this._activeStep = UIStore.editProfileInfoModalActiveStep;
    this.refreshVisible = false;
    UIStore.editProfileInfoModalActiveStep = "main";
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.resetAlert();
  }

  render() {
    const { isOpen, onAbort } = this.props;
    return (
      <Modal
        className="edit-profile-modal"
        size="lg"
        isOpen={isOpen}
        onExit={onAbort}
      >
        <ModalHeader>{this._renderModalHeader()}</ModalHeader>
        <ModalBody className="change-modal">
          {this._renderModalBody()}
        </ModalBody>
        <ModalFooter className="justify-content-start">
          {this._renderModalFooter()}
        </ModalFooter>
      </Modal>
    );
  }

  _renderModalHeader = () => {
    const {
      translations: { edit }
    } = this.props;

    let mainBreadCrumb = (
      <BreadcrumbItem>{edit.editProfile.title}</BreadcrumbItem>
    );
    let confirmationBreadCrumb = null;
    let activeBreadCrumb = null;

    if (this._activeStep === "change password") {
      activeBreadCrumb = (
        <BreadcrumbItem active>{edit.editItems.password}</BreadcrumbItem>
      );
    }

    if (this._activeStep === "change telephone") {
      const breadcrumbFirst =
        UIStore.editProfileInfoModalPhoneTypeAction === "change"
          ? edit.editItems.phone
          : edit.editItems.addPhone;

      if (this._isConfirmationStep) {
        activeBreadCrumb = <BreadcrumbItem>{breadcrumbFirst}</BreadcrumbItem>;
        confirmationBreadCrumb = (
          <BreadcrumbItem active>Подтверждение</BreadcrumbItem>
        );
      } else {
        activeBreadCrumb = (
          <BreadcrumbItem active>{breadcrumbFirst}</BreadcrumbItem>
        );
      }
    }

    if (this._activeStep === "change email") {
      if (this._isConfirmationStep) {
        activeBreadCrumb = (
          <BreadcrumbItem>{edit.editItems.email}</BreadcrumbItem>
        );
        confirmationBreadCrumb = (
          <BreadcrumbItem active>Подтверждение</BreadcrumbItem>
        );
      } else {
        activeBreadCrumb = (
          <BreadcrumbItem active>{edit.editItems.email}</BreadcrumbItem>
        );
      }
    }

    return (
      <Fragment>
        <Breadcrumb>
          {mainBreadCrumb}
          {activeBreadCrumb}
          {confirmationBreadCrumb}
        </Breadcrumb>
      </Fragment>
    );
  };

  _renderModalBody = () => {
    if (this._activeStep === "main") {
      return this._renderMainStep();
    }

    if (this._activeStep === "change password") {
      return this._renderChangePasswordStep();
    }

    if (this._activeStep === "change email") {
      if (IS_EASY_REGISTRATION) {
        return this._renderChangeEmailStep();
      }

      return this._isConfirmationStep
        ? this._renderChangeEmailConfirmationStep()
        : this._renderChangeEmailStep();
    }

    if (this._activeStep === "change telephone") {
      return this._isConfirmationStep
        ? this._renderChangeTelephoneConfirmationStep()
        : this._renderChangeTelephoneStep();
    }

    return null;
  };

  _renderMainStep = () => {
    const {
      defaultData,
      translations: { edit, t }
    } = this.props;
    const { email, phone } = defaultData;

    return (
      <Fragment>
        <div>
          <Alert
            color={this.alertStatus}
            className={this.isAlertVisible ? "d-block" : "d-none"}
          >
            {this.alertText}
          </Alert>
          {!IS_EASY_REGISTRATION ? (
            <LinkButton onClick={this._changeStep.bind(this, "change email")}>
              {edit.editItems.email}
            </LinkButton>
          ) : null}

          {/* <LinkButton onClick={this._changeStep.bind(this, "change telephone")}>
            {edit.editItems.phone}
          </LinkButton> */}

          <LinkButton onClick={this._changeStep.bind(this, "change password")}>
            {edit.editItems.password}
          </LinkButton>
        </div>

        <Input
          name="email"
          id="email"
          icon={envelope}
          key="1"
          label="E-mail"
          type="email"
          ref={this._refs.emailMain}
          disabled={!IS_EASY_REGISTRATION}
          defaultValue={email || t("profile:mainInfo:emailUndefined")}
        />

        {this.renderPrivateData()}

        <Input
          name="telephone"
          id="telephone"
          icon={phoneIcon}
          key="2"
          label={edit.editProfile.phone}
          type="telephone"
          disabled
          value={phone}
        />
      </Fragment>
    );
  };

  _renderChangePasswordStep = () => {
    const {
      translations: { edit }
    } = this.props;

    return (
      <Fragment>
        <Input
          name="old-password"
          type="password"
          id="old-password"
          icon={keyIcon}
          key="10"
          label={edit.editPassword.oldPassword}
          required
          autocoplete="off"
          ref={this._refs.oldPassword}
          defaultValue={null}
        />

        <Input
          name="password"
          type="password"
          id="password"
          icon={keyIcon}
          key="11"
          label={edit.editPassword.newPassword}
          required
          autocoplete="off"
          ref={this._refs.newPassword}
          defaultValue={null}
        />

        <Input
          name="r-password"
          type="password"
          id="r-password"
          icon={keyIcon}
          key="12"
          label={edit.editPassword.repeatPassword}
          required
          autocoplete="off"
          ref={this._refs.repeatPassword}
          defaultValue={null}
        />
      </Fragment>
    );
  };

  _renderChangeEmailStep = () => {
    const {
      translations: { edit }
    } = this.props;

    return (
      <Fragment>
        <Input
          type="email"
          name="email"
          icon={envelope}
          id="email"
          label={edit.editEmail.newEmail}
          ref={this._refs.email}
          key="21"
        />

        {IS_EASY_REGISTRATION ? null : (
          <div className="text-secondary">{edit.editEmail.message}</div>
        )}
      </Fragment>
    );
  };

  renderPrivateData() {
    const { hasPrivateData } = this.props;
    const {
      defaultData,
      translations: { edit }
    } = this.props;
    const { firstName, lastName, middleName } = defaultData;

    if (hasPrivateData) {
      return (
        <Fragment>
          <Input
            name="last-name"
            id="last-name"
            icon={pencileAlt}
            key="3"
            label={edit.editProfile.lastName}
            ref={this._refs.lastName}
            defaultValue={lastName}
          />

          <Input
            name="first-name"
            id="first-name"
            icon={pencileAlt}
            label={edit.editProfile.firstName}
            ref={this._refs.firstName}
            defaultValue={firstName}
          />

          <Input
            name="middle-name"
            id="middle-name"
            icon={pencileAlt}
            key="4"
            label={edit.editProfile.patronymic}
            ref={this._refs.middleName}
            defaultValue={middleName}
          />
        </Fragment>
      );
    }
  }

  _renderChangeEmailConfirmationStep = () => {
    const {
      translations: { edit, operations }
    } = this.props;

    return (
      <Fragment>
        <div className="text-secondary" ref={this._refs.sendCodeMessage}>
          {edit.editEmail.secondMessage}
        </div>
        <Input
          id="code"
          name="code"
          type="text"
          label={operations.code}
          ref={this._refs.emailCode}
          required
        />
      </Fragment>
    );
  };

  _renderChangeTelephoneStep = () => {
    const {
      translations: { edit }
    } = this.props;

    return (
      <Fragment>
        <Input
          type="tel"
          name="telephone"
          icon={phoneIcon}
          id="telephone"
          onChange={event => {
            if (event) this.phone = event.target.value;
          }}
          label={edit.editPhoneNumber.newPhoneNumber}
          ref={this._refs.phone}
          key="31"
        />

        <div className="text-secondary">{edit.editPhoneNumber.message}</div>
      </Fragment>
    );
  };

  _renderChangeTelephoneConfirmationStep = () => {
    const {
      translations: { edit }
    } = this.props;

    return (
      <Fragment>
        <div
          className="text-secondary transition--color"
          ref={this._refs.sendCodeMessage}
        >
          {edit.editPhoneNumber.secondMessage}
        </div>

        <Input
          id="code"
          name="code"
          type="text"
          label="Код"
          ref={this._refs.phoneCode}
          required
        />
      </Fragment>
    );
  };

  _renderModalFooter = () => {
    const {
      translations: { operations }
    } = this.props;

    let acceptText = operations.save;
    let abortText = operations.annulment;

    if (
      this._activeStep === "change telephone" ||
      this._activeStep === "change email"
    ) {
      acceptText = operations.sendCode;
    }

    if (this._activeStep === "change password") {
      acceptText = operations.change;
    }

    if (this._isConfirmationStep) {
      acceptText = operations.send;
      abortText = operations.back;
    }

    if (this._activeStep === "main") {
      abortText = operations.cancel;
    }

    const acceptButtonClassName = this._activeStep.replace(/\s/g, "-") + "-btn";

    return (
      <Fragment>
        <Button
          color="primary"
          className={acceptButtonClassName}
          onClick={this._handleAccept}
        >
          <span>{acceptText}</span>
          {this.renderAcceptContentButton()}
        </Button>
        <Button
          color="primary"
          outline
          className={"abort-btn"}
          onClick={this._handleAbort}
        >
          {abortText}
        </Button>
        {this.renderRefreshButton()}
      </Fragment>
    );
  };

  renderRefreshButton() {
    if (this.refreshVisible) {
      return <SendAganeButton visible onRefresh={this._handleRefreshCode} />;
    }

    return null;
  }

  _handleRefreshCode = async () => {
    if (this._activeStep === "change email") {
      return this.handleEmailRefresh();
    }

    if (this._activeStep === "change telephone") {
      return this.handleSmsRefresh();
    }
  };

  handleSmsRefresh = async () => {
    const {
      translations: { t }
    } = this.props;

    const phone = this.phone.trim();
    const response = await APIProvider.sendSMSCode(APIProvider.token, phone, 1);

    if (response) {
      if (response.status && response.status === "ok") {
        this._refs.sendCodeMessage.current.classList.add("text-success");
        window.setTimeout(
          () =>
            this._refs.sendCodeMessage.current.classList.remove("text-success"),
          800
        );
      } else {
        let statement = this._createStatement(
          false,
          t("profile:mainInfo:edit:editPhoneNumber:unknownError")
        );
        if (response.status && response.errorName) {
          if (response.errorName === "DoublePhone") {
            statement = this._createStatement(
              false,
              t("profile:mainInfo:edit:editPhoneNumber:doublePhone")
            );
          } else if (response.errorName === "LimitSMS") {
            statement = this._createStatement(
              false,
              t("profile:mainInfo:edit:editPhoneNumber:LimitSMS")
            );
          } else if (response.errorName === "SMSNotSend") {
            statement = this._createStatement(
              false,
              t("profile:mainInfo:edit:editPhoneNumber:SMSNotSend")
            );
          }
        }
        this._refs.phoneCode.current.setErrorState(
          statement.success,
          statement.error
        );
      }
    }
  };

  handleEmailRefresh = async () => {
    const {
      translations: { t }
    } = this.props;

    const email = this.changedEmail;
    const response = await APIProvider.sendEmailCode(
      APIProvider.token,
      email,
      1
    );

    if (response) {
      if (response.status && response.status === "ok") {
        this._refs.sendCodeMessage.current.classList.add("text-success");
        window.setTimeout(
          () =>
            this._refs.sendCodeMessage.current.classList.remove("text-success"),
          800
        );
      } else {
        let statement = this._createStatement(
          false,
          t("profile:mainInfo:edit:editPhoneNumber:unknownError")
        );
        if (response.status && response.errorName) {
          if (response.errorName === "DoublePhone") {
            statement = this._createStatement(
              false,
              t("profile:mainInfo:edit:editPhoneNumber:doublePhone")
            );
          } else if (response.errorName === "LimitSMS") {
            statement = this._createStatement(
              false,
              t("profile:mainInfo:edit:editPhoneNumber:LimitSMS")
            );
          } else if (response.errorName === "SMSNotSend") {
            statement = this._createStatement(
              false,
              t("profile:mainInfo:edit:editPhoneNumber:SMSNotSend")
            );
          }
        }
        this._refs.emailCode.current.setErrorState(
          statement.success,
          statement.error
        );
      }
    }
  };

  resetAlert() {
    this.alertText = "";
    this.alertStatus = "success";
    this.isAlertVisible = false;
  }

  _changeStep = step => {
    this.resetAlert();
    this._activeStep = step;
  };

  _handleAbort = () => {
    const { onAbort } = this.props;

    this.resetAlert();
    if (this._activeStep === "main") {
      this._acceptButtonLoading = false;
      this.refreshVisible = false;
      onAbort();
    }

    if (UIStore.editProfileInfoModalPhoneTypeAction === "add") {
      UIStore.toggleEditProfileModal();
      UIStore.editProfileInfoModalPhoneTypeAction = "change";
    }

    this._activeStep = "main";
    this._isConfirmationStep = false;
    this.refreshVisible = false;
  };

  _handleAccept = async () => {
    switch (this._activeStep) {
      case "main": {
        await this._handleAcceptMain();
        break;
      }

      case "change email": {
        await this._handleAcceptEmail();
        break;
      }

      case "change telephone": {
        await this._handleAcceptTelephone();
        break;
      }

      case "change password": {
        await this._handleAcceptPassword();
        break;
      }

      default:
        break;
    }
  };

  getInputData() {
    const firstName = this._refs.firstName.current
      ? this._refs.firstName.current.value.trim()
      : null;
    const lastName = this._refs.lastName.current
      ? this._refs.lastName.current.value.trim()
      : null;
    const middleName = this._refs.middleName.current
      ? this._refs.middleName.current.value.trim()
      : null;
    const email = this._refs.emailMain.current
      ? this._refs.emailMain.current.value.trim()
      : null;

    return {
      firstName,
      lastName,
      middleName,
      email
    };
  }

  _handleAcceptMain = async () => {
    const {
      onAccept,
      translations: { t }
    } = this.props;
    const inputs = this.getInputData();

    const firstName = inputs.firstName;
    const lastName = inputs.lastName;
    const middleName = inputs.middleName;

    let isMainProfileValid = true;
    if (OptionsStore.hasPrivateData) {
      isMainProfileValid = await this._verifyMainProfileData({
        firstName,
        lastName,
        middleName
      });
    }

    if (this.isProfileDataChanged()) {
      if (isMainProfileValid) {
        this._acceptButtonLoading = true;

        const response = await onAccept(this.getAcceptedData());
        this._acceptButtonLoading = false;

        if (response.status !== "ok") {
          this.alertStatus = "danger";
          this.alertText = t(`errors:${response.errorName}`);
          this.isAlertVisible = true;
        }
      }
    } else {
      this._handleAbort();
    }
  };

  isProfileDataChanged() {
    const defaultProfile = this.props.defaultData;
    const inputs = this.getInputData();

    const firstName = inputs.firstName;
    const lastName = inputs.lastName;
    const middleName = inputs.middleName;
    const email = inputs.email;

    const isEmailChanged = email ? email !== defaultProfile.email : true;
    let isMainChanged = false;
    if (OptionsStore.hasPrivateData) {
      const isFirstNameChanged = firstName !== defaultProfile.firstName;
      const isLastNameChanged = lastName !== defaultProfile.lastName;
      const isMiddleNameChanged = middleName !== defaultProfile.middleName;
      isMainChanged =
        isFirstNameChanged || isLastNameChanged || isMiddleNameChanged;
    }

    return isEmailChanged || isMainChanged;
  }

  getAcceptedData() {
    const acceptedData = {};
    const defaultProfile = this.props.defaultData;
    const inputs = this.getInputData();

    const firstName = inputs.firstName;
    const lastName = inputs.lastName;
    const middleName = inputs.middleName;
    const email = inputs.email;

    if (email && email !== defaultProfile.email) acceptedData.email = email;
    if (firstName !== defaultProfile.firstName)
      acceptedData.firstName = firstName;
    if (lastName !== defaultProfile.lastName) acceptedData.lastName = lastName;
    if (middleName !== defaultProfile.middleName)
      acceptedData.middleName = middleName;

    return acceptedData;
  }

  _handleAcceptTelephone = async () => {
    const {
      onPhoneChange,
      translations: { t }
    } = this.props;
    if (this._isConfirmationStep) {
      const code = this._refs.phoneCode.current.value.trim();
      this._acceptButtonLoading = true;
      const response = await this._verifyPhoneCodeField(code);

      if (response.success) {
        const response = onPhoneChange(this.changedPhone);
        const successText =
          UIStore.editProfileInfoModalPhoneTypeAction === "change"
            ? t("profile:mainInfo:successPhoneEdit")
            : t("profile:mainInfo:successPhoneAdd");

        this.isAlertVisible = true;
        this.alertText = successText;
        this.alertStatus = "success";

        this._activeStep = "main";
        this._isConfirmationStep = false;
      } else {
        this._refs.phoneCode.current.setErrorState(
          response.success,
          response.error
        );
      }

      this._acceptButtonLoading = false;

      return;
    }

    const phone = this._refs.phone.current.value.trim();

    if (await this._verifyPhoneField(phone)) {
      this._acceptButtonLoading = true;
      const response = await APIProvider.sendSMSCode(
        APIProvider.token,
        phone,
        1
      );
      this._acceptButtonLoading = false;

      if (response) {
        if (response.status && response.status === "ok") {
          this.changedPhone = phone;
          this.phoneToken = response.token;
          this._isConfirmationStep = true;
          this.refreshVisible = true;
        } else {
          let statement = this._createStatement(
            false,
            t("profile:mainInfo:edit:editPhoneNumber:unknownError")
          );
          if (response.status && response.errorName) {
            if (response.errorName === "DoublePhone") {
              statement = this._createStatement(
                false,
                t("profile:mainInfo:edit:editPhoneNumber:doublePhone")
              );
            } else if (response.errorName === "LimitSMS") {
              statement = this._createStatement(
                false,
                t("profile:mainInfo:edit:editPhoneNumber:LimitSMS")
              );
            } else if (response.errorName === "SMSNotSend") {
              statement = this._createStatement(
                false,
                t("profile:mainInfo:edit:editPhoneNumber:SMSNotSend")
              );
            } else if (response.errorName === "UserExists") {
              statement = this._createStatement(
                false,
                t("profile:mainInfo:edit:editPhoneNumber:UserExists")
              );
            }
          }
          this._refs.phone.current.setErrorState(
            statement.success,
            statement.error
          );
        }
      }
    }
  };

  _handleAcceptEmail = async () => {
    const {
      onEmailChange,
      translations: { t }
    } = this.props;
    if (this._isConfirmationStep) {
      const code = this._refs.emailCode.current.value.trim();
      this._acceptButtonLoading = true;
      if (await this._verifyEmailCodeField(code)) {
        onEmailChange(this.changedEmail);

        this.isAlertVisible = true;
        this.alertText = t("profile:mainInfo:successEmailEdit");
        this.alertStatus = "success";

        this._activeStep = "main";
        this._isConfirmationStep = false;
      }

      this._acceptButtonLoading = false;

      return;
    }

    const email = this._refs.email.current.value.trim();

    if (await this._verifyEmailField(email)) {
      this._acceptButtonLoading = true;
      const response = await APIProvider.sendEmailCode(
        APIProvider.token,
        email,
        1
      );
      this._acceptButtonLoading = false;

      if (response) {
        this.changedEmail = email;
        this.emailToken = response.token;
        this._isConfirmationStep = true;
        this.refreshVisible = true;
      }
    }
  };

  _handleAcceptPassword = async () => {
    const {
      translations: { t }
    } = this.props;

    const oldPassword = this._refs.oldPassword.current.value.trim();
    const newPassword = this._refs.newPassword.current.value.trim();
    const repeatPassword = this._refs.repeatPassword.current.value.trim();

    const oldPasswordStatement = await this._validateOneField(
      "pass",
      oldPassword
    );
    const newPasswordStatement = await this._validateOneField(
      "pass",
      newPassword
    );
    const repeatPasswordStatement = await this._validateOneField(
      "r-pass",
      newPassword,
      repeatPassword
    );

    this._refs.oldPassword.current.setErrorState(
      oldPasswordStatement.success,
      oldPasswordStatement.error
    );

    this._refs.newPassword.current.setErrorState(
      newPasswordStatement.success,
      newPasswordStatement.error
    );

    this._refs.repeatPassword.current.setErrorState(
      repeatPasswordStatement.success,
      repeatPasswordStatement.error
    );

    if (
      oldPasswordStatement.success &&
      newPasswordStatement.success &&
      repeatPasswordStatement.success
    ) {
      this._acceptButtonLoading = true;
      this.isPasswordChanged = true;

      const response = await this.props.onPasswordChange({
        pass: newPassword,
        currentpass: oldPassword
      });

      this._acceptButtonLoading = false;
      if (response !== "ok" && response === "CurrentPasswordIncorrect") {
        this._refs.oldPassword.current.setErrorState(
          false,
          t("profile:mainInfo:edit:editPassword:incorrectPassword")
        );
        return;
      }

      this.isAlertVisible = true;
      this.alertText = t("profile:mainInfo:successPassEdit");
      this.alertStatus = "success";

      this._activeStep = "main";
      this._isConfirmationStep = false;
    }
  };

  _verifyMainProfileDataPrivate = async email => {
    const emailDefault = this.props.defaultData.email;
    let finalEmailStatement = { success: true };
    if (email !== emailDefault) {
      const emailStatement = await this._validateOneField("email", email);
      this._refs.emailMain.current.setErrorState(
        emailStatement.success,
        emailStatement.error
      );
      finalEmailStatement = emailStatement;
    }

    return finalEmailStatement.success;
  };

  _verifyMainProfileData = async ({
    firstName,
    lastName,
    middleName,
    email = null
  }) => {
    const firstStatement = await this._validateOneField("firstname", firstName);
    const lastStatement = await this._validateOneField("lastname", lastName);
    const middleStatement = await this._validateOneField(
      "middlename",
      middleName
    );

    this._refs.firstName.current.setErrorState(
      firstStatement.success,
      firstStatement.error
    );
    this._refs.lastName.current.setErrorState(
      lastStatement.success,
      lastStatement.error
    );
    this._refs.middleName.current.setErrorState(
      middleStatement.success,
      middleStatement.error
    );

    return (
      firstStatement.success && lastStatement.success && middleStatement.success
    );
  };

  _verifyEmailField = async email => {
    const statement = await this._validateOneField("email", email);

    this._refs.email.current.setErrorState(statement.success, statement.error);
    return statement.success;
  };

  _verifyEmailCodeField = async code => {
    const statement = await this._validateOneField("emailCode", code);

    this._refs.emailCode.current.setErrorState(
      statement.success,
      statement.error
    );
    return statement.success;
  };

  _verifyPhoneField = async phone => {
    const statement = await this._validateOneField("phone", phone);
    this._refs.phone.current.setErrorState(statement.success, statement.error);
    return statement.success;
  };

  _verifyPhoneCodeField = async code => {
    const statement = await this._validateOneField("phoneCode", code);
    this._refs.phoneCode.current.setErrorState(
      statement.success,
      statement.error
    );

    if (statement.success) {
      if (UIStore.editProfileInfoModalPhoneTypeAction === "change") {
        await UserStore.setMainPhone(this.phone);
        UserStore.deletePhone(UserStore.profile.telephone).then(() => {});
        UserStore.changePhone(this.phone, true);
      } else {
        UserStore.addPhone(this.phone, false);
      }
    }

    return statement;
  };

  _validateOneField = async (name, value, compareValue) => {
    const {
      translations: { t }
    } = this.props;

    switch (name) {
      case "email": {
        if (!this._valueIsNotEmpty(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:emptyField")
          );
        }

        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/i.test(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:incorrectEmail")
          );
        }

        let response = await APIProvider.checkEmail(value);
        if (response === null) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:unknownError")
          );
        }
        if (response.status === "ok") {
          return this._createStatement(
            true,
            t("registrationForm:steps:step1:errors:userWithEmailExist")
          );
        } else if (response.status === "error") {
          if (response.errorName && response.errorName === "UserExists") {
            return this._createStatement(
              false,
              t("registrationForm:steps:step1:errors:userWithEmailExist")
            );
          }
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:unknownError")
          );
        }
        break;
      }

      case "pass": {
        if (!this._valueIsNotEmpty(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:emptyField")
          );
        }

        return this._createStatement(
          value.length >= 4 && value.length <= 20,
          t("registrationForm:steps:step1:errors:strongValidate")
        );
      }

      case "r-pass": {
        if (!this._valueIsNotEmpty(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:confirmPassword")
          );
        }

        return this._createStatement(
          value === compareValue,
          t("registrationForm:steps:step1:errors:passwordsNotMatch")
        );
      }

      case "phone": {
        if (!this._valueIsNotEmpty(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:emptyField")
          );
        }

        const realPhoneNumber = String(value).replace(/[^0-9]/g, "");
        return this._createStatement(
          realPhoneNumber.length === 11,
          t("registrationForm:steps:step1:errors:incorrectNumber")
        );
      }

      case "firstname": {
        return this._createStatement(
          this._valueIsNotEmpty(value),
          t("registrationForm:steps:step1:errors:emptyField")
        );
      }

      case "lastname": {
        return this._createStatement(
          this._valueIsNotEmpty(value),
          t("registrationForm:steps:step1:errors:emptyField")
        );
      }

      case "middlename": {
        return this._createStatement();
      }

      case "emailCode": {
        if (!this._valueIsNotEmpty(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:emptyField")
          );
        }

        const result = await APIProvider.confirmEmail(this.emailToken, value);
        return this._createStatement(
          result,
          t("registrationForm:steps:step2:errors:incorrectCode")
        );
      }

      case "phoneCode": {
        if (!this._valueIsNotEmpty(value)) {
          return this._createStatement(
            false,
            t("registrationForm:steps:step1:errors:emptyField")
          );
        }

        const result = await APIProvider.confirmSMSCode(this.phoneToken, value);
        if (result && result.errorName === "IncorrectCode") {
          return this._createStatement(
            false,
            t("registrationForm:steps:step2:errors:incorrectCode")
          );
        }
        return this._createStatement(
          true,
          t("registrationForm:steps:step2:errors:incorrectCode")
        );
      }

      default: {
        return this._createStatement(
          false,
          t("registrationForm:steps:step1:errors:validateError")
        );
      }
    }
  };

  _createStatement = (success = true, error) => {
    return {
      success,
      error
    };
  };

  _valueIsNotEmpty = value => {
    return (
      value !== null &&
      typeof value !== "undefined" &&
      String(value).trim().length > 0
    );
  };

  renderAcceptContentButton() {
    if (this._acceptButtonLoading)
      return (
        <FontAwesomeIcon
          style={{ marginLeft: 10 }}
          icon={spinner}
          spin
          name="circle"
        />
      );

    return null;
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    defaultData: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      middleName: PropTypes.string,

      phone: PropTypes.string
    }),

    onAbort: PropTypes.func,
    onAccept: PropTypes.func,

    onPasswordChange: PropTypes.func,

    onEmailChange: PropTypes.func,
    onTelephoneChange: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    defaultData: {
      email: null,
      firstName: null,
      lastName: null,
      middleName: null,

      phone: null
    },

    onAbort: () => null,
    onAccept: () => null,

    onPasswordChange: () => null,

    onEmailChange: () => null,
    onTelephoneChange: () => null,

    hasPrivateData: true
  };
}
