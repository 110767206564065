import React, { Component } from 'react';

import Card from '../../Components/Card';
import Filter from '../../Components/Filter/Filter';

import { observer } from 'mobx-react';

@observer
class FilterContainer extends Component {
  render() {
    return this.props.UIStore.filterVisibility ? (
      <Card
        className="filter"
        //   style = {this.computeStyle()}
        isClosable={this.props.isClosable}
        handleClose={this.handleClose}
      >
        <Filter
          types={this.props.types}
          categories={this.props.categories}
          statistics={this.props.statistics}
          UIStore={this.props.UIStore}
        />
      </Card>
    ) : null;
  }

  handleClose = () => {
    this.props.UIStore.toggleVisibilityFilter();
  };
}

export default FilterContainer;
