function StringIncludesPolyfill() {
  if (!String.prototype.includes) {
    /*eslint no-extend-native: ["off", { "exceptions": ["Object"] }]*/
    String.prototype.includes = function(search, start) {
      if (typeof start !== 'number') {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }
}

export default StringIncludesPolyfill;
