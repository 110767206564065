import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class MultilangNavLink extends Component {
  isActive = (_, location) => {
    const slash =
      location.pathname.includes('/ru/') || location.pathname.includes('/en/')
        ? '/'
        : '';
    const pathname =
      slash + location.pathname.replace('/ru/', '').replace('/en/', '');
    const to = this.props.to.replace('/ru/', '').replace('/en/', '');
    return pathname === to;
  };

  render() {
    return (
      <NavLink {...this.props} isActive={this.isActive}>
        {this.props.children}
      </NavLink>
    );
  }
}

export default MultilangNavLink;
