import React from 'react';

import CoreButton from './CoreButton';

import pencilAlt from '@fortawesome/fontawesome-free-solid/faPencilAlt';

function EditButton ({ onClick, ...restProps }) {
    return (
        <CoreButton
            onClick={onClick}
            icon={pencilAlt}
            {...restProps}
        />
    );
}

export default EditButton;
