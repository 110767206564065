import React from 'react';

import Check from './Check';
import { Col } from 'reactstrap';

import { withRouter } from 'react-router-dom';

const CheckWithRouter = withRouter(Check);

function CheckWrapper() {
  return (
    <Col xs={{ size: 8, offset: 2 }}>
      <CheckWithRouter />
    </Col>
  );
}

export default CheckWrapper;
