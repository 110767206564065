import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableNewsContainer = Loadable({
  loader: () => import('../NewsContainer/containers/OneNewsContainer'),
  loading: Loader,
  delay: 400
});

export default LoadableNewsContainer;
