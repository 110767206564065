import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Input, Select } from "../../MaterialDesign";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import spinner from "@fortawesome/fontawesome-free-solid/faSpinner";

import PropTypes from "prop-types";

@observer
export default class ChangeDocumentModal extends Component {
  @observable _acceptButtonLoading = false;
  @observable mask = "99 ** 999999";

  _refs = {
    select: React.createRef(),
    input: React.createRef()
  };

  constructor(props) {
    super(props);

    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    this.forceUpdate();
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>{this._renderHeader()}</ModalHeader>

        <ModalBody className="change-modal">{this._renderBody()}</ModalBody>

        <ModalFooter className="justify-content-start">
          {this._renderFooter()}
        </ModalFooter>
      </Modal>
    );
  }

  _renderHeader = () => {
    const {
      action,
      translations: { edit }
    } = this.props;

    switch (String(action).toLowerCase()) {
      case "add": {
        return edit.addDoc.title;
      }

      case "edit": {
        return edit.editDoc.title;
      }

      case "delete": {
        return edit.deleteDoс.title;
      }

      default: {
        return action;
      }
    }
  };

  _renderBody = () => {
    const { action } = this.props;

    switch (String(action).toLowerCase()) {
      case "add": {
        return this._renderAddOrEditAction();
      }

      case "edit": {
        return this._renderAddOrEditAction();
      }

      case "delete": {
        return this._renderDeleteAction();
      }

      default: {
        console.warn("Unknown action - " + action);
        return null;
      }
    }
  };

  _renderAddOrEditAction = () => {
    const {
      action,
      defaultData: { title },
      translations: { labels }
    } = this.props;
    const defaultValue = action === "edit" ? title : "";

    return (
      <Fragment>
        {this._renderSelect()}

        <Input
          type="text"
          label={labels.seriesAndNumber}
          name="number"
          id="number-doc-modal"
          mask={this.mask}
          defaultValue={defaultValue}
          ref={this._refs.input}
          containerStyle={{
            marginTop: 15,
            marginLeft: 0,
            marginRight: 0
          }}
        />
      </Fragment>
    );
  };

  _renderSelect = () => {
    const {
      action,
      defaultData,
      translations: { sertificates, labels }
    } = this.props;

    const options = [
      {
        label: sertificates.vehicleCertificate,
        value: "STS"
      },
      {
        label: sertificates.driverLicense,
        value: "VU"
      }
    ];

    return (
      <Select
        defaultValue={action === "add" ? null : defaultData.type}
        label={labels.documentType}
        onSelect={this._handleSelect}
        options={options}
        ref={this._refs.select}
      />
    );
  };

  _renderDeleteAction = () => {
    const {
      defaultData: { title, typeLabel },
      translations: { edit }
    } = this.props;

    return (
      <div dangerouslySetInnerHTML={edit.deleteDoс.message(typeLabel, title)} />
    );
  };

  _renderFooter = () => {
    const {
      action,
      translations: { operations }
    } = this.props;

    let acceptText = operations.save;
    switch (String(action).toLowerCase()) {
      case "add": {
        acceptText = operations.add;
        break;
      }

      case "delete": {
        acceptText = operations.delete;
        break;
      }

      default:
        break;
    }

    return (
      <Fragment>
        <Button color="primary" outline onClick={this._handleAccept}>
          <span>{acceptText}</span>
          {this._acceptButtonLoading ? (
            <FontAwesomeIcon
              style={{ marginLeft: 10 }}
              icon={spinner}
              spin
              name="circle"
            />
          ) : null}
        </Button>
        <Button color="danger" outline onClick={this._handleAbort}>
          {operations.annulment}
        </Button>
      </Fragment>
    );
  };

  _allFieldsIsValid = async (type, number) => {
    const documentTypeStatement = await this._validateOneField(
      "documentType",
      type
    );
    let documentNumberStatement;
    if (type && String(type).length !== 0) {
      documentNumberStatement = await this._validateOneField(
        String(type).toUpperCase(),
        number
      );
    } else {
      documentNumberStatement = await this._validateOneField("number", number);
    }

    const select = this._refs.select.current;
    const input = this._refs.input.current;

    select.setErrorState(
      documentTypeStatement.success,
      documentTypeStatement.error
    );
    input.setErrorState(
      documentNumberStatement.success,
      documentNumberStatement.error
    );

    return documentNumberStatement.success && documentTypeStatement.success;
  };

  _handleSelect = ({ value }) => {
    if (value === "STS") {
      this.mask = "99 aa 999999";
    }

    if (value === "VU") {
      this.mask = "99 ** 999999";
    }

    this._refs.input.current.clear();
    this._refs.input.current.focus();
  };

  _handleAccept = async () => {
    const { action, onAccept, defaultData } = this.props;

    if (action === "add") {
      const selectedType = this._refs.select.current.value;
      const number = this._refs.input.current.value;

      if (await this._allFieldsIsValid(selectedType, number, "add")) {
        onAccept({
          action,
          item: { title: number, type: selectedType }
        });
      }

      return;
    }

    if (action === "edit") {
      const selectedType = this._refs.select.current.value;
      const number = this._refs.input.current.value;

      if (await this._allFieldsIsValid(selectedType, number, "edit")) {
        onAccept({
          action,
          oldItem: defaultData,
          newItem: { title: number, type: selectedType }
        });
      }

      return;
    }

    onAccept({ action, item: defaultData });
  };

  _handleAbort = () => {
    const { onAbort } = this.props;

    onAbort();
  };

  _validateOneField = async (name, value) => {
    const {
      validateDocument,
      defaultData,
      action,
      translations: {
        edit: { errors }
      }
    } = this.props;

    switch (name) {
      case "documentType": {
        return this._createStatement(
          this._valueIsNotEmpty(value),
          errors.docType
        );
      }

      case "number": {
        return this._createStatement(false, errors.notEmpty);
      }

      case "STS": {
        const newValue = value.replace(/\s/g, "");

        if (!this._valueIsNotEmpty(newValue)) {
          return this._createStatement(false, errors.notEmpty);
        }

        if (!/^\d{2}[a-zА-я]{2}\d{6}$/i.test(newValue)) {
          return this._createStatement(false, errors.stsFormat);
        }

        return this._createStatement(
          await validateDocument({
            id: defaultData.id,
            type: "STS",
            number: value,
            action
          }),
          errors.occupied
        );
      }

      case "VU": {
        const newValue = value.replace(/\s/g, "");

        if (!this._valueIsNotEmpty(newValue)) {
          return this._createStatement(false, errors.notEmpty);
        }

        if (!/^\d{2}[a-zА-я\d]{2}\d{6}$/i.test(newValue)) {
          return this._createStatement(false, errors.dlFormat);
        }

        return this._createStatement(
          await validateDocument({
            id: defaultData.id,
            type: "VU",
            number: value,
            action
          }),
          errors.occupied
        );
      }

      default: {
        return this._createStatement(false, errors.validError);
      }
    }
  };

  _createStatement = (success = true, error) => {
    return {
      success,
      error
    };
  };

  _valueIsNotEmpty = value => {
    return (
      value !== null &&
      typeof value !== "undefined" &&
      String(value).trim().length > 0
    );
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    action: PropTypes.oneOf(["add", "edit", "delete"]),
    defaultData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        type: PropTypes.string,
        title: PropTypes.string,
        typeLabel: PropTypes.string
      })
    ]),

    onAbort: PropTypes.func,
    onAccept: PropTypes.func,
    validateDocument: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    action: "add",
    defaultData: {
      id: 0,
      type: "Документ",
      title: "000000000"
    },

    onAbort: () => null,
    onAccept: () => null,
    validateDocument: async () => true
  };
}
