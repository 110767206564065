import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableRegistration = Loadable({
  loader: () => import('../Registration'),
  loading: Loader,
  delay: 400 // 0.3 seconds
});

export default LoadableRegistration;
