import React from "react";
import { Sessions } from "../View";
import { observer } from "mobx-react";
import UserStore from "../../../../../Stores/UserStore";
import { SectionBlock } from "../../../../../Components/ProfileComponents";
import ModalStore from "../../../../../Stores/ModalStore";
import SettingsStore from "../../../../../Stores/SettingsStore";
import PaymentStore from "../../../../../Stores/PaymentStore";
import { translate } from "react-i18next";
import CancelDefferedSessionBtn from "../../../../../Components/Common/Buttons/Payments/CancelDefferedSessionBtn";

@translate(["header"], { wait: true })
@observer
class DefferedSessionsContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      amountToPay: 0,
      isCancelModalOpen: false
    };
  }

  componentDidMount() {
    UserStore.loadProfileIfNeeded();
  }

  handlePayment = async values => {
    const result = await PaymentStore.cancelDefferedSession({ ...values });
    return result;
  };

  handleCancelDefferedSession = session => {
    ModalStore.openModal("cancelSession", {
      t: this.props.t,
      amountToPay: this.state.amountToPay,
      onAccept: this.handlePayment,
      paymentMethods: SettingsStore.settings.paymentsMethods,
      orderId: session.id,
      title: "Оплата отложенной сессии"
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <SectionBlock
          title={t("parking:defferedParkings:title")}
          isLoading={UserStore.profile.isLoading}
        >
          <div className="main-wrapper">
            <Sessions
              t={t}
              sessions={UserStore.getDefferedSessions()}
              onCancel={this.handleCancelDefferedSession}
              button={CancelDefferedSessionBtn}
            />
          </div>
        </SectionBlock>
      </>
    );
  }
}

export default DefferedSessionsContainer;
