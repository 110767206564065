import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';
import FormikSelect from '../../../../../Components/Common/Inputs/FormikSelect';
import FormikInput from '../../../../../Components/Common/Inputs/FormikInput';

import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Email должен быть валидным')
    .required('Это поле обязательно к заполнению'),
  phone: Yup.string().matches(
    /^[7|8|\\+7].[0-9]{9,10}$/,
    'Телефон должен быть валидным'
  ),
  topic: Yup.string().nullable(),
  message: Yup.string()
    .trim()
    .required('Это поле обязательно к заполнению'),
});

const initialValues = (user) => ({
  message: '',
  topic: '',
  email: user.email || '',
  phone: user.hasOwnProperty('telephone') ? user.telephone.phone : '',
});

function Feedback({ user, topics = [], onSubmit = null }) {
  const handleSubmit = async (values, { setStatus, resetForm }) => {
    if (onSubmit) {
      const response = await onSubmit(values);

      if (response) {
        if (response.status !== 'ok') {
          setStatus({ status: response.status, message: response.errorName });
        } else {
          resetForm({});
          setStatus({ status: response.status, message: response.userMessage });
          setTimeout(() => setStatus(null), 8000);
        }
      }
    }
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues(user)}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting, status }) => (
        <Form>
          {status ? (
            <Alert
              style={{ margin: '10px auto' }}
              color={status.status !== 'ok' ? 'danger' : 'success'}
            >
              {status.message}
            </Alert>
          ) : null}
          <Field
            placeholder="Телефон"
            label="Телефон"
            name="phone"
            fullWidth
            style={{
              marginBottom: 15,
            }}
            component={FormikInput}
          />
          <Field
            placeholder="E-mail"
            label="E-mail"
            name="email"
            fullWidth
            style={{
              marginBottom: 15,
            }}
            component={FormikInput}
          />
          <Field
            label="Выберите тему"
            name="topic"
            fullWidth
            items={topics}
            style={{
              marginBottom: 15,
            }}
            component={FormikSelect}
          />
          <Field
            placeholder="Сообщение"
            label="Сообщение"
            name="message"
            multiline
            fullWidth
            style={{
              marginBottom: 15,
            }}
            component={FormikInput}
          />
          <Button type="submit" color="primary" disabled={isSubmitting}>
            Отправить
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default Feedback;
