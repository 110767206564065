import ApiProvider from './APIProvider';

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
class FeedbackProvider {
  authHeader() {
    return { Authorization: `${ApiProvider.token}` };
  }

  async sendFeedback(feedback) {
    const response = await ApiProvider.APIRequest({
      method: 'post',
      path: '/api/appealProcessing/appealRegistration/v1',
      body: {
        ...feedback,
      },
      headers: this.authHeader(),
    });

    return response;
  }

  async loadMessages(feedbackId) {
    const response = await ApiProvider.APIRequest({
      method: 'get',
      path: '/api/appealProcessing/getMessages/v1',
      params: {
        idAppeal: feedbackId,
      },
      headers: this.authHeader(),
    });

    return response;
  }

  async loadShortFeedbackList() {
    const response = await ApiProvider.APIRequest({
      method: 'get',
      path: '/api/appealProcessing/getAppeals/v1',
      headers: this.authHeader(),
    });

    return response;
  }

  async sendMessageToFeedback(id, message) {
    const response = await ApiProvider.APIRequest({
      method: 'post',
      path: '/api/appealProcessing/messageRegistration/v1',
      body: {
        message,
        idAppeal: id,
      },
      headers: this.authHeader(),
    });

    return response;
  }
}

export default new FeedbackProvider();
