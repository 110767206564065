class TextFormatting {
  static joinName(name) {
    return [name.firstName, name.secondName, name.patronymic].join(' ');
  }

  static convertPhone(phone) {
    return String(phone)
      .replace(/[^\d+]/g, '')
      .replace(/[+]/g, '');
  }

  static formatingNumber(number, sepor = '', howMany = 1) {
    const strNum = number.toString();

    let start = strNum.length;
    let end;

    let result = [];

    while ((end = start) > 0) {
      start -= howMany;
      result.push(strNum.substring(start, end));
    }

    result.reverse();

    return result.join(sepor);
  }

  static latinToCirylic(string) {
    const mappingToCirylic = {
      a: 'а',
      c: 'с',
      e: 'e',
      x: 'х',
      o: 'о',
      h: 'н',
      p: 'р',
      k: 'к',
      m: 'м',

      A: 'А',
      C: 'С',
      E: 'Е',
      X: 'Х',
      O: 'О',
      H: 'Н',
      P: 'Р',
      K: 'К',
      M: 'М'
    };

    const mappedArray = [...string].map(character => {
      if (!mappingToCirylic[character]) {
        return character;
      }

      return mappingToCirylic[character];
    });

    return { origin: string, transformed: mappedArray.join('') };
  }

  static isUppercase(string) {
    if (string === string.toUpperCase()) {
      return true;
    }

    return false;
  }

  static toCase(string, isUpper) {
    if (isUpper) {
      return string.toUpperCase();
    }

    return string.toLowerCase();
  }
}

export default TextFormatting;
