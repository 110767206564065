import React, { Component, Fragment } from 'react';

import faUser from '@fortawesome/fontawesome-free-solid/faUser';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class ProfileButton extends Component {
    render () {
        return (
            <Fragment>
                { this.getProfileButton() }
            </Fragment>
        );
    }

    /**
     * @description Возвращает кнопку, если юзер залогинен
     */
    getProfileButton () {
        return (
            this.props.isLoggedIn
                ?
                null
                :
                <div className="login-btn-small">
                    <div onClick={ this.handleClick }>
                        <FontAwesomeIcon icon={ faUser }/>
                    </div>
                </div>
        );
    }

    handleClick = () => {
        this.props.onClick();
    }
}

export default ProfileButton;
