import React, { Component } from 'react';

import './style.css';

class Crumbs extends Component {
  render() {
    return <div className="crumbs parking-button__info">{this.props.text()}</div>;
  }
}

export default Crumbs;
