import React from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faRuble from '@fortawesome/fontawesome-free-solid/faRubleSign';

import TextFormatting from '../../utils/textFormatting';

function AbonementCost(props) {
  const { cost = 0 } = props;

  function formattingCost() {
    return TextFormatting.formatingNumber(cost, ' ', 3);
  }

  return (
    <div className="abonement__cost">
      {`${formattingCost()}.00`}
      <FontAwesomeIcon className="abonement__ruble-sign" icon={faRuble} />
    </div>
  );
}

export default AbonementCost;
