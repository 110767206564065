import React, { useState } from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';

import FromikSelect from '../../../../../Components/Common/Inputs/FormikSelect';
import AutoSubmit from './../../../../../Components/Common/Inputs/AutoSubmit';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const schema = () =>
  Yup.object().shape({
    paymentMethod: Yup.string().required()
  });

const initialValues = orderId => ({
  paymentMethod: '',
  orderId
});

const CancelSessionModal = ({
  t,
  closeModal,
  onAccept,
  paymentMethods = [],
  orderId,
  title,
  paymentMessage
}) => {
  const [paymentInfo, setPaymentInfo] = useState({
    amountToPay: null,
    redirectUrl: null
  });

  const normalizePaymentMethods = () => {
    return paymentMethods.map(method => ({
      label: method.methodNameForShow,
      value: method.methodName
    }));
  };

  const handlePaymentSubmit = async (values, { setSubmitting, setStatus }) => {
    if (onAccept && !paymentInfo.amountToPay) {
      const response = await onAccept(values);

      if (response.status !== 'ok') {
        setStatus(t(`errors:${response.errorName}`));
      } else {
        setPaymentInfo({ amountToPay: response.amountToPay, redirectUrl: response.redirectUrl });
        setStatus(null);
      }
    }
    setSubmitting(false);

    if (paymentInfo.amountToPay) {
      window.open(paymentInfo.redirectUrl, '_blank');
      closeModal();
    }
  };

  return (
    <Formik
      validationSchema={schema()}
      validateOnBlur={false}
      initialValues={initialValues(orderId)}
      onSubmit={handlePaymentSubmit}
    >
      {({ status, isSubmitting, submitForm, values, touched }) => (
        <>
          <Form>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
              {status ? <Alert color="danger">{status}</Alert> : null}
              {paymentInfo.amountToPay ? (
                <div>Сумма к оплате {paymentInfo.amountToPay} рублей</div>
              ) : null}
              {paymentInfo.amountToPay ? null : <div>Выберете метод оплаты чтобы продолжить</div>}
              {paymentInfo.amountToPay ? <p style={{ margin: '8px 0' }}>{paymentMessage}</p> : null}
              <div style={{ marginTop: 10 }}>
                <Field
                  id="paymentMethod"
                  name="paymentMethod"
                  items={normalizePaymentMethods()}
                  label={'Метод оплаты'}
                  acceptEmpty={false}
                  component={FromikSelect}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                disabled={!paymentInfo.amountToPay || isSubmitting}
              >
                Оплатить
              </Button>
              <Button color="primary" outline onClick={closeModal}>
                Отмена
              </Button>
            </ModalFooter>
          </Form>
          <AutoSubmit
            submitForm={submitForm}
            values={values}
            isValid={Object.keys(touched).length !== 0}
          />
        </>
      )}
    </Formik>
  );
};

export default CancelSessionModal;
