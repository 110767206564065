import React, { Component } from "react";
import { observer } from "mobx-react";

import OperationsTable from "../../../../Components/OperationsComponents/OperationsTable";
import TableLoader from "../../../../Components/OperationsComponents/OperationsTable/TableLoader";
import Scroll from "../../../../Components/Common/Scroll";

import UserStore from "../../../../Stores/UserStore";
import OperationsHistoryStore from "../../../../Stores/OperationsHistoryStore";
import { HAS_STATUS_IN_OPERATIONS } from "../../../../Config";

@observer
class OperationsContainer extends Component {
  constructor() {
    super();

    this.isFirstTime = true;
    this.isNeedToDownload = true;
    this.filters = OperationsHistoryStore.filters;

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    if (!this.isFilterSame()) {
      this.isNeedToDownload = true;
    }

    this.filters = OperationsHistoryStore.filters;
  }

  isFilterSame() {
    return OperationsHistoryStore.isFiltersSame(this.filters);
  }

  fetchData = async () => {
    const hasMoreOperations = await OperationsHistoryStore.fetchOperations();

    this.isFirstTime = false;
    this.isNeedToDownload = hasMoreOperations;
    this.forceUpdate();
  };

  isAllDataLoaded() {
    if (this.isFirstTime) {
      return UserStore.operations.done;
    }

    return this.isFilterSame() ? true : UserStore.operations.done;
  }

  render() {
    return (
      <Scroll
        onScrollToOffset={this.fetchData}
        isNeedToHandle={this.isNeedToDownload}
        loader={<TableLoader />}
      >
        <div className="operations">
          <OperationsTable
            hasStatus={HAS_STATUS_IN_OPERATIONS}
            isAllDataLoaded={this.isAllDataLoaded()}
          />
        </div>
      </Scroll>
    );
  }
}

export default OperationsContainer;
