import React, { Component } from 'react';
import Link from '../../Containers/Links/LngLink';

import { translate } from 'react-i18next';
import './style.css';

@translate(['notFound'], { wait: true })
export default class NoMatch extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="not-found">
        <h2 className="not-found__title">
          <span>{this.props.t('notFound:title')}</span>
        </h2>

        <div className="not-found__logo" />
        <p className="not-found__description">{t('notFound:description')}</p>
        <Link to={'/'}>{t('operations:backToHome')}</Link>
      </div>
    );
  }
}
