import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import UIStore from '../../../../Stores/UIStore';
import { Row, Col, Button, Modal } from 'reactstrap';
import Loader from '../../../../Components/Loader';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import signInAlt from '@fortawesome/fontawesome-free-solid/faSignInAlt';
import LoginStore from "../../../../Stores/LoginStore";

@translate(['loginForm'], { wait: true })
@observer
class SelectLoginType extends Component {

  render() {
    const Content = this.modalContent();
    return (
      <Fragment>
        <Modal
          autoFocus={false}
          isOpen={UIStore.loginModal}
          className="modal-login"
          onClosed={() => (this._isLoading = false)}
        >
          <Content />
        </Modal>
      </Fragment>
    );
  }

  modalContent = () => {
    return () => {
      return (
        <Fragment>
          {this.renderHeader()}
          {this.renderBody()}
        </Fragment>
      );
    };
  };

  renderHeader() {
      const { t } = this.props;
    return (
      <Row className="modal-login__header">
        <Col className="modal-login__enter" xs={10}>
            {t('loginForm:login')}
        </Col>
        <Col className="modal-login__close" xs={2}>
          <FontAwesomeIcon icon={faTimes} onClick={this.handleClose} />
        </Col>
      </Row>
    );
  }

  renderBody() {
    const { t, toLegal, toStandart } = this.props;
    return (
      <Row noGutters className="justify-content-center align-items-center">
        <Col xs={12} className="login__container">
              <div className=" modal-login__select-type">
                <div className="modal-login__footer-inner">
                  <Button
                      color="primary"
                      className="btn-small-round modal-login__submit"
                      onClick={toStandart}
                  >
                    {this.buttonIcon()}
                    <span className="modal-login__submit_inner">{t('loginForm:loginIndividual')}</span>
                  </Button>

                  <Button
                      color="primary"
                      className="btn-small-round modal-login__submit"
                      onClick={toLegal}
                  >
                    {this.buttonIcon()}
                    <span className="modal-login__submit_inner">{t('loginForm:loginLegalEntity')}</span>
                  </Button>
                </div>
              </div>
        </Col>
      </Row>
    );
  }

  buttonIcon() {
    return <FontAwesomeIcon icon={signInAlt} />;
  }

  handleClose = () => {
      const { onClose } = this.props;
      LoginStore.reset();
      // if (withReset) {
      //   ProfileParkingStore.setDefaultZoneNumber(null);
      // }
      UIStore.toggleLoginModal();
  };

}

export default SelectLoginType;
