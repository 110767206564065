import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import CarNumberStore from "../../../Stores/CarNumberStore";

export default class StateNumber extends PureComponent {
  constructor(props) {
    super(props);

    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;
  }

  render() {
    let { region, country, type, matches, className, children } = this.props;

    let MainNumber = this.renderMainNumber;

    const numberType = CarNumberStore.getType(type);
    const modificator = numberType ? `state-number--${numberType}` : "";
    const myClassName = `state-number ${modificator}`;

    return (
      <div className={`${className} ${myClassName}`}>
        <MainNumber matches={matches} />

        <div className="region">
          <div className="number">{region}</div>

          <div className="country">{country}</div>
          {children}
        </div>
      </div>
    );
  }

  renderMainNumber = props => {
    const { matches } = props;
    const { firstLetter, secondNumbers, lastLetters } = this.props;

    if (matches.length === 4) {
      return (
        <div className="main-number">
          <div className="first-letter">{matches[1]}</div>

          <div className="numbers">{matches[2]}</div>

          <div className="last-letters">{matches[3]}</div>
        </div>
      );
    }

    if (matches.length === 3) {
      const style = {
        main: { justifyContent: "center" },
        firstLetter: { marginRight: "10px" }
      };

      return (
        <div className="main-number" style={style.main}>
          <div className="first-letter" style={style.firstLetter}>
            {matches[1]}
          </div>

          <div className="last-letters">{matches[2]}</div>
        </div>
      );
    }

    return (
      <div className="main-number">
        <div className="first-letter">{firstLetter}</div>

        <div className="numbers">{secondNumbers}</div>

        <div className="last-letters">{lastLetters}</div>
      </div>
    );
  };

  static propTypes = {
    number: PropTypes.string,
    firstLetter: PropTypes.string,
    secondNumbers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastLetters: PropTypes.string,

    region: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country: PropTypes.string
  };

  static defaultProps = {
    number: null,

    firstLetter: "О",
    secondNumbers: "000",
    lastLetters: "ОО",

    region: "000",
    country: "RUS"
  };
}
