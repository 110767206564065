import { observable, action } from 'mobx';
import ApiProvider from './APIProvider';
import moment from 'moment';

class Penalty {
  @observable
  fetching = false;

  @observable
  done = false;

  @observable
  error = false;

  @observable
  penalty = [];

  @action
  loadPenalty = async () => {
    if (!this.fetching && !this.done) {
      this.fetching = true;
      this.done = false;
      try {
        const response = await ApiProvider.loadPenalty();
        if (response.status === 'ok') {
          this.penalty = this.normalizePenaltys(response.fines);
        } else {
          this.error = true;
        }
      } catch {
        this.error = true;
      }
      this.done = true;
      this.fetching = false;
    }
  };

  @action
  loadPenaltyPicture = async uin => {
    let penaltyList = [];
    for (let i = 0; i < this.penalty.length; i++) {
      if (this.penalty[i].uin === uin && !this.penalty[i].imgSrc) {
        const response = await ApiProvider.loadPenaltyPicture(uin);
        penaltyList.push({ ...this.penalty[i], imgSrc: response.image });
      } else {
        penaltyList.push(this.penalty[i]);
      }
    }
    this.penalty = penaltyList;
  };

  normalizePenaltys(penaltys) {
    if (penaltys) {
      return penaltys.map(penalty => ({
        fineStatus: penalty.fineStatus,
        uin: penalty.uin,
        date: moment(penalty.date).format('DD.MM.YYYY HH:mm'),
        totalSum: penalty.totalSum,
        paidSum: penalty.paidSum,
        sts: penalty.sts,
        grz: penalty.grz,
        start: moment(penalty.start).format('DD.MM.YYYY HH:mm'),
        end: moment(penalty.end).format('DD.MM.YYYY HH:mm'),
        place: penalty.place,
        type: penalty.type,
        provider: penalty.provider,
        color: this.getPenaltyColor(penalty.fineStatus)
      }));
    }
    return [];
  }

  getPenaltyColor(status) {
    switch (status) {
      case 'Оплачено':
        return '#28a745';

      case 'Приостановлено':
      case 'Отправлено':
      case 'Доставлено':
      case 'Отменено':
      case 'Подписано':
      case 'Возвращено':
        return '#6c757d';

      case 'ОтправленоВФССП':
        return '#dc3545';

      default:
        return 'black';
    }
  }
}

export default new Penalty();
