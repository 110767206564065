import React, { Component } from 'react';
import UserStore from '../../Stores/UserStore';
import { observer } from 'mobx-react';
import OptionsStore from '../../Stores/OptionsStore';
import Footer from '../../Components/Footer';
import { translate } from 'react-i18next';

@translate(['footer'], { wait: true })
@observer
class FooterContainer extends Component {
  static defaultProps = {
    noMenu: false
  };

  render() {
    return !OptionsStore.fetching ? (
      <Footer
        fixed
        tel={OptionsStore.contacts.phone}
        email={OptionsStore.contacts.email}
        menuItems={OptionsStore.footerMenuItems}
        {...this.props}
      />
    ) : null;
  }
}

export default FooterContainer;
