const updatePageText = {
  rostov: {
    title:
      '19 августа мы запускаем новый сайт и мобильное приложение для платного парковочного пространства г. Ростов-на-Дону',
    prefix: 'р61',
    appName: '"Парковки Ростова-на-Дону"',
    terminal: 'Элекснет'
  },
  sevastopol: {
    title:
      '11 апреля 2019 мы запускаем новый сайт и мобильное приложение для платного парковочного пространства г. Севастополя.',
    prefix: 'р92',
    appName: '"Парковки Севастополя"',
    terminal: 'PayBerry'
  }
};

export default updatePageText;
