import React, { Component } from "react";

function tickHoc(Comp) {
  return class Tick extends Component {
    constructor() {
      super();

      this.state = {
        date: new Date()
      };
    }

    componentDidMount() {
      this.initTimer();
    }

    componentWillUnmount() {
      this.removeTimer();
    }

    render() {
      return <Comp {...this.props} />;
    }

    initTimer() {
      this.timerID = setInterval(() => this.tick(), 1000);
    }

    tick() {
      if (this.props.onTick) {
        this.props.onTick();
      }
      this.setState({
        date: new Date()
      });
    }

    removeTimer() {
      clearInterval(this.timerID);
    }
  };
}

export default tickHoc;
