import React from 'react';

import Title from './Title';
import Description from './Description';
import SectionPayment from './SectionPayment';
import SectionRenew from './SectionRenew';
import SectionAdd from './SectionAdd';
import SectionCancel from './SectionCancel';
import AppContainer from './AppContainer';

import Apple from './imgs/Apple.svg';
import Google from './imgs/google.png';
import Yandex from './imgs/Yandex.svg';
import QRSev from './imgs/qr.png';
import QRRost from './imgs/qr-rost.png';

import OptionsStore from '../../Stores/OptionsStore';

import text from './text';
import { CITY_NAME } from '../../Config';
import './style/index.css';

function UpdatePage() {
  const city = CITY_NAME;

  function yandex() {
    if (city === 'sevastopol') {
      return (
        <AppContainer
          className="yandex"
          white
          url={Yandex}
          src={OptionsStore.appLinks.yandexStoreLink}
        />
      );
    }

    return '';
  }

  function qrCode() {
    return (
      <div className="qr">
        <p>
          {city === 'sevastopol'
            ? `Приложение для андроид можно скачать* по прямой ${(
                <a href={OptionsStore.appLinks.apkLink}>ссылке</a>
              )} или с помощью QR-кода`
            : null}
        </p>
        <img src={city === 'sevastopol' ? QRSev : QRRost} alt="qr code" />
      </div>
    );
  }

  function hint() {
    if (city === 'sevastopol') {
      return (
        <Description className="update-page__warning">
          *Обратите внимание, что в этом случае необходимо убрать ограничение на установку внешних
          приложений в настройках безопасности смартфона.
        </Description>
      );
    }

    return null;
  }

  return (
    <article className="update-page">
      <section className="line">
        <section className="update-page__update">
          <Title size="big" className="upper">
            Обновление сайта, приложения и новый номер для смс-команд
          </Title>
          <Description className="update-page__update-description">
            <p>{text[city].title}</p>
            <p>
              Обновление призвано упростить поиск информации, оплату парковки и пополнение баланса.
            </p>
          </Description>
          <div className="update-page__update-bg-oval" />
          <div className="update-page__update-bg" />
        </section>

        <section className="update-page__new-number">
          <Title size="lg">Важные изменения:</Title>
          <span className="update-page__number">7878</span>
          <Description size="big">новый короткий номер для СМС-команд</Description>
        </section>
      </section>
      <section className="update-page__new-commands">
        <div>
          <section>
            <Title size="lg">При этом СМС-команды остались прежними:</Title>
            <Description>СМС с командой отправлять на номер 7878</Description>
          </section>
          <SectionPayment />
          <SectionRenew />
          <SectionCancel />
          <SectionAdd />
        </div>
        <div className="update-page__new-commands-bg" />
      </section>

      <section className="update-page__about-us">
        <div className="update-page__about-us-inner">
          <Title size="lg">{text[city].appName}</Title>
          <Description>
            {`Новое мобильное приложение теперь называется ${text[city].appName} и доступно в
            AppStore, Google Play ${city === 'sevastopol' ? 'и Yandex Store.' : ''}`}
          </Description>
          <section className="update-page__apps">
            <AppContainer className="apple" url={Apple} src={OptionsStore.appLinks.iosLink} />
            <AppContainer className="google" url={Google} src={OptionsStore.appLinks.androidLink} />
            {yandex()}
            {qrCode()}
            {hint()}
          </section>
        </div>
        <div className="update-page__app-bg" />
      </section>
      <section className="update-page__footer">
        <Title size="lg">Прочие методы оплаты</Title>
        <div className="update-page__footer-inner">
          <ul className="update-page__another-payments">
            <li>Оплата с помощью паркомата</li>
            <li>{`Оплата через платежные терминалы ${text[city].terminal}`}</li>
            <li>Оплата через личный кабинет</li>
            <li>Абонементы</li>
          </ul>
          <div className="terminal-bg" />
        </div>
      </section>
    </article>
  );
}

export default UpdatePage;
