import React from 'react';
import Popup from 'reactjs-popup';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';

const textBlockStyle = {
  position: 'relative',
  padding: 15,
  borderRadius: 8,
  backgroundColor: '#f0f0f6',
  marginBottom: 10,
};

const titleStyle = {
  display: 'inline',
  marginRight: 5,
  fontWeight: '600',
  fontSize: 15.5,
};

const textMessage = {
  fontSize: 16,
  marginTop: 0,
  marginBottom: 0,
};

const time = {
  display: 'block',
  fontSize: 12,
  textAlign: 'right',
  opacity: 0.7,
};

const errorMenuItem = {
  cursor: 'pointer',
  padding: '4px 8px',
  fontSize: 12,
};

const textTitle = {
  textAlign: 'left',
};

const popupContent = {
  padding: 0,
  borderRadius: 3,
  width: 'auto',
  left: '50%',
};

const textMessageWrapper = { display: 'flex', justifyContent: 'space-between' };

const errorWrapper = { position: 'absolute', left: -22, top: 14 };
const errorIcon = { cursor: 'pointer' };

const TextBlock = ({ id, title, text, date, style, error, onResend }) => {
  const handleResend = () => {
    onResend(id);
  };

  return (
    <div style={{ ...textBlockStyle, ...style }}>
      <div>
        {title ? (
          <div style={textTitle}>
            <h2 style={titleStyle}>{title}</h2>
          </div>
        ) : null}

        <div style={textMessageWrapper}>
          <p style={textMessage}>{text}</p>
        </div>
        <time style={time} dateTime={date}>
          {date}
        </time>
      </div>
      {error ? (
        <Popup
          position="top left"
          on="hover"
          contentStyle={popupContent}
          trigger={
            <span
              style={errorWrapper}
              title="Возникла ошибка, повторить отправку?"
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                color="#fc3d39"
                style={errorIcon}
              />
            </span>
          }
        >
          <div className="error-info">
            <div
              className="error-info__item"
              style={errorMenuItem}
              onClick={handleResend}
            >
              Отправить еще раз
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default TextBlock;
