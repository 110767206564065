import React from "react";
import {
  NobalanceContainer,
  SessionsContainer,
  DefferedSessionsContainer
} from "./";
import PlanarPaymentContainer from "../../PlanarPayment/Container";
import { SectionBlock } from "../../../../../Components/ProfileComponents";
import { Row, Col, Button } from "reactstrap";
import { observer } from "mobx-react";
import { translate } from "react-i18next";
import SettingsStore from "../../../../../Stores/SettingsStore";
import ProfileParkingStore from "../../../../../Stores/ProfileParkingStore";
import ParkingCategory from "../../../../../constants/parkTypes";

@observer
@translate(["profile"], { wait: true })
class NoBalanceLayot extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedParkingType: "roadside"
    };
  }

  UNSAFE_componentWillMount() {
    if (ProfileParkingStore.parkingCode === ParkingCategory.PLANAR) {
      this.setState({ selectedParkingType: "planar" });
    }
  }

  componentWillUnmount() {
    ProfileParkingStore.setDefaultZoneNumber(null);
    ProfileParkingStore.parkingCode = null;
  }

  _compareValues = (currentValue, expectedValue, successValue, failValue) => {
    if (currentValue === expectedValue) {
      return successValue;
    }

    return failValue;
  };

  _getParkingTypeButtonClass = filter => {
    return this._compareValues(
      this.state.selectedParkingType,
      filter,
      "btn-options",
      "btn-options-grey"
    );
  };

  _getParkingTypeButtonColor = filter => {
    return this._compareValues(
      this.state.selectedParkingType,
      filter,
      "primary",
      "light"
    );
  };

  _setParkingType = event => {
    const target = event.target;

    if (target.getAttribute) {
      const filter = target.getAttribute("data-type");

      if (!!filter) {
        this.setState({ selectedParkingType: filter });
      }
    }
  };

  render() {
    return (
      <Row
        noGutters
        style={{ flex: 1 }}
        className="profile-parking justify-content-center"
      >
        <Col md={12} lg={8}>
          <Row
            noGutters
            className="justify-content-center flex-column-reverse flex-lg-row"
          >
            <Col md={12} lg={6}>
              <div className="md-card">
                <div className="info-field">
                  <div className="value">
                    <Button
                      className={this._getParkingTypeButtonClass("roadside")}
                      color={this._getParkingTypeButtonColor("roadside")}
                      data-type="roadside"
                      onClick={this._setParkingType}
                    >
                      {this.props.t("parking:payments:parkingZones:roadSide")}
                    </Button>
                    {SettingsStore.settings.useFlatParking ? (
                      <Button
                        className={this._getParkingTypeButtonClass("planar")}
                        color={this._getParkingTypeButtonClass("planar")}
                        data-type="planar"
                        onClick={this._setParkingType}
                      >
                        {this.props.t(
                          "parking:payments:parkingZones:flatBarrier"
                        )}
                      </Button>
                    ) : null}
                  </div>
                </div>
                <SectionBlock title={this.props.t("parking:payments:title")}>
                  {this.state.selectedParkingType === "planar" ? (
                    <PlanarPaymentContainer />
                  ) : (
                    <NobalanceContainer />
                  )}
                </SectionBlock>
              </div>
            </Col>

            <Col md={12} lg={6}>
              <div className="md-card">
                <SessionsContainer />
              </div>
              <div className="md-card">
                <DefferedSessionsContainer />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default NoBalanceLayot;
