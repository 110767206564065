import React from 'react';
import { Redirect } from 'react-router-dom';
import RootLngRoute from '../RootLngRoute';
import UserStore from '../../../Stores/UserStore';

function OnlyGuestRoute({ component: Component, ...rest }) {
  return (
    <RootLngRoute
      {...rest}
      render={props => {
        return !UserStore.isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
}

export default OnlyGuestRoute;
