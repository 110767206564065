import React from 'react';
import {DialogContent, DialogTitle, Typography, Button, DialogActions, DialogContentText} from "@material-ui/core";
import CloseButton from "../Buttons/CloseButton";
//import {CloseIcon} from '@material-ui/core/icon';

const NotifyModal = ({ title, message, closeModal,  className = '' }) => {

    const styles = {
        closeButton: {
            position: 'absolute',
            right: '1em',
            top: '1em',
            color: '#000',
        },
        modal: {
          background: '#fff',
          padding: '1em 1em 0 1em',
          borderRadius: '5px',
          minWidth: '450px',
        },
    }



  return (
      <div className={`${className}`} style={{...styles.modal}}>

          {title && <DialogTitle>
              <Typography variant="h6">{title}</Typography>
                  {/*<CloseButton onClose={closeModal} style={{...styles.closeButton}}/>*/}
          </DialogTitle>
          }
          <DialogContent>
              <DialogContentText>
                  {message}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={closeModal} color="primary">
                  Закрыть
              </Button>
          </DialogActions>
      </div>);
};

export default NotifyModal;
