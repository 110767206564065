import i18n from '../i18n/i18n';

class CloudPaymetStore {
  DESCRIPTION_TYPES = {
    REFILL: 'refill',
    PAYMENT: 'payment',
    EXTENSION: 'extension'
  };

  getDescription(type) {
    const { store, language } = i18n;

    return store.data[language].cloudPayments[type] || '';
  }
}

export default new CloudPaymetStore();
