import {
  PAYMENTSTATUSES_RU,
  PAYMENTSTATUSES_MAP_TO_EN
} from '../../Stores/OperationsHistoryStore/constants';

const operationsHistoryEng = {
  title: 'Operations history',
  error: 'Error',
  errorMessage: 'An error occurred in loading the operations history',
  tryAgain: 'Try again',
  emptyOperationsList: 'No operations',
  emptyOperationsListWithCriteria: 'No operations with the given criteria',
  skidka: 'Discount',
  parkVehicle: 'Zone/Parking',
  filters: {
    labels: {
      periodLabel: 'Period',
      operationTypeLabel: 'Operation type',
      vehicleNumberLabel: 'License plate',
      zoneLabel: 'Zone'
    },
    filterButtons: {
      period: {
        allTime: 'Whole period',
        yesterday: 'Yesterday',
        today: 'Today',
        thisWeek: 'This week',
        thisMonth: 'This month',
        myPeriod: 'Indicate period',
        from: 'From',
        to: 'To'
      }
    }
  },
  operationType: {
    all: 'All',
    payment: 'Payment',
    refill: 'Top Up',
    prolong: 'Extend',
    refund: 'Refund',
    interrupt: 'Cancel',
    paymentGate: 'Planar parking'
  },
  paymentStatuses: {
    wait: 'Waiting for payment',
    processing: 'Payment in process',
    done: 'Payed',
    cancel: 'Cancel',
    error: 'Error',
    ...PAYMENTSTATUSES_MAP_TO_EN
  },
  list: {
    labels: {
      date: 'Date',
      operation: 'Operation',
      amount: 'Amount',
      description: 'Description',
      status: 'Payment Status'
    },
    zone: 'Zone',
    parking: 'Parking'
  }
};

const operationsHistoryRu = {
  title: 'История операций',
  error: 'Ошибка',
  errorMessage: 'Произошла ошибка при загрузке истории операций',
  tryAgain: 'Попробовать еще раз',
  emptyOperationsList: 'Список операций пуст',
  emptyOperationsListWithCriteria: 'Операций с заданными критериями нет',
  skidka: 'Скидка',
  parkVehicle: 'Зона/Парковка',
  filters: {
    labels: {
      periodLabel: 'Период',
      operationTypeLabel: 'Тип операции',
      vehicleNumberLabel: 'Номер ТС',
      zoneLabel: 'Зона'
    },
    filterButtons: {
      period: {
        allTime: 'Все время',
        yesterday: 'Вчера',
        today: 'Сегодня',
        thisWeek: 'Эта неделя',
        thisMonth: 'Этот месяц',
        myPeriod: 'Указать период',
        from: 'От',
        to: 'До'
      }
    }
  },
  operationType: {
    all: 'Все',
    payment: 'Оплата',
    refill: 'Пополнение',
    prolong: 'Продление',
    refund: 'Возврат',
    interrupt: 'Прерывание',
    paymentGate: 'Плоскостная парковка'
  },
  paymentStatuses: {
    waiting: 'Ожидание оплаты',
    processing: 'Обработка платежа',
    done: 'Оплачено',
    cancel: 'Отменен',
    error: 'Ошибка',
    [PAYMENTSTATUSES_RU.done]: 'Оплачено',
    [PAYMENTSTATUSES_RU.cancel]: 'Отменен',
    [PAYMENTSTATUSES_RU.error]: 'Ошибка',
    [PAYMENTSTATUSES_RU.waiting]: 'Ожидание оплаты'
  },
  list: {
    labels: {
      date: 'Дата',
      operation: 'Операция',
      amount: 'Сумма',
      description: 'Описание',
      status: 'Статус платежа'
    },
    zone: 'Зона',
    parking: 'Парковка'
  }
};

export default {
  eng: operationsHistoryEng,
  ru: operationsHistoryRu
};
