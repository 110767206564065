const datesRu = {
  workingDay: 'рабочий день',
  week: 'неделя',
  mounth: 'месяц',
  quarter: 'квартал',
  year: 'год'
};

const datesEn = {
  workingDay: 'working day',
  weel: 'week',
  mounth: 'mounth',
  quarter: 'quarter',
  year: 'year'
};

export default {
  ru: datesRu,
  en: datesEn
};
