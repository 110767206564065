const PERIOD_FILTERS = {
  ALL: 'all',
  CUSTOM: 'custom',
  YESTERDAY: 'yesterday',
  MOUNTHLY: 'monthly',
  TODAY: 'today',
  WEEKLY: 'weekly'
};

export default PERIOD_FILTERS;
