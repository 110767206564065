import React, { Component } from "react";

import PropTypes from "prop-types";
import UserStore from "../../../Stores/UserStore";
import fitToWindow from "../../../utils/fitToElement";
export default class Select extends Component {
  _optionsContainerRef = React.createRef();
  _selectContainerRef = React.createRef();

  constructor(props) {
    super(props);

    this.blurCount = 0;

    this.props = props;
    this.state = this.initialState = {
      labelClass: "",
      opened: false,
      selectedValue: null,
      displayValue: null,

      empty: true,
      error: false,
      errorText: null
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.manual) {
      window.document.addEventListener("click", this._outClickEventHandler);
    } else this.initOpen();
  }

  componentDidMount() {
    this.initValues();
  }

  componentWillUnmount() {
    if (!this.props.manual)
      window.document.removeEventListener("click", this._outClickEventHandler);
  }

  componentDidUpdate() {
    if (this.state.opened)
      fitToWindow(
        document.documentElement,
        this._optionsContainerRef.current,
        10,
        true
      );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    if (this.props.manual) {
      this.initOpen();
    }

    this.forceUpdate();
  }

  render() {
    let {
      labelClass,
      opened,
      displayValue,
      error,
      errorText,
      empty
    } = this.state;

    const { label, className } = this.props;
    const errorClass = error ? " error" : "";
    errorText = error && errorText && !empty ? errorText : <span>&nbsp;</span>;

    return (
      <div
        className={`md-select${errorClass} ${className}`}
        ref={this._selectContainerRef}
      >
        <div className="select-box">
          <div className={`md-label${labelClass}`}>{label}</div>
          <div className="select-value">
            {!!displayValue ? displayValue : <span>&nbsp;</span>}
          </div>
        </div>

        <div className="md-error">{errorText}</div>

        <div
          className={`select-options${opened ? " active" : ""}`}
          tabIndex={-1}
          ref={this._optionsContainerRef}
        >
          {this.renderOption()}
        </div>
      </div>
    );
  }

  _outClickEventHandler = event => {
    const { opened, selectedValue } = this.state;

    const select = this._selectContainerRef.current;
    const target = event.target;

    if (select && !opened) {
      if (select.contains(target) || select === target) {
        this.setState({ labelClass: " active", opened: true });
        return false;
      }

      return true;
    }

    this.setState({ opened: false });

    if (!selectedValue) {
      this.setLabelClass("");
    }
  };

  setLabelClass = className => {
    this.setState({ labelClass: className });
  };

  handleOptionKeyDown = (event, option) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this._setValue(option);

      event.currentTarget.blur();
    }
  };

  _setValue = option => {
    const { onSelect } = this.props;
    onSelect({
      value: option.value,
      label: option.label,
      payload: option.payload
    });
    this.setState({ selectedValue: option.value, displayValue: option.label });
  };

  renderOption = () => {
    const { selectedValue } = this.state;
    const { filter, filterBy, options } = this.props;
    const filteredOptions = [];
    options.forEach((option, index) => {
      const selected = option.value === selectedValue ? " selected" : "";
      const element = (
        <div
          key={"md-option-" + index}
          tabIndex={0}
          className={`md-option${selected}`}
          onKeyDown={event => this.handleOptionKeyDown(event, option)}
          onBlur={this.handleBlur}
          onClick={() => {
            this._setValue(option);
          }}
        >
          {option.label}
        </div>
      );

      if (filter === false) {
        filteredOptions.push(element);
      } else {
        const isPayloadExist = option.hasOwnProperty("payload");
        const optionUpper = String(option.value).toUpperCase();
        const payloadUpper =
          isPayloadExist && option.payload.address
            ? option.payload.address.toUpperCase()
            : "";
        const isPhone = isPayloadExist ? option.payload.isPhone : false;

        const filterUpper = isPhone
          ? UserStore.convertPhone(filterBy).toUpperCase()
          : String(filterBy).toUpperCase();

        if (
          optionUpper.indexOf(filterUpper) !== -1 ||
          payloadUpper.indexOf(filterUpper) !== -1
        ) {
          filteredOptions.push(element);
        }
      }
    });

    return filteredOptions;
  };

  handleBlur = event => {
    this.blurCount++;

    const { options, onBlur } = this.props;

    const target = event.target;
    const parent = target.parentElement;

    if (this.blurCount > options.length - 1) {
      if (onBlur) this.props.onBlur(event);

      this.blurCount = 0;

      parent.classList.remove("active");
    }
  };

  get value() {
    return this.state.selectedValue;
  }

  reset = () => {
    this.setState(this.initialState);
    this.initValues();
  };

  setErrorState = (isValid = true, description) => {
    this.setState({
      empty: false,
      error: !isValid,
      errorText: description
    });
  };

  initOpen() {
    this.setState({
      opened: this.props.open
    });
  }

  initValues() {
    const { defaultValue, options } = this.props;

    if (!!defaultValue) {
      this.setLabelClass(" active");
      const selectedOption = options.find(option => {
        return option.value === defaultValue.value;
      });

      const displayValue = selectedOption ? selectedOption.label : null;
      this.setState({ selectedValue: defaultValue.value, displayValue });

      return;
    }

    this.setState({ labelClass: "", selectedValue: null, displayValue: null });
  }

  static propTypes = {
    label: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.any,
          value: PropTypes.any
        })
      )
    ]),
    defaultValue: PropTypes.any,
    open: PropTypes.bool,
    filter: PropTypes.bool,
    manual: PropTypes.bool,

    containerStyle: PropTypes.object,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    label: null,
    options: [],
    defaultValue: null,
    open: false,
    filter: false,
    manual: false,

    containerStyle: {},
    onSelect: () => null
  };
}
