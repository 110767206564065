import React, { Component } from 'react';
import { translate } from 'react-i18next';

@translate(['operationsHistory'], { wait: true })
export default class TableHead extends Component {
  render() {
    const { t, hasStatus } = this.props;
    return (
      <thead>
        <tr>
          <th>{t('operationsHistory:list:labels:date')}</th>
          <th>{t('operationsHistory:list:labels:operation')}</th>
          <th>{t('operationsHistory:list:labels:amount')}</th>
          {hasStatus ? <th>{t('operationsHistory:list:labels:status')}</th> : null}
          <th>{t('operationsHistory:list:labels:description')}</th>
        </tr>
      </thead>
    );
  }
}
