import i18n from '../i18n/i18n';

class Translations {
  constructor() {
    this.translateData = i18n.store.data;
  }

  LANGUAGES = {
    ru: 'ru-RU',
    en: 'en-US'
  };

  getLanguage() {
    return this.LANGUAGES[i18n.language];
  }

  getProfiletTranslation = () => {
    for (let lang in this.translateData) {
      if (lang === i18n.language) {
        if (this.translateData.hasOwnProperty(lang)) return this.translateData[lang].profile;
      }
    }
  };

  getParkingTranslation = () => {
    for (let lang in this.translateData) {
      if (lang === i18n.language) {
        if (this.translateData.hasOwnProperty(lang)) return this.translateData[lang].parking;
      }
    }
  };

  getOperationTranslation = () => {
    for (let lang in this.translateData) {
      if (lang === i18n.language) {
        if (this.translateData.hasOwnProperty(lang)) return this.translateData[lang].operations;
      }
    }
  };

  getErrorTranslation = () => {
    for (let lang in this.translateData) {
      if (lang === i18n.language) {
        if (this.translateData.hasOwnProperty(lang)) return this.translateData[lang].errors;
      }
    }
  };

  getRegistrationSteps = () => {
    return [
      {
        title: i18n.t('common:step', { count: 1 }),
        description: i18n.t('registration:firstStep')
      },
      {
        title: i18n.t('common:step', { count: 2 }),
        description: i18n.t('registration:secondStep')
      },
      {
        title: i18n.t('common:step', { count: 3 }),
        description: i18n.t('registration:thirdStep')
      }
    ];
  };
}

export default new Translations();
