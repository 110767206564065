import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import SpinnerButton from '../../../../../Components/Common/Buttons/SpinnerButton';
import { FormikInput } from '../../../../../Components/Common/Inputs';
import PaymentMethodSelector from '../../../../../Components/ProfileComponents/Payment/PaymentMethodSelector';
import Alert from 'reactstrap/lib/Alert';
import FormGroup from 'reactstrap/lib/FormGroup';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faRuble from '@fortawesome/fontawesome-free-solid/faRubleSign';

const schema = t =>
  Yup.object().shape({
    cardNumber: Yup.string().required(t('errors:RequiredField')),
    paymentService: Yup.string().required(t('errors:RequiredField'))
  });

const Planar = ({ t, onSubmit, cost = null, paymentService }) => {
  const handleParkingSubmit = async (values, { resetForm, setStatus }) => {
    if (onSubmit) {
      await onSubmit(values, { resetForm, setStatus });
    }
  };

  const submitText = () => {
    if (Boolean(cost) && cost === 0) {
      return t('reservation:parking:free');
    }

    if (Boolean(cost)) {
      return t('operations:payForParking');
    }

    return t('operations:getPrice');
  };

  return (
    <div className="main-wrapper">
      <Formik
        initialValues={{
          cardNumber: '',
          paymentService
        }}
        validateOnBlur={false}
        validationSchema={schema(t)}
        onSubmit={handleParkingSubmit}
      >
        {({ isSubmitting, status = null, isValid, setFieldValue }) => (
          <Form>
            {status ? (
              <Alert
                style={{ margin: '10px auto' }}
                color={status.status !== 'ok' ? 'danger' : 'success'}
              >
                {status.message}
              </Alert>
            ) : null}
            <FormGroup>
              <Field
                fullWidth
                label={t('parking:payments:labels:cardNumber')}
                name="cardNumber"
                component={FormikInput}
              />
            </FormGroup>
            <FormGroup>
              <PaymentMethodSelector
                onMethodSelect={methodName => {
                  setFieldValue('paymentService', methodName);
                }}
              />
            </FormGroup>
            {isValid && cost ? (
              <div>
                {t('parking:payments:costParking')}: {cost}{' '}
                <FontAwesomeIcon style={{ fontSize: 10, fontWeight: 100 }} icon={faRuble} />
              </div>
            ) : null}

            <SpinnerButton
              color="primary"
              type="submit"
              className="submit"
              style={{ marginTop: 10 }}
              isLoading={isSubmitting}
              disabled={!isValid || isSubmitting}
            >
              {submitText()}
            </SpinnerButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Planar;
