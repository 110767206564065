import React, { Component, Fragment } from "react";
import EditableList from "../EditableList";
import FieldPreLoader from "../../FieldPreLoader";

import PropsTypes from "prop-types";

export default class DocumentsList extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    this.forceUpdate();
  }

  render() {
    const {
      items,
      onEditButtonClick,
      onRemoveButtonClick,
      isLoading,
      translations
    } = this.props;

    if (isLoading) {
      return (
        <Fragment>
          <FieldPreLoader
            visible
            delay={500}
            style={{ height: 30, marginBottom: 10 }}
          />
          <FieldPreLoader visible delay={500} style={{ height: 30 }} />
        </Fragment>
      );
    }

    if (!items || items.length === 0) {
      return <div className="text-secondary">{translations.emptyList}</div>;
    }

    return (
      <EditableList
        onEditButtonClick={onEditButtonClick}
        onRemoveButtonClick={onRemoveButtonClick}
        items={items}
        renderItem={this._renderItem}
      />
    );
  }

  _renderItem = item => {
    return (
      <Fragment>
        <div className="document-title">{item.title}</div>

        {item.type ? (
          <div className="document-description">{item.typeLabel}</div>
        ) : null}
      </Fragment>
    );
  };

  static propTypes = {
    items: PropsTypes.oneOfType([
      PropsTypes.object,
      PropsTypes.arrayOf(
        PropsTypes.shape({
          title: PropsTypes.string.isRequired,
          type: PropsTypes.oneOf(["STS", "VU"]),
          typeLabel: PropsTypes.string
        })
      )
    ]),

    isLoading: PropsTypes.bool,

    onEditButtonClick: PropsTypes.func,
    onRemoveButtonClick: PropsTypes.func
  };

  static defaultProps = {
    items: [],
    isLoading: false,

    onEditButtonClick: () => null,
    onRemoveButtonClick: () => null
  };
}
