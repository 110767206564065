import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, Select } from '../../MaterialDesign';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import spinner from '@fortawesome/fontawesome-free-solid/faSpinner';

import UserStore from '../../../Stores/UserStore';
import CarNumberStore from '../../../Stores/CarNumberStore';

import TextFormatting from '../../../utils/textFormatting';

import PropTypes from 'prop-types';

export default class ChangeTransportModal extends Component {
  // _inputNameRef = React.createRef();     НЕ ПОДДЕРЖИВОЕМОЕ ПОЛЕ
  _inputCodeRef = React.createRef();
  _stsInput = React.createRef();
  _checkboxRef = React.createRef();
  _selectCategoryRef = React.createRef();
  motoCheckboxRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>{this._renderHeader()}</ModalHeader>
        <ModalBody className="change-modal">{this._renderBody()}</ModalBody>
        <ModalFooter className="justify-content-start">{this._renderFooter()}</ModalFooter>
      </Modal>
    );
  }

  _renderHeader = () => {
    const {
      action,
      translations: { edit }
    } = this.props;

    switch (String(action).toLowerCase()) {
      case 'add': {
        return edit.tsEdit.addTM.title;
      }

      case 'edit': {
        return edit.tsEdit.editTM.title;
      }

      case 'delete': {
        return edit.tsEdit.deleteTM.title;
      }

      default: {
        return action;
      }
    }
  };

  _renderBody = () => {
    const { action } = this.props;

    switch (String(action).toLowerCase()) {
      case 'add': {
        return this._renderAddOrEditAction();
      }

      case 'edit': {
        return this._renderAddOrEditAction();
      }

      case 'delete': {
        return this._renderDeleteAction();
      }

      default: {
        console.warn('Unknown action - ' + action);
        return null;
      }
    }
  };

  _renderAddOrEditAction = () => {
    const {
      action,
      defaultData: { number, region, formatGrz, category },
      translations,
      translations: { edit, anotherTs }
    } = this.props;
    let defaultNumber = null;
    let defaultCheck = null;
    let defaultCategory = null;

    if (action === 'edit') {
      defaultNumber = !UserStore.getGrzFormat(formatGrz) ? number + region : number;
      defaultCheck = UserStore.getGrzFormat(formatGrz);
      defaultCategory = category ? { label: category, value: category } : null;
    }

    const categoryOptions = [
      { label: 'A', value: 'A' },
      { label: 'B', value: 'B' },
      { label: 'C', value: 'C' },
      { label: 'D', value: 'D' },
      { label: 'M', value: 'M' }
    ];
    const defaultCategoryOption = defaultCategory || categoryOptions[1];

    return (
      <Fragment>
        {this.renderError()}

        <Input
          type="text"
          name="number"
          key="51"
          ref={this._inputCodeRef}
          label={edit.tsEdit.labels.registrationNumber}
          defaultValue={defaultNumber}
          id="number-ts-modal"
          containerStyle={{ marginLeft: 0, marginRight: 0 }}
        />

        <Select
          options={categoryOptions}
          label={translations.category}
          defaultValue={defaultCategoryOption}
          ref={this._selectCategoryRef}
        />

        <Input
          containerStyle={{ margin: 0, fontSize: '12px' }}
          label={anotherTs}
          ref={this._checkboxRef}
          name="tsType"
          id="tsType"
          defaultChecked={defaultCheck}
          type="checkbox"
        />

        {this._renderSelect()}
      </Fragment>
    );
  };

  _renderDeleteAction = () => {
    const {
      defaultData: { number, region, formatGrz },
      translations: { edit }
    } = this.props;
    const message = !UserStore.getGrzFormat(formatGrz)
      ? edit.tsEdit.deleteTM.message(this._transformNumber(number), region)
      : edit.tsEdit.deleteTM.message(this._transformNumber(number), '');

    return (
      <Fragment>
        {this.renderError()}
        <div dangerouslySetInnerHTML={message} />
      </Fragment>
    );
  };

  _renderFooter = () => {
    const {
      action,
      translations: { operations }
    } = this.props;

    let acceptText = operations.save;

    switch (String(action).toLowerCase()) {
      case 'add': {
        acceptText = operations.add;
        break;
      }

      case 'delete': {
        acceptText = operations.delete;
        break;
      }

      default:
        break;
    }

    const acceptButtonClassName =
      String(action)
        .toLowerCase()
        .replace(/\s/g, '-') + '-btn';

    return (
      <Fragment>
        <Button color="primary" className={acceptButtonClassName} onClick={this._handleAccept}>
          <span>{acceptText}</span>
          {this.props.isLoading ? (
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={spinner} spin name="circle" />
          ) : null}
        </Button>
        <Button color="primary" outline className={'abort-btn'} onClick={this._handleAbort}>
          {operations.annulment}
        </Button>
      </Fragment>
    );
  };

  _renderSelect = () => {
    const {
      action,
      defaultData: { STSNumber },
      // documents,
      translations
    } = this.props;
    // if (!documents || documents.length === 0) {
    //   return null;
    // }

    let defaultValue = '';

    if (action === 'edit' && !!STSNumber) {
      defaultValue = STSNumber;
    }

    // const items = documents.map(document => {
    //   return {
    //     label: document.title,
    //     value: document.title,
    //   };
    // });

    return (
      <Input
        type="text"
        label={translations.stsNumber}
        name="STSnumber"
        id="STSnumber"
        defaultValue={defaultValue}
        mask={'99 aa 999999'}
        formatChars={{ a: '[А-Яа-я0-9]', '9': '[0-9]' }}
        ref={this._stsInput}
        spellCheck={false}
        containerStyle={{
          marginTop: 15,
          marginLeft: 0,
          marginRight: 0
        }}
      />
    );
  };

  renderError = () => {
    const {
      error,
      translations: { errors }
    } = this.props;

    return error ? (
      <Row className="justify-content-center">
        <Col xs={11}>
          <div className="alert alert-danger"> {errors.actionError} </div>
        </Col>
      </Row>
    ) : null;
  };

  _transformNumber = number => {
    return String(number)
      .replace(/\s/g, '')
      .replace(/^([A-zА-я])(\d{3})([A-zА-я]{2})(\d{2,3})$/i, '$1 $2 $3 $4')
      .toUpperCase();
  };

  _validateNumber = async (value, formatGrz) => {
    const {
      action,
      defaultData: { id },
      validateNumber,
      translations: { errors }
    } = this.props;

    if (value.length === 0) {
      return this._createStatement(false, errors.notEmpty);
    }

    if (value.length > 9) {
      return this._createStatement(false, errors.maxLength);
    }

    if (!formatGrz) {
      const { isValid } = CarNumberStore.validateNumberByType(value);

      if (!isValid) {
        return this._createStatement(false, errors.format);
      }
    }

    const type = !formatGrz ? CarNumberStore.validateNumberByType(value).type : '';
    const split = !formatGrz ? this._splitNumber(value, type) : value;

    return this._createStatement(
      await validateNumber({
        number: !formatGrz ? split.number : value,
        region: !formatGrz ? split.region : '',
        action,
        id,
        formatGrz
      }),
      errors.occupied
    );
  };

  _validateSts = async value => {
    const {
      action,
      defaultData: { id },
      translations: { errors },
      translations: { edit },
      validateSTS
    } = this.props;

    const newValue = value.replace(/\s/g, '');

    if (newValue !== '' && !/^\d{2}[a-zА-я0-9]{2}\d{6}$/i.test(newValue)) {
      return this._createStatement(false, edit.docEdit.errors.stsFormat);
    }
    if (newValue !== '')
      return this._createStatement(
        await validateSTS({
          number: newValue,
          action,
          id
        }),
        edit.docEdit.errors.occupied
      );

    return this._createStatement(true, '');
  };

  _createStatement = (success = true, error) => {
    return {
      success,
      error
    };
  };

  _splitNumber = (number, type) => {
    try {
      const regExp = CarNumberStore.numberTemplates[type];
      const split = String(number).match(regExp);

      CarNumberStore.getParts(split);

      return CarNumberStore.getParts(split);
    } catch (e) {
      return { number: null, region: null };
    }
  };

  getCountry() {
    const formatGrz = this._checkboxRef.current.value || false;

    return formatGrz ? 'other' : 'rus';
  }

  _handleAccept = async () => {
    const { onAccept, defaultData, action } = this.props;

    if (action === 'delete') {
      onAccept({ action, item: defaultData });

      return;
    }

    const { RUSSIA, FOREIGN } = UserStore.GRZ_FORMATS;

    const formatGrz = this._checkboxRef.current.value || false;
    const formatGrzText = formatGrz ? FOREIGN : RUSSIA;

    let value = this._inputCodeRef.current.value.toUpperCase();
    const stsValue = this._stsInput.current.value.toUpperCase();
    const categoryValue = this._selectCategoryRef.current.value;

    value = !formatGrz
      ? TextFormatting.latinToCirylic(value).transformed.replace(/\s+/g, '')
      : value.replace(/\s+/g, '');

    const formatedValue = String(value)
      .replace(/\s/g, '')
      .toUpperCase()
      .replace(/\s+/g, '');
    const formatedStsValue = String(stsValue)
      .replace(/\s/g, '')
      .toUpperCase()
      .replace(/\s+/g, '');

    const type = CarNumberStore.validateNumberByType(formatedValue).type;
    let { number, region } = this._splitNumber(formatedValue, type);
    number = number.replace(' ', '');

    const statement = await this._validateNumber(formatedValue, formatGrz);
    const stsStatement = await this._validateSts(formatedStsValue);
    const categoryStatement = this.validateCategory(type, categoryValue);

    this._stsInput.current.setErrorState(stsStatement.success, stsStatement.error);
    this._inputCodeRef.current.setErrorState(statement.success, statement.error);

    this._selectCategoryRef.current.setErrorState(
      categoryStatement.success,
      categoryStatement.error
    );

    if (statement.success && stsStatement.success && categoryStatement.success) {
      //Выпиленное поле "name"
      // const name = this._inputNameRef.current.value;

      const STSNumber = this._stsInput.current
        ? this._stsInput.current.value.split(' ').join('')
        : '';

      let item = {};

      if (action === 'add') {
        if (formatGrz) {
          item = {
            number: value,

            STSNumber,
            country: this.getCountry(),
            formatGrz: formatGrzText,
            docType: 'СвидетельствоОРегистрацииТС',
            category: categoryValue,
            mainTs: defaultData.mainTs
          };

          onAccept({ action, item });
          return;
        }

        item = {
          // name,
          number,
          region,

          STSNumber,
          country: this.getCountry(),
          formatGrz: formatGrzText,
          docType: 'СвидетельствоОРегистрацииТС',
          category: categoryValue,
          mainTs: defaultData.mainTs
        };

        onAccept({ action, item });

        return;
      }

      if (formatGrz) {
        item = {
          number: value,

          STSNumber,
          country: this.getCountry(),
          formatGrz: formatGrzText,
          docType: 'СвидетельствоОРегистрацииТС',
          category: categoryValue,
          mainTs: defaultData.mainTs
        };
        onAccept({ action, oldItem: defaultData, newItem: item });
        return;
      }

      item = {
        id: defaultData.id,
        // name,
        number,
        region,

        country: this.getCountry(),
        formatGrz: formatGrzText,
        STSNumber,
        docType: 'СвидетельствоОРегистрацииТС',
        category: categoryValue,
        mainTs: defaultData.mainTs
      };
      onAccept({ action, oldItem: defaultData, newItem: item });
    }
  };

  validateCategory(type, category) {
    const {
      translations: { errors }
    } = this.props;
    const isEmpty = !category;
    const isTypeInSync = CarNumberStore.isCategoryInSyncWithType(type, category);
    if (isEmpty) {
      return { success: false, error: errors.notEmpty };
    }

    if (!isTypeInSync) {
      return { success: false, error: 'Категория авто не совпадает с его типом' };
    }

    return { success: true, error: false };
  }

  _handleAbort = () => {
    const { onAbort } = this.props;

    onAbort();
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    action: PropTypes.oneOf(['add', 'edit', 'delete']),
    defaultData: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      number: PropTypes.string,
      region: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      country: PropTypes.string,

      STSNumber: PropTypes.string
    }),

    documents: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          type: PropTypes.string,
          title: PropTypes.string,
          typeLabel: PropTypes.string
        })
      )
    ]),

    validateNumber: PropTypes.func,

    onAbort: PropTypes.func,
    onAccept: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    action: 'add',

    defaultData: {
      id: null,
      number: null,
      region: null,
      country: null,
      STSNumber: null
    },

    documents: [],
    validateNumber: async () => true,

    onAbort: () => null,
    onAccept: () => null
  };
}
