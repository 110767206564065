import React from 'react';
import Link from '../../../../Containers/Links/LngLink';

import logoSvg from '../../../../logo.svg';

function HeaderLogo(props) {
  return (
    <Link
      to="/"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img alt="logo" src={logoSvg} width="40" height="40" />
      <span className="logo-title">{props.children}</span>
    </Link>
  );
}

export default HeaderLogo;
