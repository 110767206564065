import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import { EditGroup, EditButton, DeleteButton } from "../../Common/Edit";

import SetMainPhoneButton from "../../../Components/Common/Buttons/SetMainPhoneButton";
import FieldPreLoader from "../../FieldPreLoader";

import FontAwesome from "@fortawesome/react-fontawesome";
import plus from "@fortawesome/fontawesome-free-solid/faPlus";

import "./index.css";
import { IS_EASY_REGISTRATION } from "../../../Config";

export default class MainInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removePhoneModalIsOpen: false,
      selectedPhone: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;
  }

  handlePhoneChange = phone => {
    const { onChangePhoneClick } = this.props;
    return () => {
      return onChangePhoneClick(phone);
    };
  };

  handlePhoneRemove = phone => {
    return () => {
      this.openRemovePhoneModal(phone);
    };
  };

  renderPhone = () => {
    const { allPhones, phone } = this.props;
    if (allPhones.length === 0) {
      return <div className="value">{phone}</div>;
    }

    if (allPhones.length === 1) {
      return <div className="value">{allPhones[0].phone}</div>;
    } else {
      return <div style={{ marginTop: 15 }}>{this.renderPhoneRows()}</div>;
    }
  };

  renderPhoneRows() {
    const { allPhones } = this.props;

    return allPhones.map((phone, i) => {
      return (
        <div key={i} className={"profile-phone"}>
          <div style={{ marginBottom: 10 }} className="value">
            <span
              className={`${phone.isMain ? "main-mark" : ""}`}
              style={{
                color: "#555"
              }}
            >
              <span className={"phone-value"}>{phone.phone}</span>
            </span>
          </div>
          {this.renderEditGroup(phone)}
        </div>
      );
    });
  }

  renderEditGroup(phone) {
    return (
      <EditGroup>
        <SetMainPhoneButton phone={phone.phone} primary={phone.isMain} />
        {phone.isMain ? (
          <EditButton onClick={this.handlePhoneChange(phone.phone)} />
        ) : null}
        {!phone.isMain ? (
          <DeleteButton onClick={this.handlePhoneRemove(phone.phone)} />
        ) : null}
      </EditGroup>
    );
  }

  openRemovePhoneModal = phone => {
    this.setState({
      currentPhone: phone
    });
    this.removePhoneModalToggle();
  };

  removePhoneModalToggle = () => {
    this.setState({
      removePhoneModalIsOpen: !this.state.removePhoneModalIsOpen
    });
  };

  renderRemovePhoneModal = () => {
    let { removePhoneModalIsOpen } = this.state;
    const { translations, deletePhone } = this.props;
    return (
      <Modal
        className="edit-profile-modal"
        size="md"
        isOpen={removePhoneModalIsOpen}
        onExit={() => {
          this.setState({ removePhoneModalIsOpen: false });
        }}
      >
        <ModalBody>
          {translations.edit.editPhoneNumber.removePhoneModalText}
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-st",
              width: "100%"
            }}
          >
            <Button
              color="gray"
              onClick={() => {
                deletePhone(this.state.currentPhone);
                this.removePhoneModalToggle();
              }}
              className="change-button"
            >
              {translations.edit.editPhoneNumber.confirm}
            </Button>
            <Button
              color="gray"
              onClick={this.removePhoneModalToggle}
              className="change-button"
            >
              {translations.edit.editPhoneNumber.abort}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  renderAddPhoneButton() {
    const { translations } = this.props;
    if (!IS_EASY_REGISTRATION) {
      return (
        <div>
          <Button
            color="light"
            className="action-button"
            onClick={this.props.onAddPhoneClick}
          >
            <div>
              <FontAwesome icon={plus} />
            </div>
            <span
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              }}
            >
              {translations.edit.editPhoneNumber.add}
            </span>
          </Button>
        </div>
      );
    }
  }

  render() {
    const {
      name,
      email,
      isLoading,
      translations,
      allPhones,
      hasPrivateData
    } = this.props;

    return (
      <Fragment>
        {this.renderRemovePhoneModal()}
        <div className="info-field">
          {isLoading ? (
            <FieldPreLoader visible style={{ width: 300 }} />
          ) : hasPrivateData ? (
            <Fragment>
              <div className="name">{translations.fullName}</div>
              <div className="value">{name}</div>
            </Fragment>
          ) : null}
        </div>
        <div className="info-field">
          {isLoading ? (
            <FieldPreLoader visible style={{ width: 200 }} />
          ) : (
            <Fragment>
              <div className="name">E-mail:</div>
              <div className="value">
                {email || translations.emailUndefined}
              </div>
            </Fragment>
          )}
        </div>

        <div className="info-field">
          {isLoading ? (
            <FieldPreLoader visible style={{ width: 250 }} />
          ) : (
            <Fragment>
              <div
                className="name"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  {allPhones.length > 1
                    ? translations.phones
                    : translations.phone}
                </div>
                {this.renderAddPhoneButton()}
              </div>
              {this.renderPhone()}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }

  static propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    allPhones: PropTypes.array,
    isLoading: PropTypes.bool,
    deletePhone: PropTypes.func
  };

  static defaultProps = {
    name: "",
    email: "",
    phone: null,
    allPhones: null,
    isLoading: false,
    deletePhone: () => {},
    hasPrivateData: true
  };
}
