import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import UIStore from '../../Stores/UIStore';
import PathStore from '../../Stores/PathStore';

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

@translate(['auth'], { wait: true })
@observer
class SimpleModal extends Component {
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Modal
          autoFocus={false}
          className=""
          isOpen={UIStore.simpleModal}
          onClosed={() => {
            UIStore.simpleModal = false;
            this.props.history.push(PathStore.pathWithLang());
            UIStore.toggleLoginModal();
          }}
          toggle={UIStore.toggleSimpleModal}
        >
          <ModalBody>{t('auth:tokenExpired')}</ModalBody>
          <ModalFooter>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-st',
                width: '100%'
              }}
            >
              <Button
                color="gray"
                onClick={() => {
                  UIStore.simpleModal = false;
                }}
                className="change-button"
              >
                OK
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default SimpleModal;
