import React, { useState } from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';

const ConfirmModal = ({ message, closeModal, onAccept, title }) => {
  const [status, setStatus] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleAccept = async () => {
    setSubmitting(true);
    const result = await onAccept();
    setSubmitting(false);
    setStatus(result.status);
  };

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {status ? (
          <Alert color={status === 'ok' ? 'success' : 'danger'}>
            {status === 'ok' ? 'Сессия успешно отменена' : 'Произошла ошибка'}
          </Alert>
        ) : null}
        {message}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isSubmitting || status}
          color="primary"
          onClick={handleAccept}
        >
          Подтвердить
        </Button>
        <Button color="primary" outline onClick={closeModal}>
          Отмена
        </Button>
      </ModalFooter>
    </>
  );
};

export default ConfirmModal;
