import React, { Component, createRef } from "react";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import moment from "moment";

class Timer extends Component {
  constructor() {
    super();

    this.state = {
      date: new Date()
    };

    this.progressBarContainer = createRef();

    this.textStyles = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -120%)",
      fontSize: "15px",
      fontWeight: 600
    };
  }

  componentDidMount() {
    if (this.props.selfLess) this.initTimer();

    this.setTextTransform();
  }

  componentWillUnmount() {
    if (this.props.selfLess) this.removeTimer();
  }

  render() {
    const { textUnder, textClassName, textForPercentage } = this.props;

    return (
      <div style={{ position: "relative", width: "150px", height: "150px" }}>
        <span className={textClassName} style={this.textStyles}>
          {textUnder}
        </span>

        <div
          style={{ position: "absolute", width: "100%" }}
          ref={this.progressBarContainer}
        >
          <CircularProgressbar
            percentage={this.getDifferenteTimeInPct()}
            styles={{
              text: { fontSize: "0.9em", transform: "translate(0, 6px)" }
            }}
            textForPercentage={textForPercentage}
          />
        </div>
      </div>
    );
  }

  getDifferenteTimeInPct() {
    const now = moment();

    const endTime = moment(this.props.endTime);
    const startTime = moment(this.props.startTime);

    const percentageComplete =
      ((now - startTime) / (endTime - startTime)) * 100;

    return percentageComplete;
  }

  getDifferenteTime() {
    const times = ["hours", "minutes"];

    const now = moment();
    const endTime = moment(this.props.endTime);
    const minutes = moment(moment(endTime).diff(moment(now))).format("mm");

    return {
      hours: endTime.diff(now, times[0]),
      minutes: +minutes
    };
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  initTimer() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  removeTimer() {
    clearInterval(this.timerID);
  }

  setTextTransform() {
    const progressBarContainer = this.progressBarContainer.current;
    const svgText = progressBarContainer.querySelector(
      ".CircularProgressbar-text"
    );

    let transform = svgText.style.transform;

    transform = transform
      .split(",")
      .join(" ")
      .split("px")
      .join("");

    svgText.setAttribute("transform", transform);
  }
}

export default Timer;
