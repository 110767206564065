const eng = {
  title: 'Page not found',
  description: 'This link is broken or page has been moved.',
  trouble: 'An error occured in downloading',
  tryAgain: 'Try again'
};

const ru = {
  title: 'Страница не найдена',
  description: 'Эта ссылка сломана или страница была перенесена.',
  trouble: 'Возникли проблемы при загрузке',
  tryAgain: 'попробуйте снова'
};

export default {
  ru,
  eng
};
