function getCoords(elem) {
  const box = elem.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    left: box.left + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset
  };
}

export default getCoords;
