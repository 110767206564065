import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Row, Col, Button } from "reactstrap";
import {
  TransportList,
  SectionBlock,
  MainInfo,
  ChangeTransportModal,
  EditProfileInfoModal
} from "../../../Components/ProfileComponents";
import Payment from "../../../Components/ProfileComponents/Payment";
import UserStore from "../../../Stores/UserStore";
import UIStore from "../../../Stores/UIStore";
import OptionsStore from "../../../Stores/OptionsStore";
import LoginStore from "../../../Stores/LoginStore";
import TranslationsStore from "../../../Stores/TranslationsStore";
import FieldPreLoader from "../../../Components/FieldPreLoader/index";
import { translate } from "react-i18next";
import PathStore from "../../../Stores/PathStore";
import APIProvider from "../../../Stores/APIProvider";
import CarNumberStore from "../../../Stores/CarNumberStore";

import pencilAlt from "@fortawesome/fontawesome-free-solid/faPencilAlt";
import plus from "@fortawesome/fontawesome-free-solid/faPlus";
import SettingsStore from "../../../Stores/SettingsStore";
import PageWrapper from "../../../Components/ProfileComponents/Common/PageWrapper";
import Settings from "../../../Components/ProfileComponents/Settings";
import userStore from "../../../Stores/UserStore";

@translate(["loadingError"], { wait: true })
@observer
export default class InformationContainer extends Component {
  @observable documents = [];

  @observable changeTransportModalAction = "add";
  @observable changeDocumentModalAction = "add";

  @observable changeTransportModalItem = {};
  @observable changeDocumentModalItem = {};

  @observable isErrorSaveTs = false;
  @observable errorDescription = null;

  constructor(props) {
    super(props);

    this.props = props;

    this.t = props.t;

    this._openChangeTransportModalAdd = this._openChangeTransportModal.bind(
      this,
      {
        action: "add"
      }
    );

    this._openChangeDocumentModalAdd = this._openChangeDocumentModal.bind(
      this,
      { action: "add" }
    );
  }

  async componentDidMount() {
    this._fetch();
  }

  UNSAFE_componentWillReceiveProps() {
    // this._transformData({ documents: this.documents });
  }

  render() {
    if (!UserStore.profile.error) {
      return this._renderContent();
    }

    return this._renderError();
  }

  _renderError = () => {
    return (
      <PageWrapper>
        <span>{this.t("profile:error:error")}</span>
        <div className="text-secondary">
          {this.t("profile:error:loadProfileError")}
        </div>
        <div className="error-buttons">
          <button
            style={{ fontSize: 14, margin: 0 }}
            onClick={this._fetch}
            className="menu-login btn btn-primary btn-small-round logout"
          >
            {this.t("profile:error:tryAgain")}
          </button>
          <button
            style={{ fontSize: 14, margin: 0 }}
            onClick={this.logout}
            className="menu-login btn btn-primary btn-small-round logout"
          >
            {this.t("auth:logout")}
          </button>
        </div>
      </PageWrapper>
    );
  };

  deletePhone = phone => {
    UserStore.deletePhone(phone).then(() => {});
  };

  _renderContent = () => {
    const profileTranslation = TranslationsStore.getProfiletTranslation();
    const operationsTranslation = TranslationsStore.getOperationTranslation();
    const transport = this.transport();

    return (
      <Fragment>
        {UserStore.profile.done ? (
          <EditProfileInfoModal
            isOpen={UIStore.modals.editProfileInfoModalIsOpen}
            defaultData={this._getDefaultDataForEditProfileModal()}
            onAccept={this._handleEditProfileAccept}
            onAbort={this._handleEditProfileAbort}
            onEmailChange={this.onEmailChange}
            onPhoneChange={this.onPhoneChange}
            onPasswordChange={this.onPasswordChange}
            hasPrivateData={OptionsStore.hasPrivateData}
            translations={{
              edit: profileTranslation.mainInfo.edit,
              operations: operationsTranslation,
              t: this.props.t
            }}
          />
        ) : null}

        <ChangeTransportModal
          isOpen={UIStore.modals.changeTransportModalIsOpen}
          action={this.changeTransportModalAction}
          defaultData={this._getDefaultDataForChangeTransportModal()}
          error={this.isErrorSaveTs}
          isLoading={UserStore.isTsSaving}
          validateNumber={this._validateTransport}
          validateSTS={this._validateSTS}
          onAccept={this._handleChangeTransportAccept}
          onAbort={this._handleChangeTransportAbort}
          translations={this.getTranslations()}
        />

        <Payment
          actionType="refill"
          isOpen={UIStore.modals.paymentModalIsOpen}
          onSubmit={this._handlePaymentAccept}
          onExit={this._handlePaymentAbort}
        />

        <Row
          noGutters
          className="profile-info justify-content-center"
          style={{ flex: 1 }}
        >
          <Col md={12} lg={9} className="main-wrapper">
            <Row noGutters className="justify-content-center">
              <Col md={12} lg={6}>
                <div className="md-card">
                  <SectionBlock
                    title={profileTranslation.mainInfo.title}
                    buttonIcon={pencilAlt}
                    buttonLabel={operationsTranslation.edit}
                    isLoading={!UserStore.profile.done}
                    onButtonClick={this._openEditProfileInfoModal}
                  >
                    {UserStore.profile.done ? (
                      <MainInfo
                        name={UserStore.getFullName()}
                        email={UserStore.getEmail()}
                        phone={this.phoneNumber()}
                        deletePhone={this.deletePhone}
                        allPhones={this.getAllPhones()}
                        translations={profileTranslation.mainInfo}
                        isLoading={!UserStore.profile.done}
                        hasPrivateData={OptionsStore.hasPrivateData}
                        onChangePhoneClick={phone => {
                          UIStore.editProfileInfoModalActiveStep =
                            "change telephone";
                          UIStore.editProfileInfoModalPhoneTypeAction =
                            "change";
                          this._openEditProfileInfoModal();
                        }}
                        onAddPhoneClick={() => {
                          UIStore.editProfileInfoModalActiveStep =
                            "change telephone";
                          UIStore.editProfileInfoModalPhoneTypeAction = "add";
                          this._openEditProfileInfoModal();
                        }}
                      />
                    ) : (
                      <FieldPreLoader visible style={{ width: 300 }} />
                    )}
                  </SectionBlock>
                </div>
                <div className="md-card">
                  <SectionBlock
                      title={profileTranslation.settings.title}
                      isLoading={!UserStore.settings.done}
                  >
                    <Settings
                        translations={profileTranslation.settings}
                    />
                  </SectionBlock>
                </div>
                <div className="md-card d-none d-lg-block">
                  <SectionBlock
                    title={profileTranslation.exit.title}
                    isLoading={!UserStore.profile.done}
                  >
                    {UserStore.profile.done ? (
                      <Button
                        color="primary"
                        size="lg"
                        style={{
                          fontSize: 14,
                          margin: 0
                        }}
                        onClick={this.logout}
                      >
                        {this.props.t("operations:exit")}
                      </Button>
                    ) : (
                      <FieldPreLoader
                        visible
                        style={{ height: 40, width: 100 }}
                      />
                    )}
                  </SectionBlock>
                </div>
              </Col>

              <Col md={12} lg={6}>
                <div className="md-card">
                  <SectionBlock
                    title={profileTranslation.transports.title}
                    buttonLabel={operationsTranslation.add}
                    isLoading={!UserStore.profile.done}
                    onButtonClick={this._openChangeTransportModalAdd}
                  >
                    <TransportList
                      items={transport}
                      isLoading={!UserStore.profile.done}
                      onEditButtonClick={this._openChangeTransportModal}
                      onRemoveButtonClick={this._openChangeTransportModal}
                      onSetMainButtonClick={obj => {
                        // UserStore.setMainVehicle(obj.oldItem.numberTs);
                        this._handleChangeTransportAccept(obj);
                      }}
                      translations={profileTranslation.transports}
                    />
                  </SectionBlock>
                </div>
                {SettingsStore.settings.dontUseBalance ? null : (
                  <div className="md-card">
                    <SectionBlock
                      title={profileTranslation.balance.title}
                      isLoading={!UserStore.profile.done}
                      buttonLabel={operationsTranslation.recharge}
                      onButtonClick={this._openPaymentModal}
                      buttonIcon={plus}
                    >
                      {!UserStore.profile.done ? (
                        <FieldPreLoader visible style={{ height: 25 }} />
                      ) : (
                        <span className="text-secondary">
                          {profileTranslation.balance.message}
                          <span className="text-black-50">
                            {" " +
                              this.balance() +
                              " " +
                              this._getNoun(
                                this.balance(),
                                profileTranslation.balance.currency.one,
                                profileTranslation.balance.currency.two,
                                profileTranslation.balance.currency.three
                              )}
                          </span>
                        </span>
                      )}
                    </SectionBlock>
                  </div>
                )}

                <div className="md-card d-block d-lg-none">
                  <SectionBlock
                    title={profileTranslation.exit.title}
                    isLoading={!UserStore.profile.done}
                  >
                    {UserStore.profile.done ? (
                      <button
                        style={{
                          fontSize: 14,
                          margin: 0
                        }}
                        onClick={this.logout}
                        className="menu-login btn btn-primary btn-small-round logout"
                      >
                        {this.props.t("operations:exit")}
                      </button>
                    ) : (
                      <FieldPreLoader
                        visible
                        style={{ height: 40, width: 100 }}
                      />
                    )}
                  </SectionBlock>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  };

  _fetch = () => {
    UserStore.loadProfileIfNeeded();
    UserStore.loadAccountSettings();
  };

  _transformPhoneNumber(phone) {
    if (/[^\d]/.test(phone)) {
      return phone;
    }

    return String(phone).replace(
      /([\d])([\d]{3})([\d]{3})([\d]{2})([\d]{2})/,
      "+ $1 ($2) $3-$4-$5"
    );
  }

  phoneNumber() {
    const phoneNumber = UserStore.getTelephone().phone;
    return this._transformPhoneNumber(phoneNumber);
  }

  getAllPhones() {
    let allPhones = UserStore.getAllPhones();

    allPhones = allPhones.map(phone => {
      return {
        ...phone,
        phone: this._transformPhoneNumber(phone.phone)
      };
    });
    return allPhones;
  }

  balance() {
    const balance = UserStore.getBalance();
    return balance !== -1 ? balance : 0;
  }

  prepareTransports(transports) {
    if (transports && transports.length > 0) {
      return transports
        .map(transport => {
          if (transport.formatGrz === UserStore.GRZ_FORMATS.FOREIGN) {
            return {
              number: transport.numberTs,
              country: transport.country,
              STSNumber: transport.STSNumber,
              formatGrz: transport.formatGrz,
              id: this.transport.id,

              ...transport
            };
          }

          const type = CarNumberStore.getTypeOfNumber(transport.numberTs);
          const split = transport.numberTs.match(
            CarNumberStore.numberTemplates[type]
          );

          if (!split) {
            return null;
          }

          const { number, region } = CarNumberStore.getParts(split);

          return {
            number,
            region,
            country: transport.country,
            STSNumber: transport.STSNumber,
            formatGrz: transport.formatGrz,
            id: this.transport.id,

            ...transport
          };
        })
        .filter(transport => !!transport);
    }

    return [];
  }

  transport() {
    const transports = UserStore.getTs();
    const preparedTransports = this.prepareTransports(transports);

    const donedTransport = preparedTransports.map((transport, index) => {
      return transport;
    });

    return donedTransport;
  }

  _transformNumber = number => {
    return String(number)
      .replace(/\s/g, "")
      .replace(/(\d{2})([A-zА-я\d]{2})(\d{6})/i, "$1 $2 $3")
      .toUpperCase();
  };

  documents() {
    return [];
  }

  _getDocumentItemTypeLabel = type => {
    const translate = TranslationsStore.getProfiletTranslation();
    const certificates = translate.documents.sertificates;

    const sts = certificates.vehicleCertificate;
    const driverLicense = certificates.driverLicense;

    switch (type) {
      case "STS": {
        return sts;
      }

      case "VU": {
        return driverLicense;
      }

      default:
        return null;
    }
  };

  // _getVacantDocuments = () => {
  //   return this.documents.filter(document => {
  //     return document.type === 'STS' && !this.transport.some(transport => {
  //       return String(transport.STSNumber) === String(document.title);
  //     });
  //   });
  // };

  _getDefaultDataForEditProfileModal = () => {
    return this._getDefaultDataForEditProfileModalFactory();
  };

  _getDefaultDataForEditProfileModalFactory = () => {
    return OptionsStore.hasPrivateData
      ? this._getDefaultDataForEditProfileModalV1()
      : this._getDefaultDataForEditProfileModalV2();
  };

  _getDefaultDataForEditProfileModalV1 = () => {
    let firstName = UserStore.getFirstName();
    let lastName = UserStore.getlastName();
    let middleName = UserStore.getMiddleName();

    return {
      firstName,
      lastName,
      middleName,

      email: UserStore.getEmail(),
      phone: UserStore.getMainPhone().phone
    };
  };

  _getDefaultDataForEditProfileModalV2 = () => {
    return {
      email: UserStore.getEmail(),
      phone: UserStore.getMainPhone().phone
    };
  };

  _getDefaultDataForChangeTransportModal = () => {
    return this.changeTransportModalItem;
  };

  _getDefaultDataForChangeDocumentModal = () => {
    return this.changeDocumentModalItem;
  };

  _openEditProfileInfoModal = () => {
    UIStore.toggleEditProfileModal();
  };

  _openChangeTransportModal = ({ action, item }) => {
    this.changeTransportModalAction = action;
    this.changeTransportModalItem = item;
    UIStore.launchChangeTransportModal();
  };

  _openChangeDocumentModal = ({ action, item }) => {
    this.changeDocumentModalAction = action;
    this.changeDocumentModalItem = item;

    UIStore.toggleChangeDocumentModal();
  };

  _openPaymentModal = () => {
    UIStore.togglePaymentModal();
  };

  _handleEditProfileAbort = () => {
    UIStore.closeEditProfileModal();
  };

  _validateTransport = async ({ id, number, region, action, formatGrz }) => {
    return !this.transport().some(transport => {
      let transportNumber = transport.number;

      if (!transportNumber) {
        transportNumber = [
          transport.firstLetter,
          transport.secondNumbers,
          transport.lastLetters
        ].join("");
      }

      const compareLogic = formatGrz
        ? String(transportNumber) === String(number)
        : String(transportNumber) === String(number) &&
          String(transport.region) === String(region);

      if (!!transportNumber) {
        return (
          ((action === "edit" && String(id) !== String(transport.id)) ||
            action === "add") &&
          compareLogic
        );
      }

      return false;
    });
  };

  _validateDocument = async ({ id, type, number, action }) => {
    return !this.documents.some(document => {
      return (
        ((action === "edit" && String(id) !== String(document.id)) ||
          action === "add") &&
        String(type) === String(document.type) &&
        String(number) === String(document.title)
      );
    });
  };

  _validateSTS = async ({ id, type, number, action }) => {
    return !this.transport().some(ts => {
      return (
        ((action === "edit" && String(id) !== String(ts.id)) ||
          action === "add") &&
        String(number).toUpperCase() === String(ts.STSNumber).toUpperCase()
      );
    });
  };

  _handleEditProfileAccept = async changedData => {
    const response = await UserStore.chnageMainInfo(changedData);
    if (response.status === "ok") UIStore.closeEditProfileModal();
    return response;
  };

  _handleChangeTransportAccept = async ({ action, item, oldItem, newItem }) => {
    // item возвращается при action === add или delete
    // oldItem и newItem при edit
    const transport = this.transport();

    switch (action) {
      case "add": {
        const formatGrz = UserStore.getGrzFormat(item.formatGrz);

        this.isErrorSaveTs = !UserStore.addTs({
          numberTs: formatGrz ? item.number : item.number + item.region,
          formatGrz: item.formatGrz,
          country: item.country,
          docType: item.docType,
          STSNumber: item.STSNumber,
          mainTs: transport.length === 0,
          category: item.category
        });

        if (!this.isErrorSaveTs) UIStore.closeChangeTransportModal();
        break;
      }

      case "edit": {
        // const foundIndex = transport.findIndex(transport => {
        //   return String(transport.id) === String(oldItem.id);
        // });
        // if (foundIndex >= 0) {
        const formatGrz = UserStore.getGrzFormat(newItem.formatGrz);

        this.isErrorSaveTs = !UserStore.editTs(oldItem.id, {
          numberTs: formatGrz
            ? newItem.number
            : newItem.number + newItem.region,
          formatGrz: newItem.formatGrz,
          country: newItem.country,
          docType: newItem.docType,
          STSNumber: newItem.STSNumber,
          mainTs: newItem.mainTs,
          category: newItem.category
        });
        if (!this.isErrorSaveTs) UIStore.closeChangeTransportModal();
        // }

        break;
      }

      case "delete": {
        const index = transport.find(transport => {
          return item.id === transport.id;
        }).id;
        this.isErrorSaveTs = !UserStore.deleteTs(index);
        if (!this.isErrorSaveTs) UIStore.closeChangeTransportModal();
        break;
      }

      default:
        break;
    }
  };

  _handleChangeTransportAbort = () => {
    this.isErrorSaveTs = false;
    UIStore.closeChangeTransportModal();
  };

  _handleChangeDocumentAbort = () => {
    UIStore.closeChangeDocumentModal();
  };

  _handlePaymentAccept = () => {
    UIStore.closePaymentModal();
  };

  _handlePaymentAbort = () => {
    UIStore.closePaymentModal();
  };

  onEmailChange(newEmail) {
    UserStore.changeEmail(newEmail);
  }

  onPhoneChange(phone) {
    UserStore.changePhone(phone);
  }

  onPasswordChange = async ({ pass, currentpass }) => {
    return await APIProvider.changePassword({ pass, currentpass });
  };

  _sleep = (ms = 1500) => {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  };

  _getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  };

  getTranslations = () => {
    const profileTranslation = TranslationsStore.getProfiletTranslation();
    const operationsTranslation = TranslationsStore.getOperationTranslation();

    return {
      edit: {
        tsEdit: profileTranslation.transports.edit,
        docEdit: profileTranslation.documents.edit
      },
      labels: profileTranslation.transports.edit.labels,
      errors: profileTranslation.transports.edit.errors,
      operations: operationsTranslation,
      stsNumber: profileTranslation.transports.sts,
      anotherTs: profileTranslation.transports.anotherTS,
      sertificates: profileTranslation.documents.sertificates,
      category: profileTranslation.transports.category
    };
  };

  logout = () => {
    LoginStore.logOut();
    window.location.href = PathStore.pathWithLang();
  };
}
