import React from 'react';

import { Col, Row } from 'reactstrap';

function operationsError(props) {
  const { errorTitle, errorMessage, tryAganeMessage, className, fetch } = props;

  return (
    <Row noGutters className={`${className} justify-content-center`} style={{ flex: 1 }}>
      <Col md={12} lg={8} className="main-wrapper">
        <div className="md-card">
          <span>{errorTitle}</span>

          <div className="text-secondary">{errorMessage}</div>

          <div className="error-buttons">
            <button
              style={{ fontSize: 14, margin: 0 }}
              onClick={fetch}
              className="menu-login btn btn-primary btn-small-round logout"
            >
              {tryAganeMessage}
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default operationsError;
