import { observable } from 'mobx';
import UserStore from '../UserStore';
import ApiProvider from '../APIProvider';

import {
  PERIOD_FILTERS,
  OPERATION_TYPES_FILTERS,
  OPERATION_TYPES_FILTERS_RU,
  PAYMENTSTATUSES_MAP_TO_EN,
  PAYMENTSTATUSES_RU,
} from './constants';

import moment from 'moment';

class OperationsHistoryStore {
  PERIOD_FILTERS = PERIOD_FILTERS;
  OPERATION_TYPES_FILTERS = OPERATION_TYPES_FILTERS;
  OPERATION_TYPES_FILTERS_RU = OPERATION_TYPES_FILTERS_RU;
  PAYMENTSTATUSES_MAP_TO_EN = PAYMENTSTATUSES_MAP_TO_EN;
  PAYMENTSTATUSES_RU = PAYMENTSTATUSES_RU;
  OPERATIONS_ON_PAGE = 50;

  initFilters = {
    currentPeriodFilter: 'monthly',
    currentOperationTypeFilter: 'all',
    currentTransportFilter: 'all',
    currentZoneFilter: 'all',
    customPeriodStart: moment().startOf('day'),
    customPeriodEnd: moment().endOf('day'),
    periodStart: moment()
      .startOf('month')
      .format(),
    periodEnd: moment().format(),
  };

  @observable
  filters = { ...this.initFilters };

  prevFilters = this.filters;

  get filters() {
    return this.filters;
  }

  isFiltersSame(filters) {
    return this.filters === filters;
  }

  setFilters(filters, withLoading = true) {
    this.prevFilters = this.filters;

    this.filters = {
      ...this.filters,
      ...filters,
    };

    if (withLoading) {
      UserStore.removeOperations();
      this.fetchOperations();
    }
  }

  setPeriodType(type) {
    this.setFilters({ currentPeriodFilter: type }, false);
  }

  setPeriodsF(type, periodStart, periodEnd) {
    if (type !== this.filters.currentPeriodFilter) {
      this.setFilters({
        currentPeriodFilter: type,
        periodStart: periodStart ? moment(periodStart).format() : null,
        periodEnd: periodEnd ? moment(periodEnd).format() : null,
      });
    }
  }

  setPeriodsByType(period) {
    const {
      ALL,
      CUSTOM,
      YESTERDAY,
      MOUNTHLY,
      TODAY,
      WEEKLY,
    } = this.PERIOD_FILTERS;
    const today = moment();

    switch (period) {
      case ALL:
        this.setPeriodsF(ALL, null, null);
        break;
      case CUSTOM:
        this.setPeriodType(CUSTOM);
        break;
      case YESTERDAY:
        const yesterday = moment(today)
          .add(-1, 'days')
          .endOf('day');
        const roundedYesterday = moment(yesterday).startOf('day');

        this.setPeriodsF(YESTERDAY, roundedYesterday, yesterday);
        break;

      case MOUNTHLY:
        const roundedPrevMounth = moment().startOf('month');

        this.setPeriodsF(MOUNTHLY, roundedPrevMounth, today);
        break;

      case TODAY:
        const roundedToday = moment(today).startOf('day');

        this.setPeriodsF(TODAY, roundedToday, today);
        break;

      case WEEKLY:
        const roundedWeek = moment().startOf('week');

        this.setPeriodsF(WEEKLY, roundedWeek, today);
        break;

      default:
    }
  }

  setOperationType(operationType) {
    if (operationType !== this.filters.currentOperationTypeFilter) {
      this.setFilters({ currentOperationTypeFilter: operationType });
    }
  }

  setTransportFilter(type) {
    if (type !== this.filters.currentTransportFilter) {
      this.setFilters({ currentTransportFilter: type });
    }
  }

  setZoneFilter(type) {
    if (type !== this.filters.currentZoneFilter) {
      this.setFilters({ currentZoneFilter: type });
    }
  }

  getRuOperationFilter(operationType) {
    const {
      ALL,
      PAYMENT,
      REFILL,
      RENEW,
      CANCEL,
      SUBSCRIPTION,
      BONUS20,
    } = this.OPERATION_TYPES_FILTERS;

    switch (operationType) {
      case ALL:
        return null;

      case PAYMENT:
        return this.OPERATION_TYPES_FILTERS_RU.PAYMENT;

      case REFILL:
        return this.OPERATION_TYPES_FILTERS_RU.REFILL;

      case RENEW:
        return this.OPERATION_TYPES_FILTERS_RU.RENEW;

      case CANCEL:
        return this.OPERATION_TYPES_FILTERS_RU.CANCEL;

      case SUBSCRIPTION:
        return this.OPERATION_TYPES_FILTERS_RU.SUBSCRIPTION;

      case BONUS20:
        return this.OPERATION_TYPES_FILTERS_RU.BONUS20;
      default:
        return null;
    }
  }

  getOptions = () => {
    const {
      currentOperationTypeFilter,
      currentTransportFilter,
      currentZoneFilter,
    } = this.filters;

    const currentOperations = UserStore.getOperations();
    const orderOfLastOperation = currentOperations.length
      ? currentOperations[currentOperations.length - 1].dateOperation
      : null;

    const options = {
      ...this.filters,
      currentOperationTypeFilter:
        currentOperationTypeFilter === 'all'
          ? null
          : this.getRuOperationFilter(currentOperationTypeFilter),
      currentTransportFilter:
        currentTransportFilter === 'all' ? null : currentTransportFilter,
      currentZoneFilter: currentZoneFilter === 'all' ? null : currentZoneFilter,
      orderNumber: orderOfLastOperation,
    };

    return options;
  };

  fetchOperations = async () => {
    const {
      currentOperationTypeFilter,
      currentTransportFilter,
      currentZoneFilter,
    } = this.filters;

    const currentOperations = UserStore.getOperations();
    const orderOfLastOperation = currentOperations.length
      ? currentOperations[currentOperations.length - 1].dateOperation
      : null;

    const options = {
      ...this.filters,
      currentOperationTypeFilter:
        currentOperationTypeFilter === 'all'
          ? null
          : this.getRuOperationFilter(currentOperationTypeFilter),
      currentTransportFilter:
        currentTransportFilter === 'all' ? null : currentTransportFilter,
      currentZoneFilter: currentZoneFilter === 'all' ? null : currentZoneFilter,
      orderNumber: orderOfLastOperation,
    };

    if (!UserStore.operations.isLoading) {
      UserStore.setOperationLoadStatuses({
        isLoading: true,
        done: false,
        error: false,
      });
      const operationsResponse = await ApiProvider.getOperationsWithFilter(
        options
      );

      UserStore.setOperationLoadStatuses({
        isLoading: false,
        done: true,
        error: false,
      });

      const operations = operationsResponse.result;
      UserStore.addOperations(operations);

      const countOfOperations = operations && operations.length;
      return (
        !countOfOperations < this.OPERATIONS_ON_PAGE && countOfOperations > 0
      );
    }

    return true;
  };
}

export default new OperationsHistoryStore();
