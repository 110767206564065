import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadablePage = Loadable({
  loader: () => import('../Pages'),
  loading: Loader,
  delay: 400 // 0.3 seconds
});

export default LoadablePage;
