 import React from 'react';

export default ({ fill, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 584 574">
            <defs>
                <clipPath id="a">
                    <path fill="none" d="M0 0h584v574H0z"/>
                </clipPath>
            </defs>
            <g>
                <g>
                    <g clipPath="url(#a)">
                        <path className='ruble-outline' d="M571.5 287c0 151.6-125.14 274.5-279.5 274.5S12.5 438.6 12.5 287 137.64 12.5 292 12.5 571.5 135.4 571.5 287z" stroke="#000105" strokeMiterlimit="10" strokeWidth="25" fill="none"/>
                    </g>
                    <path className='ruble-icon' d="M263.13 392.49v-211h58.49a50.58 50.58 0 0 1 39.08 18c18.79 22.79 15.61 58-4.42 77.67-9.44 9.27-21.51 15.49-34.86 15.49H208.84m0 55.58h117.81" strokeLinecap="round" stroke="#000105" strokeMiterlimit="10" strokeWidth="25" fill="none"/>
                </g>
            </g>
        </svg>
    );
}
