function fetch(type, errorBag = null) {
  switch (type) {
    case 'loading':
      return {
        isPending: false,
        isLoading: true,
        done: false,
        error: null,
      };
    case 'pending':
      return {
        isPending: true,
        isLoading: false,
        done: false,
        error: null,
      };
    case 'done':
      return {
        isPending: false,
        isLoading: false,
        done: true,
        error: null,
      };
    case 'error':
      return {
        isPending: false,
        isLoading: false,
        done: false,
        error: errorBag,
      };
  }
}

export default fetch;
