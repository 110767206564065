import React, { Component, createRef } from 'react';

import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';

class Formikinput extends Component {
  render() {
    const {
      label,
      field: { onChange, onBlur, name, value },
      form: { touched, errors, isSubmitting },
      ...rest
    } = this.props;

    const isFieldTouched = !!getIn(touched, name);
    const error = getIn(errors, name);
    const showError = !!error && isFieldTouched;

    return (
      <TextField
        {...rest}
        name={name}
        label={label}
        error={showError}
        onBlur={onBlur}
        value={value}
        helperText={showError ? error : rest.helperText}
        disabled={rest.disabled ? rest.disabled : isSubmitting}
        onChange={onChange}
      />
    );
  }
}

export default Formikinput;
