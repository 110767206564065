import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import Link from '../../Containers/Links/LngLink';

import FontAwesome from '@fortawesome/react-fontawesome';
import checkCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';

const style = {
  phonePaymentCallback: {
    fontSize: 20
  },
  checkCircle: {
    fontSize: 160,
    color: 'green',
    marginRight: 20
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  }
};

const PhonePaymentCallback = () => {
  return (
    <Container className="d-flex">
      <Row className="justify-content-center" style={style.phonePaymentCallback}>
        <Col xs={8} className="d-flex align-items-center">
          <div style={style.container}>
            <FontAwesome icon={checkCircle} style={style.checkCircle} />
            <p>
              Дождитесь СМС-сообщения и следуйте инструкции для завершения оплаты. После завершения
              оплаты информация о платеже будет доступна на странице{' '}
              <Link to={'/profile/operations'}>История операций</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PhonePaymentCallback;
