import React, { Component } from 'react';

import StandartLogin from './StandartLogin';
//import CoopLogin from './CoopLogin';
import LoginStore from '../../../Stores/LoginStore';

import './style.css';
import OptionsStore from "../../../Stores/OptionsStore";
import SelectLoginType from "./SelectLoginType";

class Login extends Component {
  static STANDART = 'standart';
  static COOP = 'coop';
  static SELECT_LOGIN_TYPE = 'selectLoginType';

  constructor() {
    super();

    this.state = {
      currentModal: OptionsStore.isCoopProfile ? Login.SELECT_LOGIN_TYPE : Login.STANDART,
    };
  }

  toCoop = () => {
    this.setState({
      currentModal: Login.COOP
    });
  };

  toLegal = () => {
      //history.pushState('/legal/auth', null);
    window.location.replace('/legal/auth');
  }

  toStandart = () => {
    this.setState({
      currentModal: Login.STANDART
    });
  };

  toSelectLoginType = () => {
    this.setState({
      currentModal: Login.SELECT_LOGIN_TYPE
    });
  }

  handleClose = () => {
    if ( OptionsStore.isCoopProfile ) {
      this.toSelectLoginType();
    }
  };

  render() {
    // eslint-disable-next-line
    let f = LoginStore.captchaBase64;
    const { currentModal } = this.state;
    const {
      logIn,
      setTokenToUser,
      //setTokenToCoopUser,
      loadProfile,
      //coopLogIn,
      //loadCoopProfile,
      easy
    } = this.props;

    if (currentModal === Login.STANDART) {
      return (
        <StandartLogin
          easy={easy}
          toCoop={this.toCoop}
          toLegal={this.toLegal}
          logIn={logIn}
          setTokenToUser={setTokenToUser}
          loadProfile={loadProfile}
          onClose={this.handleClose}
          {...this.props}
        />
      );
    }

    return (
        <SelectLoginType
            onClose={this.handleClose}
            toLegal={this.toLegal}
            toStandart={this.toStandart}
            {...this.props}
        />
/*
      <CoopLogin
        toStandart={this.toStandart}
        coopLogIn={coopLogIn}
        loadCoopProfile={loadCoopProfile}
        setTokenToCoopUser={setTokenToCoopUser}
        onClose={this.handleClose}
        {...this.props}
      />
*/
    );
  }
}

export default Login;
