import React from 'react';
import ThemeBlock from './ThemeBlock';

const FeedbackList = ({
  list,
  messages,
  errorMessages,
  messagesSending,
  onSend,
  onThemeClick,
  onResend,
}) => {
  if (list.length > 0) {
    return list.map((feedback) => {
      const messagesById = messages[feedback.id];
      const messagesInFeedback = errorMessages[feedback.id];
      const errorMessagesById = messagesInFeedback
        ? errorMessages[feedback.id]
        : [];
      const fetchStatus = messagesById ? messagesById.fetchStatus : {};

      const messagesForFeedback = messages[feedback.id]
        ? messages[feedback.id].items
        : [];

      const goodMessages = messagesForFeedback.filter((_, index) => {
        return !errorMessagesById.includes(index);
      });

      const allErrorMessages = errorMessagesById.map(
        (errorId) => messagesForFeedback[errorId]
      );

      return (
        <ThemeBlock
          key={feedback.id}
          feedback={feedback}
          goodMessages={goodMessages}
          errorMessages={allErrorMessages}
          messagesSending={messagesSending[feedback.id]}
          onSend={onSend}
          onThemeClick={onThemeClick}
          isLoading={fetchStatus.isLoading}
          onResend={onResend}
        />
      );
    });
  }

  return 'Вы не оставили ни одного отзыва';
};

export default FeedbackList;
