import React from 'react';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormik, yupToFormErrors } from 'formik';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';
import isEmpty from 'lodash/isEmpty';
import TextField from '@material-ui/core/TextField';

import FormControl from '../../common/FormControl';
import {
  FormikSelect,
  TsSelect as FormikAutoSelect,
} from '../../../../Common/Inputs';
import SpinnerButton from '../../../../Common/Buttons/SpinnerButton';
import AutoSubmit from '../../../../Common/Inputs/AutoSubmit';
import FloatPrice from '../../../../Common/FloatPrice';

const StartForm = ({
  t,
  toPaymentService,
  price,
  amountToPay,
  dontUseBalance,
  ts = '',
  duration = 0,
  zone = '',
  services = [],
  phones = [],
  onChange = () => null,
  onSubmit = () => null,
  onAbort = () => null,
  onGetPrice = () => null,
  gettingPrice,
}) => {
  const submitRef = React.useRef(null);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      service: services.length > 0 ? services[0] : null,
      phone: phones.length > 0 ? phones[0] : null,
    },
    // Validation phase
    validate: (values) => {
      const phoneRegExp = /^((\+7|7|8)+([0-9]){10})$/;
      const schema = Yup.object().shape({
        service: Yup.object().nullable(),
        phone: Yup.object().when('service', {
          is: (val) => val && val.isPhoneRequired,
          then: Yup.object().shape({
            phone: Yup.string()
              .required(t('errors:RequiredField'))
              .matches(phoneRegExp, t('errors:InvalidFormat')),
            isMain: Yup.bool(),
          }),
          otherwise: Yup.object().nullable(),
        }),
      });

      // Catch Yup errors
      try {
        schema.validateSync(values);
      } catch (validationError) {
        return yupToFormErrors(validationError);
      }
      return {};
    },
    // Submit phase
    onSubmit: async (values, { resetForm, setStatus }) => {
      if (onSubmit) {
        const response = await onSubmit(values);

        if (response && response.status !== 'ok') {
          setStatus(t(`errors:${response.errorName}`));
        } else {
          resetForm({});
          setStatus(null);
        }
      }
    },
  });

  const handleAbort = React.useCallback(() => {
    if (onAbort) {
      onAbort();
    }
  });

  const { values, status, isValid, errors, setStatus, setSubmitting } = formik;

  React.useEffect(() => {
    if (submitRef && submitRef.current) {
      submitRef.current.href = toPaymentService;
    }
  });

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        {status ? <Alert color="danger">{status}</Alert> : null}
        <FormControl fullWidth>
          <TextField label="Зона" value={zone} disabled />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Транспортное средство" value={ts} disabled />
        </FormControl>
        <FormControl fullWidth>
          <TextField label="Длительность" value={duration} disabled />
        </FormControl>

        <FormControl fullWidth>
          <FormikSelect
            form={formik}
            field={formik.getFieldProps({ name: 'service' })}
            label="Методы оплаты"
            name="service"
            items={services}
            renderValue={(value) => {
              return value.methodNameForShow;
            }}
            renderItems={(items) =>
              items.map((item) => {
                if ("Квитанция" != item.methodName) {
                  return (
                    <MenuItem key={item.methodNameForShow} value={item}>
                  <span>
                    <span>{item.methodNameForShow}</span>
                    <div className="text-secondary">
                      {item.methodDescription}
                    </div>
                  </span>
                    </MenuItem>);
                } else {
                  return (<></>);
                }
              })
            }
          />
        </FormControl>
        <FormControl fullWidth>
          {values.service && values.service.isPhoneRequired ? (
            <FormikAutoSelect
              form={formik}
              field={formik.getFieldProps({ name: 'phone' })}
              label="Номер телефона"
              name="phone"
              options={phones}
              clearOnEscape={false}
              disableClearable
              noOptionsText="Добавьте номер телефона в профиль"
              getOptionLabel={(phone) => phone.phone}
            />
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <p className="text-info">
            Вы будете перенаправлены на страницу платёжной системы, где сможете
            произвести оплату.
          </p>
        </FormControl>

        <FormControl>
          <div>
            <div>
              {t('parking:currentParkings:amountToPaid')} &nbsp;
              <FloatPrice number={price} className="sum neutral" />
            </div>
            {dontUseBalance ? null : (
              <div>
                {t('parking:currentParkings:toDeposite')} &nbsp;
                <FloatPrice number={amountToPay} className="sum neutral" />
              </div>
            )}
          </div>
        </FormControl>
        <div>
          {!toPaymentService ? (
            <SpinnerButton
              tag={'button'}
              color="primary"
              type="submit"
              className="submit"
              target="_blank"
              innerRef={submitRef}
              style={{ appearance: 'none' }}
              isLoading={gettingPrice}
              disabled={!isValid || gettingPrice}
              onClick={() => onGetPrice(values)}
            >
              Получить ссылку на пополнение
            </SpinnerButton>
          ) : (
            <SpinnerButton
              tag={'a'}
              color="primary"
              className="submit"
              target="_blank"
              innerRef={submitRef}
              style={{ appearance: 'none' }}
              disabled={!toPaymentService}
              onClick={() => onSubmit(values)}
            >
              {t('operations:pay')}
            </SpinnerButton>
          )}
          <Button
            outline
            color="primary"
            onClick={handleAbort}
            className="cancel"
          >
            {t('operations:annulment')}
          </Button>
        </div>

        <AutoSubmit
          t={t}
          submitForm={onChange}
          values={values}
          isValid={isValid && isEmpty(errors)}
          setSubmitting={setSubmitting}
          setStatus={setStatus}
        />
      </form>
    </>
  );
};

export default StartForm;
