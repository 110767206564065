import React, { PureComponent } from 'react';

import UIStore from '../../Stores/UIStore';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faBars from '@fortawesome/fontawesome-free-solid/faBars';

class SideMenuToggler extends PureComponent {
  render() {
    return (
      <div className="nav-dropdown-btn">
        <div id="side-menu-toggler" onClick={this.toggleSideMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
    );
  }

  toggleSideMenu = () => {
    UIStore.toggleSideMenu();
    document.body.style.overflow = 'hidden';
  };
}

export default SideMenuToggler;
