import React from "react";
import { observer } from "mobx-react";
import { translate } from "react-i18next";

import SmsCommandView from "../View/SmsCommand";
import SmsCommandsStore from "../../../Stores/SmsCommandsStore";
import OptionsStore from "../../../Stores/OptionsStore";

import Loader from "../../../Components/Loader";
import { Redirect } from "react-router";

import Title from "../../../Components/UpdatePage/Title";
import Description from "../../../Components/UpdatePage/Description";

@translate(["smsCommands"], { wait: true })
@observer
class SmsCommandsContainer extends React.Component {
  componentDidMount() {
    SmsCommandsStore.loadCommands();
  }

  render() {
    if (
      SmsCommandsStore.status === SmsCommandsStore.LOADING ||
      SmsCommandsStore.status === SmsCommandsStore.PENDING
    ) {
      return <Loader />;
    }

    if (
      SmsCommandsStore.status === SmsCommandsStore.DONE &&
      SmsCommandsStore.commands.length === 0
    ) {
      return <Redirect to="/" />;
    }

    const { t } = this.props;

    return (
      <article className="update-page">
        <section className="update-page__new-commands">
          <div>
            <section>
              <Title size="lg">{t("smsCommands:title")}:</Title>
              <Description>
                {t("smsCommands:sendTo")} {OptionsStore.numberSmsSendTo}
              </Description>
            </section>
            {SmsCommandsStore.commands.map((command, index) => (
              <SmsCommandView
                key={command.hint}
                name={command.name}
                hint={command.hint}
                example={command.example}
                command_transcription={command.command_transcription}
              />
            ))}
          </div>
          <div className="update-page__new-commands-bg" />
        </section>
      </article>
    );
  }
}

export default SmsCommandsContainer;
