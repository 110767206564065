import { observable } from 'mobx';
import APIProvider from './APIProvider';
import i18n from '../i18n/i18n';
class OptionsStore {
  @observable options = {};
  @observable mapCenter = null;
  @observable headerMenuItems = [];
  @observable footerMenuItems = [];
  @observable mobileMenuItems = [];
  @observable done = false;
  @observable fetching = false;
  @observable appLinks = {
    androidLink: null,
    iosLink: null,
    yandexStoreLink: null,
  };
  @observable contacts = {
    email: null,
    phone: null,
  };
  @observable notification = {
    message: '',
    url: '',
  };
  @observable hasPrivateData = false;

  @observable isClosed = false;
  @observable isCoopProfile = false;
  @observable initialZoom = 16;
  @observable numberSmsSendTo = 7878;

  mapCenterNormalize = (mapCenter) => {
    mapCenter = JSON.parse(mapCenter);
    mapCenter[0] = mapCenter[0] ? Number(mapCenter[0]) : 0;
    mapCenter[1] = mapCenter[1] ? Number(mapCenter[1]) : 0;
    return mapCenter;
  };

  getOptions = async () => {
    if (!this.done) {
      this.fetching = true;

      let options = await APIProvider.getOptions();

      if (options) {
        this.error = false;
        this.mapCenter = this.mapCenterNormalize(options.map.mapCenter);
        this.headerMenuItems = this.menuItemsNormalize(
          options.menuItems['header']
        );
        this.footerMenuItems = this.menuItemsNormalize(
          options.menuItems['footer']
        );
        this.mobileMenuItems = this.menuItemsNormalize(
          options.menuItems['mobile']
        );
        this.numberSmsSendTo = options.numberSmsSendTo || this.numberSmsSendTo;

        if (options.appLinks) {
          this.appLinks = {
            androidLink: options.appLinks.androidLink
              ? options.appLinks.androidLink
              : null,
            iosLink: options.appLinks.iosLink ? options.appLinks.iosLink : null,
            yandexStoreLink: options.appLinks.yandexStoreLink
              ? options.appLinks.yandexStoreLink
              : null, // eslint-disable-line
            apkLink: options.appLinks.apkLink ? options.appLinks.apkLink : null,
          };
        }
        if (options.contacts) {
          this.contacts = {
            email: options.contacts.email ? options.contacts.email : null,
            phone: options.contacts.phone ? options.contacts.phone : null,
          };
        }
        this.notification = options.notififcation || this.notification;
        this.isClosed =
          typeof options.isClose !== 'boolean' ? true : options.isClose;
        this.isCoopProfile =
          typeof options.isCoopProfile !== 'boolean'
            ? false
            : options.isCoopProfile;
        this.initialZoom = options.initialZoom
          ? options.initialZoom
          : this.initialZoom;
        this.hasPrivateData = options.hasPrivateData || false;
      } else {
        this.isClosed = true;
      }

      this.fetching = false;
      this.done = true;
    }
  };

  menuItemsNormalize = (menuItems) => {
    return menuItems.map((menuItem) => {
      return {
        path: menuItem.path,
        titles: menuItem.titles,
        order: menuItem.itemOrder,
      };
    });
  };

  getCurrentLocaleTitle = (titles) => {
    let currentTitle = null;
    for (let locale in titles) {
      if (locale === i18n.language) {
        return titles[locale];
      }
    }
    return currentTitle;
  };
}

export default new OptionsStore();
