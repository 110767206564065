import { Component } from "react";

import ApiProvider from "../../Stores/APIProvider";
import UserStore from "../../Stores/UserStore";
import CloudPaymentStore from "../../Stores/CloudPaymentStore";
import TranslationStore from "../../Stores/TranslationsStore";
import PathStore from "../../Stores/PathStore";

import moment from "moment";

import Config from "../../Config";

class CloudPaymentContainer extends Component {
  componentDidMount() {
    this.initCloudPayment();
  }

  render() {
    return null;
  }

  initCloudPayment() {
    const { history } = this.props;
    const paymentConfig = this.getWidgetData();

    const path = PathStore.pathWithLang("/");
    const language = this.getLanguage();

    if (!paymentConfig.invoiceId) {
      history.push(path);
      return;
    }

    const widget = new window.cp.CloudPayments({ language });

    try {
      widget.charge(
        {
          // options
          ...paymentConfig
        },
        this.handlePaymentSuccess,
        this.handlePaymentFail
      );
    } catch (error) {
      history.push(path);
    }
  }

  handlePaymentSuccess = options => {
    const { history } = this.props;
    const path = PathStore.pathWithLang("/");

    const { invoiceId, amount, auth } = options;
    const data = {
      amount,
      status: auth ? "authorized" : "not authorized",
      source: "CloudPayments",
      order: invoiceId,
      billNumber: "",
      paymentDate: moment.utc().format("YYYYMMDDhhmmss")
    };

    ApiProvider.sendPaymentInfo(data);

    history.push(path);
    window.location.reload();
  };

  handlePaymentFail = options => {
    const { history } = this.props;
    const path = PathStore.pathWithLang("/");

    history.push(path);
    window.location.reload();
  };

  getLanguage() {
    const {
      match,
      search: { language }
    } = this.props;
    const {
      params: { lng = "ru" }
    } = match;

    return language ? language : TranslationStore.getLanguage(lng);
  }

  getWidgetData() {
    const { search } = this.props;
    const {
      amount,
      currency = "RUB",
      requireEmail = "",
      invoiceId = "",
      email = "",
      type
    } = search;

    return {
      publicId: Config.CloudPayments.public_key,
      amount: Number(amount),
      description: CloudPaymentStore.getDescription(type),
      currency: String(currency),
      invoiceId,
      email: email || UserStore.getEmail(),
      requireEmail: Boolean(requireEmail) || false
    };
  }
}

export default CloudPaymentContainer;
