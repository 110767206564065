import ProfileButton from './ProfileButton';
import LoginButton from './LoginButton';
import RegistrationButton from './RegistrationButton';
import OpenInAppButton from './OpenInAppButton/OpenInAppButton';
import CancleSessionButton from './cancleSessionButton';
import SetMainPhoneButton from './SetMainPhoneButton';
import StartWithoutRegistrationButton from './StartWithoutReg';
import CoopLogoutBtn from './CoopLogoutBtn';

export {
    ProfileButton,
    RegistrationButton,
    LoginButton,
    OpenInAppButton,
    CancleSessionButton,
    SetMainPhoneButton,
    StartWithoutRegistrationButton,
    CoopLogoutBtn
}
