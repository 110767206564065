import React, { PureComponent } from "react";

import { Button } from "reactstrap";
import FontAwesome from "@fortawesome/react-fontawesome";
import plus from "@fortawesome/fontawesome-free-solid/faPlus";
import Loading from "../../../Components/Common/TwitchLoader";
import FieldPreLoader from "../../FieldPreLoader";
import PropTypes from "prop-types";

export default class SectionBlock extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    this.forceUpdate();
  }

  render() {
    const {
      title,
      buttonLabel,
      buttonIcon,
      children,
      onButtonClick,
      isLoading
    } = this.props;
    const textButtonStyle = {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    };

    return (
      <div className="info-section">
        <div className="title">
          {isLoading ? (
            <FieldPreLoader visible style={{ width: 200, height: 29 }} />
          ) : (
            <span>{title}</span>
          )}

          {!isLoading && buttonLabel ? (
            <Button
              color="light"
              className="action-button"
              onClick={onButtonClick}
            >
              <div>
                <FontAwesome icon={buttonIcon} />
              </div>
              <span style={textButtonStyle}>{buttonLabel}</span>
            </Button>
          ) : null}
        </div>

        {isLoading ? <Loading /> : children}
      </div>
    );
  }

  static propTypes = {
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonIcon: PropTypes.object,
    onButtonClick: PropTypes.func,

    isLoading: PropTypes.bool
  };

  static defaultProps = {
    title: "",
    buttonLabel: null,
    buttonIcon: plus,
    onButtonClick: () => null,
    isLoading: false
  };
}
