import React from 'react';

import Title from './Title';
import Description from './Description';
import ExampleItemBr from './ExampleItemBr';
import text from './text';
import { CITY_NAME } from '../../Config';
function SectionRenew() {
  const city = CITY_NAME;
  return (
    <section className="update-page--renew">
      <Title>Продление парковки:</Title>
      <Description className="example">
        <span className="black">{text[city].prefix}</span>*<span className="orange mg-l">x</span>
        <span className="black">*</span>
        <span className="blue">количество часов</span>
      </Description>
      <Description>
        <p>Пример продления на 3 часа</p>
        <div className="update-page__example">
          <ExampleItemBr>{text[city].prefix}</ExampleItemBr>*
          <ExampleItemBr color="orange">x</ExampleItemBr>*
          <ExampleItemBr color="blue">3</ExampleItemBr>
        </div>
      </Description>
    </section>
  );
}

export default SectionRenew;
