import React from 'react';
import { observer } from 'mobx-react';
import FeedbackListView from '../view';
import FeedbackStore from '../../../../../Stores/FeedbackStore';
import TwitchLoader from '../../../../../Components/Common/TwitchLoader';

@observer
class FeedbackListContainer extends React.Component {
  componentDidMount() {
    FeedbackStore.loadList();
  }

  handleSend = (text, feedback, messageId) => {
    return FeedbackStore.sendMessage(text, feedback, messageId);
  };

  handleThemeClick = (feedback) => {
    FeedbackStore.loadMessages(feedback.id);
  };

  handleResend = ({ messageId, feedback }) => {
    return this.handleSend(
      FeedbackStore.messages[feedback.id].items[messageId].text,
      feedback,
      messageId
    );
  };

  render() {
    if (
      FeedbackStore.getListShortStatus('isLoading') ||
      !FeedbackStore.getListShortStatus('done')
    ) {
      return <TwitchLoader />;
    }

    const messages = FeedbackStore.messages;
    const errorMessages = FeedbackStore.errorMessages;
    const feedbackList = FeedbackStore.feedbacks.items;
    const messagesSending = FeedbackStore.messageIsSending;

    return (
      <FeedbackListView
        list={feedbackList}
        messages={messages}
        errorMessages={errorMessages}
        messagesSending={messagesSending}
        onSend={this.handleSend}
        onThemeClick={this.handleThemeClick}
        onResend={this.handleResend}
      />
    );
  }
}

export default FeedbackListContainer;
