import getCoords from './getCoords';

function fitToWindow(toElem, elem, paddingBottom = 0, withScroll = false) {
  if (toElem && elem) {
    const elemCoords = getCoords(elem);

    const toElemHeight = withScroll ? toElem.scrollHeight : toElem.offsetHeight;
    if (elemCoords.bottom > toElemHeight) {
      const height =
        elem.offsetHeight - (Math.abs(elemCoords.bottom) - Math.abs(toElemHeight)) - paddingBottom;
      elem.style.height = height + 'px';

      return;
    }

    elem.style.height = elem.offsetHeight;
  }

  return;
}

export default fitToWindow;
