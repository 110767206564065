import React from 'react';

import CoreButton from './CoreButton';

import trash from '@fortawesome/fontawesome-free-solid/faTrashAlt';

function DeleteButton ({ onClick, ...restProps }) {
    return (
        <CoreButton
            onClick={onClick}
            icon={trash}
            {...restProps}
        />
    );
}

export default DeleteButton;
