import React, { Component } from "react";
import { observer } from "mobx-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import APIProvider from "../../Stores/APIProvider";
import { Container, Row } from "reactstrap";

@observer
export default class ProgressLine extends Component {
  constructor() {
    super();
    this.state = {
      color: "#1587c8"
    };
  }

  componentDidMount() {
    this.setState({
      color: window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--bgMain")
    });
  }

  render() {
    return APIProvider.loading ? (
      <Container fluid>
        <Row>
          <LinearProgress
            style={{
              zIndex: 1049,
              height: "3px",
              width: "100%",
              marginBottom: "-3px"
            }}
          />
        </Row>
      </Container>
    ) : null;
  }
}
