import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Link from '../../Links/LngLink';

import StartWithoutReg from '../../../Components/Common/Buttons/StartWithoutReg';
import SettingsStore from '../../../Stores/SettingsStore';

@observer
class StartWithoutRegContainer extends Component {
  render() {
    return SettingsStore.settings.useSessionWithoutRegister ? (
      <Link to="/guest-payment">
        <StartWithoutReg>{this.props.children}</StartWithoutReg>
      </Link>
    ) : null;
  }
}

export default StartWithoutRegContainer;
