const menuFields = [
  { title: 'Карта парковок', path: '/' },
  { title: 'Способы оплаты', path: '/payments' },
  { title: 'Льготы', path: '/privileges' },
  { title: 'Штрафы', path: '/penalty' }
  // {title:'Помощь', path:'/help'},
  //{title:'Регистрация', path:'/registration'}
];

export default menuFields;
