import React, { Component, Fragment, createRef } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import { Select, Input } from "../../../MaterialDesign";

import SettingsStore from "../../../../Stores/SettingsStore";

@observer
class PaymentMethodSelector extends Component {
  static defaultProps = {
    onPhoneChange: () => null,
    onMethodSelect: () => null
  };

  @observable selectedMethod = null;
  @observable withPhone = false;

  constructor() {
    super();

    this.refComponents = {
      method: createRef(),
      phone: createRef()
    };
  }

  componentDidMount() {
    const { onMethodSelect } = this.props;

    this.selectedMethod = SettingsStore.settings.paymentsMethods[0];
    SettingsStore.settings.paymentsMethods &&
    SettingsStore.settings.paymentsMethods.length !== 0
      ? SettingsStore.settings.paymentsMethods[0].methodName
      : null;

    if (this.selectedMethod) {
      onMethodSelect(this.selectedMethod.methodName || "");
    }
  }

  UNSAFE_componentWillReceiveProps() {
    this.selectedMethod =
      SettingsStore.settings.paymentsMethods &&
      SettingsStore.settings.paymentsMethods.length !== 0
        ? SettingsStore.settings.paymentsMethods[0].methodName
        : null;

    this.withPhone =
      SettingsStore.settings.paymentsMethods &&
      SettingsStore.settings.paymentsMethods.length !== 0
        ? SettingsStore.settings.paymentsMethods[0].isPhoneRequired
        : false;
  }

  defaultPaymentMethod() {
    if (
      SettingsStore.settings.paymentsMethods &&
      SettingsStore.settings.paymentsMethods.length !== 0
    ) {
      return {
        label: (
          <span>
            <span>
              {SettingsStore.settings.paymentsMethods[0].methodNameForShow}
            </span>
            <div className="text-secondary">
              {SettingsStore.settings.paymentsMethods[0].methodDescription}
            </div>
          </span>
        ),
        value: SettingsStore.settings.paymentsMethods[0].methodName
      };
    }
  }

  getOptions = () => {
    if (SettingsStore.settings.paymentsMethods) {
      return SettingsStore.settings.paymentsMethods.map(method => {
        return {
          label: (
            <span>
              <span>{method.methodNameForShow}</span>
              <div className="text-secondary">{method.methodDescription}</div>
            </span>
          ),
          value: method.methodName,
          payload: {
            isPhoneRequired: method.isPhoneRequired,
            isPhone: method.isPhoneRequired
          }
        };
      });
    }
  };

  _handleMethodSelect = item => {
    const { onMethodSelect } = this.props;

    this.selectedMethod = item.value;
    this.withPhone = (item.payload && item.payload.isPhoneRequired) || false;

    onMethodSelect(this.selectedMethod);
  };

  handlePhoneChange = event => {
    const { onPhoneChange } = this.props;
    const phoneNumber = event.target.value;

    onPhoneChange(phoneNumber, this.refComponents.phone);
  };

  getPhoneOptions() {
    const { phoneLabel } = this.props;

    return {
      input: {
        name: "phone",
        id: "phone",
        label: phoneLabel,
        type: "tel",
        autoComplete: "off",
        containerStyle: { margin: 0 },
        onChange: this.handlePhoneChange
      }
    };
  }

  renderPhone() {
    if (this.withPhone) {
      const { input } = this.getPhoneOptions();
      return <Input {...input} ref={this.refComponents.phone} />;
    }
  }

  render() {
    const { label } = this.props;

    return (
      <Fragment>
        <Select
          label={label}
          options={this.getOptions()}
          defaultValue={this.defaultPaymentMethod()}
          ref={this.refComponents.method}
          onSelect={this._handleMethodSelect}
        />
        {this.renderPhone()}
      </Fragment>
    );
  }
}

export default PaymentMethodSelector;
