import React, { Fragment } from 'react';

import LongNumber from './LongNumber';
import SquareNumber from './SquareNumber';
import ForeignNumber from './ForeignNumber';

import CarNumberStore from '../../../Stores/CarNumberStore';
import UserStore from '../../../Stores/UserStore';

import './style.css';

class Number extends React.PureComponent {
  render() {
    let {
      number,

      region,
      country,
      formatGrz,
      children,

      ...restProps
    } = this.props;

    let matches = [];

    const type = CarNumberStore.getTypeOfNumberWithoutRegion(number);

    if (!!number) {
      number = number.replace(/\s/g, '');
      matches = number.match(CarNumberStore.numberTemplatesWithoutRegion[type]);
    }

    const { TYPES } = CarNumberStore;

    if (formatGrz === UserStore.GRZ_FORMATS.FOREIGN) {
      return (
        <Fragment>
          <ForeignNumber number={number} {...restProps}>
            {children}
          </ForeignNumber>
        </Fragment>
      );
    }

    if (TYPES.MOTO === type || TYPES.MVD_MOTO === type) {
      return (
        <Fragment>
          <SquareNumber
            number={number}
            region={region}
            country={country}
            type={type}
            matches={matches}
            {...restProps}
          >
            {children}
          </SquareNumber>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <LongNumber
          number={number}
          region={region}
          country={country}
          type={type}
          matches={matches}
          {...restProps}
        >
          {children}
        </LongNumber>
      </Fragment>
    );
  }
}

export default Number;
