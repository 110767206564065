import React, { Component } from 'react';
import { translate } from 'react-i18next';
import MakePrimaryButton from '../../Buttons/SetPrimaryButton';

import UserStore from '../../../../Stores/UserStore';
@translate(['operations'], { wait: true })
class SetMainPhoneButton extends Component {
  static defaultProps = {
    onClick: () => {}
  };

  handleClick = async () => {
    const { phone, onClick } = this.props;
    onClick();
    await UserStore.setMainPhone(phone);
  };

  render() {
    const { t, style, primary } = this.props;

    return (
      <MakePrimaryButton
        size="sm"
        onClick={this.handleClick}
        color="light"
        className={'set-main-phone-btn'}
        style={{ ...style, color: '#555' }}
        primary={primary}
      >
        {t('operations:makePrimary')}
      </MakePrimaryButton>
    );
  }
}

export default SetMainPhoneButton;
