import React, { Component } from 'react';
import NavLink from '../../Common/NavLink';

import PathStore from '../../../Stores/PathStore';
import OptionsStore from '../../../Stores/OptionsStore';

import { Nav, NavItem } from 'reactstrap';

class HeaderMenu extends Component {
  render() {
    const { isLoading, items } = this.props;
    if (isLoading || items.length === 0) {
      return null;
    }

    return (
      <nav>
        <Nav>{this.getMenuFields()}</Nav>
      </nav>
    );
  }

  getMenuFields = () => {
    const { items = [] } = this.props;

    return items.map((item, index) => {
      return (
        <NavItem className="menu-item" key={index}>
          {typeof item === 'object' ? (
            <NavLink
              to={item.path}
              className={'nav-link'}
              activeClassName={'active'}
              exact
            >
              {OptionsStore.getCurrentLocaleTitle(item.titles)}
            </NavLink>
          ) : (
            <span>{item}</span>
          )}
        </NavItem>
      );
    });
  };
}

export default HeaderMenu;
