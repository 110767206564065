import React from 'react';
import { translate } from 'react-i18next';
import PlanarForm from '../View';
import ApiProvider from '../../../../../Stores/APIProvider';
import SettingsStore from '../../../../../Stores/SettingsStore';
import ProfileParkingStore from "../../../../../Stores/ProfileParkingStore";
import ModalStore from "../../../../../Stores/ModalStore";

@translate(['header'], { wait: true })
class PlanarPaymentContainer extends React.Component {
  static steps = { price: 'price', payment: 'payment' };

  constructor() {
    super();
    this.MAXIMUM_TIME_TO_PAY = 30000;
    this.TIME_TO_SHOW_MESSAGE = 7000;

    this.state = {
      step: PlanarPaymentContainer.steps.price,
      cost: null
    };
  }

  async handlePaymentSubmission({ cardNumber, paymentService }, { resetForm, setStatus }) {
    clearTimeout(this.costTimerId);

    const response = await ApiProvider.payPlanar({
      parkingNumber: this.state.parkingNumber,
      paymentService: paymentService,
      sessionId: this.state.sessionId
    });

    if (!response.result) {
      resetForm({});
      this.setState({ step: PlanarPaymentContainer.steps.price, cost: null });
      setStatus({ status: response.status, message: response.errorMessage ||  this.props.t(`errors:${response.errorName}`) });
    }

    if (response.result) {
      if (response.result.redirectURL){
        window.open(response.result.redirectURL, '_self');
      } else {
        if (response.result.message) {
          ModalStore.openModal('notify', {
            message: response.result.message
          })
        }
      }
      this.setState({ step: PlanarPaymentContainer.steps.price, cost: null });
    }
    return response;
  }

  async handleCostSubmition(cardNumber, { resetForm, setStatus }) {
    const parkingCode = ProfileParkingStore.parkingCode;
    const response = await ApiProvider.getPlanarPrice(cardNumber, parkingCode);
    this.costTimerId = setTimeout(() => {
      this.setState({ step: PlanarPaymentContainer.steps.price, cost: null });
      setStatus({
        status: 'error',
        message: this.props.t('errors:PriceChangedTryLater')
      });
    }, this.MAXIMUM_TIME_TO_PAY);

    if (!response.result) {
      clearTimeout(this.costTimerId);
      setStatus({ status: response.status, message: response.errorMessage });
    }

    if (response.result) {
      if (response.result.cost > 0) {
        this.setState({
          step: PlanarPaymentContainer.steps.payment,
          cost: response.result.cost,
          sessionId: response.result.sessionId,
          cardNumber: cardNumber,
          parkingNumber: response.result.parkingNumber,
        });
      } else {
        resetForm({});
        clearTimeout(this.costTimerId);
        this.setState({ step: PlanarPaymentContainer.steps.price });
        setStatus({
          status: response.status,
          message: this.props.t('errors:ParkingSessionWasFree')
        });
      }
    }

    return response;
  }

  handleSubmit = async ({ cardNumber = null, paymentService }, { resetForm, setStatus }) => {
    setStatus(null);
    if (this.state.step === PlanarPaymentContainer.steps.price) {
      return this.handleCostSubmition(cardNumber, { resetForm, setStatus });
    }

    if (this.state.step === PlanarPaymentContainer.steps.payment) {
      return this.handlePaymentSubmission({ cardNumber, paymentService }, { resetForm, setStatus });
    }

    resetForm({});
    setTimeout(() => setStatus(null), this.TIME_TO_SHOW_MESSAGE);
  };

  render() {
    const { t } = this.props;
    const defaultPaymentMethod = SettingsStore.settings.paymentsMethods[0];
    return (
      <PlanarForm
        t={t}
        onSubmit={this.handleSubmit}
        cost={this.state.cost}
        resetType={PlanarPaymentContainer.steps.payment}
        paymentService={defaultPaymentMethod ? defaultPaymentMethod.methodName : ''}
      />
    );
  }
}

export default PlanarPaymentContainer;
