// import * as Sentry from '@sentry/browser';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// const path = require('path');
// const { upload } = require('sentry-files');

class SentryUtil {
  // static uploadSourceMapsToServer () {
  //     upload({
  //         version: version,
  //         organization: process.env.ERROR_REPORTING_ORGANIZATION,
  //         project: process.env.ERROR_REPORTING_PROJECT,
  //         token: process.env.ERROR_REPORTING_API_TOKEN,
  //         files: SentryUtil.getFiles(),
  //     })
  // }

  // static getFiles () {
  //     const BUILD_DIR = 'build';
  //     const assetsFile = path.resolve(BUILD_DIR, 'asset-manifest.json');
  //     const filePaths = require(assetsFile);
  //     const jsFilesRegex = /(\.js(.map)?)$/;

  //     return Object.keys(filePaths)
  //         .filter(f => jsFilesRegex.test(f))
  //         .map(f => ({
  //             name: `~/${filePaths[f]}`,
  //             path: path.resolve('build', filePaths[f]),
  //         }));
  // }

  constructor() {
    Sentry.init({
      dsn: process.env.REACT_APP_DSN,
      release: process.env.REACT_APP_VERSION,
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
}

export default SentryUtil;
