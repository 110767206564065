import React, { Component } from 'react';

import { Button } from 'reactstrap';
import faStar from '@fortawesome/fontawesome-free-solid/faStar';
import FontAwesome from '@fortawesome/react-fontawesome';

class SetPrimaryButton extends Component {
    handleClick = async () => {
        const {onClick} = this.props;
        onClick();
    }

    render () {
        const { style, className, primary } = this.props;

        return (
            <Button
                className={className}
                size="sm"
                onClick={this.handleClick}
                color="light"
                
                style={{
                    ...style,
                    color: '#555',
                    backgroundColor: primary ? '#c0c0c0' : ''
                }}
            >
                <FontAwesome
                  style={{
                    color: primary ? 'yellow' : 'gray'
                  }}
                  icon={faStar}
                />
            </Button>
        );
    }
}

export default SetPrimaryButton;
