import React from 'react';

const floatStyle = {
  color: '#999'
};

function FloatPrice({ number = 0, className = null }) {
  return (number ^ 0) === number ? (
    <span className={className}>{number}</span>
  ) : (
    <span className={className}>
      <span>{Math.trunc(number)}</span>
      <span style={floatStyle}>.{(number % 1).toFixed(2).substring(2)}</span>
    </span>
  );
}

export default FloatPrice;
