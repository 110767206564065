import React, { Component } from 'react';

import Config from '../../Config';
import { Row, Col } from 'reactstrap';
import CityStore from '../../Stores/CityStore';
import Select from '../MaterialDesign/SearchSelect';
import './style.css';
import { translate } from 'react-i18next';

@translate(['auth'], { wait: true })
class SelectCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: null
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Row>
          <Col style={{ textAlign: 'center', marginBottom: 10 }}>
            {t('changeCity:selectCityFromList')}
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              clearInputAfterClick
              selectOptions={this.getSelectOptions()}
              inputOptions={this.getInputOptions()}
              onlySelect
              bcsClr
            />
          </Col>
        </Row>
        {this.state.city !== null ? (
          <Row>
            <Col
              style={{
                justifyContent: 'center',
                display: 'flex',
                marginTop: 20
              }}
            >
              <div
                onClick={() => {
                  CityStore.setCity(this.state.city);
                }}
                className={'confirm-city-btn'}
              >
                {t('changeCity:confirmButton')}
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }

  getInputOptions = () => {
    const { t } = this.props;

    return {
      name: 'gorod',
      id: 'gorod',
      label: t('changeCity:inputPlaceholder'),
      type: 'text'
      //onChange: this.handleSelect
    };
  };

  getSelectOptions = () => {
    return {
      label: 'Города',
      options: this.getLabels(),
      onSelect: this.handleSelect,
      defaultValue: this.props.defaultValue || ''
    };
  };

  handleSelect = selected => {
    let city = null;
    Config.backEnds.forEach(item => {
      if (item.nameForShow === selected.value) city = item.city;
    });
    this.setState({
      city: city
    });
  };

  getLabels = () => {
    return Config.backEnds
      .map((item, index) => {
        const label = <div key={index}>{item.nameForShow}</div>;

        return {
          label,
          value: item.nameForShow
        };
      })
      .sort((a, b) => {
        return String(a.value).localeCompare(String(b.value));
      });
  };
}

export default SelectCity;
