import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
class Captcha extends Component {
  @observable UIStore;

  render() {
    return (
      <div>
        <Row>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img alt="captcha" style={{ height: 50 }} src={this.props.value} />
          </Col>
        </Row>
      </div>
    );
  }

  static propTypes = {
    value: PropTypes.string
  };

  static defaultProps = {
    value: ''
  };
}

export default Captcha;
