import React, { useEffect, useState } from "react";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import { Icon } from "@blueprintjs/core";
import { IconButton } from "@material-ui/core";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import APIProvider from "../../../../../Stores/APIProvider";
import TextField from "@material-ui/core/TextField";
import { Col, Row } from "reactstrap";
import { saveAs } from 'file-saver';

const GetReceipt = (props) => {
  const [isSend, setIsSend] = useState(false);
  const [email, setEmail] = useState('');
  const [emailDirty, setEmailDirty] = useState(false);
  const [emailError, setEmailError] = useState('Необходимо заполнить');
  const [formValid, setFormValid] = useState(false);

  const getPdf = async () => {
    const result = await APIProvider.getReceipt(props.receiptnumber);
    const pdfBlob = new Blob([result], { type: 'application/pdf' });
    saveAs(pdfBlob, 'receipt.pdf');
  }

  const printPdf = async () => {
    const result = await APIProvider.getReceipt(props.receiptnumber);
    const pdfBlob = new Blob([result], { type: 'application/pdf' });
    let blobURL = URL.createObjectURL(pdfBlob);
    let iframe = document.createElement('iframe'); //load content in an iframe to print later
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function() {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  useEffect(
    () => {
      if (emailError) {
        setFormValid(false);
      } else {
        setFormValid(true);
      }
    }, [
      emailError,
    ]
  )

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let result = await APIProvider.sendReceiptByEmail({
      'number': props.receiptnumber,
      'email': formData.get("email"),
    });
    props.onAbort();
  }

  const emailHandler = (e) => {
    setEmail(e.target.value);
    if (!e.target.value.length) {
      setEmailError('Необходимо заполнить');
    } else if (e.target.value.length > 50) {
      setEmailError('Длинна не более 50 символов');
    } else {
      const re = /^[\w-\+\$!\.\%:]+@([\w-]+\.)+[\w-]{2,4}$/
      if (!re.test(String(e.target.value))) {
        setEmailError('Неверный формат адреса');
      } else {
        setEmailError('');
      }
    }
  }

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmailDirty(true);
        break;
    }
  }

  const typeText = props.receiptType === 'legal' ? 'счет' : 'квитанцию';

  return (
    <>
      {!isSend ? (
        <>
          <p>Распечатайте {typeText}</p>
          <FormGroup>
            <Button
              outline
              color="secondary"
              size="lg"
              onClick={printPdf}
            >
              <Icon icon="print" className="mr-3" />
              Распечатать {typeText}
            </Button>
          </FormGroup>
          <p>Вы также можете сохранить {typeText} на диск или отправить на email и распечатать позднее:</p>
          <Button
            outline
            color="secondary"
            size="sm"
            onClick={getPdf}
          >
            <Icon icon="document" className="mr-3" />
            Скачать {typeText}
          </Button>
          <Button
            disabled={false}
            outline
            color="secondary"
            size="sm"
            onClick={() => {setIsSend(true)} }
          >
            <Icon icon="email" className="mr-3" />
            Отправить на email
          </Button>
          <FormGroup className="mt-3 text-center">
            <Col className={"text-center"}>
              <Button
                outline
                color="primary"
                onClick={props.onAbort}
                className="cancel"
              >
                {props.t('operations:annulment')}
              </Button>
            </Col>
          </FormGroup>
        </>
      ) : (
        <>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <FormGroup>
              <Row>
                <Col>
                  <label className="w-100 form-label" htmlFor="email">Email:
                    { (emailDirty && emailError) && <span className="pl-2" style={{color:'red'}}>{emailError}</span> }
                  </label>
                  <input
                    defaultValue={email}
                    onChange={e => emailHandler(e)}
                    onBlur={e => blurHandler(e)}
                    className="w-100 form-control"
                    id="email"
                    type="text"
                    name="email"/>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="mt-3 text-center">
              <Row>
                <Col className={"text-center"}>
                  <Button
                    disabled={!formValid}
                    color="primary"
                    type="submit"
                    className="submit"
                    style={{ appearance: 'none' }}
                  >
                    Отправить
                  </Button>
                </Col>
                <Col className={"text-center"}>
                  <Button
                    outline
                    color="primary"
                    onClick={props.onAbort}
                    className="cancel"
                  >
                    {props.t('operations:annulment')}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </form>
        </>
      )}
    </>
  );
}

export default GetReceipt;