import React, { Component } from 'react';

import { Button } from 'reactstrap';
import PaymentStore from '../../../Stores/PaymentStore';

class CancleSessionButton extends Component {
    static defaultProps = {
        onClick: () => null
    }

    handleClick = () => {
        const { onClick, session } = this.props;

        PaymentStore.cancelParkingSession(session);
        onClick();
    }

    render () {
        const { className, children } = this.props;

        return (
            <Button
                outline
                className={ className }
                onClick={ this.handleClick }
                color="danger"
            >
                { children }
            </Button>
        );
    }
}

export default CancleSessionButton;
