const errorsEng = {
  // UnknownError: 'Unknown error',
  AccountNotFound: 'Account not found',
  CalendarIsEmpty: 'Production calendar not filled in',
  NoSaving: 'Less than half an hour. It is impossible to interrupt',
  TypeOfDayIsNotDefined: 'Unable to set production calendar day view for date',
  NoMoneyNoHoney: 'Insufficient funds to pay for the Parking session',
  EmptyTs: 'Choose vehicle',
  NotInCity: 'You are not in city zone',
  CantFindLocation: 'Cant find you location',
  CantFindParking: 'Cant find parking',
  VIN_IMEI_EMPTY: 'VIN or IMEI are empty',
  EmailExists: 'E-mail already in use',
  RequiredField: 'This field is required',
  FuturePost: 'Cannot select future time',
  ParkingSessionWasFree: 'Parking session wasFree',
  NoDurationSelected: 'Select the duration',
  NoZoneSelected: 'Select the parking zone',
  NoSumEntered: 'Enter the top up amount',
  PriceChangedTryLater: 'Price changed try agane later',

  UnknownError: 'An unknown error. Try again. ',
  ValidationError: 'Data verifying error. ' + 'Verify the data you entered.',
  TokenNotFound:
    'Problems with your session. ' + 'Try logging out and in again.',
  ParkingSessionIsOver:
    'The parking session has already been completed. ' +
    'It is not possible to extend / cancel the session.',
  ZoneNotFound: 'The selected zone is not found.',
  ParkingSessionNotFound: 'The parking session is not found.',
  TariffNotFound: 'The zone tariff is not found.',
  ParkingSessionAlreadyExists:
    'The parking session for this' + 'vehicle already exists.',
  PaymentServiceNotFound:
    'The selected payment method is not' +
    'supported or temporarily unavailable.',
  CalculationCostError: 'Unable to calculate the session cost.',
  NotSelectedPaymentService:
    'Not sufficient funds. ' + 'Select the payment method to top up.',
  InDevelopingError: 'In Developing',
  ServerGateParkingError: 'Error on planar parking server side',
  SetEmailInProfile: 'Enter your email address on your profile page',
};

const errorsRu = {
  // UnknownError: 'Неизвестная ошибка',
  AccountNotFound: 'Аккаунт не найден',
  CalendarIsEmpty: 'Не заполнен производственный календарь',
  TariffNotFound: 'Не удалось найти тариф для зоны',
  TypeOfDayIsNotDefined:
    'Не удалось устновить вид дня по производственному календарю для даты',
  CalculationCostError: 'Не удалось расчитать стоимость сессии',
  NoMoneyNoHoney: 'Не достаточно средств для оплаты парковочной сессии',
  EmptyTs: 'Выберете транспорное средство',
  NotInCity: 'Вы находитесь вне города',
  CantFindLocation: 'Не удалось найти ваше местоположение',
  CantFindParking: 'Не удалось найти парковку',
  VIN_IMEI_EMPTY: 'VIN или IMEI не заполнен',
  EmailExists: 'E-mail уже используется',
  RequiredField: 'Это поле обязательно для заполнения',
  FuturePost: 'Нельзя выбрать будущее время при постоплате',
  ParkingSessionWasFree: 'Парковочная сессия бесплатна',
  NoDurationSelected: 'Необходимо выбрать продолжительность.',
  NoZoneSelected: 'Необходимо выбрать парковочную зону.',
  NoSumEntered: 'Необходимо ввести сумму пополнения.',
  PriceChangedTryLater:
    'Цена за парковку изменилась, узнайте стоимость еще раз',

  UnknownError: 'Произошла неизвестная ошибка. Попробуйте повторить попытку.',
  ValidationError:
    'Произошла ошибка при проверке данных. ' +
    'Проверьте корректность введенных Вами данных.',
  TokenNotFound:
    'Возникли проблемы с Вашей сессией. ' +
    'Попробуйте выйти из аккаунта и заново авторизоваться.',
  ParkingSessionIsOver:
    'Парковочная сессия уже завершилась. ' +
    'Не возможно продлить / прервать сессию.',
  ZoneNotFound: 'Не удалось найти выбранную Вами зону.',
  ParkingSessionNotFound: 'Не удалось найти парковочную сессию.',
  ParkingSessionAlreadyExists:
    'Парковочная сессия для этого ' + 'транспортного средства уже существует.',
  NoSaving: 'Осталось меньше получаса. Невозможно прервать.',
  PaymentServiceNotFound:
    'Выбранный метод оплаты не ' + 'поддерживается или временно недоступен.',
  NotSelectedPaymentService:
    'На балансе недостаточно средств. ' +
    'Необходимо выбрать способ пополнения.',
  InDevelopingError: 'Данный функционал находится в разработке',
  ServerGateParkingError: 'Ошибка на стороне сервера плоскостной парковки',
  ItNeedToBeANumber: 'Это должно быть числовым значением',
  ItNeedToBeAString: 'Это должно быть строковым значением',
  InvalidFormat: 'Неверный формат',
  RefillAmountCanNotBeMaxAllowable: 'Превышена максимальная сумма пополнения',
  PaymentServiceError: 'Ошибка сервиса оплаты',
  SubscriptionAlreadyExists: 'Подписка уже существует',
  SetEmailInProfile: 'Укажите свою почту на странице профиля',
};

export default {
  eng: errorsEng,
  ru: errorsRu,
};
