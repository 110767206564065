import { observable, action } from "mobx";

import APIProvider from "./APIProvider";
import UserStore from "./UserStore";
import CarNumberStore from "./CarNumberStore";

import moment from "moment";

class PaymentStore {
  PAYMENT_STATUSES = {
    WAIT: "wait",
    DONE: "done",
    POCESSING: "processing"
  };

  PAYMENT_STATUSES_RU = {
    WAIT: "Ожидание платежа",
    DONE: "Выполнен",
    POCESSING: "Обработка"
  };

  PAYMENT_TYPES = {
    POST_PAYMENT: "postPayment",
    PAYMENT: "payment",
    CONTINUE_SESSION: "continueSession",
    PAYMENT_UNREGISTER: "paymentUnregister",
    POST_PAYMENT_UNREG: "postPaymentUnregister"
  };

  @observable isPaymentInProccess = false;
  @observable isCostInProcess = false;

  getPaymentStatus(status) {
    switch (status) {
      case this.PAYMENT_STATUSES_RU.DONE:
        return this.PAYMENT_STATUSES.DONE;
      case this.PAYMENT_STATUSES_RU.WAIT:
        return this.PAYMENT_STATUSES.WAIT;
      case this.PAYMENT_STATUSES_RU.POCESSING:
        return this.PAYMENT_STATUSES.POCESSING;
      default:
        return "";
    }
  }

  @action
  paymentRefill = async ({ service, amount, phone }) => {
    if (!this.isPaymentInProccess) {
      this.isPaymentInProccess = true;
      const response = await APIProvider.paymentRefill({
        payment: service.methodName,
        amount: amount,
        phone: service.isPhoneRequired ? phone.phone : null
      });
      this.isPaymentInProccess = false;

      return response;
    }

    return null;
  };

  @action
  cancelParkingSession = async session => {
    if (!this.isPaymentInProccess) {
      this.isPaymentInProccess = true;
      Promise.all([
        await APIProvider.paymentCancel({ reservation: session.id })
      ]);

      this.isPaymentInProccess = false;
      UserStore.updateAll();
    }
  };

  @action
  startNoBalanceSession = async (vehicle, parking) => {
    const result = await APIProvider.startNoBalanceSession({
      Zone: parking.zone.zonenumber,
      ParkingCode: parking.code,
      Vehicle: {
        Number: vehicle.numberTs,
        NumberFormat: vehicle.formatGrz,
        Category: vehicle.category
        // NumberFormat: vehicle.formatGrz
      }
    });
    if (result.status === "ok") {
      UserStore.updateAll();
    }
    this.isPaymentInProccess = false;

    return result;
  };

  @action
  cancelNoBalanceSession = async ({ orderId, paymentMethod = null }) => {
    const result = await APIProvider.cancelNoBalanceSession({
      orderId,
      paymentMethod
    });

    if (
      result.status === "ok" ||
      result.errorName === "ParkingSessionWasFree"
    ) {
      UserStore.updateAll();
    }

    this.isPaymentInProccess = false;
    if (result.status === "ok") {
      return {
        status: result.status,
        amountToPay: result["СуммаПополнения"],
        redirectUrl: result["RedirectURL"]
      };
    }

    return {
      status: result.status,
      errorName: result.errorName
    };
  };

  @action cancelDefferedSession = async ({ orderId, paymentMethod }) => {
    const result = await APIProvider.cancelDefferedSession({
      orderId,
      paymentMethod
    });

    this.isPaymentInProccess = false;
    if (result.status === "ok") {
      return {
        status: result.status,
        amountToPay: result["СуммаПополнения"],
        redirectUrl: result["RedirectURL"]
      };
    }

    return {
      status: result.status,
      errorName: result.errorName
    };
  };

  @action
  paySession = async (type, paymentParams) => {
    const {
      number,
      category,
      numberFormat,
      payment,
      phone,
      zone,
      duration,
      parkingCode,
      postPaymentStartTime
    } = paymentParams;

    const formatGrz = CarNumberStore.getFormatGrz(numberFormat);

    if (!this.isPaymentInProccess) {
      this.isPaymentInProccess = true;
      const paymentMethod = this.getPaymentMethod(type);

      const paymentData = await paymentMethod({
        transport: {
          Number: number,
          Category: category || "B",
          NumberFormat: formatGrz
        },

        payment,
        phone,
        zone,
        duration,
        parkingCode,
        postPaymentStartTime
      });

      this.isPaymentInProccess = false;

      return paymentData;
    }
  };

  getPaymentMethod = type => {
    switch (type) {
      case this.PAYMENT_TYPES.PAYMENT:
        return APIProvider.paymentStart;
      case this.PAYMENT_TYPES.POST_PAYMENT:
        return APIProvider.postPaymentStart;
      case this.PAYMENT_TYPES.CONTINUE_SESSION:
        return APIProvider.sessionContinue;
      case this.PAYMENT_TYPES.PAYMENT_UNGER:
        return APIProvider.unregisterPaymentStart;
      case this.PAYMENT_TYPES.POST_PAYMENT_UNREG:
        return APIProvider.unregisterPostPaymentStart;
      default:
        return () => null;
    }
  };

  costOfSession = async (paymentParams, isUnregister = false) => {
    const {
      zone,
      transport,
      duration,
      parkingCode,
      timestart,
      reservation
    } = paymentParams;

    if (zone && transport && duration && !this.isCostInProcess) {
      const ts = this.getTsForPaymentByNumber(transport);

      this.isCostInProcess = true;
      const costMethod = isUnregister
        ? APIProvider.unregisterPaymentCost
        : APIProvider.paymentCost;

      const response = await costMethod({
        reservation,
        duration: duration,
        zone,
        parkingCode,
        timestart: timestart ? moment(timestart) : null,
        transport: {
          number: String(transport).toUpperCase(),
          category: ts.category,
          numberFormat: ts.formatGrz
        }
      });
      this.isCostInProcess = false;
      if (response.status === "ok") {
        const costData = response;

        let sum = costData.totalcost;
        let sumSale = costData.totaldiscount;
        let benefitsName = "";

        if (costData.totaldiscount > 0) {
          benefitsName = costData.benefit;
        }

        let price = "";
        let priceRest = "";

        if (String(sum).match(/[.,]/)) {
          const split = String(sum).split(/[.,]/);

          price = split[0];
          priceRest = (n => {
            const str = String(n);
            if (str.length === 1) {
              return n + "0";
            }

            if (str.length > 2) {
              return str[0] + "" + str[1];
            }

            return str;
          })(split[1]);
        } else {
          price = Math.floor(costData.totalcost);
          priceRest = "00";
        }

        const moneyEnough = costData.balance >= sum;

        return {
          costData,
          moneyEnough,
          price,
          priceRest,
          sumSale,
          benefitsName,
          status: response.status,
          errorName: response.errorName,
          message: response.message
        };
      }

      return {
        status: response.status,
        errorName: response.errorName,
        message: response.message
      };
    }
  };

  payRenew = async ({ reservation, payment, duration, phone, timestart }) => {
    if (!this.isPaymentInProccess) {
      this.isPaymentInProccess = true;

      const result = await APIProvider.paymentRenew({
        reservation,
        payment,
        duration,
        phone,
        timestart
      });
      this.isPaymentInProccess = false;
      return result;
    }
  };

  paySubscription = async ({
    vehicle,
    timestart,
    paymentService,
    phone,
    codeSubscription
  }) => {
    if (!this.isPaymentInProccess) {
      this.isPaymentInProccess = true;

      const preparedVehicle = {
        category: vehicle.category,
        number: vehicle.numberTs,
        numberFormat: vehicle.formatGrz
      };

      const result = await APIProvider.paySubscription({
        paymentService: paymentService ? paymentService.methodName : "balance",
        phone:
          paymentService && paymentService.isPhoneRequired ? phone.phone : null,
        vehicle: preparedVehicle,
        codeSubscription,
        timestart
      });
      this.isPaymentInProccess = false;
      return result;
    }
  };

  getTsForPaymentByNumber = number => {
    const ts = UserStore.getCarByNumber(number);
    const category = ts ? ts.category : "B";
    const formatGrz = ts ? ts.formatGrz : UserStore.GRZ_FORMATS.RUSSIA;

    return {
      number,
      category,
      formatGrz
    };
  };
}

export default new PaymentStore();
