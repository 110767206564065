import React, { Component, createRef } from "react";
import { Input, Select } from "../../MaterialDesign";

import UserStore from "../../../Stores/UserStore";

import "./style.css";
import fitToWindow from "../../../utils/fitToElement";

export default class SearchSelect extends Component {
  constructor(props) {
    super(props);

    this.onlySelect = props.onlySelect || false;

    this.searchSelect = createRef();
    this.inputWrap = createRef();
    this.label = createRef();
    this.input = createRef();
    this.select = createRef();

    this.state = this.initialState = {
      touched: false,
      inputValue: "",
      selectedValue: "",
      payload: {},
      labelValue: "",
      defaultValue: "",
      anyMatched: true,

      selectDisplayed: false,
      labelDisplayed: false
    };
  }

  UNSAFE_componentWillMount() {
    const { defaultValue } = this.props.selectOptions;
    this.setState({
      defaultValue: defaultValue || "",
      inputValue: this.props.value || ""
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const defaultValue = nextProps.selectOptions.defaultValue;
    this._setDefaultValue(defaultValue);
  }

  componentDidMount() {
    this._setDefaultValue();

    this.initSearchSelectBlur();
    document.addEventListener("click", this._outClickEventHandler);
  }

  componentWillUnmount() {
    this.removeSearchSelectBlur();
    document.removeEventListener("click", this._outClickEventHandler);
  }

  render() {
    const {
      selectOptions,
      inputOptions,
      clearInputAfterClick,
      className
    } = this.props;

    const {
      inputValue,
      labelValue,
      defaultValue,
      selectDisplayed
    } = this.state;

    if (selectDisplayed) {
      fitToWindow(document.documentElement, this.select.current);
    }

    const filterValue =
      this.props.type === "tel"
        ? UserStore.convertPhone(inputValue)
        : inputValue;
    return (
      <div
        className={`search-select-group ${className || ""}`}
        ref={this.searchSelect}
      >
        <div
          style={{ display: "block", position: "relative" }}
          onKeyDown={this.handleInputKeyPress}
          ref={this.inputWrap}
        >
          <Input
            {...inputOptions}
            style={this.inputStyle()}
            onChange={this.handleInput}
            value={inputValue}
            onFocus={this.handleInputFocus}
            label={inputOptions.label}
            spellCheck={false}
            onClick={() => {
              if (clearInputAfterClick) this.setState({ inputValue: "" });
            }}
            ref={this.input}
          />

          <div
            className="select-value"
            style={this.labelStyle()}
            onClick={this.handleLabelClick}
            ref={this.label}
          >
            {labelValue}
          </div>
        </div>

        <div style={this.selectStyles()} ref={this.select}>
          <Select
            {...selectOptions}
            manual
            filter
            ref={this.select}
            open={selectDisplayed}
            filterBy={filterValue}
            defaultValue={defaultValue}
            tabIndex={-1}
            onSelect={this.handleSelectClick}
          />
        </div>
      </div>
    );
  }

  _setDefaultValue = defVal => {
    let { defaultValue, options } = this.props.selectOptions;

    defaultValue = defVal || defaultValue;
    if (!this.state.touched && !!defaultValue) {
      const selectedOption = options.find(option => {
        return option.value === defaultValue.value;
      });

      const labelDisplayed = selectedOption ? selectedOption.label : null;

      this.setState({
        inputValue: defaultValue.value || "",
        selectedValue: defaultValue.value || "",
        payload: defaultValue.payload,

        labelValue: labelDisplayed,

        labelDisplayed: true
      });
    }
  };

  handleInputKeyPress = event => {
    if (this.props.onlySelect && this.state.selectedValue) {
      if (event.key !== "Backspace") {
        event.preventDefault();

        return;
      }
    }
    switch (event.key) {
      case "Backspace":
        if (this.props.bcsClr && this.state.selectedValue !== "") {
          if (this.props.inputOptions.onChange) {
            this.props.inputOptions.onChange({ value: "" });
          }

          this.setState({
            selectedValue: "",
            payload: {},
            inputValue: ""
          });
        }
        break;

      case "Enter":
      case "ArrowDown":
        const firstSelectElement = document.querySelectorAll(
          ".select-options > div"
        )[0];
        firstSelectElement.focus();
        break;

      case "Escape":
        this.input.current._inputRef.current.blur();
        this.setState({
          inputValue:
            this.state.selectedValue === "" ? "" : this.state.selectedValue,
          labelValue:
            this.state.selectedValue === "" ? "" : this.state.selectedValue,

          labelDisplayed: this.state.selectedValue !== "",
          selectDisplayed: false
        });
        if (this.props.onAction) this.props.onAction("escape");

        break;

      default:
        return;
    }
  };

  handleInputFocus = () => {
    const { options } = this.props.selectOptions;

    this.setState({
      selectDisplayed: !!options.length && this.state.anyMatched,
      labelDisplayed: false,
      touched: true
    });
  };

  handleLabelClick = event => {
    this.input.current._inputRef.current.focus();
  };

  handleSelectClick = option => {
    this.props.selectOptions.onSelect({
      value: option.value,
      payload: option.payload
    });

    if (this.props.onAction) this.props.onAction("selectClikc");

    this.setState({
      inputValue: option.value,
      selectedValue: option.value,
      payload: option.payload,
      labelValue: option.label,
      labelDisplayed: true,
      selectDisplayed: false
    });
  };

  initSearchSelectBlur() {
    this.searchSelect.current.addEventListener("blur", this.onBlurSelect, true);
  }

  removeSearchSelectBlur() {
    this.searchSelect.current.removeEventListener("blur", this.onBlurSelect);
  }

  onBlurSelect = event => {};

  handleInput = event => {
    const { options } = this.props.selectOptions;
    const { onChange } = this.props.inputOptions; //callBack
    const value = event.target.value;
    const matchedOptions = this.matchedOptions(value, options);

    if (options.length === 0 && matchedOptions.length === 0) {
      //Скрывает селетк,// если введенные данные не совпадают с данными селекта
      this.setState({
        selectDisplayed: false,
        anyMatched: false,
        inputValue: value,
        labelValue: value
      });
    } else {
      this.setState({
        selectDisplayed: true,
        anyMatched: true,
        inputValue: value,
        labelValue: value
      });
    }

    if (!this.props.onlySelect) onChange({ value: value });

    if (this.props.onAction) this.props.onAction("outclick");
  };

  //Находим элементы селекта, которые похожи на введенное значение
  matchedOptions(value, options) {
    const {
      inputOptions: { type }
    } = this.props;
    const matchedOptions = options.filter(option => {
      const optionUpper = String(option.value).toUpperCase();
      const isPayload = option.hasOwnProperty("payload");
      let payloadToUpperCase = "";

      if (isPayload) {
        const isAddress = option.payload.hasOwnProperty("address");

        payloadToUpperCase = isAddress
          ? String(option.payload.address).toUpperCase()
          : "";
      }

      const trueValue = type === "tel" ? UserStore.convertPhone(value) : value;

      const filterUpper = trueValue.toUpperCase();
      const withPayload = isPayload
        ? payloadToUpperCase.indexOf(filterUpper) !== -1
        : false;
      return optionUpper.indexOf(filterUpper) !== -1 || withPayload;
    });

    return matchedOptions;
  }

  _outClickEventHandler = event => {
    const searchSelect = this.searchSelect.current;
    const eventTarget = event.target;
    if (!searchSelect.contains(eventTarget)) {
      this.setState({
        selectDisplayed: false
      });
      if (this.props.onlySelect && !this.state.selectedValue) {
        this.reset();
      }

      if (
        this.props.onlySelect &&
        this.state.selectedValue !== this.state.inputValue
      ) {
        this.setState({
          labelDisplayed: true,
          labelValue: this.state.labelValue,
          inputValue: this.state.selectedValue
        });
      }

      if (this.props.onlySelect && this.state.selectedValue) {
        this.setState({
          labelDisplayed: true,
          inputValue: this.state.selectedValue
        });
      }

      if (this.state.inputValue || this.state.value === "") {
        this.props.selectOptions.onSelect({
          value: this.state.inputValue,
          payload: this.state.payload
        });

        if (this.props.onAction) this.props.onAction("outclick");
      }

      if (this.onlySelect && this.state.selectedValue === "") {
        this.setState({
          inputValue: "",

          selectedValue: "",
          payload: {}
        });

        this.props.selectOptions.onSelect({ value: "" });
      }

      if (
        this.onlySelect &&
        this.state.selectedValue !== "" &&
        this.state.inputValue !== this.state.selectedValue
      ) {
        this.setState(prevState => {
          return {
            inputValue: this.state.selectedValue
          };
        });

        this.props.selectOptions.onSelect({
          value: this.state.inputValue,
          payload: this.state.payload
        });
      }

      if (this.state.inputValue === "") {
        this.setState({
          inputValue: "",
          selectedValue: "",
          payload: {},
          labelValue: ""
        });
      }
    }
  };

  reset = () => {
    this.setState({ ...this.initialState });
    this.input.current.reset && this.select.current.reset();
    this.input.current.clear && this.input.current.clear();
  };

  inputStyle() {
    const { labelDisplayed } = this.state;
    return {
      background: labelDisplayed ? "transparent" : "none",
      color: labelDisplayed ? "transparent" : "black",
      textDecoration: "none",
      transition: "none"
    };
  }

  labelStyle() {
    const { labelDisplayed } = this.state;

    return {
      display: labelDisplayed ? "block" : "none",
      position: "absolute",
      top: "6px"
    };
  }

  selectStyles() {
    const { selectDisplayed } = this.state;

    return {
      display: selectDisplayed ? "block" : "none"
    };
  }
}
