import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import FloatPrice from '../../../Common/FloatPrice';

function IntervalsBlock(props) {
  const {
    costData,
    periodTitle = i18n.t('parking:payments:intervals:period'),
    costTitle = i18n.t('parking:payments:intervals:cost'),
    benefitTitle = i18n.t('parking:payments:intervals:benefit')
  } = props;

  const getTime = intervalTime => {
    let t = moment(intervalTime);
    let hours = t.hours();
    let minutes = t.minutes();
    hours = String(hours).length === 1 ? '0' + hours : hours;
    minutes = String(minutes).length === 1 ? '0' + minutes : minutes;

    return hours + ':' + minutes;
  };

  const no = i18n.t('parking:payments:intervals:no');
  const isIntervalsHasBenefit = () => {
    return costData.intervals.some(interval => interval.hasOwnProperty('benefit'));
  };

  return (
    <div
      style={{
        marginBottom: 10,
        marginTop: 10
      }}
    >
      {costData && costData.intervals ? (
        <Row
          noGutters
          style={{
            borderBottom: '0.5px solid #cccccc'
          }}
        >
          <Col>{periodTitle}</Col>
          {isIntervalsHasBenefit() ? <Col>{benefitTitle}</Col> : null}
          <Col>{costTitle}</Col>
        </Row>
      ) : null}
      {costData && costData.intervals
        ? costData.intervals.map((interval, i) => (
            <Row
              noGutters
              key={i}
              style={{
                borderBottom: '0.5px solid #ececec'
              }}
            >
              <Col>
                {getTime(interval.timestart)} - {getTime(interval.timeend)}
              </Col>
              {isIntervalsHasBenefit() ? <Col>{interval.benefit || no}</Col> : null}
              <Col>
                <FloatPrice number={interval.cost} className="sum neutral" />
              </Col>
            </Row>
          ))
        : null}
    </div>
  );
}

export default IntervalsBlock;
