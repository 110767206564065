import React from 'react';

import PropTypes from 'prop-types';

import CarNumberStore from '../../../Stores/CarNumberStore';

class SquareNumber extends React.PureComponent {
  render() {
    let { region, country, type, matches, className, children } = this.props;

    const numberType = CarNumberStore.getType(type);
    const modificator = numberType ? `state-number--${numberType}` : '';
    const myClassName = `state-number ${modificator} state-number--square`;

    return (
      <div className={`${myClassName} ${className}`}>
        <div className="main-number">
          <div className="first-letter">{matches[1]}</div>
        </div>
        <div className="bottom-row">
          <div className="last-letters">{matches[2]}</div>

          <div className="region">
            <div className="number">{region}</div>

            <div className="country">{country}</div>
          </div>
        </div>
        {children}
      </div>
    );
  }

  renderMainNumber = props => {
    const { matches } = props;
    const { firstLetter, lastLetters } = this.props;

    if (matches.length === 3) {
      const style = {
        main: { justifyContent: 'center' },
        firstLetter: { marginRight: '10px' }
      };

      return (
        <div className="main-number" style={style.main}>
          <div className="first-letter" style={style.firstLetter}>
            {matches[1]}
          </div>

          <div className="last-letters">{matches[2]}</div>
        </div>
      );
    }

    return (
      <div className="main-number">
        <div className="first-letter">{firstLetter}</div>

        <div className="last-letters">{lastLetters}</div>
      </div>
    );
  };

  static propTypes = {
    number: PropTypes.string,
    firstLetter: PropTypes.string,
    secondNumbers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastLetters: PropTypes.string,

    region: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country: PropTypes.string
  };

  static defaultProps = {
    number: null,

    firstLetter: 'О',
    secondNumbers: '000',
    lastLetters: 'ОО',

    region: '000',
    country: 'RUS'
  };
}

export default SquareNumber;
