import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';

@translate(['operationsHistory'], { wait: true })
class RootLngRoute extends React.Component {
  render() {
    const { computedMatch, i18n, ...rest } = this.props;
    const lngParam = computedMatch ? computedMatch.params.lng : null;
    if (!lngParam) {
      return <Route {...rest} />;
    }
    if (i18n.languages.includes(lngParam)) {
      return <Route {...rest} />;
    } else return <Redirect to="/" />;
  }
}

export default RootLngRoute;
