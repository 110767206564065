import React from 'react';

function AppContainer(props) {
  const { white, url, className, src } = props;
  const clN = white ? 'white' : '';

  const style = {
    backgroundImage: `url(${url})`
  };

  return <a style={style} className={`app-container ${clN} ${className}`} href={src} />;
}

export default AppContainer;
