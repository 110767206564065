import React from 'react';
import FontAwesome from '@fortawesome/react-fontawesome';
import faClock from '@fortawesome/fontawesome-free-solid/faClock';
import TimePicker from 'rc-time-picker';

const TimePickerP = ({ onChange, errorText, error, placeholder }) => {
  const handleTimePick = time => {
    if (onChange) {
      onChange(time);
    }
  };

  return (
    <>
      <TimePicker
        placeholder={placeholder}
        showSecond={false}
        inputIcon={
          <FontAwesome
            style={{
              transform: 'translate(-22px, 6px)',
              position: 'absolute'
            }}
            icon={faClock}
          />
        }
        onChange={handleTimePick}
      />
      {error && <div style={{ color: 'red', fontSize: 10, marginTop: 4 }}>{errorText}</div>}
    </>
  );
};

export default TimePickerP;
