import React from "react";
import { observer } from "mobx-react";

import PageWrapper from "../../../Components/ProfileComponents/Common/PageWrapper";
import PenaltyStore from "../../../Stores/PenaltyStore";

import TwitchLoader from "../../../Components/Common/TwitchLoader";
import PenlatyContainer from "./Container";

@observer
class Penalty extends React.Component {
  componentDidMount() {
    PenaltyStore.loadPenalty();
  }

  render() {
    return (
      <PageWrapper>
        {PenaltyStore.fetching ? <TwitchLoader /> : <PenlatyContainer />}
      </PageWrapper>
    );
  }
}

export default Penalty;
