import Number from './Number';
import LongNumber from './LongNumber';
import SquareNumber from './SquareNumber';

export default Number;

export {
  Number, 
  LongNumber, 
  SquareNumber
}
