import SessionCancelModal from '../../Containers/ProfileContainer/Parking/NoBalance/View/CancelSessionModal';
import confirm from '../../Components/Common/Modals/ConfirmModal';
import ImageModal from '../../Components/Common/Modals/ImageModal';
import NotifyModal from "../../Components/Common/Modals/NotifyModal";

const modalList = {
  cancelSession: SessionCancelModal,
  confirm,
  image: ImageModal,
  notify: NotifyModal,
};

export default modalList;
