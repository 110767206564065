import ApiProvider from '../APIProvider';
import CoopUser from '../../CoopUser';
import CityStore from '../../CityStore';

import Token from '../../../utils/Token';

class CoopApi {
  get token() {
    return Token.getTokenForCity(CityStore.zone, CoopUser.USER_TYPE);
  }

  async auth(inn, contractNumber) {
    const response = await ApiProvider.APIRequest({
      method: 'POST',
      path: '/api/auth/login-corp-v1',
      body: { inn, contractNumber }
    });

    return response;
  }

  async getProfile() {
    const response = await ApiProvider.APIRequest({
      method: 'GET',
      path: '/api/corp-profile/get-profile',
      params: { token: this.token }
    });

    return response;
  }

  async addTs(number, VINNumber) {
    const response = await ApiProvider.APIRequest({
      method: 'POST',
      path: '/api/profile/ts/1',
      params: this.token,
      body: {
        number,
        VINNumber
      }
    });

    return response;
  }

  activateDriver = async (driver, inn) => {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/corp-profile/activate-driver',
      body: {
        fio: driver.name,
        phone_number: driver.phone,
        inn: inn
      }
    });

    return response;
  };

  deActivateDriver = async (driver, inn) => {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/corp-profile/deactivate-driver',
      body: {
        fio: driver.name,
        phone_number: driver.phone,
        inn: inn
      }
    });

    return response;
  };

  activateDevice = async (device, inn) => {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/corp-profile/activate-device',
      body: {
        grz: device.numberTs,
        vin: device.vin,
        imei: device.imei,
        inn: inn
      }
    });

    return response;
  };

  deActivateDevice = async (device, inn) => {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/corp-profile/deactivate-device',
      body: {
        grz: device.numberTs,
        vin: device.vin,
        imei: device.imei,
        inn: inn
      }
    });

    return response;
  };

  async addDevice(number, VINNumber) {
    const response = await ApiProvider.APIRequest({
      method: 'POST',
      path: '/api/profile/ts/1',
      params: this.token,
      body: {
        number,
        VINNumber
      }
    });

    return response;
  }

  async removeDevice(id) {
    const response = await ApiProvider.APIRequest({
      method: 'DELETE',
      path: `/api/profile/ts/${id}`,
      params: this.token
    });

    return response;
  }

  async addDriver(driver) {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/corp-profile/add-driver',
      body: {
        driver: driver.name,
        car_number: driver.ts,
        phone: driver.phone,
        token: this.token
      }
    });

    return response;
  }

  async editDriver(oldDriver, newDriver) {
    const response = await ApiProvider.APIRequest({
      method: 'POST',
      path: '/api/corp-profile/edit-driver',
      body: {
        token: this.token,
        driver: oldDriver.name,
        car_number: oldDriver.ts,
        phone: oldDriver.phone,
        driver_n: newDriver.name,
        car_number_n: newDriver.numberTs,
        phone_n: newDriver.phone
      }
    });

    return response;
  }

  async removeDriver(driver) {
    const response = await ApiProvider.APIRequest({
      method: 'DELETE',
      path: '/api/corp-profile/remove-driver',
      body: {
        driver: driver.name,
        car_number: driver.ts,
        phone: driver.phone,
        token: this.token
      }
    });

    return response;
  }

  async changeCars(cars) {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/profile/change-cars-v2',
      body: {
        token: this.token,
        car: cars
      }
    });

    return response;
  }

  async addCar(car) {
    const response = await ApiProvider.APIRequest({
      method: 'POST',
      path: '/api/profile/car',
      body: {
        token: this.token,
        ...car
      }
    });

    return response;
  }

  async changeCar(newCar, oldCar) {
    const response = await ApiProvider.APIRequest({
      method: 'PUT',
      path: '/api/profile/car',
      body: {
        token: this.token,
        car: {
          ...newCar,
          newgrz: newCar['ГРЗ'],
          oldgrz: oldCar['ГРЗ']
        }
      }
    });

    return response;
  }

  async removeCar(car) {
    const response = await ApiProvider.APIRequest({
      method: 'DELETE',
      path: '/api/profile/car',
      body: {
        token: this.token,
        ...car
      }
    });

    return response;
  }

  async getBalance() {
    const response = await ApiProvider.APIRequest({
      method: 'GET',
      path: '/api/profile/get-balance',
      params: { token: this.token }
    });

    return response;
  }

  async operationsFilter(filter) {
    const response = await ApiProvider.APIRequest({
      method: 'POST',
      path: '/api/profile/get-operations-with-filter',
      params: {
        token: this.token,
        StartDate: filter ? filter.periodStart : null,
        EndDate: filter ? filter.periodEnd : null,
        Operation: filter ? filter.currentOperationTypeFilter : null,
        Vehicle: filter ? filter.currentTransportFilter : null,
        Zone: filter ? filter.currentZoneFilter : null,
        OrderNumber: filter ? filter.orderNumber : null,
        Driver: filter ? filter.currentDriverFilter : null
      }
    });

    return response;
  }
}

export default new CoopApi();
