import React from 'react';
import Title from '../../../Components/UpdatePage/Title';
import Description from '../../../Components/UpdatePage/Description';
import ExampleItemBr from '../../../Components/UpdatePage/ExampleItemBr';

const SmsCommand = ({ name, hint = '', example = '', command_transcription = '' }) => {
  const SEPORATOR = '*';
  const colorSet = ['black', 'orange', 'blue', 'pink'];

  const commandTranscriptionSplit = command_transcription.split(SEPORATOR);
  const exampleSplit = example.split(SEPORATOR);

  const getColor = index => {
    if (index > colorSet.length) {
      const calcIndex =
        Math.round((index + 1) / colorSet.length) + (index % colorSet.length) - colorSet.length + 2;
      return colorSet[calcIndex];
    }
    return colorSet[index];
  };

  const isAddSeporator = (index, list) => {
    return index !== list.length - 1;
  };

  return (
    <section key={name}>
      <Title className="update-page__title">{name}</Title>
      {commandTranscriptionSplit.length !== 0 ? (
        <Description className="example">
          {commandTranscriptionSplit.map((commandTranscriptionPart, index) =>
            commandTranscriptionPart ? (
              <React.Fragment key={index}>
                <span className={getColor(index)}>{commandTranscriptionPart}</span>
                {isAddSeporator(index, commandTranscriptionSplit) ? SEPORATOR : null}
              </React.Fragment>
            ) : null
          )}
        </Description>
      ) : null}
      <Description>
        <p>{hint}</p>
        <div className="update-page__example">
          {exampleSplit.map((examplePart, index) => (
            <React.Fragment key={index}>
              <ExampleItemBr color={getColor(index)}>{examplePart}</ExampleItemBr>
              {isAddSeporator(index, exampleSplit) ? SEPORATOR : null}
            </React.Fragment>
          ))}
        </div>
      </Description>
    </section>
  );
};

export default SmsCommand;
