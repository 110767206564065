import React, { useRef, useEffect, useState } from 'react';
import CloseButton from '../Common/Buttons/CloseButton';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './style/PopUp.css';

const calculateMaxHeight = (titleEl, popupEl) => {
  if (titleEl && popupEl) {
    const popupelStyles = getComputedStyle(popupEl.current);
    const summPadding =
      parseFloat(popupelStyles.getPropertyValue('padding-top')) +
      parseFloat(popupelStyles.getPropertyValue('padding-bottom'));
    return popupEl.current.offsetHeight - titleEl.current.offsetHeight - summPadding;
  }

  return 0;
};

const PopUp = ({ isClosed = false, onClose, title, content }) => {
  const MAX_CONTENT_HEIGHT = 300;

  const popUpRef = useRef(null);
  const titleRef = useRef(null);

  const [maxHeight, setMaxHeight] = useState(MAX_CONTENT_HEIGHT);

  useEffect(() => {
    setMaxHeight(calculateMaxHeight(titleRef, popUpRef));
  }, [title, content]);

  return (
    <div
      className={`popUp ${isClosed ? 'popUp--closed' : ''}`}
      style={{ maxHeight: MAX_CONTENT_HEIGHT }}
      ref={popUpRef}
    >
      <h3 className="popUp__title" ref={titleRef}>
        <span className="popUp__title-text">{title}</span>{' '}
        <CloseButton className="popUp__title-close" onClose={onClose} />
      </h3>
      <SimpleBar style={{ maxHeight }}>
        <div className="popUp__content-wrapper" dangerouslySetInnerHTML={{ __html: content }} />
      </SimpleBar>
    </div>
  );
};

export default PopUp;
