import React, { Component, Fragment } from "react";

import Header from "../../Components/Header";

import UserStore from "../../Stores/UserStore";
import UIStore from "../../Stores/UIStore";
import { translate } from "react-i18next";

import Payment from "../../Components/ProfileComponents/Payment";

import { observable } from "mobx";
import { observer } from "mobx-react";
import OptionsStore from "../../Stores/OptionsStore";
import LoginStore from "../../Stores/LoginStore";

@translate(["header"], { wait: true })
@observer
class HeaderContainer extends Component {
  @observable UserStore;
  @observable UIStore;

  componentDidMount() {
    OptionsStore.getOptions();
    if (UserStore.isLoggedIn()) {
      UserStore.loadProfileIfNeeded();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (UserStore.isLoggedIn()) UserStore.loadProfileIfNeeded();
  }

  closeStartModal = () => {
    UIStore.closeModal("startModal");
  };

  render() {
    return (
      <Fragment>
        <Payment
          actionType="start"
          isOpen={UIStore.startModal}
          onSubmit={this.closeStartModal}
          onExit={this.closeStartModal}
          startDisabled={false}
        />
        <Header
          dropdowns={UIStore.dropdowns}
          refillModalIsOpen={UIStore.refillModal}
          toggleLoginModal={UIStore.toggleLoginModal}
          toggleDropDown={UIStore.toggleDropDown}
          closeRefillModal={UIStore.closeRefillModal}
          isLoggedIn={UserStore.isLoggedIn()}
          isProfileLoaded={UserStore.profile}
          menuFields={OptionsStore.headerMenuItems}
          logout={this.logout}
          changeLanguage={this.changeLanguage}
          isMenuLoading={OptionsStore.fetching}
        />
      </Fragment>
    );
  }

  logout = () => {
    this.props.history.push("/");
    LoginStore.logOut();
  };
}

export default HeaderContainer;
