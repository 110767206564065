import React, { Children, cloneElement } from 'react';

import '../style/index.css';

function EditGroup ({ children }) {
  function addClassNameToChildrens () {
    const withoutNull = Children.toArray(children).filter(child => child !== null);
    return withoutNull.map((child) => (
      cloneElement(child, {className: 'edit-group__item'})
    ));
  }

  return (
      <div className="edit-group">
        { addClassNameToChildrens() }
      </div>
  );
}

export default EditGroup;
