import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.min.css';
import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';
import ReceiptSvg from "../../icons/ReceiptSvg";

@translate(['operationsHistory'], { wait: true })
@observer
export default class TableRow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderStatus() {
    const { paymentStatus, hasStatus } = this.props;
    if (hasStatus) {
      return (
        <td>
          <span className="operation-status">{paymentStatus}</span>
        </td>
      );
    }
    return null;
  }

  render() {
    const {
      index,
      operation,
      dateStart,
      dateOperation,
      sumClassName,
      sum,
      type,
      transport,
      zoneLabel,
      zone,
      parkingLabel,
      parking,
      timeInterval,
    } = this.props;

    const { REFILL } = OperationsHistoryStore.OPERATION_TYPES_FILTERS;

    return (
      <tr key={index}>
        <td>{dateOperation}</td>
        <td align="center">{this.swapOperationToLocale(type)}
          {operation.checkFiscal &&
          <a href={operation.checkFiscal} target="_blank" title="Чек операции"><ReceiptSvg width={28} height={28} color={"#1587c8"} style={{transform:'translateY(8px)', paddingLeft:"5px" }}/> </a>}
        </td>
        <td className="text-secondary">
          <span className={'sum ' + sumClassName}>
            {this._getBeautifySum(sum)}
          </span>
        </td>
        {this.renderStatus()}
        <td>
          <span>
            {parking ? (
              <div className="zone">
                {parkingLabel}
                {parking}
              </div>
            ) : null}
            <div className="zone">
              {zoneLabel}
              {zone}
            </div>
          </span>
          {type ===
          OperationsHistoryStore.OPERATION_TYPES_FILTERS.PAYMENTGATE ? null : (
            <span>
              <span>ТС: </span>
              <span className="text-black-50 ts-number">{transport}</span>
            </span>
          )}
          {type ===
          OperationsHistoryStore.OPERATION_TYPES_FILTERS.PAYMENTGATE ? null : (
            <>
              <br />
              <span className="text-secondary">{timeInterval}</span>
            </>
          )}
        </td>
      </tr>
    );
  }

  _getBeautifySum = (sum) => {
    const rounded = Math.round(sum * 100) / 100;
    const numbers = String(rounded).split(/[.,]/);
    let firstPart = numbers[0];
    let lastPart = null;

    if (
      numbers.length === 1 ||
      (numbers.length > 1 && numbers[1].trim().length === 0)
    ) {
      lastPart = '00';
    } else {
      lastPart = numbers[1];
      if (lastPart.length === 1) {
        lastPart += '0';
      } else if (lastPart.length > 2) {
        // lastPart = lastPart
      }
    }
    return (
      <Fragment>
        <span>{firstPart}</span>
        <span className="rest">.{lastPart}</span>
      </Fragment>
    );
  };

  swapOperationToLocale(type) {
    const { t, driverPhone } = this.props;
    const {
      PAYMENT,
      REFILL,
      RENEW,
      CANCEL,
      SUBSCRIPTION,
      PAYMENTGATE,
      BONUS20,
    } = OperationsHistoryStore.OPERATION_TYPES_FILTERS;

    if (driverPhone) {
      return driverPhone;
    }

    switch (type) {
      case CANCEL:
        return t('operations:refund');
      case REFILL:
        return t('operations:refill');
      case PAYMENT:
        return t('operations:payment');
      case RENEW:
        return t('operations:extension');
      case SUBSCRIPTION:
        return t(`operations:${SUBSCRIPTION}`);
      case PAYMENTGATE:
        return t(`operations:paymentGate`);
      case BONUS20:
        return t(`operations:bonus20`);
      default:
        return type;
    }
  }
}
