import React from 'react';

import { Button } from 'reactstrap';
import FontAwesome from '@fortawesome/react-fontawesome';

import '../../../../Assets/Styles/Profile/Compiled/all.css';

function CoreButton ({ onClick, icon, className }) {
    return (
        <Button
            className={className}
            color="light"
            onClick={onClick}
        >
            <div className="translate">
                <FontAwesome icon={icon}/>
            </div>
        </Button>
    );
}

export default CoreButton;
