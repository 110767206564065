import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import FieldPreLoader from '../../FieldPreLoader';

function TwitchLoader (props) {
  return (
    <Fragment>
      {
        [ 1, 2, 3 ].map((element) => {
          return (
            <Row noGutters key={ 'holder-' + element }
                  style={ { marginLeft: -10 } }>
              <Col xs={ 3 }>
                <FieldPreLoader visible style={ { margin: 10 } }/>
              </Col>
              <Col xs={ 1 }>
                <FieldPreLoader visible style={ { margin: 10 } }/>
              </Col>
              <Col xs={ 2 }>
                <FieldPreLoader visible style={ { margin: 10 } }/>
              </Col>
              <Col xs={ 2 }>
                <FieldPreLoader visible style={ { margin: 10 } }/>
              </Col>
              <Col xs={ 4 }>
                <FieldPreLoader visible style={ { margin: 10 } }/>
              </Col>
            </Row>
          );
        }) 
      }
    </Fragment>
  );
}

export default TwitchLoader;
