import React from 'react';

function ExampleItemBr(props) {
  const { color } = props;

  return (
    <span className={`update-page__example-item update-page__example-item--${color}`}>
      {props.children}
    </span>
  );
}

ExampleItemBr.defaultProps = {
  color: ''
};

export default ExampleItemBr;
