import React from "react";
import Button from "reactstrap/lib/Button";

export default class CustomDatePickerInput extends React.Component {
  render() {
    const value = this.props.value || this.props.placeholder;

    return (
      <>
        <span style={{ fontSize: 12 }}>{this.props.placeholder}</span>
        <Button
          size="sm"
          style={{ display: "block", marginTop: 5, marginBottom: 5 }}
          color="primary"
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          {value}
        </Button>
      </>
    );
  }
}
