import React from 'react';
import PathStore from '../../../Stores/PathStore';
import { NavLink } from 'react-router-dom';

function ProfileButton  (props) {
        return (
            <NavLink className='btn btn-link btn-sm menu-registration px-0'
                     to={PathStore.pathWithLang('/registration')}>
                { props.children }
            </NavLink>
        );
}

export default ProfileButton;
