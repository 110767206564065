import React from 'react';
import './style/index.css';

function CurrentPositionButton(props) {
    const { className, ...rest } = props;
    return (
        <button className={`current-geo-btn ${className}`} { ...rest }/>
    );
}

CurrentPositionButton.defaultProps = {
    className: ''
};

export default CurrentPositionButton;
