import ArrayFindPolyfill from './ArrayFindPolyfill';
import ArrayFromPolyfill from './ArrayFromPolyfill';
import StringIncludesPolyfill from './StringIncludesPolyfill';
import objectFitImages from 'object-fit-images';
import * as yup from 'yup';
class PolyfillService {
  static init() {
    ArrayFindPolyfill();
    ArrayFromPolyfill();
    StringIncludesPolyfill();
    objectFitImages();
    yup.addMethod(yup.mixed, 'defined', function() {
      return this.test('defined', '{path} must be defined', value => value !== undefined);
    });
  }
}

export default PolyfillService.init();
