import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import UIStore from '../../Stores/UIStore';

import { Scrollbars } from 'react-custom-scrollbars';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import './style.css';

@observer
class Card extends Component {
  @observable UIStore;

  constructor(props) {
    super();
    this.isClosable = props.isClosable;
    this.withShadow = props.withShadow;
  }

  render() {
    const mapHeight = UIStore.mapStyle.height.replace('px', '');
    const offsetHeight = document.body.offsetWidth < 767 ? 0 : 300;
    const maxCardHeight = mapHeight - UIStore.footerHeight - offsetHeight - 25;
    const withShadowClassName = this.props.withShadow ? '' : 'custom-card--no-shadow';
    return (
      <div
        className={`custom-card ${this.props.className} ${withShadowClassName}`}
        ref={this.customCard}
        style={{ ...this.props.style }}
      >
        <Scrollbars autoHeight autoHeightMax={maxCardHeight} hideTracksWhenNotNeeded>
          <div className="custom-card__body">
            {this.renderClose()}
            {this.props.children}
          </div>
        </Scrollbars>
      </div>
    );
  }

  renderClose = () => {
    return this.isClosable ? (
      <div className="cross-body">
        <FontAwesomeIcon
          className="cross-body__close"
          icon={faTimes}
          onClick={this.props.handleClose}
        />
      </div>
    ) : null;
  };

  static propTypes = {
    isClosable: PropTypes.bool,
    handleClose: PropTypes.func
  };

  static defaultProps = {
    isClosable: false,
    handleCLose: () => null
  };
}

export default Card;
