import React from 'react';
import { Redirect } from 'react-router-dom';
import OnlyGuestRoute from '../OnlyGuestRoute';
import SettingsStore from '../../../Stores/SettingsStore';

function UnregisterPaymentRoute({ component: Component, ...rest }) {
  function renderContent(props) {
    return SettingsStore.settings.useSessionWithoutRegister ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    );
  }

  return <OnlyGuestRoute {...rest} component={renderContent} />;
}

export default UnregisterPaymentRoute;
