import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import UserStore from '../../../Stores/UserStore';
import { translate } from 'react-i18next';

import TableBody from './TableBody';
import OperationsTablePreLoader from './OperationsTablePreLoader';
import OperationsTableErrorMessage from './OperationsTableErrorMessage';
import TableHead from './TableHead';

@translate(['operationsHistory'], { wait: true })
@observer
export default class OperationsTable extends Component {
  render() {
    const { t, operations, hasStatus } = this.props;

    if (!this.props.isAllDataLoaded) {
      return <OperationsTablePreLoader />;
    } else {
      const sessions = operations || UserStore.getOperations();
      if (sessions.length === 0) {
        return <OperationsTableErrorMessage message={t('operationsHistory:emptyOperationsList')} />;
      }

      return (
        <div className="table-responsive">
          <table className="table" id="operations-table">
            <TableHead hasStatus={hasStatus} />
            <TableBody operations={sessions} hasStatus={hasStatus} />
          </table>
        </div>
      );
    }
  }
}
