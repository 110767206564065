import React, { Fragment } from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faCar from '@fortawesome/fontawesome-free-solid/faCar';

function FreePlacesRow({ free, total, t }) {
  function isFreebroken() {
    return free === -1;
  }

  function placesWithoutFree() {
    return (
      <Fragment>
        <div className="reservation-title">
          <span>{`${t('reservation:parking:places:totalPlaces')}: ${total}`}</span>
        </div>
      </Fragment>
    );
  }

  function placesWithFree() {
    return (
      <div className="reservation-title">
        <span>
          {`
						${free}
						${t('reservation:parking:places:from')}
						${total} ${t('reservation:parking:places:placesFree')}
					`}
        </span>
      </div>
    );
  }

  function places() {
    if (isFreebroken()) {
      return placesWithoutFree();
    }

    return placesWithFree();
  }

  return (
    <Fragment>
      <div className="reservation-icon">
        <FontAwesomeIcon icon={faCar} />
      </div>
      {places()}
    </Fragment>
  );
}

export default FreePlacesRow;
