import React, { Component } from "react";
import { observer } from "mobx-react";

import Operations from "../../../../Components/ProfileComponents/Operations";
import OperationsError from "../../../../Components/ProfileComponents/Operations/OperationsError";
import OperationsFilters from "../../../../Components/OperationsComponents/Filters";
import OperationsTable from "../TableContainer";

import UserStore from "../../../../Stores/UserStore";
import MapStore from "../../../../Stores/MapStore";

@observer
class OperationsContainer extends Component {
  async componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    UserStore.loadProfileIfNeeded();
    MapStore.loadZonesIfNeeded();
  };

  isLoadError() {
    return (
      UserStore.profile.error &&
      UserStore.operations.error &&
      MapStore.zones.error
    );
  }

  isDataForFilterLoaded() {
    return UserStore.profile.done && MapStore.zones.done;
  }

  isAllDataLoaded() {
    return (
      this.isDataForFilterLoaded() &&
      UserStore.operations.done &&
      UserStore.operations.items &&
      UserStore.operations.items.length
    );
  }

  render() {
    if (this.isLoadError()) {
      return (
        <OperationsError
          className="profile-abonements"
          fetch={this.fetch}
          errorTitle="Ошибка загрузки статистики"
          errorMessage="Ошибка"
          tryAganeMessage="Попробуйте снова"
        />
      );
    }

    const isLoading = !this.isAllDataLoaded();
    const isDataForFilterLoaded = this.isDataForFilterLoaded();

    return (
      <Operations
        filter={<OperationsFilters isAllDataLoaded={isDataForFilterLoaded} />}
        table={<OperationsTable isLoading={isLoading} />}
        isLoading={isLoading}
        isAllLoaded={isDataForFilterLoaded}
      />
    );
  }
}

export default OperationsContainer;
