import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

function PageWrapper({ children }) {
  return (
    <Row noGutters className="profile-info justify-content-center" style={{ flex: '1 0 auto' }}>
      <Col md={12} lg={9} className="main-wrapper" style={{ maxWidth: '900px' }}>
        <Row noGutters className="justify-content-center">
          <Col>
            <div className="md-card">{children}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PageWrapper;
