import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import { Button } from 'reactstrap';
import { translate } from 'react-i18next';

import OperationsHistoryStore from '../../../Stores/OperationsHistoryStore';
import SettingsStore from '../../../Stores/SettingsStore';

@translate(['operationsHistory'], { wait: true })
@observer
export default class OperationsFilters extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="info-field">
        <div className="name">
          {t('operationsHistory:filters:labels:operationTypeLabel')}
        </div>

        <div className="value">
          <Button
            className={this._getOperationTypeButtonClass('all')}
            color={this._getOperationTypeButtonColor('all')}
            data-filter="all"
            onClick={this._applyOperationTypeFilter}
          >
            {t('operationsHistory:operationType:all')}
          </Button>

          <Button
            className={this._getOperationTypeButtonClass('payment')}
            color={this._getOperationTypeButtonColor('payment')}
            data-filter="payment"
            onClick={this._applyOperationTypeFilter}
          >
            {t('operationsHistory:operationType:payment')}
          </Button>
          <Button
            className={this._getOperationTypeButtonClass('refill')}
            color={this._getOperationTypeButtonColor('refill')}
            data-filter="refill"
            onClick={this._applyOperationTypeFilter}
          >
            {t('operationsHistory:operationType:refill')}
          </Button>
          <Button
            className={this._getOperationTypeButtonClass('renew')}
            color={this._getOperationTypeButtonColor('renew')}
            data-filter="renew"
            onClick={this._applyOperationTypeFilter}
          >
            {t('operationsHistory:operationType:prolong')}
          </Button>
          <Button
            className={this._getOperationTypeButtonClass('cancel')}
            color={this._getOperationTypeButtonColor('cancel')}
            data-filter="cancel"
            onClick={this._applyOperationTypeFilter}
          >
            {t('operationsHistory:operationType:refund')}
          </Button>
          <Button
            className={this._getOperationTypeButtonClass('paymentSubscription')}
            color={this._getOperationTypeButtonColor('paymentSubscription')}
            data-filter="paymentSubscription"
            onClick={this._applyOperationTypeFilter}
          >
            {t('operations:paymentSubscription')}
          </Button>
          {SettingsStore.settings.useBonus ? (
            <Button
              className={this._getOperationTypeButtonClass('bonus20')}
              color={this._getOperationTypeButtonColor('bonus20')}
              data-filter="bonus20"
              onClick={this._applyOperationTypeFilter}
            >
              {t('operations:bonus20')}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  _applyOperationTypeFilter = (event) => {
    const target = event.target;

    if (target.getAttribute) {
      const filter = target.getAttribute('data-filter');

      if (!!filter) {
        OperationsHistoryStore.setOperationType(filter);
      }
    }
  };

  _compareValues = (currentValue, expectedValue, successValue, failValue) => {
    if (currentValue === expectedValue) {
      return successValue;
    }

    return failValue;
  };

  _getOperationTypeButtonClass = (filter) => {
    return this._compareValues(
      OperationsHistoryStore.filters.currentOperationTypeFilter,
      filter,
      'btn-options',
      'btn-options-grey'
    );
  };

  _getOperationTypeButtonColor = (filter) => {
    return this._compareValues(
      OperationsHistoryStore.filters.currentOperationTypeFilter,
      filter,
      'primary',
      'light'
    );
  };

  swapOperationToLocale(type) {
    const { t } = this.props;

    switch (type) {
      case 'Прерывание':
      case 'Возврат':
        return t('operations:refund');
      case 'Пополение':
        return t('operations:refill');
      case 'Оплата':
        return t('operations:payment');
      case 'Продление':
        return t('operations:extension');
      case 'Абонемент':
        return t('operations:paymentSubscription');
      default:
        return type;
    }
  }
}
