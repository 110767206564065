import React, { Component, Children, Fragment } from 'react';
import PropTypes from 'prop-types';

import './style.css';

export class ControlChild extends Component {
  render() {
    return (
      <div className="controls-group__child" {...this.props}>
        <div className="button-control">
          {Children.map(this.props.children, (child, index) => {
            return <Fragment>{child}</Fragment>;
          })}
        </div>
      </div>
    );
  }
}

class ControlGroup extends Component {
  render() {
    const isShadowOn = this.props.isShadowOn;
    const children = this.props.children;

    return (
      <div className="controls-group" style={isShadowOn ? {} : { boxShadow: 'none' }}>
        {Children.map(children, (child, index) => {
          return <Fragment>{child}</Fragment>;
        })}
      </div>
    );
  }

  static propTypes = {
    isShadowOn: PropTypes.bool
  };

  static defaultProps = {
    isShadowOn: false
  };
}

export default ControlGroup;
