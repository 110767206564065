import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

import FloatPrice from '../../../../Components/Common/FloatPrice';

import './style/penaltyItem.css';

const imgStyle = {
  width: '100%',
  height: 'auto'
};

const spacing = {
  padding: 20
};

const titleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: '600',
  fontSize: 18,
  ...spacing
};

const descriptionItemStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

const PenaltyItem = ({
  fineStatus,
  uin,
  date,
  totalSum,
  paidSum,
  sts,
  grz,
  start,
  end,
  place,
  type,
  provider,
  color,
  imgSrc,

  onClick = () => null,

  t
}) => {
  const handleClick = () => {
    onClick(uin);
  };

  let [borderColor, setBorderColor] = useState('#456cab');

  useEffect(() => {
    setBorderColor(getComputedStyle(document.documentElement).getPropertyValue('--bgSecondary'));
  }, []);
  return (
    <Accordion>
      <AccordionItem
        onClick={handleClick}
        style={{ borderLeft: `8px solid ${borderColor}` }}
        className="accrodion-item accordion-item--hover-shodow"
      >
        <AccordionItemTitle
          style={titleStyle}
          className={'accordion-item-title accrodion-item-title--no-bg'}
        >
          <span style={{ color: color }}>{fineStatus}</span>
          <span>#{uin}</span>
          <span>
            <FloatPrice number={totalSum} className="sum neutral" />
          </span>
        </AccordionItemTitle>

        <AccordionItemBody style={{ borderTop: 'none', ...spacing }}>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:totalSum') : 'Сумма штрафа'}:</span>
            <span>
              <FloatPrice number={totalSum} className="sum neutral" />
            </span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:paidSum') : 'Сумма оплаты'}:</span>
            <span>
              <FloatPrice number={paidSum} className="sum neutral" />
            </span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:date') : 'Дата и время вынесения постановления'}:</span>{' '}
            <span>{date}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:grz') : 'Транспортное средство'}:</span> <span>{grz}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:sts') : 'СТС'}:</span> <span>{sts}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:start') : 'Время начала парковки'}:</span> <span>{start}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:end') : 'Время окончания парковки'}:</span> <span>{end}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:place') : 'Место нарушения'}:</span> <span>{place}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:type') : 'Тип нарушения'}:</span> <span>{type}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:uin') : 'УИН'}:</span> <span>{uin}</span>
          </p>
          <p style={descriptionItemStyle}>
            <span>{t ? t('penalty:provider') : 'Источник штрафа'}:</span> <span>{provider}</span>
          </p>
          {imgSrc ? (
            <img style={imgStyle} src={`data:image/png;base64, ${imgSrc}`} alt={place} />
          ) : null}
        </AccordionItemBody>
      </AccordionItem>
    </Accordion>
  );
};

export default PenaltyItem;
