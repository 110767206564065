import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {Input} from "../../MaterialDesign";
import {observable} from "mobx";
import userStore from "../../../Stores/UserStore";
import {observer} from "mobx-react";

@observer
export default class Settings extends Component {
  _checkboxRef = React.createRef();

  constructor(props) {
    super(props);
  }

  render() {
    const {
      isLoading,
      translations
    } = this.props;

    return (
      <Fragment>
        <Input
            containerStyle={{ margin: 0, fontSize: '12px' }}
            label={translations.reportEmail}
            ref={this._checkboxRef}
            name="reportEmail"
            id="reportEmail"
            type="checkbox"
            disabled={userStore.settings.isLoading}
            onChange={(e) => this.handleChange(e, 'reportHistoryOperation')}
            checked={userStore.settings.reportHistoryOperation}
        />
      </Fragment>
    );
  }

  handleChange = async(e, name) => {
    e.preventDefault();
    const response = await userStore.changeAccountSettings({value: e.target.checked, name});
    if (response.status === 'ok') {
      //this.getData();
    }
    // console.log(response);
  }

  static propTypes = {
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    isLoading: false,
    hasPrivateData: true
  };
}
