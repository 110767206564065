import React from 'react';

import { Button } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import spinner from '@fortawesome/fontawesome-free-solid/faSpinner';

function SpinnerButton(props) {
  const { color, isLoading, className, onClick, children, ...rest } = props;

  const handleClick = e => {
    if (onClick) {
      onClick(e);
    }
  };

  function renderSpinner() {
    if (isLoading) {
      return <FontAwesomeIcon style={{ marginLeft: 10 }} name="circle" icon={spinner} spin />;
    }

    return null;
  }

  return (
    <Button
      color={color}
      className={className}
      onClick={handleClick}
      disabled={isLoading}
      {...rest}
    >
      {children}
      {renderSpinner()}
    </Button>
  );
}

SpinnerButton.defaultProps = {
  color: 'primary',
  children: 'Нажать',
  isLoading: false
};

export default SpinnerButton;
