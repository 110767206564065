import React from 'react';
import getUrlParams from '../../../utils/getUrlParams';

function Check(props) {
  const { search } = props.location;
  const queryParams = getUrlParams(search);

  return (
    <div className="check">
      <div className="check__header">
        <h3 className="check__title">{queryParams.title}</h3>
      </div>
      <div className="check__body">
        <div className="check__date-in">{queryParams.dateIn}</div>
        <div className="check__date-out">{queryParams.dateOut}</div>
        <div className="check__price">{queryParams.price}</div>
        <div className="check__ts">{queryParams.ts}</div>
      </div>
    </div>
  );
}

export default Check;
