import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Row, Col } from 'reactstrap';
import FieldPreLoader from '../../FieldPreLoader/index';
import { translate } from 'react-i18next';

@translate(['operationsHistory'], { wait: true })
@observer
export default class OperationsTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div className="d-flex flex-row">
        <span className="d-none d-lg-inline-block" style={{ width: 15, height: 1 }} />
        <div style={{ flex: 1 }}>
          {[1, 2, 3, 4].map(element => {
            return (
              <Row noGutters key={'holder-' + element} style={{ marginLeft: -10 }}>
                <Col xs={3}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={3}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={3}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
                <Col xs={3}>
                  <FieldPreLoader visible style={{ margin: 10 }} />
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    );
  }
}
