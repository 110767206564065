import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import Link from '../../../Containers/Links/LngLink';

import { Dropdown, DropdownToggle, DropdownItem } from 'reactstrap';

import DropdownMenuMaterial from '../../Common/DropdownMenuMaterial';

import { ControlChild } from '../../ControlGroup';

import ProfileIcon from '../../icons/AvatarSvg';

import UIStore from '../../../Stores/UIStore';
import ProfileStore from '../../../Stores/ProfileStore';

@translate(['header'], { wait: true })
@observer
class Profile extends Component {
  render() {
    const { t } = this.props;
    const dropdowns = UIStore.dropdowns;

    return (
      <Dropdown
        isOpen={dropdowns.dropdownOpenProfile}
        className="profile"
        toggle={this.toggleProfile}
      >
        <DropdownToggle className="reset-style-btn">
          <ControlChild>
            <div className="header__button">
              <ProfileIcon />
            </div>

            <div className="header__button_title">{t('profile:menu:profile')}</div>
          </ControlChild>
        </DropdownToggle>

        <DropdownMenuMaterial right>
          {this.renderProfileOption()}

          <DropdownItem divider />

          <Link to="/">
            <DropdownItem onClick={this.props.logout}>{t('auth:logout')}</DropdownItem>
          </Link>
        </DropdownMenuMaterial>
      </Dropdown>
    );
  }

  renderProfileOption() {
    return ProfileStore.menuItems.map((item, index) => {
      return (
        <Link to={`/profile/${item.type}`} key={index}>
          <DropdownItem>{item.name}</DropdownItem>
        </Link>
      );
    });
  }

  toggleProfile = () => {
    UIStore.toggleDropDown('Profile');
  };
}

export default Profile;
