import { observable, action } from 'mobx';

class ModalStore {
  @observable type = null;
  @observable open = false;
  @observable props = {};
  @observable internalProps = {};

  @action
  hideModal() {
    this.type = null;
    this.open = false;
    this.props = { open: false };
    this.internalProps = {};
  }

  @action
  toggle() {
    this.open = !this.open;
  }

  @action
  openModal(type, props, internalProps = {}) {
    this.type = type;
    this.open = true;
    this.props = { ...this.props, ...props };
    this.internalProps = internalProps;
  }
}

export default new ModalStore();
