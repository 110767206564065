import React from 'react';

import '../../../../Assets/Styles/Common/reset.css';
import '../../Header/style/index.css';
import './style/index.css';

function StartWithoutReg(props) {
    const { onClick, className } = props;

    return (
        <button
            className={`btn start-without-reg-btn ${className}`}
            onClick={onClick}
        >
            { props.children }
        </button>
    );
}

StartWithoutReg.defaultProps = {
    onClick: () => null,
    className: ''
}

export default StartWithoutReg;
