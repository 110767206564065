import React from 'react';

import './style/index.css';

function Marquee (props) {
  const { text, duration } = props;
  const style = {
    animationDuration: `${duration}ms`
  };

  return (
    <div className="marquee" style={style}>
      <span className="marquee__text">{ text }</span>
    </div>
  );
}

Marquee.defaultProps = {
  duration: '5000ms',
  text: ''
};

export default Marquee;