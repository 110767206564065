import React from 'react';
import RefillFormView from '../view';
import { observer } from 'mobx-react';
import userStore from '../../../../../Stores/UserStore';
import SettingsStore from '../../../../../Stores/SettingsStore';
import PaymentStore from '../../../../../Stores/PaymentStore';
import { translate } from 'react-i18next';
import UIStore from '../../../../../Stores/UIStore';

const inititalState = {
  toPaymentService: null,
  gettingPrice: false,
};

@translate(['parking'], { wait: true })
@observer
class RefillFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = inititalState;
  }

  reset = () => {
    this.setState({ ...inititalState });
  };

  handleFormChangeWhenValid = async (values) => {
    this.setState({
      gettingPrice: true,
    });
    if ('Квитанция' != values.service.methodName) {
      const response = await PaymentStore.paymentRefill({
        service: values.service,
        amount: values.amount,
        phone: values.phone
      });

      this.setState({
        gettingPrice: false,
      });

      if (response) {
        this.setState({
          toPaymentService: response.hasOwnProperty('RedirectURL')
            ? response.RedirectURL.replace(',', '.')
            : null,
        });
      }
      return response;

    } else {
      this.props.setReceiptMode({
        amount: values.amount,
        isReceiptMode: true,
        receiptReferer: 'refill',
      });
    }
  };

  onAbort = () => {
    const { onAbort } = this.props;

    onAbort();
  };

  render() {
    return (
      <RefillFormView
        t={this.props.t}
        toPaymentService={this.state.toPaymentService}
        services={SettingsStore.settings.paymentsMethods}
        phones={userStore.getAllPhones()}
        onChange={this.reset}
        onSubmit={this.props.onSubmit}
        onGetPrice={this.handleFormChangeWhenValid}
        onAbort={this.onAbort}
        gettingPrice={this.state.gettingPrice}
        closeModal={UIStore.closeRefillModal}
      />
    );
  }
}

export default RefillFormContainer;
