import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "mdbreact";
import EditableListItem from "../EditableListItem";

import PropTypes from "prop-types";

export default class TransportList extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;

    this.forceUpdate();
  }

  render() {
    const {
      items,
      renderItem,
      onEditButtonClick,
      onRemoveButtonClick,
      onActivateButtonClick,
      onDeactivateButtonClick,
      listGroupClassName
    } = this.props;

    return (
      <ListGroup>
        {items.map((item, index) => {
          return (
            <ListGroupItem className={listGroupClassName} key={index}>
              <EditableListItem
                onEditButtonClick={
                  onEditButtonClick
                    ? () => onEditButtonClick({ action: "edit", item, index })
                    : null
                }
                onRemoveButtonClick={
                  onRemoveButtonClick
                    ? () =>
                        onRemoveButtonClick({ action: "delete", item, index })
                    : null
                }
                onActivateButtonClick={
                  onActivateButtonClick
                    ? () =>
                        onActivateButtonClick({
                          action: "activate",
                          item,
                          index
                        })
                    : null
                }
                onDeactivateButtonClick={
                  onDeactivateButtonClick
                    ? () =>
                        onDeactivateButtonClick({
                          action: "deactivate",
                          item,
                          index
                        })
                    : null
                }
                item={item}
              >
                {renderItem(item)}
              </EditableListItem>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  }

  static propTypes = {
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    renderItem: PropTypes.func,

    onEditButtonClick: PropTypes.func,
    onRemoveButtonClick: PropTypes.func,

    listGroupClassName: PropTypes.string
  };

  static defaultProps = {
    items: [],
    renderItem: item => String(item),

    onEditButtonClick: null,
    onRemoveButtonClick: null,

    listGroupClassName: ""
  };
}
