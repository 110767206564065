import { observable } from 'mobx';
import Config, { SERVER_URI, MAP_SOURCE, SOUTH_WEST, NORTH_EAST } from '../Config';

class CityStore {
  @observable
  serverURI = SERVER_URI;

  @observable
  profile = null;

  @observable
  zone = null;

  @observable
  nameCityForShow = '';

  @observable
  mapSource = MAP_SOURCE;

  @observable
  mapBounds = {
    southWest: SOUTH_WEST,
    northEast: NORTH_EAST
  };

  @observable
  modalIsOpen = false;

  setCityFromLocaleStorageIfExist() {
    if (
      window.localStorage.getItem('city') &&
      window.localStorage.getItem('city') !== null &&
      window.localStorage.getItem('city') !== 'null' &&
      window.localStorage.getItem('city') !== ''
    ) {
      const city = window.localStorage.getItem('city');

      if (this.isCityExistInBackends(city)) {
        this.zone = window.localStorage.getItem('city');
        this.setParamsForCity(this.zone);
      } else {
        this.removeCity();
      }
    }
  }

  setCity(city) {
    this.city = city;
    window.localStorage.setItem('city', city);
    this.setParamsForCity(city);
    window.location.href = '/';
  }

  removeCity() {
    this.city = null;
    window.localStorage.setItem('city', null);
  }

  setParamsForCity = city => {
    let params = this.getParamsFromBackEnds(city);
    this.serverURI = params.apiUrl;
    this.mapBounds = params.mapBounds;
    this.mapSource = params.mapSource;
    this.nameCityForShow = params.nameForShow;
    this.profile = params.profile;
  };

  isCityExistInBackends = city => {
    return Config.backEnds.find(item => item.city === city);
  };

  getParamsFromBackEnds = city => {
    let params = null;
    Config.backEnds.forEach(item => {
      if (item.city === city) params = item;
    });
    return params;
  };

  toggleModal = () => {
    this.modalIsOpen = !this.modalIsOpen;
  };
}

export default new CityStore();
