import React from 'react';

export default ({color = "#000", width = "32", height = "32", style={}, ...rest}) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={width} height={height} style={style}>
            <g fill="none" stroke={color} stroke-width="2" stroke-miterlimit="10">
                <path d="M52 63V1H26L12 15v48l4-2 4 2 4-2 4 2 4-2 4 2 4-2 4 2 4-2z"/>
                <path d="M12 15h14V1"/>
            </g>
            <path fill="none" stroke={color} stroke-width="2" stroke-miterlimit="10"
                  d="M26 48V20m0 1h5.95s10.08-.72 10.08 8-10.08 8-10.08 8H22m0 5h11"/>
        </svg>);
}
