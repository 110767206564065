import React, { Children } from 'react';

const penaltyListStyle = {
  listStyle: 'none',
  padding: 0,
  margin: 0
};

const PenaltyList = ({ children, t }) => {
  if (Children.count(children) === 0) {
    return 'У вас нет штрафов';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ fontSize: 18, fontWeight: 600 }}>
          {t ? t('penalty:list') : 'Список Штрафов'}
        </span>
        <span>
          {t ? t('penalty:found') : 'Найдено'}: {children.length}
        </span>
      </p>
      <ul style={penaltyListStyle}>
        {Children.map(children, child => (
          <li>{child}</li>
        ))}
      </ul>
    </div>
  );
};

export default PenaltyList;
