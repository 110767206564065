import React from "react";
import Standart from "./Standart";
import NoBalance from "./NoBalance";
import MapStore from "../../../Stores/MapStore";
import { observer } from "mobx-react";
import SettingsStore from "./../../../Stores/SettingsStore/index";

@observer
class Parking extends React.Component {
  componentDidMount() {
    MapStore.loadData();
  }

  render() {
    if (SettingsStore.settings.dontUseBalance) {
      return <NoBalance />;
    }

    return <Standart />;
  }
}

export default Parking;
