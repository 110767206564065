import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import i18n from '../../i18n/i18n';

import { Container, Row, Col, NavItem, Nav } from 'reactstrap';
import NavLink from '../Common/NavLink';

import { observer } from 'mobx-react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import apple from '@fortawesome/fontawesome-free-brands/faApple';
import android from '@fortawesome/fontawesome-free-brands/faAndroid';
import phone from '@fortawesome/fontawesome-free-solid/faPhone';
import envelope from '@fortawesome/fontawesome-free-solid/faEnvelope';

import './Footer.css';
import OptionsStore from '../../Stores/OptionsStore';
import PathStore from '../../Stores/PathStore';

import isMobile from '../../utils/isMobile';
import UIStore from '../../Stores/UIStore';
import UrlManager from '../../utils/UrlManager';

@translate(['footer'], { wait: true })
@observer
class Footer extends Component {
  static propTypes = {
    ixed: PropTypes.bool,
    tel: PropTypes.string,
    menuItems: PropTypes.object,
    email: PropTypes.string,
    noMenu: PropTypes.bool,
  };

  static defaultProps = {
    menuItems: [],
    noMenu: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      lng: i18n.language,
    };

    this.footer = createRef();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      lng: i18n.language,
    });
  }

  componentDidMount() {
    UIStore.setFooterHeight(this.footer.current.offsetHeight);
    this.initEvents();
  }

  componentDidUpdate() {
    this.initFooterHeightOnResize();
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  initEvents = () => {
    window.addEventListener('resize', this.initFooterHeightOnResize);
  };

  removeEvents = () => {
    window.removeEventListener('resize', this.initFooterHeightOnResize);
  };

  initFooterHeightOnResize = () => {
    if (!OptionsStore.fetching && this.footer.current) {
      UIStore.setFooterHeight(this.footer.current.offsetHeight);
    } else UIStore.setFooterHeight(0);
  };

  renderAppIcons = () => {
    return (
      <Col md={3} className="footer__apps-container">
        <div>
          {/*eslint-disable-next-line*/}
          {typeof OptionsStore.appLinks.iosLink !== 'undefined' &&
          OptionsStore.appLinks.iosLink ? (
            <a
              href={OptionsStore.appLinks.iosLink}
              className="apps"
              title="Apple Store "
            >
              <FontAwesomeIcon icon={apple} />
            </a>
          ) : null}

          {/*eslint-disable-next-line*/}
          {typeof OptionsStore.appLinks.androidLink !== 'undefined' &&
          OptionsStore.appLinks.androidLink ? (
            <a
              href={OptionsStore.appLinks.androidLink}
              className="apps"
              title="Google Play"
            >
              <FontAwesomeIcon icon={android} />
            </a>
          ) : null}

          {/*eslint-disable-next-line*/}
          {typeof OptionsStore.appLinks.yandexStoreLink !== 'undefined' &&
          OptionsStore.appLinks.yandexStoreLink ? (
            <a
              href={OptionsStore.appLinks.yandexStoreLink}
              className="apps"
              title="Яндекс Store"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 208.7 209.15"
                width={18}
                height={18}
              >
                <g style={{ transform: 'translateY(20px)' }}>
                  {/*eslint-disable-next-line*/}
                  <path
                    className="icon-store"
                    d="M.23,104.69c0-24.33.52-48.67-.2-73C-.37,18.22,10.29.52,30.74.26,67.39-.22,104,.11,140.7.13c5.73,0,9.77,3.09,12.58,7.74q26.54,44,52.88,88c3.5,5.87,3.31,12.15-.2,18q-26.08,43.68-52.28,87.29c-3.27,5.45-7.89,7.92-14.47,7.89-35.65-.19-71.31-.41-107,0-18.42.23-32.82-15.51-32.24-32C.86,153,.23,128.85.23,104.69ZM88.34,135l32.18-44.21H99V51.46H77.06V91.13H56.31Zm-56.11,2.21c0,5.89.23,11.37-.08,16.82-.23,4,1.27,5.11,5.17,5.08,19.14-.17,38.28-.08,57.43-.08,15,0,30,.07,44.94-.11,1.51,0,4.25-1.52,4.31-2.46.38-6.4.2-12.83.2-19.06H132.06v8.39H45.18v-8.58Z"
                  />
                </g>
              </svg>
            </a>
          ) : null}

          {typeof OptionsStore.appLinks.apkLink !== 'undefined' &&
          OptionsStore.appLinks.apkLink ? (
            <a
              href={OptionsStore.appLinks.apkLink}
              className="apps"
              style={{ display: 'inline-flex' }}
              title="Android .apk"
            >
              <img
                width={18}
                height={18}
                alt="yandex store"
                src={'/static/media/icons/apk-file-format-symbol.svg'}
              />
            </a>
          ) : null}
        </div>
      </Col>
    );
  };

  // Render methods
  renderMenu = () => {
    const { menuItems, noMenu } = this.props;
    if (noMenu) {
      return <Col md={6} className="footer-column" />;
    }

    return (
      <Col md={6} className="footer-column">
        <Nav>
          {menuItems.map((item, index) => {
            return (
              <NavItem className="menu-item-footer" key={index}>
                {UrlManager.isDifferentDomain(item.path) ? (
                  <a
                    className="nav-link"
                    href={item.path}
                    target="_blank"
                    key={index}
                  >
                    {OptionsStore.getCurrentLocaleTitle(item.titles)}
                  </a>
                ) : (
                  <NavLink
                    to={item.path}
                    className={'nav-link'}
                    activeClassName={'active'}
                    exact
                  >
                    {OptionsStore.getCurrentLocaleTitle(item.titles)}
                  </NavLink>
                )}
              </NavItem>
            );
          })}
        </Nav>
      </Col>
    );
  };

  renderAboutUs = () => {
    return (
      <Fragment>
        <Col className="footer__about-us" md={3}>
          <Row>
            <div className="footer__phone">
              <FontAwesomeIcon className="footer__icon" icon={phone} />
              <span>{this.renderPhoneNumber()}</span>
            </div>
            <div>
              <FontAwesomeIcon className="footer__icon" icon={envelope} />
              <a href={`mailTo:${this.props.email}`} style={{ color: 'white' }}>
                {this.props.email}
              </a>
            </div>
          </Row>
        </Col>
      </Fragment>
    );
  };

  renderPhoneNumber() {
    const { tel } = this.props;

    return (
      <a href={`tel:${tel}`} style={{ color: 'white' }}>
        {tel}
      </a>
    );
  }

  render() {
    const { fixed } = this.props;
    const fixedClass = fixed ? 'fixed' : '';
    const footerClasses = `footer ${fixedClass}`;
    return (
      <footer className={footerClasses} id="footer" ref={this.footer}>
        <Container fluid>
          <Row className="footer-content">
            {this.renderAboutUs()}
            {!OptionsStore.fetching ? this.renderMenu() : null}
            {this.renderAppIcons()}
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
