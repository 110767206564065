import React, { Fragment, Component, Children, createRef } from "react";

class Scroll extends Component {
  static defaultProps = {
    offset: 250,
    onScroll: () => null,
    loader: null,
    isNeedToHandle: false,
    className: ""
  };

  constructor() {
    super();
    this.state = {
      isHandling: false
    };

    this.elementHeightWScroll = 0;
    this.scrollHeight = 0;
    this.scroll = createRef();
  }

  componentDidMount() {
    this.calculateHeight(this.scroll.current);
  }

  calculateHeight(element) {
    const { scrollHeight, scrollTop, offsetHeight, clientTop } = element;

    const rightClientTop = clientTop < 0 ? 0 : clientTop;
    const elementOffsets = offsetHeight - rightClientTop;

    this.elementHeightWScroll = scrollHeight - elementOffsets;
    this.scrollHeight = scrollTop;

    return {
      elementHeightWScroll: this.elementHeightWScroll,
      scrollHeight: this.scrollHeight
    };
  }

  isScrolledToOffset() {
    const { offset } = this.props;

    if (this.scrollHeight) {
      return this.elementHeightWScroll - this.scrollHeight <= offset;
    }
    return false;
  }

  handleScroll = async event => {
    const { isNeedToHandle, onScrollToOffset } = this.props;
    const { isHandling } = this.state;

    const element = event.target;
    this.calculateHeight(element);

    if (isNeedToHandle && this.isScrolledToOffset() && !isHandling) {
      this.setState({ isHandling: true });
      await onScrollToOffset();
      this.setState({ isHandling: false });
    }
  };

  content() {
    const { children } = this.props;

    if (Children.count(children) === 1) {
      return children;
    }

    return <div>{children}</div>;
  }

  loader() {
    const { isHandling } = this.state;
    const { loader } = this.props;
    if (isHandling) {
      return loader;
    }

    return null;
  }

  render() {
    const { className } = this.props;
    return (
      <Fragment>
        <div
          ref={this.scroll}
          className={`scroll ${className}`}
          onScroll={this.handleScroll}
        >
          {this.content()}
        </div>
        {this.loader()}
      </Fragment>
    );
  }
}

export default Scroll;
