import React from 'react';
import { observer } from 'mobx-react';

import SettingsStore from '../../../../../Stores/SettingsStore';
import FeedbackStore from '../../../../../Stores/FeedbackStore';
import UserStore from '../../../../../Stores/UserStore';

import Feedback from '../View/Feedback';

@observer
class FeedbackContainer extends React.Component {
  handleSubmit = async (values) => {
    return await FeedbackStore.sendFeedback(values);
  };

  render() {
    return (
      <Feedback
        user={UserStore.getProfile()}
        topics={SettingsStore.settings.topics}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default FeedbackContainer;
