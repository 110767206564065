import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Config from "../../../../Config/index";
import { getPlatform, getOS } from "../../../../utils/bowser";

import RegistrationStore from "../../../../Stores/RegistrationStore";

import "./style/index.css";

class OpenInAppButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      onConfirmEmail: !!(
        props.location.pathname.indexOf("confirmation/email") + 1
      )
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      onConfirmEmail: !!(
        nextProps.location.pathname.indexOf("confirmation/email") + 1
      )
    });
  }

  render() {
    return this.renderButton();
  }

  renderButton() {
    const button = (
      <div className={this.getClass()}>
        <div className="open-in-app__close" onClick={this.handleCloseClick}>
          X
        </div>
        <div className="open-in-app__icon" />
        <div className="open-in-app__body">
          <div className="open-in-app__title">Парковочное пространство</div>
          <div className="open-in-app__description">
            Пракуйтесь в городе воронеже
          </div>
        </div>
        <div className="open-in-app-btn" onClick={this.handleClick}>
          Открыть
        </div>
      </div>
    );

    return getPlatform() === "desktop" ? null : button;
  }

  handleClick = () => {
    this.timer = setTimeout(this.openWebApp, 1000);
    window.location.replace(
      `${Config.appsLink.app}${
        this.state.onConfirmEmail
          ? `regCode=${RegistrationStore.emailCode}`
          : ""
      }`
    );
  };

  handleCloseClick = () => {
    this.setState({
      isHidden: true
    });
  };

  getClass = () => {
    return `open-in-app ${this.state.isHidden ? "open-in-app--hidden" : ""}`;
  };

  openWebApp = () => {
    if (getOS() === "Android")
      window.location.replace(Config.appsLink.playMarket);
    else window.location.replace(Config.appsLink.itunes);
  };
}

export default withRouter(OpenInAppButton);
