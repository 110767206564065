import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';
import SelectCity from '../SelectCity';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CityStore from '../../Stores/CityStore';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

@translate(['auth'], { wait: true })
@observer
class SelectCityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: null
    };
  }

  render() {
    return (
      <Modal
        autoFocus={false}
        className=""
        isOpen={CityStore.modalIsOpen}
        toggle={CityStore.toggleModal}
      >
        <ModalHeader
          style={{
            display: 'flex',
            padding: '0 10px 0 10px',
            justifyContent: 'flex-end'
          }}
        >
          <FontAwesomeIcon
            style={{ fontSize: 16, cursor: 'pointer' }}
            icon={faTimes}
            onClick={CityStore.toggleModal}
          />
        </ModalHeader>
        <ModalBody>
          <SelectCity />
        </ModalBody>
      </Modal>
    );
  }
}

export default SelectCityModal;
