import UserStore from './UserStore';

class CarNumberStore {
  TYPES = {
    STANDART: 'standart',
    // TRAILER: 'trailer',
    TRANSIT: 'transit',
    TAXI: 'taxi',
    MOTO: 'moto',
    MVD_STANDATR: 'mvdStandart',
    MVD_TRAILER: 'mvdTrailer',
    MVD_MOTO: 'mvdMoto'
  };

  numberTemplates = {
    [this.TYPES
      .STANDART]: /^([АВЕКМНОРСТУХавекмнорстух])(\d{3})([АВЕКМНОРСТУХавекмнорстух]{2})(\d{2,3}$)/i,
    // [this.TYPES.TRAILER]: /^([АВЕКМНОРСТУХавекмнорстух]{2})(\d{4})(\d{2,3}$)/i,
    [this.TYPES
      .TRANSIT]: /^([АВЕКМНОРСТУХавекмнорстух]{2})(\d{3})([АВЕКМНОРСТУХавекмнорстух]{1})(\d{2,3}$)/i,
    [this.TYPES.TAXI]: /^([АВЕКМНОРСТУХавекмнорстух]{2})(\d{3})(\d{2,3}$)/i,
    [this.TYPES.MOTO]: /^(\d{4})([АВЕКМНОРСТУХавекмнорстух]{2})(\d{2,3}$)/i,
    [this.TYPES.MVD_MOTO]: /^(\d{4})([АВЕКМНОРСТУХавекмнорстух]{1})(\d{2,3}$)/i,
    [this.TYPES.MVD_STANDATR]: /^([АВЕКМНОРСТУХавекмнорстух]{1})(\d{4})(\d{2,3}$)/i,
    [this.TYPES.MVD_TRAILER]: /^(\d{3})([АВЕКМНОРСТУХавекмнорстух]{1})(\d{2,3}$)/i
  };

  numberTemplatesWithoutRegion = {
    [this.TYPES.STANDART]: /^([АВЕКМНОРСТУХавекмнорстух])(\d{3})([АВЕКМНОРСТУХавекмнорстух]{2})/i,
    // [this.TYPES.TRAILER]: /^([АВЕКМНОРСТУХавекмнорстух]{2})(\d{4})/i,
    [this.TYPES.TRANSIT]: /^([АВЕКМНОРСТУХавекмнорстух]{2})(\d{3})([АВЕКМНОРСТУХавекмнорстух]{1})/i,
    [this.TYPES.MOTO]: /^(\d{4})([АВЕКМНОРСТУХавекмнорстух]{2})/i,
    [this.TYPES.TAXI]: /^([АВЕКМНОРСТУХавекмнорстух]{2})(\d{3})/i,
    [this.TYPES.MVD_STANDATR]: /^([АВЕКМНОРСТУХавекмнорстух]{1})(\d{4})/i,
    [this.TYPES.MVD_TRAILER]: /^(\d{3})([АВЕКМНОРСТУХавекмнорстух]{1})/i,
    [this.TYPES.MVD_MOTO]: /^(\d{4})([АВЕКМНОРСТУХавекмнорстух]{1})/i
  };

  validateNumberByType(number) {
    for (let key in this.TYPES) {
      const type = this.TYPES[key];

      if (this.numberTemplates[type].test(number)) {
        return {
          isValid: true,
          type
        };
      }
    }

    return {
      isValid: false,
      type: ''
    };
  }

  isCategoryInSyncWithType(type, category) {
    if (!category) {
      return false;
    }

    category = category.toUpperCase();
    const typeIsMoto = type === this.TYPES.MOTO || type === this.TYPES.MVD_MOTO;

    const inSyncWithMoto = typeIsMoto && category === 'A';
    const inSyncWithCar = !typeIsMoto && category !== 'A';

    if (inSyncWithMoto || inSyncWithCar) {
      return true;
    } else false;
  }

  validateNumberByTypeWithoutRegion(number) {
    for (let key in this.TYPES) {
      const type = this.TYPES[key];

      if (this.numberTemplatesWithoutRegion[type].test(number)) {
        return {
          isValid: true,
          type
        };
      }
    }

    return {
      isValid: false,
      type: ''
    };
  }

  getParts(split) {
    let number = [];
    let region = split[split.length - 1];

    for (let i = 1; i < split.length - 1; i++) {
      number.push(split[i]);
    }

    return {
      number: number.join(''),
      region
    };
  }

  getTypeOfNumber(number) {
    return this.validateNumberByType(number).type;
  }

  getTypeOfNumberWithoutRegion(number) {
    const result = this.validateNumberByTypeWithoutRegion(number).type;
    return result;
  }

  getType(type) {
    if (type.indexOf('mvd') !== -1) {
      return 'mvd';
    }

    if (type.indexOf('taxi') !== -1) {
      return 'taxi';
    }

    return '';
  }

  getFormatGrz(numberFormat) {
    const russianFormatEn = UserStore.GRZ_FORMATS_EN.RUSSIA;
    const foreignFormatEn = UserStore.GRZ_FORMATS_EN.FOREIGN;
    const russianFormat = UserStore.GRZ_FORMATS.RUSSIA;

    if (numberFormat === russianFormat || !numberFormat) {
      return russianFormatEn;
    } else {
      return foreignFormatEn;
    }
  }
}

export default new CarNumberStore();
