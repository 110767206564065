import OBJECT_TYPES from '../../../constants/objectTypes';
import L from 'leaflet';
import { toJS } from 'mobx';

export function isParkingMarker(marker) {
  return (
    marker.options &&
    marker.options.feature &&
    marker.options.feature.properties &&
    marker.options.feature.properties.objecttype === OBJECT_TYPES.PARKINGS
  );
}

export function findAllParkings(markerClusterGroup) {
  let allMarkers = [];

  markerClusterGroup._featureGroup.eachLayer(function(x) {
    if (x instanceof L.MarkerCluster) {
      const allMarkersInCluster = x.getAllChildMarkers();
      const onlyParkingsMarkers = allMarkersInCluster.filter(marker => isParkingMarker(marker));
      allMarkers.push(...onlyParkingsMarkers);
    } else {
      if (isParkingMarker(x)) {
        allMarkers.push(x);
      }
    }
  });

  return allMarkers;
}

/**
 * @param {Object} point - Marker of current position
 * @param {Object[]} searchAmong - Array of parking markers
 */
export function findNearestToPoint(point, searchAmong) {
  const pointLatLng = point.getLatLng();
  let minId = 0;
  let minValue = Number.POSITIVE_INFINITY;
  let latLng = [];

  searchAmong.forEach((marker, i) => {
    const coords = toJS(marker.geometry.coordinates);
    const latLngArr = createLatlngsFromArr(coords);

    const bound = L.latLngBounds(...latLngArr);
    const boundCenter = bound.getCenter();

    const distance = pointLatLng.distanceTo(boundCenter);

    if (distance < minValue) {
      minValue = distance;
      minId = i;
      latLng = boundCenter;
    }
  });

  return latLng;
}

export function findOnly(type, data) {
  return data.filter(item => item.properties.objecttype === type);
}

export function findMarkerByLatLng(latlnt, markers) {
  return markers.find(marker => latlnt.distanceTo(marker.getLatLng()) < 5);
}

function createLatlngsFromArr(coordsgArr) {
  return coordsgArr.map((coord, index) => {
    return coord instanceof Array ? L.latLng(coord) : L.latLng(...coordsgArr);
  });
}
