const OPERATION_TYPES_FILTERS = {
  ALL: 'all',
  PAYMENT: 'payment',
  REFILL: 'refill',
  RENEW: 'renew',
  CANCEL: 'cancel',
  SUBSCRIPTION: 'paymentSubscription',
  PAYMENTGATE: 'paymentGate',
  BONUS20: 'bonus20',
};

const OPERATION_TYPES_FILTERS_RU = {
  PAYMENT: 'Оплата',
  REFILL: 'Пополнение',
  RENEW: 'Продление',
  CANCEL: 'Прерывание',
  SUBSCRIPTION: 'ОплатаПарковочногоРазрешения',
  BONUS20: 'НачислениеБонусов',
};

const PAYMENTSTATUSES_RU = {
  done: 'Выполнен',
  cancel: 'Отменен',
  error: 'Ошибка',
  waiting: 'ОжиданиеПлатежа',
};

const PAYMENTSTATUSES_MAP_TO_EN = {
  [PAYMENTSTATUSES_RU.done]: 'done',
  [PAYMENTSTATUSES_RU.cancel]: 'cancel',
  [PAYMENTSTATUSES_RU.error]: 'error',
  [PAYMENTSTATUSES_RU.waiting]: 'waiting',
};

export {
  OPERATION_TYPES_FILTERS,
  OPERATION_TYPES_FILTERS_RU,
  PAYMENTSTATUSES_RU,
  PAYMENTSTATUSES_MAP_TO_EN,
};
