const en = {
  title: 'Report on the parking account for the period from {{from}} to {{to}}',
  footer: 'The report is generated {{now}} by the information system \n"Парковочное пространство"'
};

const ru = {
  title: 'Отчет по парковочному счету за период с {{from}} до {{to}}',
  footer: 'Отчет сформирован {{now}} средствами информационной \nсистемы "Парковочное пространство"'
};

export default {
  en,
  ru
};
