class DOMManipulations {
  constructor(body) {
    this.body = body;
  }

  removeFirstTimeLoader() {
    const body = document.body;
    const firstTimeLoader = body.querySelector('#first-time-loader');

    if (firstTimeLoader) {
      body.removeChild(firstTimeLoader);
    }
  }
}

export default DOMManipulations;
