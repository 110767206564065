
import React from 'react';

export default ({ fill, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 584 574">
            <g>
                <g fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="25">
                    <ellipse className='parking-outline' cx="292" cy="287" rx="279.5" ry="274.5"/>
                    <path className='parking-icon' d="M263.13 392.49v-211h58.49a50.58 50.58 0 0 1 39.08 18c18.79 22.79 15.61 58-4.42 77.67-9.44 9.27-21.51 15.49-34.86 15.49H265" strokeLinecap="round"/>
                </g>
            </g>
        </svg>
    );
}



