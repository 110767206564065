import React from 'react';

import Loader from '../../Components/Loader';
import Loadable from 'react-loadable';

const LoadableCoopProfile = Loadable.Map({
  loader: {
    CoopProfile: () => import('../CoopProfile')
  },
  render(loaded, props) {
    const CoopProfile = loaded.CoopProfile.default;

    return <CoopProfile {...props} />;
  },
  loading: Loader,
  delay: 400 // 0.3 seconds
});

export default LoadableCoopProfile;
