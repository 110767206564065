import React, { Component, Children } from 'react';

import TranslationsStore from '../../../Stores/TranslationsStore';

class ForeignNumber extends Component {
  render() {
    const { number, className, children } = this.props;
    const {
      transports: { foreign }
    } = TranslationsStore.getProfiletTranslation();

    return (
      <div className={`state-number state-number--foreign ${className}`}>
        {number}
        {children}
        <span className="state-number__description">{foreign}</span>
      </div>
    );
  }
}

export default ForeignNumber;
