import React from 'react';
import { DropdownMenu } from 'reactstrap';

import './style/index.css';

function DropdownMenuMaterial (props) {
    const { children, className, ...rest} = props;

    return (
        <DropdownMenu className={`material-dropdown-menu ${className}`} {...rest} >
            { children }
        </DropdownMenu>
    );
} 

export default DropdownMenuMaterial;
