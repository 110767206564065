import React from 'react';
import { APP_MODAL_BANNER_REDIRECT_URL } from '../../../Config';

const ImageModal = ({ href, imgSrc, closeModal, className = '' }) => {
  return (
    <a href={href} target="_blank" onClick={closeModal}>
      <img
        src={imgSrc}
        className={`image-modal__image ${className}`}
        style={{ width: '100%', display: 'block' }}
      />
    </a>
  );
};

export default ImageModal;
