const Token = {
  getTokens: function() {
    if (!window.localStorage.getItem('tokens')) {
      window.localStorage.setItem('tokens', JSON.stringify({}));
    }
    return window.localStorage.getItem('tokens');
  },

  getTokenForCity: function(city, type) {
    if (window.localStorage.getItem('tokens')) {
      let tokens = window.localStorage.getItem('tokens');

      tokens = JSON.parse(tokens);
      return tokens[city] && tokens[city][type];
    }
  },

  setTokenForCity: function(city, token, type) {
    let tokens = this.getTokens();
    tokens = JSON.parse(tokens);

    if (token === null) {
      if (tokens[city] && tokens[city][type]) delete tokens[city];
    } else {
      tokens[city] = {
        ...tokens[city],
        [type]: token
      };
    }
    this.saveTokens(tokens);
  },

  saveTokens: function(tokens) {
    window.localStorage.setItem('tokens', JSON.stringify(tokens));
  }
};

export default Token;
