import React from "react";
import PenaltyList from "../View/PenaltyList";
import PenaltyItem from "../View/PenaltyItem";
import PenaltyStore from "../../../../Stores/PenaltyStore";
import { observer } from "mobx-react";
import TwitchLoader from "../../../../Components/Common/TwitchLoader";
import { translate } from "react-i18next";

@translate(["penalty"], { wait: true })
@observer
class PenlatyContainer extends React.Component {
  componentDidMount() {
    PenaltyStore.loadPenalty();
  }

  handlePenaltyClick(uin) {
    PenaltyStore.loadPenaltyPicture(uin);
  }

  render() {
    if (PenaltyStore.fetching) {
      return <TwitchLoader />;
    }

    return (
      <PenaltyList t={this.props.t}>
        {PenaltyStore.penalty.map(penalty => (
          <PenaltyItem
            t={this.props.t}
            fineStatus={penalty.fineStatus}
            uin={penalty.uin}
            date={penalty.date}
            totalSum={penalty.totalSum}
            paidSum={penalty.paidSum}
            sts={penalty.sts}
            grz={penalty.grz}
            start={penalty.start}
            end={penalty.end}
            place={penalty.place}
            type={penalty.type}
            provider={penalty.provider}
            key={penalty.uin}
            color={penalty.color}
            onClick={this.handlePenaltyClick}
            imgSrc={penalty.imgSrc}
          />
        ))}
      </PenaltyList>
    );
  }
}

export default PenlatyContainer;
