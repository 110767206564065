import React from 'react';
import Loader from 'react-loader-spinner';

function TableLoader() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5
  };

  return (
    <div style={style}>
      <Loader type="Puff" height="50" width="50" />
    </div>
  );
}

export default TableLoader;
