import { observable, action } from 'mobx';

import ApiProvider from '../APIProvider/CoopProfileApi';
import Token from '../../utils/Token';
import CityStore from '../CityStore';
import LoginStore from '../LoginStore';
import UserStore, { defaultTs } from '../UserStore';

import TextFormatter from '../../utils/textFormatting';

class CoopUser {
  USER_TYPE = 'coop';

  defaultStatus = {
    isLoading: false,
    done: false,
    error: false
  };

  defaultProfile = {
    ...this.defaultStatus,
    ts: [],
    drivers: [],
    devices: []
  };

  @observable
  profile = { ...this.defaultProfile };

  @observable
  operations = [];

  @observable
  isOperationsLoading = false;

  @observable
  isOperationsDone = false;

  @observable token = null;

  @action
  removeOperations() {
    this.operations = [];
  }

  @action
  addOperations(operations) {
    this.operations = [...this.operations, ...UserStore.normalizeOperations(operations)];
  }

  setProfile(profile) {
    this.profile = this.normalizeProfile(profile);
    this.profile.done = true;
    this.profile.isLoading = false;
  }

  @action.bound
  loadProfileIfNeeded() {
    if (this.isLoggedIn && !this.profile.done && !this.profile.isLoading) {
      this.profile.isLoading = true;
      this.updateProfile();
      // this.loadBalance();
    }
  }
  @action
  async loadBalance() {
    if (this.profile.balance !== null) {
      const newBalance = await ApiProvider.getBalance();

      this.profile = {
        ...this.profile,
        balance: newBalance
      };
    }
  }

  @action
  updateProfile = async () => {
    this.profile.isLoading = true;
    this.profile.done = false;

    const profile = await ApiProvider.getProfile();
    if (profile.status === 'ok') {
      this.profile = {
        ...this.profile,
        ...this.normalizeProfile(profile),
        isLoading: false,
        done: true,
        error: false
      };
    } else {
      this.profile = {
        ...this.profile,
        isLoading: false,
        done: false,
        error: true
      };

      LoginStore.logOutCoop();
    }
  };

  normalizeProfile(profile) {
    const profileResult = profile.result;
    return {
      email: profileResult['Email'],
      inn: profileResult['ИНН'],
      name: profileResult['Наименование'],
      balance: profileResult['СуммаОстаток'] | 0,
      // telephones: profileResult['Телефоны'],
      ts: UserStore.normalizeTs(profileResult['ТС']),
      drivers: this.normalizeDrivers(profileResult['Водители']),
      devices: this.normalizeDevices(profileResult['Устройства'])
    };
  }

  normalizeDrivers(drivers = []) {
    return drivers.map((driver, id) => ({
      name: driver['Водитель'],
      phone: driver['Телефон'],
      ts: driver['ТС'],
      device: driver['УстройстваПлатежа'],
      active: driver['Активен'] || false,
      id
    }));
  }

  normalizeDevices(devices = []) {
    return devices.map((device, id) => ({
      active: device['СтатусУстройства'] == 'Не активно' || false,
      imei: device['IMEI'],
      vin: device['VIN'],
      id: String(id)
    }));
  }

  @action
  setToken(token) {
    this.token = token;
    Token.setTokenForCity(CityStore.zone, token, this.USER_TYPE);
  }

  isLoggedIn() {
    const token = Token.getTokenForCity(CityStore.zone, this.USER_TYPE);
    this.token = token;

    return token && token !== null && token !== 'null';
  }

  logOut = () => {
    this.setToken(null);
    this.clear();
  };

  clear = () => {
    this.profile = this.defaultProfile;
  };

  get drivers() {
    return this.profile.drivers;
  }

  get driversNames() {
    return this.profile.drivers.map(driver => driver.name);
  }

  getDriverName(name) {
    const splitName = name.split(' ');
    if (splitName.length == 2) {
      return {
        firstName: splitName[1],
        secondName: splitName[0],
        patronym: ''
      };
    }

    return {
      firstName: splitName[1],
      secondName: splitName[0],
      patronym: splitName[2]
    };
  }

  get ts() {
    return this.profile.ts;
  }

  @action
  async toggleActivate(from, item, handler, activeStatus) {
    const response = await handler(item, this.profile.inn);
    if (response.status === 'ok') {
      from[item.id] = {
        ...from[item.id],
        active: activeStatus
      };
    }

    return response;
  }

  @action
  activateDriver = async driver => {
    return await this.toggleActivate(
      this.profile.drivers,
      driver,
      ApiProvider.activateDriver,
      true
    );
  };

  @action
  deActivateDriver = async driver => {
    return await this.toggleActivate(
      this.profile.drivers,
      driver,
      ApiProvider.deActivateDriver,
      false
    );
  };

  @action
  activateDevice = async device_n => {
    const devices = this.profile.devices;
    const matched_device = devices.find(
      device => device.iemi == device_n.device || device.vin == device_n.device
    );

    if (device_n.numberTs) {
      const response = await this.toggleActivate(
        this.profile.ts,
        { ...matched_device, ...device_n },
        ApiProvider.activateDevice,
        true
      );

      if (response.status === 'ok') {
        const tsList = this.profile.ts.map(ts => {
          if (ts.numberTs == device_n.numberTs) return { ...ts, active: true };
          return ts;
        });

        this.profile.ts = [...tsList];
      }
      return response;
    }
  };

  @action
  deActivateDevice = async device_n => {
    const response = await ApiProvider.deActivateDevice(device_n, this.profile.inn);

    if (response.status === 'ok') {
      this.profile.ts = this.profile.ts.map(ts => {
        if (device_n.numberTs === ts.numberTs) {
          ts.active = false;
        }
        return ts;
      });
    }

    return response;
  };

  @action
  addDriver = async driver => {
    driver.name = driver.name;
    driver.id = this.profile.drivers.length;

    const response = await ApiProvider.addDriver(driver);
    if (response && response.status === 'ok') {
      this.profile.drivers = [driver, ...this.profile.drivers];
    }

    return response;
  };

  @action
  editDriver = async editedDriver => {
    const name = TextFormatter.joinName(editedDriver);
    editedDriver.name = editedDriver.name;

    const response = await ApiProvider.editDriver(
      this.profile.drivers[editedDriver.id],
      editedDriver
    );

    if (response.status === 'ok') {
      this.profile.drivers[editedDriver.id] = editedDriver;
    }

    return response;
  };

  @action
  deleteDriver = async deleteDriver => {
    const newDrivers = this.profile.drivers.filter(driver => {
      return driver.id !== deleteDriver.id;
    });

    const response = await ApiProvider.removeDriver(deleteDriver);

    if (response.status === 'ok') {
      this.profile.drivers = newDrivers;
    }

    return response;
  };

  isTsExists(tsn) {
    const vehicles = this.profile.ts;

    return vehicles.some(vehicle => {
      if (tsn.id !== vehicle.id) {
        return vehicle.numberTs.toUpperCase() === tsn.numberTs.toUpperCase();
      } else return false;
    });
  }

  isDeviceExists(deviceNew) {
    const devices = this.profile.devices;

    return devices.some(device => {
      if (deviceNew.id !== device.id) {
        return device.vin === deviceNew.vin || device.imei === deviceNew.imei;
      } else {
        return false;
      }
    });
  }

  isDeviceExistsInCars(deviceNew) {
    const cars = this.profile.ts;

    return cars.some(car => {
      return car.vin === deviceNew.vin || car.imei === deviceNew.imei;
    });
  }

  @action
  addTs = async newTs => {
    if (!this.isTsExists(newTs)) {
      newTs = {
        id: this.profile.ts.length,
        numberTs: newTs.numberTs.toUpperCase(),
        formatGrz: /^(?!\d+$)[a-zA-Z\d\s]+$/.test(newTs.numberTs)
          ? UserStore.GRZ_FORMATS.FOREIGN
          : UserStore.GRZ_FORMATS.RUSSIA,
        ...newTs
      };

      const preparedTsArr = UserStore.prepareForSaveOne(newTs);

      const response = await ApiProvider.addCar(preparedTsArr);
      if (response.status === 'ok') {
        this.profile.ts = [newTs, ...this.profile.ts];
      }
      return response;
    } else {
      return {
        status: 'error',
        message: 'Данное транспортное средство уже существует'
      };
    }
  };

  @action
  editTs = async (id, ts) => {
    const isTsNotExist = !this.isTsExists(ts);

    let oldPlace = 0;
    const oldTs = this.profile.ts.find((currentTs, index) => {
      oldPlace = index;
      return currentTs.id === ts.id;
    });
    if (isTsNotExist) {
      const newTs = {
        ...oldTs,
        numberTs: ts.numberTs.toUpperCase(),
        vin: ts.vin,
        imei: ts.imei
      };

      let newTsArr = [...this.profile.ts];
      newTsArr[oldPlace] = { ...newTs };
      const preparedTsArr = UserStore.prepareForSaveOneV2(newTs);
      const preparedOldTs = UserStore.prepareForSaveOneV2(oldTs);
      const response = await ApiProvider.changeCar(preparedTsArr, preparedOldTs);

      if (response.status === 'ok') {
        this.profile.ts = [...newTsArr];
      }

      return response;
    } else {
      return {
        status: 'error',
        message: !isTsNotExist
          ? 'Данное транспортное средство уже существует'
          : 'Данное устройство уже занято'
      };
    }
  };

  @action
  deleteTs = async id => {
    const newTs = this.profile.ts.filter(ts => ts.id !== id);
    const preparedTsArr = UserStore.prepareForSave(newTs);
    const response = await ApiProvider.changeCars(preparedTsArr);

    if (response.status === 'ok') {
      this.profile.ts = this.profile.ts.filter(ts => ts.id !== id);
    }

    return response;
  };

  @action
  addDevice = async newDevice => {
    if (!this.isDeviceExists(newDevice)) {
      const newDeviceArr = [newDevice, ...this.profile.devices];

      const response = await this.activateDevice(newDevice);

      if (response.status === 'ok') {
        this.profile.devices = [...newDeviceArr];
      }
      return response;
    } else {
      return {
        status: 'error',
        message: 'Данное отслеживающее устройство уже существует'
      };
    }
  };

  @action
  editDevice = async editedDevice => {
    const response = await ApiProvider.editDevice(
      this.profile.devices[editedDevice.id],
      editedDevice
    );

    if (response.status === 'ok') {
      this.profile.devices[editedDevice.id] = editedDevice;
    }

    return response;
  };

  @action
  deleteDevice = async deviceNew => {
    const response = await ApiProvider.deleteDevice(deviceNew);

    if (response.status === 'ok') {
      this.profile.devices = this.profile.devices.filter(device => device.id !== deviceNew.id);
    }

    return response;
  };

  @action
  removeToken() {
    this.setToken(null);
  }
}

export default new CoopUser();
