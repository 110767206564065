import Moment from "moment/moment";
/**
 *@description Возвращает разницу во времени
 *             между настоящим и полученным
 */

export const minutesToHour = minutes => {
  if (!minutes) {
    return "Без времени";
  }

  if (minutes === 1440) {
    return "24:00";
  }

  var h = (minutes / 60) | 0,
    m = minutes % 60 | 0;
  return Moment.utc()
    .hours(h)
    .minutes(m)
    .format("HH:mm");
};

const getDifferenteTime = endTimeOfParking => {
  const times = ["hours", "minutes"];

  const now = Moment();
  const endTime = Moment(endTimeOfParking);

  const minutes = Moment(Moment(endTime).diff(Moment(now)));

  if (Moment(endTime).valueOf() - Moment(now).valueOf() >= 0) {
    return {
      hours: endTime.diff(now, times[0]),
      minutes: minutes.format("mm")
    };
  }

  return {
    hours: 0,
    minutes: 0
  };
};

export default getDifferenteTime;
