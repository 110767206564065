import React, { Component } from "react";
import { translate } from "react-i18next";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import sync from "@fortawesome/fontawesome-free-solid/faSync";

@translate(["registrationForm"], { wait: true })
class SendAganeButton extends Component {
  constructor() {
    super();
    this._refreshTimer = null;
    this._refreshCodeStartValue = 30;

    this.state = {
      _refreshTimerValue: 10
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { t } = this.props;

    const timerVisible = this.state._refreshTimerValue > 0;
    const sentRefreshLabel = this._getNoun(
      this.state._refreshTimerValue,
      t("registrationForm:seconds:one"),
      t("registrationForm:seconds:two"),
      t("registrationForm:seconds:five")
    );

    return timerVisible ? (
      <div>
        {t("registrationForm:steps:step2:sendCodeAgain:message")}
        <strong> {this.state._refreshTimerValue} </strong> {sentRefreshLabel}
      </div>
    ) : (
      <a className="code-refresh" onClick={this._handleRefreshCode}>
        <FontAwesomeIcon icon={sync} />
        <span className="link">
          {t("registrationForm:steps:step2:sendCodeAgain:button")}
        </span>
      </a>
    );
  }

  _handleRefreshCode = async event => {
    const { onRefresh } = this.props;
    event.preventDefault();

    onRefresh();

    this.setState({ _refreshTimerValue: 10 });
  };

  tick() {
    this.setState(state => ({
      _refreshTimerValue: state._refreshTimerValue - 1
    }));

    if (this.state._refreshTimerValue - 1 <= 0) {
      this.setState({ _refreshTimerValue: -1 });
      clearInterval(this._refreshTimer);

      this._refreshTimer = null;
    }
  }

  _getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  };
}

export default SendAganeButton;
