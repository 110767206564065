import React from 'react';

import Title from './Title';
import Description from './Description';
import ExampleItemBr from './ExampleItemBr';
import text from './text';
import { CITY_NAME } from '../../Config';

function SectionPayment() {
  const city = CITY_NAME;
  return (
    <section className="update-page__payment">
      <Title className="update-page__title">Оплата парковки</Title>
      <Description className="example">
        <span className="black">{text[city].prefix}</span> *
        <span className="orange mg-l">Номер парковки</span> *
        <span className="blue">полный номер автомобиля</span> *
        <span className="pink">количество часов</span>
      </Description>
      <Description>
        <p>Пример оплаты за 2 часа:</p>
        <div className="update-page__example">
          <ExampleItemBr>{text[city].prefix}</ExampleItemBr>*
          <ExampleItemBr color="orange">1001</ExampleItemBr>*
          <ExampleItemBr color="blue">А123АА92</ExampleItemBr>*
          <ExampleItemBr color="pink">2</ExampleItemBr>
        </div>
      </Description>
    </section>
  );
}

export default SectionPayment;
