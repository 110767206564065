import React, { Component } from "react";

import InputMask from "react-input-mask";
import FontAwesome from "@fortawesome/react-fontawesome";
import eye from "@fortawesome/fontawesome-free-solid/faEye";
import eyeSlash from "@fortawesome/fontawesome-free-solid/faEyeSlash";
import PropTypes from "prop-types";

export default class Input extends Component {
  _inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;

    this.state = this.initialState = {
      labelClass: "",

      text: "",
      empty: true,
      error: false,
      errorText: null,
      passwordEyeSlash: false
    };
  }

  setLabelClass = className => {
    this.setState({ labelClass: className });
  };

  componentDidMount() {
    const input = this._inputRef.current;

    if (input instanceof HTMLElement) {
      input.addEventListener("input", this.handleChange);
    }

    this.checkDefaultValue();
  }

  componentWillUnmount() {
    const input = this._inputRef.current;
    if (input instanceof HTMLElement) {
      input.removeEventListener("input", this.handleChange);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    this.checkDefaultValue();

    this.forceUpdate();
  }

  render() {
    const { type, mask } = this.props;

    if (type === "checkbox") {
      return this._renderCheckBox();
    }

    if (type === "tel" || !!mask) {
      return this._renderTel();
    }

    return this._renderDefaultInput();
  }

  _renderCheckBox = () => {
    let {
      id,
      label,
      type,
      onBlur,
      onFocus,
      containerStyle,
      required,
      passwordEye,
      trim,
      atLeastOne,
      disabled,

      ...rest
    } = this.props;

    const inputIdProp = id !== null ? { id } : {};

    let { labelClass, error, errorText, empty } = this.state;
    const errorClass = error ? " error" : "";
    errorText = error && errorText && !empty ? errorText : <span>&nbsp;</span>;

    return (
      <div className={`md-checkbox${errorClass}${disabled ? ' md-checkbox--disabled': ''}`} style={containerStyle}>
        <input
          type={type}
          {...inputIdProp}
          ref={this._inputRef}
          onFocus={event => {
            this._handleFocus();
            onFocus(event);
          }}
          onBlur={event => {
            this._handleBlur(event);
            onBlur(event);
          }}
          className="md-input"
          {...rest}
          value={this.state.value}
          onChange={this.handleChange}
        />

        {id !== null ? (
          <label htmlFor={id} className={`md-label${labelClass}`}>
            {label}
          </label>
        ) : null}

        <div
          className="md-error"
          style={{ position: "absolute", whiteSpace: "nowrap" }}
        >
          {errorText}
        </div>
      </div>
    );
  };

  _renderTel = () => {
    let {
      id,
      label,
      type,
      onBlur,
      onFocus,
      icon,
      containerStyle,
      mask,
      required,
      passwordEye,
      trim,
      atLeastOne,

      ...rest
    } = this.props;

    const inputIdProp = id !== null ? { id } : {};

    let { labelClass, error, errorText, empty } = this.state;
    const errorClass = error ? " error" : "";
    errorText = error && errorText && !empty ? errorText : <span>&nbsp;</span>;

    if (type === "tel" && !mask) {
      mask = "+7 (999) 999 - 99 - 99";
    }

    return (
      <div
        className={`md-form-group${errorClass}${!!icon ? " with-icon" : ""}`}
        style={containerStyle}
      >
        {!!icon ? (
          <div className="md-icon">
            <FontAwesome icon={icon} />
          </div>
        ) : null}
        <InputMask
          mask={mask}
          ref={this._inputRef}
          type={type === "tel" ? type : "text"}
          {...inputIdProp}
          onFocus={event => {
            this._handleFocus();
            onFocus(event);
          }}
          onBlur={event => {
            this._handleBlur(event);
            onBlur(event);
          }}
          value={this.state.value}
          onChange={this.handleChange}
          className="md-input"
          {...rest}
        />

        {id !== null ? (
          <label htmlFor={id} className={`md-label${labelClass}`}>
            {label}
          </label>
        ) : null}

        <div className="md-error">{errorText}</div>
      </div>
    );
  };

  _renderDefaultInput = () => {
    let {
      id,
      label,
      type,
      onBlur,
      onFocus,
      icon,
      containerStyle,
      required,
      passwordEye,
      trim,
      atLeastOne,

      ...rest
    } = this.props;

    const inputIdProp = id !== null ? { id } : {};

    let { labelClass, error, errorText, empty } = this.state;
    const errorClass = error ? " error" : "";
    errorText = error && errorText && !empty ? errorText : <span>&nbsp;</span>;
    return (
      <div
        className={`md-form-group${errorClass}${!!icon ? " with-icon" : ""}`}
        style={containerStyle}
      >
        {!!icon ? (
          <div className="md-icon">
            <FontAwesome icon={icon} />
          </div>
        ) : null}
        {passwordEye ? (
          <div style={{ width: "100%" }}>
            <input
              autoComplete="off"
              type={this.state.passwordEyeSlash ? "text" : type}
              {...inputIdProp}
              ref={this._inputRef}
              style={{ width: !!icon ? "calc(100% - 40px)" : "100%" }}
              onFocus={event => {
                this._handleFocus();
                onFocus(event);
              }}
              onBlur={event => {
                this._handleBlur(event);
                onBlur(event);
              }}
              value={this.state.value}
              onChange={this.handleChange}
              className="md-input"
              {...rest}
            />
            <div
              style={{
                position: "absolute",
                display: "inline-block",
                top: 7,
                right: 0
              }}
            >
              <FontAwesome
                onClick={() =>
                  this.setState({
                    passwordEyeSlash: !this.state.passwordEyeSlash
                  })
                }
                icon={this.state.passwordEyeSlash ? eyeSlash : eye}
              />
            </div>
          </div>
        ) : (
          <input
            autoComplete="off"
            type={type}
            {...inputIdProp}
            ref={this._inputRef}
            style={{ width: !!icon ? "calc(100% - 40px)" : "100%" }}
            onFocus={event => {
              this._handleFocus();
              onFocus(event);
            }}
            onBlur={event => {
              this._handleBlur(event);
              onBlur(event);
            }}
            value={this.state.value}
            onChange={this.handleChange}
            className="md-input"
            {...rest}
          />
        )}
        {id !== null ? (
          <label htmlFor={id} className={`md-label${labelClass}`}>
            {label}
          </label>
        ) : null}

        <div className="md-error">{errorText}</div>
      </div>
    );
  };

  _handleFocus = () => {
    this.setState({
      labelClass: " active"
    });
  };

  _handleBlur = event => {
    const inputValue = event.target.value;

    if (inputValue.length === 0) {
      this.setState({
        labelClass: ""
      });

      return;
    }

    this.setState({
      labelClass: " active"
    });
  };

  handleChange = event => {
    const { onChange, trim } = this.props;

    if (trim) {
      event.target.value = event.target.value.trim();
    }

    onChange(event);

    this.setState({
      text: event.target.value
    });

    if (event.target.value) {
      this.setLabelClass(" active");
    } else this.setLabelClass("");
  };

  get value() {
    if (this.props.type === "checkbox" || this.props.type === "radio") {
      return this._inputRef.current
        ? this._inputRef.current.checked
        : undefined;
    }
    return this._inputRef.current ? this._inputRef.current.value : undefined;
  }

  getValue() {
    return this._inputRef.current ? this._inputRef.current.value : "";
  }

  get name() {
    return this.props.name;
  }

  get type() {
    return this.props.type;
  }

  get required() {
    return this.props.required;
  }

  clear = () => {
    if (this.props.type === "checkbox" || this.props.type === "radio") {
      if (this._inputRef.current) {
        this._inputRef.current.checked = false;
      }
    } else {
      this._inputRef.current.value = "";
    }

    this.checkDefaultValue();
  };

  checkDefaultValue(comingDefault = null) {
    const defaultValue = comingDefault
      ? comingDefault
      : this.props.defaultValue;
    if (!!defaultValue || !!this.props.value) {
      return this.setLabelClass(" active");
    }

    if (defaultValue && this._inputRef.current instanceof HTMLElement) {
      this._inputRef.current.value = defaultValue;
    }
  }

  clear = () => {
    this.setState(this.initialState);

    this.checkDefaultValue();
  };

  focus = () => {
    // if (this._inputRef.current) {
    //   this._inputRef.current.focus();
    // }
  };

  setErrorState = (isValid = true, description) => {
    this.setState({
      empty: false,
      error: !isValid,
      errorText: description
    });
  };

  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.object,
    type: PropTypes.string,
    required: PropTypes.bool,
    containerStyle: PropTypes.any,
    mask: PropTypes.string,
    passwordEye: PropTypes.bool,
    trim: PropTypes.bool
  };

  static defaultProps = {
    id: null,
    name: "",
    icon: null,
    label: "",
    type: "text",
    required: false,
    containerStyle: {},
    mask: null,
    defaultValue: "",
    passwordEye: false,
    onBlur: () => null,
    onFocus: () => null,
    onChange: () => null,
    trim: false
  };
}
