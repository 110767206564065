import React from 'react';

import Title from './Title';
import Description from './Description';
import text from './text';
import { CITY_NAME } from '../../Config';

function SectionCancel() {
  const city = CITY_NAME;
  return (
    <section className="update-page--cancel">
      <Title>Закончить парковочную сессию</Title>
      <Description className="example">
        <span className="black">{text[city].prefix}</span>*<span className="orange mg-l">с</span>
      </Description>
    </section>
  );
}

export default SectionCancel;
