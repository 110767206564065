import React from "react";
import { NoBalance } from "../View";
import MapStore from "../../../../../Stores/MapStore";
import { observer } from "mobx-react";
import UserStore from "../../../../../Stores/UserStore";
import PaymentStore from "../../../../../Stores/PaymentStore";
import SettingsStore from "./../../../../../Stores/SettingsStore/index";
import { translate } from "react-i18next";
import Payment from "../../../../../Components/ProfileComponents/Payment";
import ProfileParkingStore from "../../../../../Stores/ProfileParkingStore";

@translate(["header"], { wait: true })
@observer
class NoBalanceContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      costData: null,
      modalOpen: false
    };

    this.modalData = {
      duration: null,
      transport: null,
      sale: null,
      parkingCode: null,
      postPaymentStartTime: null,
      zone: {
        name: null
      }
    };
  }

  componentDidMount() {
    MapStore.loadZonesIfNeeded();
    UserStore.loadProfileIfNeeded();
  }

  componentWillUnmount() {
    ProfileParkingStore.parkingCode = null;
  }

  getNotParkedVehicle() {
    return UserStore.getTs().filter(transport => {
      return !UserStore.isParked(transport.numberTs);
    });
  }

  handleNobalanceSubmit = async formValues => {
    if (formValues.type === "today") {
      const result = await PaymentStore.startNoBalanceSession(
        formValues.vehicle,
        formValues.parking
      );
      return result;
    }

    this.modalData = {
      duration: formValues.duration,
      transport: formValues.vehicle.numberTs,
      sale: this.state.costData.sumSale,
      parkingCode: formValues.parking.code,
      postPaymentStartTime: formValues.postStart,
      cost: this.state.costData.price,
      zone: {
        name: formValues.parking.zone.zonenumber
      }
    };
    this.setState({ modalOpen: true });
  };

  handleClosePaymentModal = async (completed, zone) => {
    this.setState({ modalOpen: false });
    completed && (await UserStore.updateAll());
  };

  handleChange = async values => {
    if (values.type === "post") {
      if (this.state.costData) {
        this.setState({ costData: null });
      }

      if (values.duration && values.parking && values.vehicle) {
        const response = await PaymentStore.costOfSession({
          zone: values.parking.zone.zonenumber,
          transport: values.vehicle.numberTs,
          duration: values.duration,
          parkingCode: !SettingsStore.settings.paymentByParkingId
            ? ""
            : values.parking.code,
          timestart:
            values.type === "post" && values.postStart ? values.postStart : null
        });
        if (response && response.status !== "error") {
          this.setState({ costData: response });
        }
      }
    }

    if (values.type === "today") {
      if (this.state.costData) {
        this.setState({ costData: null });
      }
      if (values.parking && values.vehicle) {
        const response = await PaymentStore.costOfSession({
          zone: values.parking.zone.zonenumber,
          transport: values.vehicle.numberTs,
          duration: 60,
          parkingCode: !SettingsStore.settings.paymentByParkingId
            ? ""
            : values.parking.code,
          timestart:
            values.type === "post" && values.postStart ? values.postStart : null
        });

        if (response && response.status !== "error") {
          this.setState({ costData: response });
        }
      }
    }

    return;
  };

  render() {
    const { t } = this.props;
    const parkings = MapStore.getParkingsForStandartForm();

    return (
      <>
        <Payment
          isOpen={this.state.modalOpen}
          actionType={"start"}
          data={this.modalData}
          onSubmit={this.handleClosePaymentModal}
          onExit={this.handleClosePaymentModal}
          costData={
            this.state.costData
              ? this.state.costData.costData
              : this.state.costData
          }
        />
        <div className="main-wrapper">
          <NoBalance
            t={t}
            onChange={this.handleChange}
            onSubmit={this.handleNobalanceSubmit}
            parkings={parkings}
            durations={SettingsStore.getTimeintervals()}
            ts={this.getNotParkedVehicle()}
            costData={this.state.costData}
            inProcess={PaymentStore.isCostInProcess}
            initialValues={{
              parking: parkings.find(
                parking => ProfileParkingStore.parkingCode === parking.code
              )
            }}
          />
        </div>
      </>
    );
  }
}

export default NoBalanceContainer;
