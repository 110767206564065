import React, { Fragment } from 'react';

import { Row, Col } from 'reactstrap';
import FieldPreLoader from '../../../Components/FieldPreLoader';

function Loading(props) {
  const { isLoading, children } = props;

  if (isLoading) {
    return (
      <Fragment>
        {[1, 2, 3].map(element => {
          return (
            <Row noGutters key={'holder-' + element} style={{ marginLeft: -10 }}>
              <Col xs={3}>
                <FieldPreLoader visible style={{ margin: 10 }} />
              </Col>
              <Col xs={1}>
                <FieldPreLoader visible style={{ margin: 10 }} />
              </Col>
              <Col xs={2}>
                <FieldPreLoader visible style={{ margin: 10 }} />
              </Col>
              <Col xs={2}>
                <FieldPreLoader visible style={{ margin: 10 }} />
              </Col>
              <Col xs={4}>
                <FieldPreLoader visible style={{ margin: 10 }} />
              </Col>
            </Row>
          );
        })}
      </Fragment>
    );
  }

  return children;
}

export default Loading;
