import { observable, action } from 'mobx';
import APIProvider from '../Stores/APIProvider';
import UserStore from '../Stores/UserStore';
import CoopUser from '../Stores/CoopUser';

import CoopProfileApi from '../Stores/APIProvider/CoopProfileApi';
import { IS_EASY_LOGIN } from '../Config';

class LoginStore {
  errorMessages = {
    emptyPass: 'EmptyPass',
    incorrectLogin: 'IncorrectLogin'
  };

  @observable status = 'waiting';
  @observable error = {};
  @observable captchaBase64 = null;

  @action
  logIn = async (email, password, captchaCode = null) => {
    let response = null;
    this.captchaBase64 = null;
    try {
      response = IS_EASY_LOGIN
        ? await APIProvider.loginV5(email, password, captchaCode)
        : await APIProvider.loginV4(email, password, captchaCode);
      this.status = response.status;
      if (response.captcha) {
        this.captchaBase64 = response.captcha;
      }
      if (response.status === 'error') {
        this.error = response.errorName ? response.errorName : null;
        this.handleErrorResponse(response);
      }
    } catch (e) {
      this.status = 'Request error';
    }

    if (this.status === 'ok') {
      CoopUser;
      return response.id_session;
    }

    return null;
  };

  @action
  coopLogIn = async (inn, contractumber, captchaCode = null) => {
    let response = null;
    this.captchaBase64 = null;
    try {
      response = await CoopProfileApi.auth(inn, contractumber);
      this.status = response.status;
      if (response.captcha) {
        this.captchaBase64 = response.captcha;
      }
      if (response.status === 'error') {
        this.error = response.errorName ? response.errorName : null;
        this.handleErrorResponse(response);
      }
    } catch (e) {
      this.status = 'Request error';
    }

    if (this.status === 'ok') {
      CoopUser.setProfile(response);
      return response.id_session;
    }

    return null;
  };

  handleErrorResponse(error) {
    switch (error.errorName) {
      case this.errorMessages.emptyPass:
        this.status = this.errorMessages.emptyPass;
        break;
      case this.errorMessages.incorrectLogin:
        this.status = 'error';
        break;
      default:
        break;
    }
  }

  @action
  reset = () => {
    this.status = 'waiting';
    this.error = {};
  };

  logOut = () => {
    UserStore.removeToken();
    UserStore.clear();
  };

  logOutCoop = () => {
    CoopUser.removeToken();
    CoopUser.clear();
  };

  setTokenToUser(token) {
    LoginStore.setTokenToUser(token);
    UserStore.setToken(token);
  }

  setProfileToUser(profile) {
    UserStore.setProfile(profile);
  }
}

export default new LoginStore();
