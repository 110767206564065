import React from 'react';
import FormControl from '@material-ui/core/FormControl';

const CustomFormControl = props => {
  return (
    <FormControl
      {...props}
      style={{
        marginBottom: 15,
        ...props.style
      }}
    />
  );
};

export default CustomFormControl;
