import React, { Component } from 'react';

import { observer } from 'mobx-react';

import { withRouter } from 'react-router-dom';

import { Dropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import MaterialDropdownMenu from '../../Components/Common/DropdownMenuMaterial';
import './style.css';

import PathStore from '../../Stores/PathStore';

import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import moment from 'moment/moment';
import i18n from 'i18next';

@observer
class ChangeLanguage extends Component {
  render() {
    const { dropdowns } = this.props;

    return (
      <Dropdown
        className={this.props.className}
        style={{ alignSelf: 'center' }}
        isOpen={dropdowns.dropdownOpenLanguage}
        toggle={this.toggleLanguage}
      >
        <DropdownToggle
          color={'warning'}
          className="reset-style-btn lng__toggle"
        >
          <div className="lng__title">
            {capitalizeFirstLetter(i18n.language)}
          </div>
        </DropdownToggle>
        {this.renderLanguageMenu()}
      </Dropdown>
    );
  }

  renderLanguageMenu() {
    return (
      <MaterialDropdownMenu className="lng" right>
        {this.renderLanguageOptions()}
      </MaterialDropdownMenu>
    );
  }

  renderLanguageOptions() {
    return ['ru', 'en'].map((item, index) => {
      if (i18n.language !== item)
        return (
          <DropdownItem
            color="primary"
            onClick={() => this.changeLanguage(item)}
            key={index}
          >
            {capitalizeFirstLetter(item)}
          </DropdownItem>
        );

      return null;
    });
  }

  changeLanguage = (lng) => {
    lng === 'en' ? moment.locale('en') : moment.locale(lng);

    i18n.changeLanguage(lng);

    this.props.history.push('/');
  };

  toggleLanguage = () => {
    this.props.toggleDropDown('Language');
  };
}

export default withRouter(ChangeLanguage);
