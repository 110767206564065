import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'react-datepicker/dist/react-datepicker.min.css';

import { SectionBlock } from '../../../Components/ProfileComponents';
import { Row, Col } from 'reactstrap';

import ExportDropDown from '../../../Components/OperationsComponents/ExportDropDown';
@observer
export default class Operations extends Component {
  static defaultProps = {
    position: 'center',
    className: 'profile-operations',
    isLoading: false,
    isAllDataLoaded: false,
    md: 12,
    lg: 9,
  };

  getPosition() {
    const { position } = this.props;

    switch (position) {
      case 'center':
        return 'justify-content-center';
      default:
        return '';
    }
  }

  _renderContent = () => {
    const { filter, table, isAllLoaded, isLoading } = this.props;

    return (
      <Row>
        <Col>
          <Row noGutters>
            <Col lg={3} md={4} sm={5} xs={12} className="filters">
              {filter}
              <div className="info-field">
                <ExportDropDown
                  isAllDataLoaded={isAllLoaded}
                  disabled={isLoading || !isAllLoaded}
                />
              </div>
            </Col>
            <Col className="operations__container" lg={9} md={8} sm={7} xs={12}>
              {table}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const { title, className, isDataForFilterLoaded, md, lg } = this.props;

    return (
      <Row
        noGutters
        style={{ flex: 1 }}
        className={`${className} ${this.getPosition()}`}
      >
        <Col md={md} lg={lg}>
          <div className="md-card">
            <SectionBlock title={title} isLoading={isDataForFilterLoaded}>
              <div className="main-wrapper">{this._renderContent()}</div>
            </SectionBlock>
          </div>
        </Col>
      </Row>
    );
  }
}
