import React, { Component, createRef } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getIn } from "formik";

class FormikAutoSelect extends Component {
  handleChange = (_, value) => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;
    setFieldValue(name, value);
  };

  render() {
    const {
      label,
      clearOnEscape = true,
      field: { onBlur, name, value },
      form: { touched, errors, isSubmitting },
      ...rest
    } = this.props;

    const isFieldTouched = !!getIn(touched, name);
    const error = getIn(errors, name);
    const showError = !!error && isFieldTouched;

    return (
      <Autocomplete
        id={name}
        onChange={this.handleChange}
        value={value}
        clearOnEscape={clearOnEscape}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            style={{ marginBottom: 10 }}
            fullWidth
            error={showError}
            onBlur={onBlur}
            helperText={showError ? error : rest.helperText}
            disabled={rest.disabled ? rest.disabled : isSubmitting}
          />
        )}
        {...rest}
      />
    );
  }
}

export default FormikAutoSelect;
