import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import resources from './resources';

const lngs = ['ru', 'en'];
i18n.use(reactI18nextModule).init({
  fallbackLng: lngs,
  resources
});

i18n.languages = lngs;

export default i18n;
