const penaltyEng = {
  price: 'Price',
  uin: 'UIN',
  article: 'Article',
  place: 'Place',
  offenseDate: 'Offense date',
  rulingDate: 'Ruling date',
  orderNumber: 'Order number',
  found: 'Found',
  list: 'Fines list',
  fineStatus: 'Fine status',
  date: 'Date and time of the ruling',
  totalSum: 'Amount of penalty',
  paidSum: 'Amount of payment',
  sts: 'Vehicle registration certificate',
  grz: 'Vehicle',
  start: 'Parking start date and time',
  end: 'Parking end date and time',
  type: 'Type of violation',
  provider: 'Source of the fine'
};
const penaltyRu = {
  price: ' Цена',
  uin: 'УИН',
  article: 'Статья КоАП',
  offenseDate: 'Дата правонарушения',
  rulingDate: 'Дата постановления',
  orderNumber: 'Номер постановления',
  found: 'Найдено',
  list: 'Список штрафов',
  fineStatus: 'Статус постановления',
  date: 'Дата и время вынесения постановления',
  totalSum: 'Сумма штрафа',
  paidSum: 'Сумма оплаты',
  sts: 'Свидетельство о регистрации ТС',
  grz: 'Транспортное средство',
  start: 'Дата и время начала парковки',
  end: 'Дата и время окончания парковки',
  place: 'Место нарушения',
  type: 'Тип нарушения',
  provider: 'Источник штрафа'
};

export default { en: { ...penaltyEng }, ru: { ...penaltyRu } };
