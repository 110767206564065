import React from 'react';

import { Button } from 'reactstrap';

import CoopUserStore from '../../../../Stores/CoopUser';

import { withRouter } from 'react-router-dom';

function CoopLogoutBtn ({ history, className }) {
    function handleLogout () {
        CoopUserStore.logOut();
        history.push('/');
    }

    return (
        <Button
            color="primary"
            className={`${className} btn-small-round logout`}
            onClick={handleLogout}
        >
            Выход
        </Button>
    );
}

export default withRouter(CoopLogoutBtn);
